import constant from './constant'
import { parseRes } from './parseResponse'
import * as storageConfig from './storageConfig'

const baseUrl = `${constant.baseUrl}/api/auth`

export function login(username, password) {
  const url = `${baseUrl}/login`
  const payload = {
    username,
    password,
  }
  return new Promise((resolve, reject) => {
    fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(payload),
    })
      .then(parseRes) // Transform the data into json
      .then(function (data) {
        resolve(data)
      })
      .catch((err) => {
        reject(err)
      })
  })
}
export function logout() {
  const url = `${baseUrl}/logout`

  return new Promise((resolve, reject) => {
    fetch(url, {
      method: 'POST',
      headers: {
        authorization: storageConfig.getToken(),
        'Content-Type': 'application/json',
      },
    })
      .then(parseRes) // Transform the data into json
      .then(function (data) {
        resolve(data)
      })
      .catch((err) => {
        reject(err)
      })
  })
}
