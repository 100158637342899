const Joi = require('joi')

/**
 *
 * @param {array} list - data
 * @param {array} header - initial header
 * @param {string} searchText - search text
 */
export const filter = (list, header, searchText) => {
  searchText = searchText.toLowerCase()
  return list.filter((datum) => {
    for (let i = 0; i < header.length; i++) {
      let value = datum[header[i].key]
      if (!value) continue
      if (header[i].type === 'picture') continue

      if (header[i].type !== 'select') {
        if (~value.toString().toLowerCase().trim().indexOf(searchText))
          return true
      } else if (
        ~header[i].select[value]
          .toString()
          .toLowerCase()
          .trim()
          .indexOf(searchText)
      ) {
        return true
      }
    }
  })
}

/**
 *  sort data for component table
 * @param {array} list - data
 * @param {array} header - initial header
 * @param {boolean} ascOrder - search text
 */
export const sort = (list, key, ascOrder, orderedHeader) => {
  if (['select'].indexOf(orderedHeader.type) === -1) {
    return [...list].sort((_a, _b) => {
      let a = _a[key].toString().toLowerCase(),
        b = _b[key].toString().toLowerCase()

      if (a < b) return ascOrder ? -1 : 1
      if (b < a) return ascOrder ? 1 : -1
      return 0
    })
  } else {
    return [...list].sort((_a, _b) => {
      let a = orderedHeader.select[_a[key]].toString().toLowerCase(),
        b = orderedHeader.select[_b[key]].toString().toLowerCase()

      if (a < b) return ascOrder ? -1 : 1
      if (b < a) return ascOrder ? 1 : -1
      return 0
    })
  }
}

/**
 *
 * @param {array} list - list of data
 * @param {object} updateRow - new object
 */
export const updateRow = (list, updateRow) => {
  return list.map((datum) => {
    if (datum.id !== updateRow.id) return datum
    return { ...updateRow }
  })
}

/**
 *
 * @param {object} rowData - data of table
 * @param {object} columnHeader - specific header
 * @param {boolean} editMode - if table is edited
 *
 * @returns {boolean} - return if row is editable
 */
export const isCellEditable = (rowData, columnHeader, editMode) => {
  return (
    editMode !== tableEditMode.NOT_EDITABLE &&
    (columnHeader.editable ||
      (columnHeader.getEditable && columnHeader.getEditable(rowData)))
  )
}

export const tableEditMode = Object.freeze({
  NOT_EDITABLE: 0,
  TABLE: 1,
  ROW: 2,
})

/**
 *
 * @param {array} list
 * @param {list} header
 * @param {string} searchText
 * @param {string} orderKey
 * @param {boolean} ascOrder
 */
export const getDisplayedData = (
  list,
  header,
  searchText,
  orderKey,
  ascOrder,
) => {
  let displayedData = !searchText.length
    ? list
    : filter(list, header, searchText)

  if (orderKey) {
    const orderedHeader = header.filter((h) => h.key === orderKey)[0]
    displayedData = sort(displayedData, orderKey, ascOrder, orderedHeader)
  }

  return displayedData
}

export const validateHeader = (headerModel) => {
  const headerObjSchema = Joi.object({
    key: Joi.string().required(),
    name: Joi.string().required(),
    classe: Joi.string(),
    getEditable: Joi.function(),
    editable: Joi.boolean().default(false).when('getEditable', {
      is: Joi.exist(),
      then: Joi.forbidden(),
    }),
    sortable: Joi.boolean().default(false),
    defaultPicture: Joi.string(),
    type: Joi.string()
      .valid(
        'select',
        'text',
        'number',
        'checkbox',
        'customize',
        'switch',
        'picture',
      )
      .required()
      .default('text'),
    select: Joi.object().when('type', {
      is: Joi.valid('select'),
      then: Joi.required(),
    }),
    isOptionDisabled: Joi.function(),
    getCell: Joi.function().when('type', {
      is: Joi.valid('customize'),
      then: Joi.required(),
    }),
  })

  const schema = Joi.array().items(headerObjSchema.required())

  const result = schema.validate(headerModel)

  if (result.error) {
    debugger
    console.error('Incorrect header in table')
    return false
  }

  return true
}

export class Table {
  constructor(list, headers) {
    if (!validateHeader(headers)) return

    this.list = list || []
    this.headers = headers || []
  }

  updateRow(other) {
    this.list = this.list.map((d) => {
      if (d.id !== other.id) return d
      return { ...other }
    })
  }
}
