import {
  Checkbox,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  SingleSelect,
  TextField,
} from '@valerahealth/ui-components/base'
import React, { useEffect, useState } from 'react'
import AddInputField from '../../../../../common/AddInputField/AddInputField'
import IconButton from '../../../../../common/IconButton/IconButton'
import './AppointmentNotifications.less'

const typeEnum = {
  email: 'Email',
  sms: 'SMS',
  app: 'App',
}

const unitEnum = {
  days: 'Days',
  hours: 'Hours',
}

const AppointmentNotifications = ({ notifications, handleChange }) => {
  const [appointmentNotifications, setAppointmentNotifications] =
    useState(notifications)

  {
    /* 
              notifications: Joi.array().items({
                enabled: Joi.boolean().required(),
                appointmentType: Joi.string().allow("N/A").required(),
                reminders: Joi.array().items({
                  notificationType: Joi.array().items(Joi.string().valid("email", "sms", "app")).unique().min(1).required(),
                  text: Joi.string().required(),
                  time: Joi.object({
                    when: Joi.string().valid("before", "after").required(),
                    value: Joi.number().integer().min(1).required(),
                    unit: Joi.string().valid("hours", "days").required()
                  }).required()
                }).min(1).required()
              }).unique("type").required()
              */
  }

  useEffect(() => {
    handleChange(appointmentNotifications)
  }, [appointmentNotifications])

  const addNotifications = () => {
    setAppointmentNotifications([
      ...appointmentNotifications,
      {
        enabled: true,
        appointmentType: 'N/A',
        reminders: [
          {
            notificationType: [],
            text: '',
            time: { when: 'before', value: 24, unit: 'hours' },
          },
        ],
      },
    ])
  }

  const removeNotification = (index) => {
    setAppointmentNotifications(
      appointmentNotifications.filter((notification, i) => {
        return i !== index
      }),
    )
  }

  const addReminder = (index) => {
    setAppointmentNotifications(
      appointmentNotifications.map((notif, i) => {
        if (i !== index) return notif
        return {
          ...notif,
          reminders: [
            ...notif.reminders,
            {
              notificationType: [],
              text: '',
              time: { when: 'before', value: 24, unit: 'hours' },
            },
          ],
        }
      }),
    )
  }

  const removeReminder = (notificationIndex, reminderIndex) => {
    setAppointmentNotifications(
      appointmentNotifications.map((notification, y) => {
        if (y !== notificationIndex) return notification
        return {
          ...notification,
          reminders: notification.reminders.filter((rem, z) => {
            return reminderIndex !== z
          }),
        }
      }),
    )
  }

  const updateNotification = (notificationIndex, notification) => {
    setAppointmentNotifications(
      appointmentNotifications.map((n, y) => {
        if (y !== notificationIndex) return n
        return notification
      }),
    )
  }

  const updateReminder = (notificationIndex, reminderIndex, reminder) => {
    setAppointmentNotifications(
      appointmentNotifications.map((notification, y) => {
        if (y !== notificationIndex) return notification
        return {
          ...notification,
          reminders: notification.reminders.map((rem, z) => {
            if (reminderIndex !== z) return rem
            return reminder
          }),
        }
      }),
    )
  }

  return (
    <div className="appointment-reminder">
      <div>Reminder</div>
      <div className="reminder-message">
        <p>
          * You can use these dynamic variables ($day, $time, $timezone) to
          format the reminder message. Appointments are shown in patient
          timezone. If patient timezone does not exists, it will use the program
          timezone.
        </p>
        <p>$day - full day name (Sunday, Monday, etc.)</p>
        <p>
          {`$time - hour and minutes in this format: "HH:mm" (04:00, 12:30, etc)`}
        </p>
        <p>
          $timezone - timezone in this format (i.e. Pacific Time, Eastern Time,
          Mountain Time)
        </p>
      </div>
      {!appointmentNotifications.length && (
        <AddInputField text="Add Notification" onClick={addNotifications} />
      )}
      {appointmentNotifications.map((notification, i) => {
        return (
          <div key={i} className="appointment-notification">
            <Grid container justifyContent="space-between">
              <Grid item>{notification.appointmentType}</Grid>
              <IconButton
                type="close"
                onClick={() => {
                  removeNotification(i)
                }}
              />
            </Grid>
            <FormControlLabel
              control={
                <Checkbox
                  checked={notification.enabled}
                  onChange={(event) => {
                    updateNotification(i, {
                      ...notification,
                      enabled: event.target.checked,
                    })
                  }}
                />
              }
              label="Active Appointment Reminder"
            />
            <AddInputField
              disable={!notification.enabled}
              text="Add reminder"
              onClick={() => {
                addReminder(i)
              }}
            />
            {notification.reminders.map((reminder, z) => {
              return (
                <div key={z} className="reminder">
                  <InputLabel
                    sx={{ width: '60px' }}
                    htmlFor={`reminder-time-${z}`}
                  >
                    Time
                  </InputLabel>
                  <TextField
                    sx={{ width: '50px', minWidth: '50px', margin: '0 10px' }}
                    type="number"
                    InputProps={{
                      inputProps: {
                        min: 1,
                      },
                    }}
                    id={`reminder-time-${z}`}
                    variant="outlined"
                    label="Value"
                    disabled={!notification.enabled}
                    value={reminder.time.value}
                    onChange={(event) => {
                      updateReminder(i, z, {
                        ...reminder,
                        time: {
                          ...reminder.time,
                          value: event.target.value,
                        },
                      })
                    }}
                  />
                  <SingleSelect
                    formControlProps={{
                      sx: {
                        width: '110px',
                        minWidth: '110px',
                        marginRight: '10px',
                      },
                    }}
                    labelId="demo-simple-select-label"
                    id={`reminder-type-${z}`}
                    value={reminder.time.unit}
                    disabled={!notification.enabled}
                    label="Time"
                    onChange={(event) => {
                      updateReminder(i, z, {
                        ...reminder,
                        time: {
                          ...reminder.time,
                          unit: event.target.value,
                        },
                      })
                    }}
                  >
                    {['days', 'hours'].map((a, y) => {
                      return (
                        <MenuItem key={y} value={a}>
                          {unitEnum[a]}
                        </MenuItem>
                      )
                    })}
                  </SingleSelect>
                  <InputLabel
                    sx={{ width: '60px' }}
                    htmlFor={`reminder-type-${z}`}
                  >
                    Type
                  </InputLabel>
                  <SingleSelect
                    formControlProps={{
                      sx: {
                        width: '110px',
                        minWidth: '110px',
                        marginRight: '10px',
                      },
                    }}
                    labelId="demo-simple-select-label"
                    id={`reminder-type-${z}`}
                    value={reminder.notificationType}
                    disabled={!notification.enabled}
                    label="Type"
                    onChange={(ntValue) => {
                      updateReminder(i, z, {
                        ...reminder,
                        notificationType: [ntValue],
                      })
                    }}
                  >
                    {['email', 'sms', 'app'].map((a, x) => {
                      return (
                        <MenuItem key={x} value={a}>
                          {typeEnum[a]}
                        </MenuItem>
                      )
                    })}
                  </SingleSelect>
                  <InputLabel
                    sx={{ width: '50px' }}
                    htmlFor={`reminder-text-${z}`}
                  >
                    Text
                  </InputLabel>
                  <TextField
                    sx={{ flexGrow: 1 }}
                    label="Text"
                    fullWidth
                    variant="outlined"
                    id={`reminder-text-${z}`}
                    value={reminder.text}
                    disabled={!notification.enabled}
                    onChange={(event) => {
                      updateReminder(i, z, {
                        ...reminder,
                        text: event.target.value,
                      })
                    }}
                  />
                  <IconButton
                    type="close"
                    onClick={() => {
                      removeReminder(i, z)
                    }}
                  />
                </div>
              )
            })}
          </div>
        )
      })}
    </div>
  )
}
export default AppointmentNotifications
