import * as types from '../actions/actionsType'
import initializeState from './initializeState'

export default function tenantReducer(state = initializeState.tenants, action) {
  switch (action.type) {
    case types.INITIALIZE_APP_STATE: {
      return initializeState.tenants
    }
    case types.INITIALIZE_TENANTS_LIST: {
      return action.tenants
    }
    case types.ADD_NEW_TENANT: {
      return [...state, action.tenant]
    }
    case types.INITIALIZE_PROGRAMS_LIST: {
      return state.map((tenant) => {
        if (tenant.id === action.tenantId) {
          return { ...tenant, programs: action.programs }
        }
        return tenant
      })
    }
    case types.SET_PROGRAMS_SETTING: {
      const { tenantId, programId, settings } = action
      return state.map((tenant) => {
        if (tenant.id === tenantId) {
          return {
            ...tenant,
            programs: tenant.programs.map((program) => {
              if (program.id === programId) {
                return { ...program, settings }
              }
              return program
            }),
          }
        }
        return tenant
      })
    }
    case types.LOAD_PROGRAM_CONFIGURATIONS:
    case types.UPDATE_PROGRAM_CONFIGURATIONS: {
      const { tenantId, programId } = action

      const configuration = action.config

      return state.map((tenant) => {
        if (tenant.id === tenantId) {
          return {
            ...tenant,
            programs: tenant.programs.map((program) => {
              if (program.id === programId) {
                return { ...program, configuration }
              }
              return program
            }),
          }
        }
        return tenant
      })
    }
    case types.LOAD_PROGRAM_SECURITY_ROLES: {
      const { tenantId, programId } = action

      const securityRoles = action.roles

      return state.map((tenant) => {
        if (tenant.id === tenantId) {
          return {
            ...tenant,
            programs: tenant.programs.map((program) => {
              if (program.id === programId) {
                return { ...program, securityRoles }
              }
              return program
            }),
          }
        }
        return tenant
      })
    }
    case types.SET_PROGRAMS_QUESTIONNAIRES: {
      const { tenantId, programId } = action

      const { questionnaires } = action
      const sendAllQuestionnaires = !!action.sendAll
      return state.map((tenant) => {
        if (tenant.id === tenantId) {
          return {
            ...tenant,
            programs: tenant.programs.map((program) => {
              if (program.id === programId) {
                return { ...program, questionnaires, sendAllQuestionnaires }
              }
              return program
            }),
          }
        }
        return tenant
      })
    }
    case types.SET_PROGRAMS_SENTIMENTS: {
      const { tenantId, programId } = action

      const { sentiments } = action
      return state.map((tenant) => {
        if (tenant.id === tenantId) {
          return {
            ...tenant,
            programs: tenant.programs.map((program) => {
              if (program.id === programId) {
                return { ...program, sentiments }
              }
              return program
            }),
          }
        }
        return tenant
      })
    }
    case types.SET_TENANT_USERS: {
      const { tenantId } = action
      const { users } = action

      const patients = []
      const providers = []
      for (let i = 0; i < users.length; i++) {
        if (users[i].type === 'Provider') providers.push(users[i])
        else patients.push(users[i])
      }

      return state.map((tenant) => {
        if (tenant.id !== tenantId) return tenant

        return { ...tenant, patients, providers }
      })
    }
    case types.SET_PROGRAMS_MEDIA_ITEMS: {
      const { tenantId, programId } = action

      const { mediaItems } = action
      return state.map((tenant) => {
        if (tenant.id === tenantId) {
          return {
            ...tenant,
            programs: tenant.programs.map((program) => {
              if (program.id === programId) {
                return { ...program, mediaItems }
              }
              return program
            }),
          }
        }
        return tenant
      })
    }
    case types.SET_PROGRAMS_CONSENT_FORM: {
      const { tenantId, programId } = action

      const { consentForms } = action

      return state.map((tenant) => {
        if (tenant.id === tenantId) {
          return {
            ...tenant,
            programs: tenant.programs.map((program) => {
              if (program.id === programId) {
                return { ...program, consentForms }
              }
              return program
            }),
          }
        }
        return tenant
      })
    }
    case types.SET_TENANT_CONSENT_FORM_ITEMS: {
      const { tenantId } = action
      const consentFormList = action.consentFormItems

      return state.map((tenant) => {
        if (tenant.id === tenantId) {
          return { ...tenant, consentFormList }
        }
        return tenant
      })
    }
    case types.CREATE_TENANTS_CONSENT_FORM: {
      const { tenantId } = action
      const { item } = action

      return state.map((tenant) => {
        if (tenant.id === tenantId) {
          return {
            ...tenant,
            consentFormList: [...tenant.consentFormList, item],
          }
        }
        return tenant
      })
    }
    /**
     * return {
    type: types.UPDATE_TENANTS_CONSENT_FORM,
    tenantId,
    item
  };
     * */
    case types.UPDATE_TENANTS_CONSENT_FORM: {
      const { tenantId } = action
      const { item } = action

      return state.map((tenant) => {
        if (tenant.id === tenantId) {
          return {
            ...tenant,
            consentFormList: tenant.consentFormList.map((cfItem) => {
              if (cfItem.id === item.id) {
                return item
              }
              return cfItem
            }),
          }
        }
        return tenant
      })
    }
    case types.SET_TENANT_MEDIA_ITEMS: {
      const { tenantId } = action
      const mediaList = action.mediaItems
      return state.map((tenant) => {
        if (tenant.id === tenantId) {
          return { ...tenant, mediaList }
        }
        return tenant
      })
    }
    case types.SET_TENANT_QUESTIONNAIRES_ITEMS: {
      const { tenantId } = action
      const questionnairesList = action.questionnairesItems
      return state.map((tenant) => {
        if (tenant.id === tenantId) {
          return { ...tenant, questionnairesList }
        }
        return tenant
      })
    }
    case types.SET_TENANT_SENTIMENTS_ITEMS: {
      const { tenantId } = action
      const sentimentsList = action.sentimentsItems
      return state.map((tenant) => {
        if (tenant.id === tenantId) {
          return { ...tenant, sentimentsList }
        }
        return tenant
      })
    }
    case types.SAVE_NEW_TENANT_TOOLS_MEDIA_ITEM: {
      const mediaItem = action.media
      const { tenantId } = action
      return state.map((tenant) => {
        if (tenant.id === tenantId) {
          return {
            ...tenant,
            mediaList:
              tenant.mediaList && tenant.mediaList.length
                ? [...tenant.mediaList, mediaItem]
                : [mediaItem],
          }
        }
        return tenant
      })
    }
    case types.UPDATE_PROGRAM_DETAILS: {
      /*
        tenantId,
        programId,
        details
        * */
      const { tenantId, programId } = action

      const { details } = action
      return state.map((tenant) => {
        if (tenant.id === tenantId) {
          return {
            ...tenant,
            programs: tenant.programs.map((program) => {
              if (program.id === programId) {
                return {
                  ...program,
                  name: details.name,
                  logo: details.logo,
                  description: details.description,
                }
              }
              return program
            }),
          }
        }
        return tenant
      })
    }
    case types.ADD_NEW_PROGRAM: {
      const { tenantId } = action
      const { program } = action
      return state.map((tenant) => {
        if (tenant.id === tenantId) {
          return { ...tenant, programs: [...tenant.programs, program] }
        }
        return tenant
      })
    }
    case types.UPDATE_TENANTS_MEDIA_ITEM: {
      const { tenantId } = action
      const { id } = action
      const { media } = action
      return state.map((tenant) => {
        if (tenant.id === tenantId) {
          return {
            ...tenant,
            mediaList: tenant.mediaList.map((mediaItem) =>
              mediaItem.id === id ? media : mediaItem,
            ),
          }
        }
        return tenant
      })
    }
    case types.SAVE_NEW_TENANT_QUESTIONNAIRE: {
      const item = {
        id: action.item.id,
        name: action.item.name,
        type: action.item.type,
      }
      if (action.item.alias) {
        item.alias = action.item.alias
      }
      if (action.item.description) {
        item.description = action.item.description
      }
      const { tenantId } = action
      return state.map((tenant) => {
        if (tenant.id === tenantId) {
          return {
            ...tenant,
            questionnairesList: [...tenant.questionnairesList, item],
          }
        }
        return tenant
      })
    }
    /*
    * UPDATE_TENANTS_QUESTIONNAIRE
    * return {
        type: types.UPDATE_TENANTS_QUESTIONNAIRE,
        item,
        id: item.id,
        tenantId
    };
    * */

    case types.UPDATE_TENANTS_QUESTIONNAIRE: {
      const item = {
        id: action.item.id,
        name: action.item.name,
        type: action.item.type,
      }
      if (action.item.alias) {
        item.alias = action.item.alias
      }
      if (action.item.description) {
        item.description = action.item.description
      }
      const { tenantId } = action
      const { id } = action
      return state.map((tenant) => {
        if (tenant.id === tenantId) {
          return {
            ...tenant,
            questionnairesList: tenant.questionnairesList.map((qu) => {
              if (qu.id === id) {
                return item
              }
              return qu
            }),
          }
        }
        return tenant
      })
    }
    case types.SET_PROGRAMS_RULES: {
      const { tenantId, programId } = action

      const { alertRules } = action
      return state.map((tenant) => {
        if (tenant.id === tenantId) {
          return {
            ...tenant,
            programs: tenant.programs.map((program) => {
              if (program.id === programId) {
                return { ...program, alertRules }
              }
              return program
            }),
          }
        }
        return tenant
      })
    }
    /*
    return {
        type: types.SET_PROGRAMS_NOTIFICATION,
        tenantId,
        programId,
        notifications
    };
    * */
    case types.SET_PROGRAMS_NOTIFICATION: {
      const { tenantId, programId } = action

      const { notifications } = action
      return state.map((tenant) => {
        if (tenant.id === tenantId) {
          return {
            ...tenant,
            programs: tenant.programs.map((program) => {
              if (program.id === programId) {
                return { ...program, notifications }
              }
              return program
            }),
          }
        }
        return tenant
      })
    }

    case types.UPDATE_PROGRAM_ALERT_RULE: {
      const { tenantId, programId } = action

      const { alertRule } = action
      return state.map((tenant) => {
        if (tenant.id === tenantId) {
          return {
            ...tenant,
            programs: tenant.programs.map((program) => {
              if (program.id === programId) {
                return {
                  ...program,
                  alertRules: program.alertRules.map((ar) => {
                    if (ar.id === alertRule.id) {
                      return alertRule
                    }
                    return ar
                  }),
                }
              }
              return program
            }),
          }
        }
        return tenant
      })
    }
    case types.SAVE_PROGRAM_ALERT_RULE_ITEM: {
      const { tenantId, programId } = action

      const { alertRule } = action
      return state.map((tenant) => {
        if (tenant.id === tenantId) {
          return {
            ...tenant,
            programs: tenant.programs.map((program) => {
              if (program.id === programId) {
                return {
                  ...program,
                  alertRules: [...program.alertRules, alertRule],
                }
              }
              return program
            }),
          }
        }
        return tenant
      })
    }
    case types.DELETE_PROGRAM_ALERT_RULE: {
      const { tenantId, programId } = action

      const { id } = action
      return state.map((tenant) => {
        if (tenant.id === tenantId) {
          return {
            ...tenant,
            programs: tenant.programs.map((program) => {
              if (program.id === programId) {
                return {
                  ...program,
                  alertRules: program.alertRules.filter((ar) => ar.id !== id),
                }
              }
              return program
            }),
          }
        }
        return tenant
      })
    }
    case types.UPDATE_PROGRAM_NOTIFICATION: {
      const { tenantId, programId } = action

      const { notification } = action
      return state.map((tenant) => {
        if (tenant.id === tenantId) {
          return {
            ...tenant,
            programs: tenant.programs.map((program) => {
              if (program.id === programId) {
                return {
                  ...program,
                  notifications: program.notifications.map((ar) => {
                    if (ar.id === notification.id) {
                      return notification
                    }
                    return ar
                  }),
                }
              }
              return program
            }),
          }
        }
        return tenant
      })
    }
    case types.SAVE_PROGRAM_NOTIFICATION_ITEM: {
      const { tenantId, programId } = action

      const { notification } = action
      return state.map((tenant) => {
        if (tenant.id === tenantId) {
          return {
            ...tenant,
            programs: tenant.programs.map((program) => {
              if (program.id === programId) {
                return {
                  ...program,
                  notifications: [...program.notifications, notification],
                }
              }
              return program
            }),
          }
        }
        return tenant
      })
    }
    case types.DELETE_PROGRAM_NOTIFICATION: {
      const { tenantId, programId } = action

      const { id } = action
      return state.map((tenant) => {
        if (tenant.id === tenantId) {
          return {
            ...tenant,
            programs: tenant.programs.map((program) => {
              if (program.id === programId) {
                return {
                  ...program,
                  notifications: program.notifications.filter(
                    (ar) => ar.id !== id,
                  ),
                }
              }
              return program
            }),
          }
        }
        return tenant
      })
    }
    case types.SELECT_PROGRAM: {
      const { tenantId, programId } = action

      return state.map((tenant) => {
        if (tenant.id === tenantId) {
          return { ...tenant, selectedProgramId: programId }
        }
        return tenant
      })
    }
    case types.LOAD_PROVIDERS: {
      const { tenantId, programId } = action

      return state.map((tenant) => {
        if (tenant.id === tenantId) {
          return {
            ...tenant,
            programs: tenant.programs.map((program) => {
              if (program.id === programId) {
                return { ...program, providers: action.providers }
              }

              return program
            }),
          }
        }
        return tenant
      })
    }
    case types.CREATE_PROGRAM_USER: {
      const { tenantId, programId } = action

      return state.map((tenant) => {
        if (tenant.id === tenantId) {
          return {
            ...tenant,
            programs: tenant.programs.map((program) => {
              if (program.id === programId) {
                return {
                  ...program,
                  providers: [action.userData, ...program.providers],
                }
              }

              return program
            }),
          }
        }
        return tenant
      })
    }
    case types.UPDATE_PROGRAM_USER: {
      const { tenantId, programId, userData } = action
      const userId = userData.id

      return state.map((tenant) => {
        if (tenant.id === tenantId) {
          return {
            ...tenant,
            programs: tenant.programs.map((program) => {
              if (program.id === programId) {
                return {
                  ...program,
                  providers: program.providers.map((provider) => {
                    if (provider.id !== userId) return provider

                    return userData
                  }),
                }
              }

              return program
            }),
          }
        }
        return tenant
      })
    }
    case types.REVOKE_PROGRAM_USER: {
      const { tenantId, programId, userId } = action

      return state.map((tenant) => {
        if (tenant.id === tenantId) {
          return {
            ...tenant,
            programs: tenant.programs.map((program) => {
              if (program.id === programId) {
                return {
                  ...program,
                  providers: program.providers.map((provider) => {
                    if (provider.id === userId)
                      return {
                        ...provider,
                        accessStatus: 'revoked',
                      }

                    return provider
                  }),
                }
              }

              return program
            }),
          }
        }
        return tenant
      })
    }
    case types.RESTORE_PROGRAM_USER: {
      const { tenantId, programId, userId } = action

      return state.map((tenant) => {
        if (tenant.id === tenantId) {
          return {
            ...tenant,
            programs: tenant.programs.map((program) => {
              if (program.id === programId) {
                return {
                  ...program,
                  providers: program.providers.map((provider) => {
                    if (provider.id === userId)
                      return {
                        ...provider,
                        accessStatus: 'restored',
                      }

                    return provider
                  }),
                }
              }

              return program
            }),
          }
        }
        return tenant
      })
    }
    case types.REMOVE_PROGRAM_USER: {
      const { tenantId, programId, userId } = action

      return state.map((tenant) => {
        if (tenant.id === tenantId) {
          return {
            ...tenant,
            programs: tenant.programs.map((program) => {
              if (program.id === programId) {
                return {
                  ...program,
                  providers: program.providers.filter((provider) => {
                    return provider.id !== userId
                  }),
                }
              }

              return program
            }),
          }
        }
        return tenant
      })
    }
    default: {
      return state
    }
  }
}
