import React from 'react'
import PropTypes from 'prop-types'
import SelectFormsItems from '../../../../GlobalComponent/SelectFormsItems/SelectFormsItems'
import CallToAction from '../../../../common/CallToAction/CallToAction'
import ChipsItem from '../../../../common/ChipsItem/ChipsItem'
import ButtonNew from '../../../../common/Button/ButtonNew'

/*
type = questionnaire/sentiment/media
tools = assessmentQuestionnairesView/assessmentSentimentView/mediaContentListView

***/
const ToolboxItemList = ({
  type,
  changeSendAllCheckBox,
  tools,
  saveFormList,
  changeCheckFormModal,
  closeAddModal,
  addItem,
  showAll,
  formItemDelete,
  sendAllChecked,
}) => {
  const sendAll = type === 'sentiment' || type === 'questionnaire'
  return (
    <div className="section-details">
      <SelectFormsItems
        title={'Add ' + type}
        saveOnClick={() => saveFormList(type)}
        changeCheckItem={(id) => changeCheckFormModal(id, type)}
        closeModal={() => closeAddModal(type)}
        isOpen={tools.modalIsOpen}
        modalFormList={tools.modalList}
      />

      <div className="assessment-body-wrapper">
        <div className="assessment-body">
          {sendAll && (
            <CallToAction
              onClick={() => addItem(type)}
              text={'Add ' + type}
              iconType="plus"
              disable={sendAllChecked}
            />
          )}

          {tools.forms &&
            tools.forms
              .slice(0, tools.showAll ? tools.forms.length : 5)
              .map((item) => {
                return (
                  <ChipsItem
                    key={item.id}
                    text={item.name}
                    deleteClick={() => formItemDelete(item.id, type)}
                    disable={sendAllChecked}
                  />
                )
              })}
          {tools.forms.length > 5 && (
            <ButtonNew
              text={tools.showAll ? 'Show Less' : 'Show All'}
              type="secondary"
              onClick={() => {
                showAll(type, !tools.showAll)
              }}
            />
          )}
        </div>
      </div>
    </div>
  )
}

ToolboxItemList.propTypes = {
  type: PropTypes.string.isRequired,
  changeSendAllCheckBox: PropTypes.func,
  tools: PropTypes.object.isRequired,
  saveFormList: PropTypes.func.isRequired,
  changeCheckFormModal: PropTypes.func.isRequired,
  closeAddModal: PropTypes.func.isRequired,
  addItem: PropTypes.func,
  showAll: PropTypes.func.isRequired,
  formItemDelete: PropTypes.func.isRequired,
  sendAllChecked: PropTypes.bool,
}

export default ToolboxItemList
