import { createStore, applyMiddleware, compose } from 'redux'
/**
 * This middleWare will warn as if we accidentally mutate Redux state.
 * */
import reduxImmutableStateInvariant from 'redux-immutable-state-invariant'
import thunk from 'redux-thunk'
import rootReducer from './reducers/index'

export default function configureStore(initializeState) {
  const composeEnhancers =
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose // add support for redux dev tools

  return createStore(
    rootReducer,
    initializeState,
    composeEnhancers(
      applyMiddleware(
        ...[thunk].concat(
          process.env.NODE_ENV === 'production'
            ? [reduxImmutableStateInvariant()]
            : [],
        ),
      ),
    ),
  )
}
