import React, { useState } from 'react'
import PropTypes from 'prop-types'
import IconButton from '../../../common/IconButton/IconButton'
import ButtonNew from '../../../common/Button/ButtonNew'
import ArticleContent from './ArticleContent'
import AudioTrackContent from './AudioTrackContent'
import Modal from 'react-modal'
import './create-media-style.less'

function CreateEditMedia({
  saveOnClick,
  isOpen,
  closeModal,
  type = 'create',
  element,
}) {
  const initMediaItemArticle = {
    title: '',
    tagsSearch: '',
    tags: [],
    tagOptions: [],
    imageType: 'url_picture',
    imageUrl: '',
    contentUrl: '',
    image: {
      file: '',
      imagePreviewUrl: '',
    },
  }

  const initMediaItemAudioTrack = {
    title: '',
    tagsSearch: '',
    tags: [],
    tagOptions: [],
    imageType: 'url_picture',
    imageUrl: '',
    image: {
      file: '',
      imagePreviewUrl: '',
    },
    backgroundImageType: 'background_url_picture',
    backgroundImageUrl: '',
    backgroundImage: {
      file: '',
      imagePreviewUrl: '',
    },
    mediaUrl: '',
    paragraph: [],
  }
  const [mediaItemCategory, setMediaItemCategory] = useState('article')

  const [mediaItemArticle, setMediaItemArticle] = useState(initMediaItemArticle)
  const [mediaItemArticleError, setMediaItemArticleError] = useState({})
  const [mediaItemAudioTrack, setMediaItemAudioTrack] = useState(
    initMediaItemAudioTrack,
  )
  const [mediaItemAudioTrackError, setMediaItemAudioTrackError] = useState({})

  const initModal = () => {
    setMediaItemCategory('article')
    setMediaItemArticleError({})
    setMediaItemAudioTrackError({})
    if (type === 'create') {
      setMediaItemAudioTrack(initMediaItemAudioTrack)
      setMediaItemArticle(initMediaItemArticle)
    } else if (element) {
      if (element.category === 'article') {
        setMediaItemAudioTrack(initMediaItemAudioTrack)
        setMediaItemArticle(
          Object.assign({}, initMediaItemArticle, {
            title: element.title || '',
            tagsSearch: '',
            tags: element.tags,
            tagOptions: [],
            imageType: 'url_picture',
            imageUrl: element.picture,
            contentUrl: element.contentUrl,
            image: {
              file: '',
              imagePreviewUrl: '',
            },
          }),
        )
      } else if (element.category === 'audio_track') {
        // element.category === audio_track
        setMediaItemArticle(initMediaItemArticle)
        setMediaItemAudioTrack(
          Object.assign({}, initMediaItemAudioTrack, {
            title: element.title || '',
            tagsSearch: '',
            tags: element.tags || [],
            tagOptions: [],
            imageType: 'url_picture',
            imageUrl: element.picture || '',
            image: {
              file: '',
              imagePreviewUrl: '',
            },
            backgroundImageType: 'background_url_picture', // background_url_picture/background_actual_picture
            backgroundImageUrl:
              element.internalContent && element.internalContent.background
                ? element.internalContent.background
                : '',
            backgroundImage: {
              file: '',
              imagePreviewUrl: '',
            },
            mediaUrl:
              element.internalContent && element.internalContent.mediaUrl
                ? element.internalContent.mediaUrl
                : '',
            paragraph:
              element.internalContent &&
              element.internalContent.paragraphs &&
              element.internalContent.paragraphs.length
                ? element.internalContent.paragraphs.map((p, index) => {
                    return { text: p, id: element.id + index }
                  })
                : [],
          }),
        )
      } else {
        setMediaItemAudioTrack(initMediaItemAudioTrack)
        setMediaItemArticle(initMediaItemArticle)
      }
      setMediaItemCategory(element.category || '')
    }
  }
  function validateMediaItem(item) {
    setMediaItemArticleError({})
    setMediaItemAudioTrackError({})
    let errObjectArticle = {}
    let errObjectAudioTrack = {}
    switch (item.category) {
      case 'article': {
        if (!item.title) {
          errObjectArticle.title = 'This field is required'
        }
        if (!item.picture) {
          errObjectArticle.picture = 'This field is required'
        }
        if (!item.contentUrl) {
          errObjectArticle.contentUrl = 'This field is required'
        }
        let okA = !(!item.title || !item.picture || !item.contentUrl)
        if (!okA) {
          setMediaItemArticleError(errObjectArticle)
          return { ok: okA, err: errObjectArticle }
        }
        return { ok: okA, data: item }
      }
      case 'audio_track': {
        if (!item.title) {
          errObjectAudioTrack.title = 'This field is required'
        }
        if (!item.picture) {
          errObjectAudioTrack.picture = 'This field is required'
        }
        if (!item.internalContent.mediaUrl) {
          errObjectAudioTrack.mediaUrl = 'This field is required'
        }
        if (!item.internalContent.background) {
          errObjectAudioTrack.background = 'This field is required'
        }
        let notOk =
          !item.title ||
          !item.picture ||
          !item.internalContent.mediaUrl ||
          !item.internalContent.background
        if (notOk) {
          setMediaItemAudioTrackError(errObjectAudioTrack)
          return { ok: false, err: errObjectAudioTrack }
        }
        return { ok: true, data: item }
      }
    }
    return { ok: false }
  }

  const saveNewMediaClick = () => {
    // Todo check validation field.
    switch (mediaItemCategory) {
      case 'article': {
        const itemToSave = {
          category: mediaItemCategory,
          title: mediaItemArticle.title,
          picture: mediaItemArticle.imageUrl,
          tags: mediaItemArticle.tags,
          contentUrl: mediaItemArticle.contentUrl,
        }
        const validateItem = validateMediaItem(itemToSave)
        if (validateItem.ok) {
          if (element && element.id) {
            return saveOnClick(itemToSave, element.id)
          }
          return saveOnClick(itemToSave)
        }
        /**
         * Filed validation validateMediaItem function show!
         * */
        break
      }
      case 'audio_track': {
        const itemToSave = {
          category: mediaItemCategory,
          title: mediaItemAudioTrack.title,
          picture: mediaItemAudioTrack.imageUrl,
          tags: mediaItemAudioTrack.tags,
          internalContent: {
            mediaUrl: mediaItemAudioTrack.mediaUrl,
            background: mediaItemAudioTrack.backgroundImageUrl,
            paragraphs: mediaItemAudioTrack.paragraph.length
              ? mediaItemAudioTrack.paragraph.map((item) => item.text)
              : [],
          },
        }
        const validateItem = validateMediaItem(itemToSave)
        if (validateItem.ok) {
          // return saveOnClick(itemToSave);
          if (element && element.id) {
            return saveOnClick(itemToSave, element.id)
          }
          return saveOnClick(itemToSave)
        } else {
          console.log('THE VALIDATION HAS FAILED article audio track')
        }
        break
      }
      default:
        break
    }
  }

  const categoryOptions = [
    {
      value: 'not_select',
      label: 'Select Category',
      key: 'category_not_select',
      hidden: true,
    },
    { value: 'article', label: 'Article', key: 'category_article' },
    { value: 'audio_track', label: 'Audio Track', key: 'category_audio_track' },
  ]

  const customStyles = {
    content: {
      height: mediaItemCategory === 'audio_track' ? '743px' : '562px',
      top: '20%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -20%)',
      padding: '0px',
      maxHeight: '90%',
      // height: "max-content",
      // minHeight: "562px",
      // maxHeight: "80%"
    },
  }
  /**
   * {mediaItemCategory === "article" && (
              <ArticleContent
                articleMediaItem={mediaItemArticle}
                setArticleMediaItem={setMediaItemArticle}
              />
            )}
   {mediaItemArticle === "audio_track" && (
              <AudioTrackContent
                audioTrackMediaItem={mediaItemAudioTrack}
                setAudioTrackMediaItem={setMediaItemAudioTrack}
              />
            )}
   * */
  return (
    <Modal
      isOpen={isOpen}
      style={customStyles}
      contentLabel="Example Modal"
      ariaHideApp={false}
      onAfterOpen={initModal}
    >
      <div className="modal-wrap large-modal-wrap">
        <div className="modal-header">
          <div>{type === 'create' ? 'Create new Media' : 'Edit Media'}</div>
          <IconButton
            onClick={() => {
              closeModal()
            }}
            type="close"
          />
        </div>
        <div className="modal-body">
          <div className="create-media-modal-body">
            <div className="modal-section">
              <div className="category-section">Category</div>
              <select
                disabled={type === 'edit'}
                defaultValue={{ value: null, label: 'Select Category' }}
                className="details-section"
                value={mediaItemCategory}
                onChange={(event) => {
                  setMediaItemCategory(event.target.value)
                }}
              >
                {categoryOptions.map((option) => {
                  if (option.hidden) {
                    return (
                      <option
                        key={option.key}
                        value={option.value}
                        disabled
                        hidden
                      >
                        {option.label}
                      </option>
                    )
                  }
                  return (
                    <option key={option.key} value={option.value}>
                      {option.label}
                    </option>
                  )
                })}
              </select>
            </div>
            {mediaItemCategory === 'article' && (
              <ArticleContent
                articleMediaItem={mediaItemArticle}
                setArticleMediaItem={setMediaItemArticle}
                error={mediaItemArticleError}
              />
            )}
            {mediaItemCategory === 'audio_track' && (
              <AudioTrackContent
                audioTrackMediaItem={mediaItemAudioTrack}
                setAudioTrackMediaItem={setMediaItemAudioTrack}
                error={mediaItemAudioTrackError}
              />
            )}
          </div>
        </div>
        <div className="modal-footer">
          <ButtonNew type="link" text={'cancel'} onClick={closeModal} />
          <ButtonNew
            text={type === 'create' ? 'Create' : 'Save'}
            onClick={saveNewMediaClick}
            type="primary"
          />
        </div>
      </div>
    </Modal>
  )
}

CreateEditMedia.propTypes = {
  saveOnClick: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  type: PropTypes.string,
}

export default CreateEditMedia
