import React from 'react'
import ButtonNew from '../../../common/Button/ButtonNew'
import PropTypes from 'prop-types'

const TenantStatus = ({ tenant, editTenantDetailsClick }) => {
  const editBtnStyle = { width: '100%' }
  return (
    <div className="tenant-component-status">
      <div className="paragraph">
        <div className="paragraph-title">Tenant Name</div>
        <div>{tenant.name}</div>
      </div>
      {/*<div className="paragraph">*/}
      {/*<div className="paragraph-title">*/}
      {/*<div>Location</div>*/}
      {/*</div>*/}
      {/*<div>{tenant.location ? tenant.location : "none"}</div>*/}
      {/*</div>*/}
      {/*<div className="paragraph">*/}
      {/*<div className="paragraph-title">*/}
      {/*<div>Member</div>*/}
      {/*<div>since</div>*/}
      {/*</div>*/}
      {/*<div>{tenant.since ? tenant.since : "none"}</div>*/}
      {/*</div>*/}
      <div className="paragraph">
        <ButtonNew
          style={editBtnStyle}
          text={'Edit'}
          type="secondary"
          onClick={editTenantDetailsClick}
        />
      </div>
    </div>
  )
}
TenantStatus.propTypes = {
  tenant: PropTypes.object.isRequired,
  editTenantDetailsClick: PropTypes.func.isRequired,
}

export default TenantStatus
