import React from 'react'
import PropTypes from 'prop-types'
import Episode from '../../../image/icon_episode.svg'
import AudioTrack from '../../../image/icon_audiotrack.svg'
import Article from '../../../image/icon_article.svg'

const iconPath = {
  episode: Episode,
  article: Article,
  audio_track: AudioTrack,
}

const MediaListItem = ({ item }) => {
  const Icon = iconPath[item.category]
  const tags = item.tags && item.tags.length ? item.tags.join(', ') : ''
  return (
    <div className="media-list-item">
      <div className="list-media-icon">{Icon && <Icon />}</div>
      <div className="list-media-name">{item.name}</div>
      <div className="list-media-category">{item.type}</div>
      <div className="list-media-tags">{tags}</div>
      <div className="list-media-options-btn" />
    </div>
  )
}

MediaListItem.propTypes = {
  item: PropTypes.object.isRequired,
}

export default MediaListItem
