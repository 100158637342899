// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `td.user-name-column > div {
    max-width: 500px;
    overflow: hidden;
    text-overflow: ellipsis;
}`, "",{"version":3,"sources":["webpack://./src/components/Tenants/TenantTabs/TenantsBodyContent/ProgramTabs/Providers/style.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,gBAAgB;IAChB,uBAAuB;AAC3B","sourcesContent":["td.user-name-column > div {\n    max-width: 500px;\n    overflow: hidden;\n    text-overflow: ellipsis;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
