import React from 'react'
import './style.less'

const InputError = ({ showError, text, addClass }) => {
  if (showError) {
    return (
      <div className={'error-style ' + (addClass ? addClass : '')}>{text}</div>
    )
  }
  return <div />
}
export default InputError
