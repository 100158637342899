// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.chat-area {
  display: flex;
  padding: 15px 0;
  align-items: center;
}

.chat-area .main-setting-title {
  width: 191px;
  font-weight: bold;
}

.chat-area .input-check-box-wrap {
  height: 40px;
}
.sub-sub-menu {
    padding-left: 30px;
}
.sub-sub-menu .input-check-box-wrap {
    align-items: flex-start;
}
.settings-section.appointment-link {
  display: block;
}
.app-links {
  margin-bottom: 20px;
  width: 50%;
}
.appointment-email .MuiFormControl-root {
  display: block;
  margin-bottom: 10px;
}

.appointment-email {
  width: 50%;
}
.appointment-reschedule {
  display: flex;
}`, "",{"version":3,"sources":["webpack://./src/components/Tenants/TenantTabs/TenantsBodyContent/ProgramTabs/Settings/style.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,eAAe;EACf,mBAAmB;AACrB;;AAEA;EACE,YAAY;EACZ,iBAAiB;AACnB;;AAEA;EACE,YAAY;AACd;AACA;IACI,kBAAkB;AACtB;AACA;IACI,uBAAuB;AAC3B;AACA;EACE,cAAc;AAChB;AACA;EACE,mBAAmB;EACnB,UAAU;AACZ;AACA;EACE,cAAc;EACd,mBAAmB;AACrB;;AAEA;EACE,UAAU;AACZ;AACA;EACE,aAAa;AACf","sourcesContent":[".chat-area {\n  display: flex;\n  padding: 15px 0;\n  align-items: center;\n}\n\n.chat-area .main-setting-title {\n  width: 191px;\n  font-weight: bold;\n}\n\n.chat-area .input-check-box-wrap {\n  height: 40px;\n}\n.sub-sub-menu {\n    padding-left: 30px;\n}\n.sub-sub-menu .input-check-box-wrap {\n    align-items: flex-start;\n}\n.settings-section.appointment-link {\n  display: block;\n}\n.app-links {\n  margin-bottom: 20px;\n  width: 50%;\n}\n.appointment-email .MuiFormControl-root {\n  display: block;\n  margin-bottom: 10px;\n}\n\n.appointment-email {\n  width: 50%;\n}\n.appointment-reschedule {\n  display: flex;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
