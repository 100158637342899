import React, { useState, useRef } from 'react'
import PropTypes from 'prop-types'
import Modal from 'react-modal'
import IconButton from '../../common/IconButton/IconButton'
import ButtonNew from '../../common/Button/ButtonNew'
import CameraPlaceHolder from '../../../image/icon_camera_placeholder.svg'
import './createToolStyle.less'

function CreateTools({ isOpen, onClose, saveClick }) {
  const fileImageInput = useRef(null)
  const [imagePreview, setImagePreview] = useState({
    file: '',
    imagePreviewUrl: '',
  })
  const [tenantInputFiled, setTenantInputFiled] = useState({
    name: '',
    description: '',
  })
  const customStyles = {
    content: {
      maxHeight: '90%',
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      padding: '0px',
      height: '384px',
      // minHeight: "477px",
      // maxHeight: "80%"
    },
  }

  const uploadImageClick = () => {
    fileImageInput.current.click()
  }
  const deleteImageClick = () => {
    console.log('Click on delete image')
    console.log('fileImageInput.current:  ', fileImageInput.current)
    fileImageInput.current.value = null
    setImagePreview({
      file: '',
      imagePreviewUrl: '',
    })
  }
  const handleImageChange = (event) => {
    event.preventDefault()

    const reader = new FileReader()
    const file = event.target.files[0]

    reader.onloadend = () => {
      setImagePreview({
        file,
        imagePreviewUrl: reader.result,
      })
    }

    reader.readAsDataURL(file)
  }
  // imagePreview
  const { imagePreviewUrl } = imagePreview

  const ImageElement = () =>
    imagePreviewUrl ? <img src={imagePreviewUrl} /> : <CameraPlaceHolder />
  return (
    <Modal isOpen={isOpen} style={customStyles} ariaHideApp={false}>
      <div className="modal-wrap large-modal-wrap">
        <div className="modal-header">
          <div>Create new Tenant</div>
          <IconButton
            onClick={() => {
              onClose()
            }}
            type="close"
          />
        </div>
        <div className="modal-body create-tenant-body-modal">
          <div className="add-tenant-category-name">
            <div className="special-input-section">Logo</div>
            <div className="simple-input-section">Name</div>
            <div className="simple-input-section">Description</div>
          </div>
          <div className="add-tenant-category-details">
            <div className="special-input-section">
              <ImageElement />
              <input
                onChange={handleImageChange}
                className="not-show-input"
                ref={fileImageInput}
                type="file"
                accept="image/x-png,image/jpeg"
              />

              <ButtonNew
                text="Upload Image"
                type="secondary"
                onClick={uploadImageClick}
              />
              <ButtonNew text="Delete" type="link" onClick={deleteImageClick} />
            </div>
            <div className="simple-input-section">
              <input
                placeholder="Enter tenant Name"
                value={tenantInputFiled.name}
                onChange={(event) =>
                  setTenantInputFiled({
                    ...tenantInputFiled,
                    name: event.target.value,
                  })
                }
              />
            </div>
            <div className="simple-input-section">
              <input
                placeholder="Enter Description"
                value={tenantInputFiled.description}
                onChange={(event) =>
                  setTenantInputFiled({
                    ...tenantInputFiled,
                    description: event.target.value,
                  })
                }
              />
            </div>
          </div>
        </div>
        <div className="modal-footer">
          <ButtonNew type="link" text="cancel" onClick={onClose} />

          <ButtonNew text="Create" onClick={() => saveClick()} type="primary" />
        </div>
      </div>
    </Modal>
  )
}

CreateTools.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  saveClick: PropTypes.func.isRequired,
}

export default CreateTools
