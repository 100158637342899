import constant from './constant'
import { parseError, parseRes } from './parseResponse'
import * as storageConfig from './storageConfig'

const baseUrl = `${constant.baseUrl}/api/tools`

export function getToolsMedia() {
  return new Promise((resolve, reject) => {
    fetch(`${baseUrl}/media`, {
      headers: {
        authorization: storageConfig.getToken(),
        'Content-Type': 'application/json',
      },
    })
      .then((resp) => resp.json()) // Transform the data into json
      .then(function (data) {
        resolve(data)
      })
      .catch((err) => {
        parseError(err).then((errMessage) => {
          reject(errMessage)
        })
      })
  })
}
export function getToolsQuestionnaires() {
  return new Promise((resolve, reject) => {
    fetch(`${baseUrl}/questionnaire`, {
      headers: {
        authorization: storageConfig.getToken(),
        'Content-Type': 'application/json',
      },
    })
      .then((resp) => resp.json()) // Transform the data into json
      .then(function (data) {
        resolve(data)
      })
      .catch((err) => {
        parseError(err).then((errMessage) => {
          reject(errMessage)
        })
      })
  })
}
export function getToolsConsentForms() {
  return new Promise((resolve, reject) => {
    fetch(`${baseUrl}/consent-forms`, {
      headers: {
        authorization: storageConfig.getToken(),
        'Content-Type': 'application/json',
      },
    })
      .then((resp) => resp.json()) // Transform the data into json
      .then(function (data) {
        resolve(data)
      })
      .catch((err) => {
        parseError(err).then((errMessage) => {
          reject(errMessage)
        })
      })
  })
}
export function saveMediaItem(item) {
  const url = `${baseUrl}/media`
  return new Promise((resolve, reject) => {
    fetch(url, {
      method: 'POST',
      headers: {
        authorization: storageConfig.getToken(),
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(item),
    })
      .then(parseRes) // Transform the data into json
      .then(function (data) {
        resolve(data)
      })
      .catch((err) => {
        parseError(err).then((errMessage) => {
          reject(errMessage)
        })
      })
  })
}
export function updateMediaItem(item, id) {
  delete item.category
  const url = `${baseUrl}/media/${id}`
  return new Promise((resolve, reject) => {
    fetch(url, {
      method: 'PUT',
      headers: {
        authorization: storageConfig.getToken(),
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(item),
    })
      .then(parseRes) // Transform the data into json
      .then(function (data) {
        resolve(data)
      })
      .catch((err) => {
        parseError(err).then((errMessage) => {
          reject(errMessage)
        })
      })
  })
}
// Questionnaires
export function saveQuestionnaireItem(item) {
  const url = `${baseUrl}/questionnaire`
  return new Promise((resolve, reject) => {
    fetch(url, {
      method: 'POST',
      headers: {
        authorization: storageConfig.getToken(),
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(item),
    })
      .then(parseRes) // Transform the data into json
      .then(function (data) {
        resolve(data)
      })
      .catch((err) => {
        parseError(err).then((errMessage) => {
          reject(errMessage)
        })
      })
  })
}
export function editQuestionnaireItem(item, id) {
  delete item.type
  const url = `${baseUrl}/questionnaire/${id}`
  return new Promise((resolve, reject) => {
    fetch(url, {
      method: 'PUT',
      headers: {
        authorization: storageConfig.getToken(),
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(item),
    })
      .then(parseRes) // Transform the data into json
      .then(function (data) {
        resolve(data)
      })
      .catch((err) => {
        parseError(err).then((errMessage) => {
          reject(errMessage)
        })
      })
  })
}

export function createNewConsentForm(item) {
  const url = `${baseUrl}/consent-forms`
  return new Promise((resolve, reject) => {
    fetch(url, {
      method: 'POST',
      headers: {
        authorization: storageConfig.getToken(),
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ ...item, creatorId: '55c9b0489dae030300bf1518' }),
    })
      .then(parseRes) // Transform the data into json
      .then(function (data) {
        resolve(data)
      })
      .catch((err) => {
        parseError(err).then((errMessage) => {
          reject(errMessage)
        })
      })
  })
}
export function saveExitsConsentForm(item, id) {
  const url = `${baseUrl}/consent-forms/${id}`
  return new Promise((resolve, reject) => {
    fetch(url, {
      method: 'PUT',
      headers: {
        authorization: storageConfig.getToken(),
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(item),
    })
      .then(parseRes) // Transform the data into json
      .then(function (data) {
        resolve(data)
      })
      .catch((err) => {
        parseError(err).then((errMessage) => {
          reject(errMessage)
        })
      })
  })
}
export function getOneQuestionnaireItem(id) {
  const url = `${baseUrl}/questionnaire/${id}`
  return new Promise((resolve, reject) => {
    fetch(url, {
      method: 'GET',
      headers: {
        authorization: storageConfig.getToken(),
        'Content-Type': 'application/json',
      },
    })
      .then(parseRes) // Transform the data into json
      .then(function (data) {
        resolve(data)
      })
      .catch((err) => {
        parseError(err).then((errMessage) => {
          reject(errMessage)
        })
      })
  })
}
