import React, { useState } from 'react'
import ButtonNew from '../common/Button/ButtonNew'
import Popup from '../common/Popup'

const DrChronoPopup = ({ handleClose, callback }) => {
  const [id, setId] = useState()
  return (
    <Popup
      title="Change DrChrono ID"
      onClosed={() => {
        handleClose()
      }}
      buttons={[
        {
          text: 'Cancel',
          type: 'link',
          onClick: () => {
            handleClose()
          },
        },
        {
          text: 'Save',
          type: 'primary',
          onClick: () => {
            if (!id) {
              return
            }

            callback(id)
          },
        },
      ]}
    >
      <div>
        <input
          type="text"
          value={id}
          onChange={(e) => {
            setId(e.target.value)
          }}
        />
      </div>
    </Popup>
  )
}
export default DrChronoPopup
