import { parsePhoneNumberFromString } from 'libphonenumber-js'

export function getTenantsById(tenants, id) {
  return tenants.find((item) => item.id === id)
}

export const checkPhone = (phone) => {
  // phone can be US or IL
  const number = getFormatedPhone(phone)

  return !!number
}

export const getFormatedPhone = (phone = '') => {
  let phoneNumber = parsePhoneNumberFromString(phone, 'US')
  if (!phoneNumber || !phoneNumber.isValid()) {
    phoneNumber = parsePhoneNumberFromString(phone, 'IL')
    if (!phoneNumber || !phoneNumber.isValid()) {
      return false
    }
  }

  return phoneNumber.number
}

export const daysLetters = {
  0: 'S',
  1: 'M',
  2: 'T',
  3: 'W',
  4: 'T',
  5: 'F',
  6: 'S',
}

export const getFormattedDate = (date) => {
  const d = new Date(date)
  const curr_date = d.getDate()
  const curr_month = d.getMonth() + 1 // Months are zero based
  const curr_year = d.getFullYear()
  return `${curr_month < 10 ? `0${curr_month}` : curr_month}/${
    curr_date < 10 ? `0${curr_date}` : curr_date
  }/${curr_year}`
}

export const redirectToAWSLoginPage = () => {
  const { AWS_COGNITO_CLIENT_ID, AWS_CUSTOM_DOMAIN } = process.env

  window.location.replace(
    `${AWS_CUSTOM_DOMAIN}/login?response_type=token&client_id=${AWS_COGNITO_CLIENT_ID}&redirect_uri=${window.location.origin}&state=STATE&scope=openid+profile+aws.cognito.signin.user.admin`,
  )
}

export const openContent = (url) => {
  const win = window.open(url, '_blank')
  if (win) {
    win.focus()
  }
}
