import React from 'react'
import PropTypes from 'prop-types'
import QuestionnaireIcon from '../../../image/icon_questionnaire.svg'
import SentimentsIcon from '../../../image/icon_sentiments.svg'

const iconPath = {
  Sentiment: SentimentsIcon,
  Questionnaire: QuestionnaireIcon,
}

const QuestionnaireListItem = ({ item }) => {
  // item.type == Questionnaire / Sentiment
  const Icon = iconPath[item.type]
  return (
    <div className="questionnaire-list-item">
      <div className="list-questionnaire-icon">{Icon && <Icon />}</div>
      <div className="list-questionnaire-name">{item.name}</div>
      <div className="list-questionnaire-type">{item.type}</div>
      <div className="list-questionnaire-options-btn" />
    </div>
  )
}

QuestionnaireListItem.propTypes = {
  item: PropTypes.object.isRequired,
}

export default QuestionnaireListItem
