import React, { useState } from 'react'
import PropTypes from 'prop-types'
import IconButton from '../../common/IconButton/IconButton'
import ListDynamic from '../../common/List/ListDynamic'
import Header from './QuestionnaireListHeader'
import QuestionnaireListItem from './QuestionnaireListItem'
import CreateEditQuestionnaire from '../Questionnaires/createQuestionnaire/CreateEditQuestionnaire'
import './questionnaires.less'

function Questionnaires({
  history,
  match,
  questionnairesList,
  showNotImplemented,
  getOneQuestionnaireItem,
  section,
  tenantId,
}) {
  const initCreateQuestionnaire = {
    isOpen: false,
  }
  const initEditQuestionnaire = {
    isOpen: false,
    element: {},
  }

  const [createQuestionnaireModal, setCreateQuestionnaireModal] = useState(
    initCreateQuestionnaire,
  )
  const [editQuestionnaireModal, setEditQuestionnaireModal] = useState(
    initEditQuestionnaire,
  )

  const questionnaireItemClick = (id) => {
    if (!questionnairesList || !questionnairesList.length) return
    const questionnaireToEdit = questionnairesList.find((m) => m.id === id)

    if (questionnaireToEdit) {
      setEditQuestionnaireModal({ isOpen: true, element: questionnaireToEdit })
      let newUrl =
        section === 'tenants'
          ? '/home/tenants/' + tenantId + '/tools/questionnaires/' + id
          : '/home/tools/questionnaires/' + id
      history.push(newUrl)
    }
  }

  const createNewQuestionnaire = () => {
    setCreateQuestionnaireModal(
      Object.assign({}, createQuestionnaireModal, { isOpen: true }),
    )
  }
  const closeModal = (operation) => {
    if (operation === 'edit') {
      let newUrl =
        section === 'tenants'
          ? '/home/tenants/' + tenantId + '/tools/questionnaires'
          : '/home/tools/questionnaires'
      history.push(newUrl)
      setEditQuestionnaireModal(initEditQuestionnaire)
    } else {
      setCreateQuestionnaireModal(initCreateQuestionnaire)
    }
  }
  const list =
    questionnairesList && questionnairesList.length
      ? questionnairesList.map((item, index) => {
          return {
            id: item.id,
            name: item.name,
            type: item.type,
          }
        })
      : []

  return (
    <div className="questionnaire-component">
      <div className="questionnaire-component-header">
        <CreateEditQuestionnaire
          isOpen={createQuestionnaireModal.isOpen}
          closeModal={() => closeModal('close')}
          section={section}
          tenantId={tenantId}
          type="create"
        />
        <IconButton
          onClick={createNewQuestionnaire}
          type={'plus'}
          labelText="Create new Questionnaire"
        />
      </div>
      <div className="questionnaire-component-body">
        <CreateEditQuestionnaire
          isOpen={editQuestionnaireModal.isOpen}
          closeModal={() => closeModal('edit')}
          section={section}
          tenantId={tenantId}
          type="edit"
          getOneQuestionnaireItem={getOneQuestionnaireItem}
          element={editQuestionnaireModal.element}
        />
        <ListDynamic
          list={list}
          HeaderComponent={Header}
          ItemComponent={QuestionnaireListItem}
          ItemClickFunc={questionnaireItemClick}
        />
      </div>
    </div>
  )
}

Questionnaires.propTypes = {
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  questionnairesList: PropTypes.array,
  getOneQuestionnaireItem: PropTypes.func.isRequired,
  section: PropTypes.string.isRequired,
  tenantId: PropTypes.string,
}

export default Questionnaires
