import React, { useState } from 'react'
import LogoValera from '../../image/logo_valera.svg'
import LogoValeraHealth from '../../image/ValeraHealth_logo.svg'
import DefaultAvatar from '../../image/avatar.svg'
import * as storageConfig from '../../api/storageConfig'
import * as loginApi from '../../api/loginApi'
import { redirectToAWSLoginPage } from '../utilities'

const Header = ({ history, initState }) => {
  const [menuOpen, setMenuOpen] = useState(false)
  const logout = async () => {
    setMenuOpen(false)
    await loginApi
      .logout()
      .then((result) => {
        storageConfig.clearStorage()
        initState(true)
        redirectToAWSLoginPage()
        // Need init state!!

        // reroute to login
      })
      .catch((err) => {
        if (err) {
          console.log(err)
          console.log('failed logout user')
        }
      })
    //
  }
  return (
    <div className="header-box">
      <div className="logo-valera">
        <LogoValera />
      </div>
      <div className="logo-valera-health">
        <LogoValeraHealth />
      </div>

      <div className="user-menu-icon">
        <DefaultAvatar
          onClick={() => {
            setMenuOpen(!menuOpen)
          }}
        />
        {menuOpen && (
          <div className="user-menu">
            <div className="user-menu-item" onClick={logout}>
              Logout
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default Header
