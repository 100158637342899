import React from 'react'
import './common-questionnaire-style.less'
import './main-item-style.less'
import IconSuccess from '../../../../image/icon_success.svg'
import ButtonNew from '../../../common/Button/ButtonNew'
import InputError from '../../../common/InputError/InputError'

const MainItem = ({
  setMainItem,
  mainItem,
  nextClick,
  sectionOpen,
  openMenu,
  error,
  disableType,
}) => {
  const isOpen = sectionOpen === 'main'
  let validState = !(
    (mainItem.states.minScore && parseInt(mainItem.states.minScore) < 0) ||
    (mainItem.states.maxScore && parseInt(mainItem.states.maxScore) < 0) ||
    (mainItem.states.minScore &&
      mainItem.states.maxScore &&
      parseInt(mainItem.states.maxScore) <= parseInt(mainItem.states.minScore))
  )
  const disableNext =
    !mainItem.name || !mainItem.language || !mainItem.type || !validState
  return (
    <div
      className={
        isOpen ? 'main-item-wrap' : 'main-item-wrap is-close with-click'
      }
    >
      <div
        onClick={openMenu}
        className={
          isOpen
            ? 'questionnaire-item-title'
            : 'questionnaire-item-title is-close'
        }
      >
        {isOpen && <div className="main-number">1</div>}
        {!isOpen && (
          <div className="main-icon">
            <IconSuccess />
          </div>
        )}
        <div>Main</div>
      </div>
      {isOpen && (
        <div className="main-item-body">
          <div className="main-item-body-details">
            <div className="details-column">
              <div className="simple-input">
                <div className="category">Name*</div>
                <div className="inputs">
                  <input
                    type="text"
                    placeholder="Name"
                    value={mainItem.name}
                    onChange={(e) =>
                      setMainItem(
                        Object.assign({}, mainItem, { name: e.target.value }),
                      )
                    }
                  />
                  <InputError
                    showError={error && error.name}
                    text="This field is required"
                  />
                </div>
              </div>
              <div className="simple-input">
                <div className="category">Type*</div>
                <div className="inputs">
                  <select
                    disabled={disableType}
                    className="details-section"
                    value={mainItem.type}
                    onChange={(e) =>
                      setMainItem(
                        Object.assign({}, mainItem, { type: e.target.value }),
                      )
                    }
                  >
                    <option value="" selected disabled hidden>
                      Select type
                    </option>
                    <option key="Questionnaire" value="Questionnaire">
                      Questionnaire
                    </option>
                    <option key="Sentiment" value="Sentiment">
                      Sentiment
                    </option>
                  </select>
                  <InputError
                    showError={error && error.type}
                    text="This field is required"
                  />
                </div>
              </div>
              <div className="simple-input">
                <div className="category">Alias</div>
                <div className="inputs">
                  <input
                    type="text"
                    placeholder="Enter Alias Name"
                    value={mainItem.alias}
                    onChange={(e) =>
                      setMainItem(
                        Object.assign({}, mainItem, { alias: e.target.value }),
                      )
                    }
                  />
                </div>
              </div>
              <div className="text-area-input">
                <div className="category">Description</div>
                <textarea
                  onChange={(e) =>
                    setMainItem(
                      Object.assign({}, mainItem, {
                        description: e.target.value,
                      }),
                    )
                  }
                  placeholder="Enter description"
                  value={mainItem.description}
                />
              </div>
            </div>

            <div className="details-column end-column">
              <div className="simple-input">
                <div className="category">Language*</div>
                <div className="inputs">
                  <select
                    className="details-section"
                    value={mainItem.language}
                    onChange={(e) =>
                      setMainItem(
                        Object.assign({}, mainItem, {
                          language: e.target.value,
                        }),
                      )
                    }
                  >
                    <option value="" selected disabled hidden>
                      {'<' + 'Choose from list' + '>'}
                    </option>
                    <option key="en" value="en">
                      English
                    </option>
                  </select>
                  <InputError
                    showError={error && error.language}
                    text="This field is required"
                  />
                </div>
              </div>

              <div className="simple-input">
                <div className="category">Page prefix</div>
                <div className="inputs">
                  <input
                    type="text"
                    placeholder="Enter prefix"
                    value={mainItem.pagePrefix}
                    onChange={(e) =>
                      setMainItem(
                        Object.assign({}, mainItem, {
                          pagePrefix: e.target.value,
                        }),
                      )
                    }
                  />
                </div>
              </div>
              <div className="text-area-input">
                <div className="category">Instruction</div>
                <textarea
                  onChange={(e) =>
                    setMainItem(
                      Object.assign({}, mainItem, {
                        instructions: e.target.value,
                      }),
                    )
                  }
                  placeholder="Enter instruction"
                  value={mainItem.instructions}
                />
              </div>
              <div className="simple-input">
                <div className="category">Stats</div>
                <div className="inputs">
                  <div className="score-inputs">
                    <input
                      type="number"
                      min="0"
                      className="score-input"
                      placeholder="Enter max score"
                      value={mainItem.states.maxScore}
                      onChange={(e) => {
                        if (e.target.value < 0) {
                          return
                        }
                        setMainItem(
                          Object.assign({}, mainItem, {
                            states: {
                              maxScore: e.target.value || '',
                              minScore:
                                mainItem.states.minScore ||
                                mainItem.states.minScore === 0
                                  ? mainItem.states.minScore
                                  : '',
                            },
                          }),
                        )
                      }}
                    />
                    <input
                      type="number"
                      min="0"
                      className="score-input"
                      placeholder="Enter min score"
                      value={mainItem.states.minScore}
                      onChange={(e) => {
                        if (e.target.value < 0) {
                          return
                        }
                        setMainItem(
                          Object.assign({}, mainItem, {
                            states: {
                              maxScore:
                                mainItem.states.maxScore ||
                                mainItem.states.maxScore === 0
                                  ? mainItem.states.maxScore
                                  : '',
                              minScore: e.target.value || '',
                            },
                          }),
                        )
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="simple-input">
                <div className="category">Score Positive*</div>
                <div className="inputs">
                  <select
                    className="details-section"
                    value={mainItem.score_nature} // "score_nature" : "Risk2"//"Original",
                    onChange={(e) =>
                      setMainItem(
                        Object.assign({}, mainItem, {
                          score_nature: e.target.value,
                        }),
                      )
                    }
                  >
                    <option key="Original" value="Original">
                      When Low
                    </option>
                    <option key="Risk2" value="Risk2">
                      When High
                    </option>
                  </select>
                </div>
              </div>
            </div>
          </div>
          <div className="main-item-body-btn-section">
            <ButtonNew
              text="Next"
              onClick={nextClick}
              type="secondary"
              disable={disableNext}
            />
          </div>
        </div>
      )}
    </div>
  )
}

export default MainItem
