import * as types from '../actions/actionsType'
import initializeState from './initializeState'

export default function globalReducer(state = initializeState.global, action) {
  switch (action.type) {
    case types.INITIALIZE_APP_STATE: {
      if (action.isAuthProblem) {
        const userMessageArray = [
          { type: 'warning', text: 'Authentication Problem', id: 1 },
        ]
        return { redirectToLogin: true, userMessageArray, ...state }
      }
      return initializeState.global
    }
    case types.CALLING_QUESTIONNAIRE:
      return { callingQuestionnaire: action.calling, ...state }
    case types.CALLING_SETTINGS:
      return { callingSettings: action.calling, ...state }
    case types.SHOW_NOT_IMPLEMENTED_DIALOG: {
      return { showNotImplementedDialog: { show: true }, ...state }
    }
    case types.HIDE_NOT_IMPLEMENTED_DIALOG: {
      return { showNotImplementedDialog: { show: false }, ...state }
    }
    case types.ADD_USER_MESSAGE_POPUP: {
      const { messageItem } = action
      /**
       * If not valid message not insert it to the message array
       * */
      return {
        ...state,
        userMessageArray:
          !messageItem.text || !messageItem.type || !messageItem.id
            ? state.userMessageArray
            : [...state.userMessageArray, messageItem],
      }
    }
    case types.REMOVE_USER_MESSAGE_POPUP: {
      const messageId = action.id
      return {
        ...state,
        userMessageArray: state.userMessageArray.filter(
          (item) => item.id !== messageId,
        ),
      }
    }
    case types.DISPLAY_CONFIRMATION:
      return {
        ...state,
        confirmation: {
          display: true,
          callback: action.confirmation.callback,
          details: {
            ...initializeState.global.confirmation.details,
            ...action.confirmation.details,
          },
        },
      }
    case types.HIDE_CONFIRMATION:
      return {
        ...state,
        confirmation: {
          display: false,
          callback: initializeState.global.confirmation.callback,
          details: initializeState.global.confirmation.details,
        },
      }
    default: {
      return state
    }
  }
}
