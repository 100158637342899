// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.insurance-list-items {
    display: grid;
    align-items: center;
    grid-template-columns: 310px 310px 20px;
    grid-row-gap: 10px;
    margin-bottom: 6px;
    grid-column-gap: 10px;
  }
  
  .insurance-list-items input {
    width: 309px;
  }
  .replace-value {
    display: grid;
    grid-template-columns: 2fr 2fr 1fr 1fr;
    align-items: center;
    margin-bottom: 10px;
  }
  
  .field-group {
    margin-top: 20px;
  }
  .configuration-page {
    height: calc(100vh - 260px);
  }

  /*INSURANCE GRID*/
  .insurance-grid input, .insurance-grid select {
    border: none !important;
  }
  .insurance-grid p {margin: 0;}
  .insurance-grid-container {
    margin-top: 15px;
}`, "",{"version":3,"sources":["webpack://./src/components/Tenants/TenantTabs/TenantsBodyContent/ProgramTabs/Configuration/style.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,uCAAuC;IACvC,kBAAkB;IAClB,kBAAkB;IAClB,qBAAqB;EACvB;;EAEA;IACE,YAAY;EACd;EACA;IACE,aAAa;IACb,sCAAsC;IACtC,mBAAmB;IACnB,mBAAmB;EACrB;;EAEA;IACE,gBAAgB;EAClB;EACA;IACE,2BAA2B;EAC7B;;EAEA,iBAAiB;EACjB;IACE,uBAAuB;EACzB;EACA,mBAAmB,SAAS,CAAC;EAC7B;IACE,gBAAgB;AACpB","sourcesContent":[".insurance-list-items {\n    display: grid;\n    align-items: center;\n    grid-template-columns: 310px 310px 20px;\n    grid-row-gap: 10px;\n    margin-bottom: 6px;\n    grid-column-gap: 10px;\n  }\n  \n  .insurance-list-items input {\n    width: 309px;\n  }\n  .replace-value {\n    display: grid;\n    grid-template-columns: 2fr 2fr 1fr 1fr;\n    align-items: center;\n    margin-bottom: 10px;\n  }\n  \n  .field-group {\n    margin-top: 20px;\n  }\n  .configuration-page {\n    height: calc(100vh - 260px);\n  }\n\n  /*INSURANCE GRID*/\n  .insurance-grid input, .insurance-grid select {\n    border: none !important;\n  }\n  .insurance-grid p {margin: 0;}\n  .insurance-grid-container {\n    margin-top: 15px;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
