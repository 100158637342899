import React, { useRef } from 'react'
import useOutsideClick from '../../../../../GlobalComponent/useOutsideClick/useOutsideClick'
import OptionsIcon from '../../../../../../image/icon_btn_options.svg'
import PropTypes from 'prop-types'

const optionRuleMenu = ({
  changeOptionsStatusOpenClose,
  deleteRule,
  topOptionsOpen,
  id,
  optionsText,
}) => {
  const ref = useRef()

  useOutsideClick(ref, () => {
    if (topOptionsOpen) {
      changeOptionsStatusOpenClose(id, id)
    }
  })

  return (
    <div className="options-rule-icon-wrap">
      <div
        onClick={() => {
          changeOptionsStatusOpenClose(id)
        }}
      >
        <OptionsIcon />
      </div>
      {topOptionsOpen && (
        <div className="options-menu" ref={ref} onClick={() => deleteRule(id)}>
          {optionsText && optionsText.text
            ? optionsText.text
            : 'Delete question'}
        </div>
      )}
    </div>
  )
}

optionRuleMenu.propTypes = {
  changeOptionsStatusOpenClose: PropTypes.func.isRequired,
  deleteRule: PropTypes.func.isRequired,
  topOptionsOpen: PropTypes.bool.isRequired,
  id: PropTypes.string.isRequired,
  optionsText: PropTypes.object,
}
export default optionRuleMenu
