import React from 'react'
import PropTypes from 'prop-types'

const BoldSubString = ({ str, boldStr }) => {
  if (!str || !str.length) {
    return <div />
  }
  if (!boldStr || !boldStr.length) {
    return <div>{str}</div>
  }
  const indexOfBold = str.toLowerCase().indexOf(boldStr.toLowerCase())
  const lengthOfBold = boldStr.length
  const subStringBold = str.slice(indexOfBold, indexOfBold + lengthOfBold)

  const showStr = str.replace(subStringBold, subStringBold.bold())

  return <div dangerouslySetInnerHTML={{ __html: showStr }} />
}

BoldSubString.propTypes = {
  str: PropTypes.string,
  boldStr: PropTypes.string,
}

export default BoldSubString
