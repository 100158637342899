import React, { useState } from 'react'
import PropTypes from 'prop-types'
import IconButton from '../../common/IconButton/IconButton'
import ListDynamic from '../../common/List/ListDynamic'
import Header from './MediaListHeader'
import MediaListItem from './MediaListItem'
import CreateEditMedia from './CreateMedia/CreateEditMedia'
import './media.less'
import { connect } from 'react-redux'
import * as toolsActions from '../../../redux/actions/toolsActions'
import * as tenantsActions from '../../../redux/actions/tenantsActions'
import * as globalAction from '../../../redux/actions/globalAction'

function Media({
  history,
  match,
  mediaList,
  linkMediaList,
  section,
  saveMediaItemTools,
  saveMediaItemTenant,
  updateMediaItemTenant,
  updateMediaItemTools,
  tenantId,
  addUserMessage,
}) {
  const initCreateMediaItem = { isOpen: false }
  const initEditMediaItem = { isOpen: false }

  const [createMediaModal, setCreateMediaModal] = useState(initCreateMediaItem)
  const [editMediaModal, setEditMediaModal] = useState(initEditMediaItem)

  const createNewMedia = () => {
    setCreateMediaModal(Object.assign({}, createMediaModal, { isOpen: true }))
  }
  const saveNewMedia = (media) => {
    switch (section) {
      case 'tools': {
        saveMediaItemTools(media, (response) => {
          if (response.ok) {
            setCreateMediaModal(initCreateMediaItem)
          }
        })
        break
      }
      case 'tenants': {
        // saveMediaItemTenant
        if (!tenantId) {
          return
        }
        saveMediaItemTenant(media, tenantId, (response) => {
          if (response.ok) {
            setCreateMediaModal(initCreateMediaItem)
          }
        })
        break
      }
    }
  }
  const updateMedia = (media, id) => {
    switch (section) {
      case 'tools': {
        updateMediaItemTools(media, id, (response) => {
          if (response.ok) {
            setEditMediaModal(initEditMediaItem)
          }
        })
        break
      }
      case 'tenants': {
        // saveMediaItemTenant
        if (!tenantId) {
          return
        }
        updateMediaItemTenant(media, id, tenantId, (response) => {
          if (response.ok) {
            setEditMediaModal(initEditMediaItem)
          }
        })
        break
      }
    }
  }
  const mediaItemClick = (id) => {
    if (!mediaList || !mediaList.length) return
    const mediaToEdit = mediaList.find((m) => m.id === id)
    if (mediaToEdit) {
      setEditMediaModal({ isOpen: true, element: mediaToEdit })
      let newUrl =
        section === 'tenants'
          ? '/home/tenants/' + tenantId + '/tools/media/' + id
          : '/home/tools/media/' + id
      history.push(newUrl)
    }
  }
  const closeModal = (operation) => {
    if (operation === 'edit') {
      let newUrl =
        section === 'tenants'
          ? '/home/tenants/' + tenantId + '/tools/media'
          : '/home/tools/media'
      history.push(newUrl)
      setEditMediaModal({ isOpen: false })
    } else {
      setCreateMediaModal(
        Object.assign({}, createMediaModal, { isOpen: false }),
      )
    }
  }

  const list =
    mediaList && mediaList.length
      ? mediaList.map((item, index) => {
          return {
            id: item.id,
            keyList: 'media-list' + item.id,
            name: item.title,
            type:
              item.category === 'audio_track' ? 'Audio Track' : item.category,
            category: item.category,
            tags: item.tags,
          }
        })
      : []
  // section == tenant/tools;
  const LinkMedia = () => {
    /**
     * At this moment there is no link media (it need to be under program).
     * **/
    return <div />
  }
  return (
    <div className="media-component">
      <div className="media-component-header">
        <CreateEditMedia
          isOpen={createMediaModal.isOpen}
          closeModal={() => closeModal('create')}
          saveOnClick={saveNewMedia}
          type="create"
        />
        <IconButton
          onClick={createNewMedia}
          type={'plus'}
          labelText="Create new Media"
        />
        <LinkMedia />
      </div>
      <div className="media-component-body">
        <CreateEditMedia
          isOpen={editMediaModal.isOpen}
          closeModal={() => closeModal('edit')}
          element={editMediaModal.element}
          saveOnClick={updateMedia}
          type="edit"
        />
        <ListDynamic
          list={list}
          HeaderComponent={Header}
          ItemComponent={MediaListItem}
          ItemClickFunc={mediaItemClick}
        />
      </div>
    </div>
  )
}

Media.propTypes = {
  tenantId: PropTypes.string,
  mediaList: PropTypes.array,
  linkMediaList: PropTypes.array,
  section: PropTypes.oneOf(['tools', 'tenants']).isRequired,
  saveMediaItemTools: PropTypes.func.isRequired,
  saveMediaItemTenant: PropTypes.func.isRequired,
  updateMediaItemTools: PropTypes.func.isRequired,
  updateMediaItemTenant: PropTypes.func.isRequired,
  addUserMessage: PropTypes.func.isRequired,
}

function mapStateToProps(state) {
  return {
    // linkMediaList:
  }
}

const mapDispatchToProps = {
  saveMediaItemTools: toolsActions.createMediaItem,
  saveMediaItemTenant: tenantsActions.createMediaItem,
  updateMediaItemTools: toolsActions.updateMediaItem,
  addUserMessage: globalAction.addUserMessage,
  updateMediaItemTenant: tenantsActions.updateMediaItem, // item, id, tenantId, cb
}

export default connect(mapStateToProps, mapDispatchToProps)(Media)
