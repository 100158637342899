import React from 'react'
import PropTypes from 'prop-types'
import './style.css'
import Popup from '../Popup'

const Confirmation = ({ details, callback, handleClose }) => {
  return (
    <Popup
      title={details.title}
      onClosed={() => {
        handleClose()
      }}
      buttons={[
        {
          text: details.cancelBtnText,
          type: 'link',
          onClick: () => {
            handleClose()
          },
        },
        {
          text: details.confirmBtnText,
          type: 'primary',
          onClick: () => {
            handleClose(true)
            callback()
          },
        },
      ]}
    >
      <div className="confirmation-popup">{details.content}</div>
    </Popup>
  )
}

Confirmation.propTypes = {
  details: PropTypes.shape({
    title: PropTypes.string,
    text: PropTypes.string,
  }),
  callback: PropTypes.func,
  onClosed: PropTypes.func,
}

export default Confirmation
