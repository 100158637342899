import React from 'react'
import CloseIcon from '../../../../image/icon_close.svg'
import './answer-item-style.less'

/**
 *
     { id: 1, score: 0, text: "", type: "regular" },
     { id: 2, score: 0, text: "", type: "freeText" }
] // {id: , score: , text, type: {regular/freeText}
 * */
const AnswerItem = ({ item, itemId, setItem, deleteItem }) => {
  // setItem: (itemId, answerId, field, newValue)=>{}
  const answerId = item.id
  const itemType = item.type
  return (
    <div
      className={
        itemType === 'freeText'
          ? 'answer-item-wrapper free-text'
          : 'answer-item-wrapper'
      }
    >
      <input
        type="text"
        value={item.text}
        onChange={(e) => setItem(itemId, answerId, 'text', e.target.value)}
      />
      <div className="close-item-icon">
        <CloseIcon
          onClick={() => {
            deleteItem(itemId, answerId)
          }}
        />
      </div>
      {item.type === 'regular' && <div>Score</div>}
      {item.type === 'regular' && (
        <input
          type="number"
          value={item.score}
          onChange={(e) => setItem(itemId, answerId, 'score', e.target.value)}
        />
      )}
    </div>
  )
}

export default AnswerItem
