export const QUESTIONNAIRE_COMPLETED = 'QUESTIONNAIRE_COMPLETED'
export const GOAL_REMINDER_ACKNOWLEDGED = 'GOAL_REMINDER_ACKNOWLEDGED'
export const CONSENT_FORM_AGREED = 'CONSENT_FORM_AGREED'
export const NOTIFICATION_OPTIONS = [
  {
    value: QUESTIONNAIRE_COMPLETED,
    text: 'Questionnaire Completed',
  },
  {
    value: 'GOAL_REMINDER_ACKNOWLEDGED',
    text: 'Goal Reminder Acknowledged',
  },
  {
    value: CONSENT_FORM_AGREED,
    text: 'Consent Form Agreed',
  },
]
