import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import TableComponent from '../common/Table/table'
import SearchInput from '../common/SearchInput'
import ButtonNew from '../common/Button/ButtonNew'
import { getPending, removePending, getPendingCount } from '../../api/userApi'
import {
  addUserMessage,
  displayConfirmation,
} from '../../redux/actions/globalAction'
import { getFormattedDate } from '../utilities'
import UserPicture from './UserPicture'

const Pending = ({ addUserMessage, displayConfirmation }) => {
  const [data, setData] = useState()
  const [searchText, setSearchText] = useState()
  const [page, setPage] = useState(1)
  const [count, setCount] = useState(0)
  const [pageNum, setPageNum] = useState()
  const PAGE_SIZE = 30

  const options = {
    rowMenu: [
      {
        title: 'Remove pending',
        onclick: (_user) => {
          displayConfirmation({
            details: {
              title: 'Remove pending',
              content: 'Pending user will be removed. Continue?',
            },
            callback: () => {
              removePending(_user.id).then(() => {
                setData(data.filter((user) => user.id !== _user.id))
                addUserMessage(
                  'success',
                  'Pending user has been successfully removed.',
                )
              })
            },
          })
        },
      },
    ],
  }

  const getData = (_data) => {
    return _data.map((t) => {
      return {
        id: t.id,
        patientId: t.patientId,
        userName: t.profile.firstName + ' ' + t.profile.lastName,
        email: t.profile.email,
        phone: t.profile.phone,
        picture: t.profile.picture,
        pendingDate: getFormattedDate(t.pendingDate),
      }
    })
  }

  const callSearchText = () => {
    setPage(1)
    callData()
  }

  const callData = (_page = page, _searchText = searchText) => {
    const payload = {
      searchText: _searchText,
      pageSize: PAGE_SIZE,
      pageNumber: _page,
    }

    getPending(payload).then((_data) => {
      setData(getData(_data))
    })

    getPendingCount({ searchText: _searchText }).then((_c) => {
      setCount(_c)
      setPageNum(Math.ceil(_c / PAGE_SIZE))
    })
  }

  useEffect(() => {
    callData()
  }, [])

  const header = [
    {
      key: 'picture',
      name: ' ',
      type: 'customize',
      classe: 'pending-cell pending-picture',
      // eslint-disable-next-line react/display-name
      getCell: (cellData) => {
        return <UserPicture data={cellData} />
      },
    },
    {
      key: 'userName',
      name: 'Name',
      classe: 'pending-cell pending-name',
      type: 'text',
    },
    // {
    //   key: "id",
    //   name: "ID",
    //   type: "text"
    // },
    // {
    //   key: "phone",
    //   name: "Phone",
    //   type: "text"
    // },
    {
      key: 'pendingDate',
      name: 'Pending date',
      type: 'text',
    },
  ]

  const getPagination = () => {
    return `Showing ${PAGE_SIZE * (page - 1) + 1} - ${
      count >= PAGE_SIZE
        ? PAGE_SIZE * page - (PAGE_SIZE - data.length)
        : data.length
    } of ${count >= PAGE_SIZE ? count : data.length}`
  }

  if (!data) return <div></div>

  return (
    <div className="treatment-page">
      <div className="treatment-header">
        <SearchInput
          handleChange={(txt) => {
            setSearchText(txt)
          }}
          handleKeyPress={(e) => {
            if (e.keyCode && e.keyCode !== 13) return
            callSearchText()
          }}
          handleErase={() => {
            callData(1, '')
          }}
        />
        <ButtonNew
          type="primary"
          text="Search"
          onClick={() => {
            callSearchText()
          }}
        />
      </div>
      <TableComponent header={header} data={data} options={options} />
      <div className="treatment-footer">
        {data && data.length <= count && (
          <div className="footer">
            <div className="left-hide">{getPagination()}</div>
            <div className="right-hide hide-arrow">
              {'page'}{' '}
              <input
                type="number"
                value={page}
                onChange={(e) => {
                  const value = parseInt(e.target.value)
                  if (page !== value) setPage(value)
                }}
                onBlur={() => {
                  callData()
                }}
                onKeyUp={(e) => {
                  if (e.keyCode && e.keyCode !== 13) return
                  callData()
                }}
              />{' '}
              {'of'} {pageNum}{' '}
              <div className="page-arrows">
                <span
                  className={'arrow-left' + (page !== 1 ? '' : ' disabled')}
                  onClick={() => {
                    callData(page - 1)
                    setPage(page - 1)
                  }}
                ></span>
                <span
                  className={
                    'arrow-right' + (page !== pageNum ? '' : ' disabled')
                  }
                  onClick={() => {
                    callData(page + 1)
                    setPage(page + 1)
                  }}
                ></span>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

function mapStateToProps(state, ownProps) {}

const mapDispatchToProps = {
  addUserMessage,
  displayConfirmation,
}

export default connect(mapStateToProps, mapDispatchToProps)(Pending)
