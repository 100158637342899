import React from 'react'
import PropTypes from 'prop-types'
import './style.less'

const InputCheckBox = ({ text, checked, onChange, name }) => {
  return (
    <div className="input-check-box-wrap">
      <input
        type="checkbox"
        name={name}
        checked={checked}
        onChange={onChange}
      />
      <div>{text}</div>
    </div>
  )
}

InputCheckBox.propTypes = {
  text: PropTypes.string.isRequired,
  checked: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
}
export default InputCheckBox
