import constant from './constant'
import { parseError, parseRes } from './parseResponse'
import * as storageConfig from './storageConfig'

const baseUrl = `${constant.baseUrl}/api/program`

export function removeUser(programId, userId) {
  const url = `${baseUrl}/${programId}/users/${userId}`
  return new Promise((resolve, reject) => {
    fetch(url, {
      method: 'DELETE',
      headers: {
        authorization: storageConfig.getToken(),
        'Content-Type': 'application/json',
      },
    })
      .then(function (data) {
        resolve(data)
      }) // Transform the data into json
      .then(function (data) {
        resolve(data)
      })
      .catch((err) => {
        parseError(err).then((errMessage) => {
          reject(errMessage)
        })
      })
  })
}

export function revokeUser(programId, userId) {
  const url = `${baseUrl}/${programId}/users/${userId}/revoke`
  return new Promise((resolve, reject) => {
    fetch(url, {
      method: 'POST',
      headers: {
        authorization: storageConfig.getToken(),
        'Content-Type': 'application/json',
      },
    })
      .then((response) => {
        return response.text()
      }) // Transform the data into json
      .then(function (data) {
        resolve(data)
      })
      .catch((err) => {
        parseError(err).then((errMessage) => {
          reject(errMessage)
        })
      })
  })
}

export function restoreUser(programId, userId) {
  const url = `${baseUrl}/${programId}/users/${userId}/restore`
  return new Promise((resolve, reject) => {
    fetch(url, {
      method: 'POST',
      headers: {
        authorization: storageConfig.getToken(),
        'Content-Type': 'application/json',
      },
    })
      .then((response) => {
        return response.text()
      })
      .then(function (data) {
        resolve(data)
      })
      .catch((err) => {
        parseError(err).then((errMessage) => {
          reject(errMessage)
        })
      })
  })
}

export function updateUser(programId, userId, userData) {
  const url = `${baseUrl}/${programId}/users/${userId}`
  return new Promise((resolve, reject) => {
    fetch(url, {
      method: 'PUT',
      headers: {
        authorization: storageConfig.getToken(),
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(userData),
    })
      .then(parseRes)
      .then(function (data) {
        resolve(data)
      })
      .catch((err) => {
        parseError(err).then((errMessage) => {
          reject(errMessage)
        })
      })
  })
}

export function createUser(programId, userData) {
  const url = `${baseUrl}/${programId}/users`
  return new Promise((resolve, reject) => {
    fetch(url, {
      method: 'POST',
      headers: {
        authorization: storageConfig.getToken(),
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(userData),
    })
      .then(parseRes)
      .then(function (data) {
        resolve(data)
      })
      .catch((err) => {
        parseError(err).then((errMessage) => {
          reject(errMessage)
        })
      })
  })
}

export function getProgramProviders(programId) {
  const url = `${baseUrl}/${programId}/users`
  return new Promise((resolve, reject) => {
    fetch(url, {
      headers: {
        authorization: storageConfig.getToken(),
        'Content-Type': 'application/json',
      },
    })
      .then(parseRes) // Transform the data into json
      .then(function (data) {
        resolve(data)
      })
      .catch((err) => {
        parseError(err).then((errMessage) => {
          reject(errMessage)
        })
      })
  })
}

export function getProgramSetting(programId) {
  const url = `${baseUrl}/${programId}/settings`
  return new Promise((resolve, reject) => {
    fetch(url, {
      headers: {
        authorization: storageConfig.getToken(),
        'Content-Type': 'application/json',
      },
    })
      .then(parseRes) // Transform the data into json
      .then(function (data) {
        resolve(data)
      })
      .catch((err) => {
        parseError(err).then((errMessage) => {
          reject(errMessage)
        })
      })
  })
}

export function getProgramQuestionnaires(programId, type = 'Questionnaire') {
  const url = `${baseUrl}/${programId}/toolbox/questionnaires?type=${type}`
  return new Promise((resolve, reject) => {
    fetch(url, {
      headers: {
        authorization: storageConfig.getToken(),
        'Content-Type': 'application/json',
      },
    })
      .then(parseRes) // Transform the data into json
      .then(function (data) {
        resolve(data)
      })
      .catch((err) => {
        parseError(err).then((errMessage) => {
          reject(errMessage)
        })
      })
  })
}
export function getProgramMediaItems(programId) {
  const url = `${baseUrl}/${programId}/toolbox/media`
  return new Promise((resolve, reject) => {
    fetch(url, {
      headers: {
        authorization: storageConfig.getToken(),
        'Content-Type': 'application/json',
      },
    })
      .then(parseRes) // Transform the data into json
      .then(function (data) {
        resolve(data)
      })
      .catch((err) => {
        parseError(err).then((errMessage) => {
          reject(errMessage)
        })
      })
  })
}
export function getProgramConsentFormItems(programId) {
  const url = `${baseUrl}/${programId}/toolbox/consent-form`
  return new Promise((resolve, reject) => {
    fetch(url, {
      headers: {
        authorization: storageConfig.getToken(),
        'Content-Type': 'application/json',
      },
    })
      .then(parseRes) // Transform the data into json
      .then(function (data) {
        resolve(data)
      })
      .catch((err) => {
        parseError(err).then((errMessage) => {
          reject(errMessage)
        })
      })
  })
}
export function getProgramAlertRules(programId) {
  const url = `${baseUrl}/${programId}/rule`
  return new Promise((resolve, reject) => {
    fetch(url, {
      headers: {
        authorization: storageConfig.getToken(),
        'Content-Type': 'application/json',
      },
    })
      .then(parseRes) // Transform the data into json
      .then(function (data) {
        resolve(data)
      })
      .catch((err) => {
        parseError(err).then((errMessage) => {
          reject(errMessage)
        })
      })
  })
}
export function getProgramNotification(programId) {
  const url = `${baseUrl}/${programId}/notification`
  return new Promise((resolve, reject) => {
    fetch(url, {
      headers: {
        authorization: storageConfig.getToken(),
        'Content-Type': 'application/json',
      },
    })
      .then(parseRes) // Transform the data into json
      .then(function (data) {
        resolve(data)
      })
      .catch((err) => {
        parseError(err).then((errMessage) => {
          reject(errMessage)
        })
      })
  })
}
export function saveSetting(settings, programId) {
  const url = `${baseUrl}/${programId}/settings`
  return new Promise((resolve, reject) => {
    fetch(url, {
      method: 'PUT',
      headers: {
        authorization: storageConfig.getToken(),
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(settings),
    })
      .then(parseRes) // Transform the data into json
      .then(function (data) {
        resolve(data)
      })
      .catch((err) => {
        parseError(err).then((errMessage) => {
          reject(errMessage)
        })
      })
  })
}
/**
 * This is also for sentiment ids.
 * */
export function saveProgramLinkQuestionnaires(programId, quesIds) {
  const url = `${baseUrl}/${programId}/toolbox/questionnaire`
  const payload = { templateIds: quesIds }
  return new Promise((resolve, reject) => {
    fetch(url, {
      method: 'PUT',
      headers: {
        authorization: storageConfig.getToken(),
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(payload),
    })
      .then(parseRes) // Transform the data into json
      .then(function (data) {
        resolve(data)
      })
      .catch((err) => {
        parseError(err).then((errMessage) => {
          reject(errMessage)
        })
      })
  })
}
export function saveProgramLinkMedia(programId, mediaIds) {
  const payload = { templateIds: mediaIds }
  const url = `${baseUrl}/${programId}/toolbox/media`
  return new Promise((resolve, reject) => {
    fetch(url, {
      method: 'PUT',
      headers: {
        authorization: storageConfig.getToken(),
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(payload),
    })
      .then(parseRes) // Transform the data into json
      .then(function (data) {
        resolve(data)
      })
      .catch((err) => {
        parseError(err).then((errMessage) => {
          reject(errMessage)
        })
      })
  })
}
export function saveProgramToolbox(programId, toolBox) {
  const url = `${baseUrl}/${programId}/toolbox`
  return new Promise((resolve, reject) => {
    fetch(url, {
      method: 'PUT',
      headers: {
        authorization: storageConfig.getToken(),
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(toolBox),
    })
      .then(parseRes) // Transform the data into json
      .then(function (data) {
        resolve(data)
      })
      .catch((err) => {
        parseError(err).then((errMessage) => {
          reject(errMessage)
        })
      })
  })
}
export function updateProgramDetails(tenantId, programId, payload) {
  const url = `${baseUrl}/${programId}`
  return new Promise((resolve, reject) => {
    fetch(url, {
      method: 'PUT',
      headers: {
        authorization: storageConfig.getToken(),
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(payload),
    })
      .then(parseRes) // Transform the data into json
      .then(function (data) {
        resolve(data)
      })
      .catch((err) => {
        parseError(err).then((errMessage) => {
          reject(errMessage)
        })
      })
  })
}

export function saveUpdateProgramAlertRule(programId, payload, save = true) {
  const url = `${baseUrl}/${programId}/rule`
  const method = save ? 'POST' : 'PUT'
  return new Promise((resolve, reject) => {
    fetch(url, {
      method,
      headers: {
        authorization: storageConfig.getToken(),
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(payload),
    })
      .then(parseRes) // Transform the data into json
      .then(function (data) {
        resolve(data)
      })
      .catch((err) => {
        parseError(err).then((errMessage) => {
          reject(errMessage)
        })
      })
  })
}
export function deleteProgramAlertRule(programId, ruleId) {
  const url = `${baseUrl}/${programId}/rule`
  const payload = { ruleId }
  return new Promise((resolve, reject) => {
    fetch(url, {
      method: 'DELETE',
      headers: {
        authorization: storageConfig.getToken(),
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(payload),
    })
      .then(parseRes) // Transform the data into json
      .then(function (data) {
        resolve(data)
      })
      .catch((err) => {
        parseError(err).then((errMessage) => {
          reject(errMessage)
        })
      })
  })
}
export function saveUpdateProgramNotification(programId, payload, save = true) {
  const url = `${baseUrl}/${programId}/notification`
  const method = save ? 'POST' : 'PUT'
  return new Promise((resolve, reject) => {
    fetch(url, {
      method,
      headers: {
        authorization: storageConfig.getToken(),
        'Content-Type': 'application/json',
      },
      body: JSON.stringify([payload]),
    })
      .then(parseRes) // Transform the data into json
      .then(function (data) {
        resolve(data)
      })
      .catch((err) => {
        parseError(err).then((errMessage) => {
          reject(errMessage)
        })
      })
  })
}
export function deleteProgramNotification(programId, notificationId) {
  const url = `${baseUrl}/${programId}/notification`
  const payload = { notificationIds: [notificationId] }
  return new Promise((resolve, reject) => {
    fetch(url, {
      method: 'DELETE',
      headers: {
        authorization: storageConfig.getToken(),
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(payload),
    })
      .then(parseRes) // Transform the data into json
      .then(function (data) {
        resolve(data)
      })
      .catch((err) => {
        parseError(err).then((errMessage) => {
          reject(errMessage)
        })
      })
  })
}

export const getConfiguration = (programId) => {
  return fetch(`${baseUrl}/${programId}/config`, {
    method: 'GET',
    headers: {
      Authorization: storageConfig.getToken(),
      'Content-Type': 'application/json',
    },
  })
    .then(parseRes)
    .catch(parseError)
}

export function updateConfiguration(programId, data) {
  const url = `${baseUrl}/${programId}/config`
  return new Promise((resolve, reject) => {
    fetch(url, {
      method: 'PUT',
      headers: {
        authorization: storageConfig.getToken(),
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    })
      .then(parseRes) // Transform the data into json
      .then(function (data) {
        resolve(data)
      })
      .catch((err) => {
        parseError(err, true).then((errMessage) => {
          reject(errMessage)
        })
      })
  })
}

export function replaceConfiguration(programId, data) {
  const url = `${baseUrl}/${programId}/config/replace`
  return new Promise((resolve, reject) => {
    fetch(url, {
      method: 'POST',
      headers: {
        authorization: storageConfig.getToken(),
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    })
      .then(parseRes) // Transform the data into json
      .then(function (data) {
        resolve(data)
      })
      .catch((err) => {
        parseError(err).then((errMessage) => {
          reject(errMessage)
        })
      })
  })
}

export const getSecurityRoles = (programId) => {
  return fetch(`${baseUrl}/${programId}/permit-security-roles`, {
    method: 'GET',
    headers: {
      Authorization: storageConfig.getToken(),
      'Content-Type': 'application/json',
    },
  })
    .then(parseRes)
    .catch(parseError)
}
