import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import GooglePlacesAutocomplete from 'react-google-places-autocomplete'
import './style.less'
import Popup from './../common/Popup'
import FormComponent from './../common/Form'
import BdayIcon from '../../image/icn_bday.svg'
import GenderIcon from '../../image/icn_gender.svg'
import PlaceIcon from '../../image/icn_place.svg'
import PhoneIcon from '../../image/icn_phone.svg'
import MailIcon from '../../image/icn_email.svg'
import IconButton from './../common/IconButton/IconButton'
import RegisterQuesIcon from '../../image/icn_register_questionnaire.svg'
import Select from 'react-select'
import { genderEnum } from '../utilities/enum'
import { checkPhone, getFormatedPhone } from '../utilities'

const EditPatientPopup = ({
  editedPatient,
  handleCancelEdit,
  updatePatient,
}) => {
  const [patient, setPatient] = useState({})
  const [tagSearchValue, setTagSearchValue] = useState('')
  const [displayError, setDisplayError] = useState(false)
  const [pctmMissing, setPctmMissing] = useState(false)
  const [invalidScale, setInvalidScale] = useState(false)
  const [invalidPhone, setInvalidPhone] = useState(false)
  const [invalidMail, setInvalidMail] = useState(false)
  const [disabledSaveBtn, setDisabledSaveBtn] = useState(false)

  useEffect(() => {
    if (editedPatient.scaleId) {
      setPatient({
        ...editedPatient,
        confirmScaleId: editedPatient.scaleId,
        peripheralDevice: 'wirelessScale',
      })
    } else {
      setPatient(editedPatient)
    }
  }, [])

  const getQuestionnaires = () => {
    let arr = []
    if (typeof patient.phqScore === 'number') {
      arr.push({
        name: 'PHQ',
        score: patient.phqScore,
      })
    }

    if (typeof patient.gad7Score === 'number') {
      arr.push({
        name: 'GAD7',
        score: patient.gad7Score,
      })
    }

    return arr.length ? arr : undefined
  }

  const checkPatientData = () => {
    let valid = true
    //required fields
    if (
      !patient.firstName.length ||
      !patient.lastName.length ||
      !patient.address ||
      !patient.address.length ||
      !patient.gender.length ||
      !patient.phone.length ||
      !patient.dateOfBirth ||
      !patient.mrn ||
      !patient.mrn.length
    ) {
      setDisplayError(true)
      valid = false
    }

    if ((!patient.id || patient.pending) && !patient.pctm) {
      setPctmMissing(true)
      valid = false
    }

    //check scale id
    if (
      patient.peripheralDevice === 'wirelessScale' &&
      (!patient.scaleId ||
        !patient.scaleId.length ||
        patient.scaleId !== patient.confirmScaleId ||
        /^[0-9a-fA-F]{24}$/.test(patient.scaleId))
    ) {
      setInvalidScale(true)
      valid = false
    }

    if (!checkPhone(patient.phone)) {
      valid = false
      setInvalidPhone(true)
      setDisplayError(true)
    }

    if (!valid) return false

    if (invalidPhone) {
      setInvalidPhone(false)
    }

    if (displayError) {
      setDisplayError(false)
    }
    if (pctmMissing) {
      setPctmMissing(false)
    }

    return true
  }

  const getFields = () => {
    const DropdownIndicator = () => {
      return <span className="arrow-down dropdown-indicator"></span>
    }
    const fields = [
      {
        text: (
          <span className="edit-patient-label">
            First name
            <span style={{ color: '#D24667' }}>*</span>
          </span>
        ),
        key: 'firstName',
        placeholder: 'John',
        type: 'text',
        required: true,
      },
      {
        text: (
          <span className="edit-patient-label">
            Last name
            <span style={{ color: '#D24667' }}>*</span>
          </span>
        ),
        key: 'lastName',
        placeholder: 'Smith',
        type: 'text',
        required: true,
      },
      {
        text: (
          <>
            {/* {showMore && <BdayIcon />} */}
            <BdayIcon />
            <span className="edit-patient-label">
              {' '}
              Birthday
              <span style={{ color: '#D24667' }}>*</span>
            </span>
          </>
        ),
        key: 'dateOfBirth',
        type: 'date',
        min: new Date(1900, 1, 1),
        max: new Date(),
        required: true,
      },
      {
        text: (
          <>
            {/* {showMore && <GenderIcon />}*/}
            <GenderIcon />
            <span className="edit-patient-label">
              {' '}
              Gender
              <span style={{ color: '#D24667' }}>*</span>
            </span>
          </>
        ),
        key: 'gender',
        type: 'select',
        emptyOption: '<Choose gender>',
        select: genderEnum,
        required: true,
      },
      {
        text: (
          <span className="edit-patient-label">
            MRN
            <span style={{ color: '#D24667' }}>*</span>
          </span>
        ),
        key: 'mrn',
        placeholder: '1234',
        type: 'text',
        required: true,
      },
      {
        text: (
          <>
            {/* {showMore && <PlaceIcon />}*/}
            <PlaceIcon />
            <span className="edit-patient-label">
              {' '}
              Address
              <span style={{ color: '#D24667' }}>*</span>
            </span>
          </>
        ),
        key: 'address',
        placeholder: '12 Sesame st., New York, NY',
        type: 'special',
        required: true,
        component: (
          <div className="address">
            <GooglePlacesAutocomplete
              selectProps={{
                components: { DropdownIndicator: null },
                value: { label: patient.address, value: patient.address },
                inputId: 'address-places',
                styles: customStyles,
                onChange: (adrs) => {
                  setPatient({ ...patient, address: adrs.value.description })
                },
              }}
            />
          </div>
        ),
      },
      {
        text: (
          <>
            {/* {showMore && <PhoneIcon />}*/}
            <PhoneIcon />
            <span className="edit-patient-label">
              {' '}
              Phone
              <span style={{ color: '#D24667' }}>*</span>
            </span>
          </>
        ),
        key: 'phone',
        placeholder: '+123456789',
        disabled: patient.pending,
        type: 'text',
        required: true,
        invalid: invalidPhone,
        invalidMessage: invalidPhone && 'Invalid phone',
      },
      {
        text: (
          <>
            {/* {showMore && <MailIcon />}*/}
            <MailIcon />
            <span className="edit-patient-label"> Email</span>
          </>
        ),
        key: 'email',
        placeholder: 'mail@mail.com',
        type: 'text',
        invalid: invalidMail,
        invalidMessage: invalidMail && 'Invalid mail',
      },
      {
        text: (
          <>
            {/* {showMore && <RegisterQuesIcon />}*/}
            <RegisterQuesIcon />
            <span className="edit-patient-label"> Phq score</span>
          </>
        ),
        key: 'phqScore',
        placeholder: '1234',
        type: 'number',
      },
      {
        text: (
          <>
            <RegisterQuesIcon />
            <span className="edit-patient-label"> Gad 7 score</span>
          </>
        ),
        key: 'gad7Score',
        placeholder: '1234',
        type: 'number',
        border: true,
      },
      {
        text: 'Patient messaging',
        key: 'patientMessaging',
        type: 'checkbox',
      },
      {
        text: 'Choose peripheral device',
        key: 'peripheralDevice',
        type: 'select',
        emptyOption: '<Choose peripheral device>',
        select: {
          wirelessScale: 'Wireless scale',
          null: 'None',
        },
        border: patient.peripheralDevice !== 'wirelessScale',
      },
      {
        text: '',
        type: 'text',
        key: 'scaleId',
        placeholder: 'Device ID',
        getVisible: (d) => d.peripheralDevice === 'wirelessScale',
        invalidMessage: null,
        invalid: invalidScale,
      },
      {
        text: '',
        type: 'text',
        key: 'confirmScaleId',
        placeholder: 'Confirm device ID',
        getVisible: (d) => d.peripheralDevice === 'wirelessScale',
        border: true,
        invalidMessage: null,
        invalid: invalidScale,
      },
      {
        text: 'Tags',
        type: 'special',
        key: 'tags',
        component: (
          <div>
            <Select
              //   menuIsOpen={true}
              styles={customStyles}
              components={{
                DropdownIndicator,
              }}
              menuPlacement={'top'}
              closeMenuOnSelect={false}
              onChange={(t) => {
                let tag = t[0].label
                const index =
                  patient.diagnosis && patient.diagnosis.indexOf(tag)
                setPatient({
                  ...patient,
                  diagnosis: patient.diagnosis
                    ? index === -1
                      ? [...patient.diagnosis, tag]
                      : patient.diagnosis.filter((a) => a.label !== t[0].label)
                    : [tag],
                })
              }}
              noOptionsMessage={() => {
                return (
                  <div className="no-diagnosis-container">
                    <div className="no-diagnosis">No existing tag</div>
                    {!!tagSearchValue.length && (
                      <div
                        className="create-new-diagnosis link-click small-font"
                        onClick={() => {
                          setPatient({
                            ...patient,
                            diagnosis: patient.diagnosis
                              ? [...patient.diagnosis, tagSearchValue]
                              : [tagSearchValue],
                          })
                        }}
                      >
                        Create new tag
                        {' "'}
                        <b>{tagSearchValue}</b>
                        {'"'}
                      </div>
                    )}
                  </div>
                )
              }}
              placeholder={'<Type or choose tags>'}
              onInputChange={(e) => {
                setTagSearchValue(e)
              }}
              value={null}
              isMulti={true}
              options={
                patient.diagnosis &&
                patient.diagnosis.map((d, i) => {
                  return {
                    value: i,
                    label: d,
                  }
                })
              }
            />
            <section className="selected-element-container">
              {patient.diagnosis &&
                patient.diagnosis.map((tag, i) => {
                  return (
                    <SelectedElement
                      key={i}
                      onDelete={() => {
                        setPatient({
                          ...patient,
                          diagnosis: patient.diagnosis.filter((t) => t != tag),
                        })
                      }}
                      placeholder={tag}
                    >
                      {tag}
                    </SelectedElement>
                  )
                })}
            </section>
          </div>
        ),
      },
    ]

    return fields
  }

  const _height = '36px'
  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      borderColor: '#dadcdf !important',
      borderRadius: '5px',
      minHeight: _height,
      height: _height,
      minWidth: '240px',
      boxShadow: state.isFocused ? null : null,
    }),
    valueContainer: (provided, state) => ({
      ...provided,
      height: _height,
      padding: '0 6px',
    }),
    groupHeading: (provided, state) => ({
      ...provided,
      color: '#5F6368',
      fontFamily: 'Avenir',
      fontSize: '13px',
      fontHeight: '300',
      letterSpacing: '0',
      lineHeight: '18px',
    }),
    input: (provided, state) => ({
      ...provided,
      margin: '0px',
    }),
    indicatorSeparator: (state) => ({
      display: 'none',
    }),
    indicatorsContainer: (provided, state) => ({
      ...provided,
      height: _height,
    }),
    menu: (provided, state) => ({
      ...provided,
      marginTop: 0,
    }),
  }

  const pctmStyle = {
    ...customStyles,
    control: (provided, state) => ({
      ...provided,
      borderColor: !pctmMissing ? '#dadcdf !important' : '#D24667',
      borderRadius: '5px',
      minHeight: _height,
      height: _height,
      boxShadow: state.isFocused ? null : null,
    }),
    option: (provided, state) => {
      return {
        ...provided,
        '&:hover': {
          backgroundColor: '#f9feff',
        },
        backgroundColor:
          state.data.value !== patient.pctm ? 'inherit' : '#E7EBED !important',
        cursor: 'pointer',
      }
    },
  }

  const careTeamStyle = {
    ...customStyles,
    option: (provided, state) => {
      return {
        ...provided,
        '&:hover': {
          backgroundColor: '#f9feff',
        },
        backgroundColor:
          state.data.value !== patient.pctm ? 'inherit' : '#E7EBED !important',
        cursor: 'pointer',
      }
    },
  }

  return (
    <Popup
      title="Edit patient"
      className={'edit-patient-popup '}
      buttons={[
        {
          text: 'Cancel',
          type: 'link',
          onClick: handleCancelEdit,
        },
        {
          text: 'Save',
          type: 'primary',
          // disable: disabledSaveBtn,
          onClick: () => {
            if (!checkPatientData()) {
              return
            }

            setDisabledSaveBtn(true)
            const email =
              patient.email && patient.email.length ? patient.email : undefined

            const date =
              typeof patient.dateOfBirth === 'string'
                ? new Date(patient.dateOfBirth)
                : patient.dateOfBirth
            const dateOfBirth = new Date(
              Date.UTC(
                date.getFullYear(),
                date.getMonth(),
                date.getDate(),
                12,
                0,
                0,
              ),
            ).toISOString()
            let data
            if (!patient.pending && patient.id) {
              data = {
                profileId: patient.profileId,
                firstName: patient.firstName,
                lastName: patient.lastName,
                address: patient.address,
                gender: patient.gender,
                phone: getFormatedPhone(patient.phone),
                email: email,
                dateOfBirth,
                mrn: patient.mrn,
                diagnosis: patient.diagnosis,
                scaleId:
                  (patient.peripheralDevice === 'wirelessScale' &&
                    patient.scaleId) ||
                  undefined,
                questionnaires: getQuestionnaires(),
                disableChat: !patient.patientMessaging,
              }
            } else {
              data = {
                profile: {
                  firstName: patient.firstName,
                  lastName: patient.lastName,
                  address: patient.address,
                  gender: patient.gender,
                  phone: getFormatedPhone(patient.phone),
                  email: email,
                  dateOfBirth,
                },
                treatment: {
                  mrn: patient.mrn,
                  diagnosis: patient.diagnosis,
                  scaleId:
                    (patient.peripheralDevice === 'wirelessScale' &&
                      patient.scaleId) ||
                    undefined,
                  questionnaires: getQuestionnaires(),
                },
                careTeam: {
                  pctmId: patient.pctm,
                  members: patient.careTeam.map((cm) => {
                    return { ctmId: cm }
                  }),
                },
                planTemplateIds: patient.plans,
              }
            }

            updatePatient(data, patient.id, (errorKey) => {
              setDisabledSaveBtn(false)
              if (errorKey === 'email') {
                setInvalidMail(true)
                setDisplayError(true)
              }
            })
          },
        },
      ]}
      onClosed={handleCancelEdit}
    >
      <div className="edited-patient-popup">
        <FormComponent
          data={patient}
          handleDataChange={(_patient) => {
            if (invalidMail && _patient.email !== patient.email) {
              setInvalidMail(false)
            }
            setPatient(_patient)
          }}
          fields={getFields()}
          displayInvalid={displayError}
        />
      </div>
    </Popup>
  )
}

EditPatientPopup.propTypes = {
  editedPatient: PropTypes.object,
  planTemplates: PropTypes.object,
  careTeam: PropTypes.object,
  loadPlanTemplates: PropTypes.func,
  loadProgramProviders: PropTypes.func,
  loadPatientCareTeam: PropTypes.func,
  loadPlans: PropTypes.func,
  updatePatient: PropTypes.func,
  handleCancelEdit: PropTypes.func,
}

export default EditPatientPopup

const SelectedElement = ({ onDelete, placeholder, children }) => {
  return (
    <div className="selected-element">
      <span className="selected-element-title" title={placeholder}>
        {children}
      </span>
      <IconButton onClick={onDelete} type="close" />
    </div>
  )
}
