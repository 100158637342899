export const TIME_UNIT = [
  { value: 'minutes', text: 'Minutes' },
  { value: 'hours', text: 'Hours' },
  { value: 'days', text: 'Days' },
  { value: 'weeks', text: 'Weeks' },
  { value: 'months', text: 'Months' },
  { value: 'years', text: 'Years' },
]
export const CONDITION_ARRAY = [
  { value: 'greater_than', text: 'Greater Than', scoreType: 'one' },
  {
    value: 'greater_than_or_equal',
    text: 'Greater Than Or Equal',
    scoreType: 'one',
  },
  { value: 'less_than', text: 'Less Than', scoreType: 'one' },
  { value: 'less_than_or_equal', text: 'Less Than Or Equal', scoreType: 'one' },
  { value: 'equal', text: 'Equal', scoreType: 'one' },
  { value: 'not_equal', text: 'Not Equal', scoreType: 'one' },
  { value: 'between', text: 'Between', scoreType: 'two' },
  { value: 'not_between', text: 'Not Between', scoreType: 'two' },
]
export const FAILED_ERROR_TEXT = 'Please insert valid value'
export const UNCHANGED_LOCATION = 'unchanged_location'
export const ASSESSMENT_SCORE = 'assessment_score'
export const ASSESSMENT_ANSWER = 'assessment_answer'
export const INCOMPLETE_ASSESSMENT = 'incomplete_assessment'
export const CHAT_DISABLED = 'chat_disabled'
