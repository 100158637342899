import React from 'react'
import InputError from '../../../../../../common/InputError/InputError'
import * as constants from '../constants'

const EscalationWindow = ({
  item,
  changeTimeWindow,
  allowedTimes = ['days'],
  withEmptyField,
  error,
}) => {
  return (
    <>
      <div className="rule-filed">
        <div className="rule-label">Escalation window</div>
        <div className="rule-input">
          <select
            className="small-input"
            disabled={false}
            value={(item.escalationWindow && item.escalationWindow.unit) || ''}
            onChange={(e) => changeTimeWindow(e, 'escalationWindow', 'unit')}
          >
            <option
              value="null"
              hidden={!withEmptyField}
              disabled={!withEmptyField}
            >
              Select unit
            </option>
            {constants.TIME_UNIT.filter(
              (unit) => allowedTimes.indexOf(unit.value) !== -1,
            ).map((unit) => {
              return (
                <option
                  key={
                    'location_time_escalation_window_' +
                    item.id +
                    '_' +
                    unit.value
                  }
                  value={unit.value}
                >
                  {unit.text}
                </option>
              )
            })}
          </select>
          <input
            type="number"
            min="0"
            className="small-input not-first"
            placeholder="Value"
            value={item.escalationWindow && item.escalationWindow.value}
            onChange={(e) => changeTimeWindow(e, 'escalationWindow', 'value')}
          />
        </div>
      </div>
      <InputError
        addClass="escalationWindowError"
        showError={error && error.escalationWindow}
        text={constants.FAILED_ERROR_TEXT}
      />
    </>
  )
}
export default EscalationWindow
