import React from 'react'
import PropTypes from 'prop-types'
import { useOptionsContext } from './optionsContext'
import IconButton from '../../common/IconButton/IconButton'
import SearchInput from '../SearchInput'

export default function TableMenu({ handleSearch }) {
  const options = useOptionsContext()
  return (
    <div className={options.menuClass || 'basic-menu'}>
      {options.addNew && (
        <div className="basic-add-new-line-button">
          <IconButton
            onClick={options.addNew.onclick}
            type={'plus'}
            labelText={options.addNew.text}
          />
        </div>
      )}
      {options.search && (
        <SearchInput handleChange={handleSearch} searchPlaceholder="Search" />
        // <input
        //   type="text"
        //   className="input-search"
        //   placeholder="&#128269; Search"
        //   onKeyUp={handleSearch}
        // />
      )}
      {options.buttons && (
        <div className="table-menu-buttons-container">
          {options.buttons.map((btn, i) => {
            return (
              <div className="table-menu-button" onClick={btn.onclick} key={i}>
                {btn.content}
              </div>
            )
          })}
        </div>
      )}
      <div className="clear"></div>
    </div>
  )
}

TableMenu.propTypes = {
  handleChangeEdit: PropTypes.func,
  handleSearch: PropTypes.func,
}
