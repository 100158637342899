import React from 'react'
import defaultAvatar from '../../image/user_male.jpeg'

const UserPicture = ({ data }) => {
  return (
    <span style={{ position: 'relative' }}>
      <img
        style={{
          width: '40px',
          height: '40px',
          borderRadius: '40px',
        }}
        src={data.picture || defaultAvatar}
        onError={(e) => {
          e.target.onerror = null
          e.target.src = defaultAvatar
        }}
      />
    </span>
  )
}

export default UserPicture
