import React from 'react'
import { Route, Switch, useLocation, useHistory } from 'react-router-dom'
import Home from './Home/Home'
import UserMessages from './GlobalComponent/userMesage/userMessages'
import Login from './Login/Login'
import '../globalStyle/guideline.less'
import '../globalStyle/overflow.less'
import '../globalStyle/modal.less'
import * as storageConfig from '../api/storageConfig'
import { redirectToAWSLoginPage } from './utilities'

function App() {
  const location = useLocation()
  const history = useHistory()

  if (!storageConfig.getToken()) {
    const urlParams = new URLSearchParams(location.hash)
    const accessToken = urlParams.get('access_token')

    if (!accessToken) {
      redirectToAWSLoginPage()
      return null
    }
    storageConfig.setToken(`Bearer ${accessToken}`)
    history.replace({ search: '' })
  }

  return (
    <>
      <UserMessages />
      <Switch>
        <Route path="/login" render={(props) => <Login {...props} />} />
        <Route path="/home" render={(props) => <Home {...props} />} />
        <Route render={(props) => <Home {...props} />} />
      </Switch>
    </>
  )
}

export default App
