import React, { useEffect, useState } from 'react'
import moment from 'moment-timezone'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import {
  Checkbox,
  ChipMultiSelect,
  FormControlLabel,
  FormGroup,
  Radio,
  RadioGroup,
  TextField,
  TimePicker,
} from '@valerahealth/ui-components/base'
import * as tenantsActions from '../../../../../../redux/actions/tenantsActions'
import * as globalAction from '../../../../../../redux/actions/globalAction'
import IconButton from '../../../../../common/IconButton/IconButton'
import InputFreeText from '../../../../../common/Input/InputFreeText'
import InputCheckBox from '../../../../../common/Input/InputCheckBox'
import ButtonNew from '../../../../../common/Button/ButtonNew'
import InputError from '../../../../../common/InputError/InputError'

import CallToAction from '../../../../../common/CallToAction/CallToAction'
import ChipsItem from '../../../../../common/ChipsItem/ChipsItem'
import AddInputField from '../../../../../common/AddInputField/AddInputField'
import SelectFormsItems from '../../../../../GlobalComponent/SelectFormsItems/SelectFormsItems'
import '../tab-page-style.less'
import { getTenantsById } from '../../../../../utilities'
import './style.css'
import AppointmentNotifications from './AppointmentNotifications'

function Setting({
  securityRoles,
  settings,
  questionnairesList,
  programPrimaryForm,
  programPrimaryForms,
  loadSecurityRoles,
  loadProgramSetting,
  loadProgramQuestionnaires,
  saveSetting,
  match,
  addUserMessage,
}) {
  const tenantId = match.params.id
  const programId = match.params.pId
  const [cancelValue, setCancelValue] = useState(0)
  const [chatConfigurationView, setChatConfigurationView] = useState({
    highlighted_words: false,
    disableMessaging: false,
  })
  const [goalReminderView, setGoalReminderView] = useState({
    state: false,
    templates: [],
  })
  const [adminEmailsView, setAdminEmailsView] = useState([])
  const [emergencyNumberView, setEmergencyNumberView] = useState('')

  const [timeZoneView, setTimeZoneView] = useState('')
  const availableTimezones = [
    ...moment.tz.zonesForCountry('us'),
    ...moment.tz.zonesForCountry('il'),
  ]

  const [offlineNotifications, setOfflineNotifications] = useState({})

  const [startAppointmentView, setStartAppointmentView] = useState({
    modalIsOpen: false,
    name: '',
    form: [],
    modalFormList: [],
  })

  const [postAppointmentView, setPostAppointmentView] = useState({
    modalIsOpen: false,
    name: '',
    form: [],
    modalFormList: [],
  })
  const [appointmentOptions, setAppointmentOptions] = useState({
    editAppointmentUrl: 'internal',
    scheduleAppointmentUrl: 'internal',
    emailBody: '',
    promptOnDeclineActive: false,
    promptOnDeclineBeforeDate: { value: 24, unit: 'hours' },
    reschedulePrompt: 'free_text',
    removeAppointment: false,
  })

  const [appointmentNotifications, setAppointmentNotifications] = useState()

  const [programPrimaryFormView, setProgramPrimaryFormView] = useState({})
  const [programPrimaryFormModalListView, setProgramPrimaryFormModalListView] =
    useState([])
  const [settingErrorField, setSettingErrorField] = useState({})
  const [programPrimaryFormsView, setProgramPrimaryFormsView] = useState([])
  const [settingsSecurityRoles, setSettingsSecurityRoles] = useState([])

  function changeQuestionnairesListHandler(isCancel) {
    setProgramPrimaryFormView({
      name:
        programPrimaryForm && programPrimaryForm.name
          ? programPrimaryForm.name
          : '',
      forms:
        programPrimaryForm && programPrimaryForm.forms
          ? programPrimaryForm.forms
          : [],
    })
    if (programPrimaryForms && programPrimaryForms.length) {
      if (!programPrimaryFormsView.length && !isCancel) {
        setProgramPrimaryFormsView(programPrimaryForms)
      } else if (isCancel) {
        setProgramPrimaryFormsView(programPrimaryForms)
      }
    }
  }
  useEffect(() => {
    changeQuestionnairesListHandler()
  }, [questionnairesList, programPrimaryForm, programPrimaryForms])

  function questionnairesListAppointmentChangeHandler(isCancel) {
    if (questionnairesList && settings) {
      if (settings.appointments) {
        setStartAppointmentView({
          modalIsOpen: false,
          name: settings.appointments.form_name
            ? settings.appointments.form_name
            : '',
          form: questionnairesList.filter(
            (quest) => quest.id === settings.appointments.formTemplateId,
          ),
          modalFormList: [],
        })

        setAppointmentOptions({
          ...appointmentOptions,
          showConfirmation: settings.appointments.showConfirmation,
          showReschedule: settings.appointments.showReschedule,
          editAppointmentUrl: settings.appointments.editAppointmentUrl,
          scheduleAppointmentUrl: settings.appointments.scheduleAppointmentUrl,
          emailBody: settings.appointments.appointmentConfirmationEmailBody,
          cantMakeIt:
            settings.appointments.appointmentConfirmationCantMakeItText,
          reschedulePrompt:
            settings.appointments.reschedulePrompt ||
            appointmentOptions?.reschedulePrompt,
          promptOnDeclineActive: settings.appointments.promptOnDeclineActive,
          promptOnDeclineBeforeDate:
            settings.appointments.promptOnDeclineBeforeDate,
          removeAppointment:
            settings.appointments.removeRequestAppointment.active,
        })

        if (settings.appointments.notifications) {
          setAppointmentNotifications(settings.appointments.notifications)
        } else setAppointmentNotifications([])
      }
      if (settings.postAppointment) {
        setPostAppointmentView({
          modalIsOpen: false,
          name: settings.postAppointment.form_name
            ? settings.postAppointment.form_name
            : '',
          form: questionnairesList.filter(
            (quest) => quest.id === settings.postAppointment.formTemplateId,
          ),
          modalFormList: [],
        })
      }
    }
  }
  useEffect(() => {
    questionnairesListAppointmentChangeHandler()
  }, [questionnairesList, settings])

  function settingChangeHandler(isCancel) {
    if (!settings) {
      return
    }

    if (settings.goalReminder) {
      setGoalReminderView({
        state: !!(settings.goalReminder.state === 'on'),
        templates:
          settings.goalReminder.templates &&
          settings.goalReminder.templates.length
            ? settings.goalReminder.templates.map((item, index) => {
                return { text: item, id: new Date().getTime() + index }
              })
            : [],
      })
    }

    if (settings.adminEmails) {
      setAdminEmailsView(
        settings.adminEmails
          ? settings.adminEmails.map((email, index) => {
              return { text: email, id: new Date().getTime() + index }
            })
          : [],
      )
    }

    if (settings.chat) {
      const chatObj = {}
      chatObj.highlighted_words = !!(settings.chat.highlighted_words === 'on')

      if (settings.chat.disableChat) {
        chatObj.disableMessaging = settings.chat.disableChat.active
        chatObj.chatEnabled = !settings.chat.disableChat.disableByDefault
      } else {
        chatObj.disableMessaging = false
        chatObj.chatEnabled = true
      }

      setChatConfigurationView(chatObj)
    }

    if (settings.emergencyNumber) {
      setEmergencyNumberView(settings.emergencyNumber)
    }

    if (settings.timeZone) {
      setTimeZoneView(settings.timeZone)
    }

    if (settings.offlineNotifications) {
      let hour = 18
      let minute = 0
      if (settings.offlineNotifications?.dailyDigest) {
        hour = settings.offlineNotifications.dailyDigest.hour
        minute = settings.offlineNotifications.dailyDigest.minute
      }

      setOfflineNotifications({
        dailyDigest: new Date(0, 0, 0, hour, minute),
      })
    }

    if (settings.permitSecurityRoles) {
      setSettingsSecurityRoles(settings.permitSecurityRoles)
    }
  }

  useEffect(() => {
    settingChangeHandler()
  }, [settings])

  useEffect(() => {
    if (!securityRoles) {
      loadSecurityRoles(tenantId, programId)
    }
  }, [securityRoles])

  function changeTenantHandler() {
    if (!settings) {
      loadProgramSetting(tenantId, programId)
    }
    if (!questionnairesList) {
      loadProgramQuestionnaires(tenantId, programId)
    }
  }

  useEffect(() => {
    changeTenantHandler()
  }, [tenantId])

  useEffect(() => {
    changeQuestionnairesListHandler(true)
    changeTenantHandler(true)
    settingChangeHandler(true)
    questionnairesListAppointmentChangeHandler(true)
    setSettingErrorField({})
  }, [cancelValue])

  if (!settings) return <div />

  function addAlertAssessment() {
    setProgramPrimaryFormModalListView(
      questionnairesList.map((item) => {
        if (
          !programPrimaryFormView.forms ||
          !programPrimaryFormView.forms.length
        ) {
          return { isSelected: false, name: item.name, id: item.id }
        }
        const isSelected = !!programPrimaryFormView.forms.find(
          (formItem) => formItem.id === item.id,
        )
        return { isSelected, name: item.name, id: item.id }
      }),
    )

    setProgramPrimaryFormView({ ...programPrimaryFormView, modalIsOpen: true })
  }

  function addStartAppointmentForm() {
    setStartAppointmentView({
      ...startAppointmentView,
      modalIsOpen: true,
      modalFormList: questionnairesList.map((item) => {
        return {
          isSelected: !!(
            startAppointmentView.form &&
            startAppointmentView.form[0] &&
            item.id === startAppointmentView.form[0].id
          ),
          name: item.name,
          id: item.id,
        }
      }),
    })
  }

  function addPostAppointmentForm() {
    setPostAppointmentView({
      ...postAppointmentView,
      modalIsOpen: true,
      modalFormList: questionnairesList.map((item) => {
        return {
          isSelected: !!(
            postAppointmentView.form &&
            postAppointmentView.form[0] &&
            item.id === postAppointmentView.form[0].id
          ),
          name: item.name,
          id: item.id,
        }
      }),
    })
  }

  function closeAddFormsAlertAssessmentModal() {
    setProgramPrimaryFormView({ ...programPrimaryFormView, modalIsOpen: false })
  }

  function closeAddStartAppointmentForm() {
    setStartAppointmentView({
      ...startAppointmentView,
      modalIsOpen: false,
      modalFormList: [],
    })
  }

  function closeAddPostAppointmentForm() {
    setStartAppointmentView({
      ...postAppointmentView,
      modalIsOpen: false,
      modalFormList: [],
    })
  }

  function deleteStartAppointmentForm(id) {
    setStartAppointmentView({
      modalIsOpen: false,
      name: '',
      form: startAppointmentView.form.filter((f) => f.id !== id),
      modalFormList: startAppointmentView.modalFormList.map((e) => {
        e.id !== id ? e : { ...e, isSelected: false }
      }),
    })
  }

  function deletePostAppointmentForm(id) {
    setPostAppointmentView({
      modalIsOpen: false,
      name: '',
      form: postAppointmentView.form.filter((f) => f.id !== id),
      modalFormList: postAppointmentView.modalFormList.map((e) => {
        e.id !== id ? e : { ...e, isSelected: false }
      }),
    })
  }

  function changeCheckFormPrimaryFormModal(id) {
    setProgramPrimaryFormModalListView(
      programPrimaryFormModalListView.map((item) => {
        if (id === item.id) {
          return { ...item, isSelected: !item.isSelected }
        }
        return item
      }),
    )
  }

  function changeCheckStartAppointmentFormModal(id) {
    setStartAppointmentView({
      ...startAppointmentView,
      modalFormList: startAppointmentView.modalFormList.map((item) => {
        return {
          ...item,
          isSelected: item.id !== id ? false : !item.isSelected,
        }
      }),
    })
  }

  function changeCheckPostAppointmentFormModal(id) {
    setPostAppointmentView({
      ...postAppointmentView,
      modalFormList: postAppointmentView.modalFormList.map((item) => {
        return {
          ...item,
          isSelected: item.id !== id ? false : !item.isSelected,
        }
      }),
    })
  }

  function saveStartAppointmentChange() {
    const selectedForms = startAppointmentView.modalFormList.filter(
      (item) => item.isSelected,
    )
    setStartAppointmentView({
      modalIsOpen: false,
      name: selectedForms ? selectedForms.name : '',
      form: selectedForms || [],
      modalFormList: [],
    })
  }

  function savePostAppointmentChange() {
    const selectedForms = postAppointmentView.modalFormList.filter(
      (item) => item.isSelected,
    )

    setPostAppointmentView({
      modalIsOpen: false,
      name: selectedForms ? selectedForms.name : '',
      form: selectedForms || [],
      modalFormList: [],
    })
  }

  function changeCheckProgramPrimaryFormModal(itemId, formId) {
    setProgramPrimaryFormsView(
      programPrimaryFormsView.map((programItem) => {
        if (programItem.id === itemId) {
          return {
            ...programItem,
            modalList: programItem.modalList.map((item) => {
              if (formId === item.id) {
                return { ...item, isSelected: !item.isSelected }
              }
              return item
            }),
          }
        }
        return programItem
      }),
    )
  }

  function saveAlertAssessmentChange() {
    const newPrimaryFormList = {
      ...programPrimaryFormView,
      modalIsOpen: false,
      forms: programPrimaryFormModalListView.filter((item) => item.isSelected),
    }
    setProgramPrimaryFormView(newPrimaryFormList)
  }

  function saveProgramAssessmentChange(itemId) {
    setProgramPrimaryFormsView(
      programPrimaryFormsView.map((item) => {
        if (item.id === itemId) {
          return {
            ...item,
            modalIsOpen: false,
            forms: item.modalList.filter((item) => item.isSelected),
            modalList: [],
          }
        }
        return item
      }),
    )
  }

  function deleteAlertAssessmentItem(id) {
    const newPrimaryFormList = {
      ...programPrimaryFormView,
      forms: programPrimaryFormView.forms.filter((item) => item.id !== id),
    }
    setProgramPrimaryFormView(newPrimaryFormList)
  }

  const changeAlertAssessmentName = (event) => {
    const { value } = event.target
    setProgramPrimaryFormView({ ...programPrimaryFormView, name: value })
  }

  const changeEmergencyNumber = (event) => {
    const { value } = event.target
    setEmergencyNumberView(value.replace(/\D/, ''))
  }

  const changeTimeZone = (event) => {
    const { value } = event.target
    setTimeZoneView(value)
  }

  const changeProgramAssessmentName = (event) => {
    const newValue = event.target.value
    const programAssessmentId = event.target.id
    setProgramPrimaryFormsView(
      programPrimaryFormsView.map((item) => {
        if (item.id === parseInt(programAssessmentId)) {
          return { ...item, name: newValue }
        }
        return item
      }),
    )
  }

  function addProgramAssessment() {
    setProgramPrimaryFormsView(
      programPrimaryFormsView.concat({
        name: '',
        forms: [],
        id: new Date().getTime() + programPrimaryFormsView.length,
      }),
    )
  }

  function addProgramAssessmentForm(itemId) {
    setProgramPrimaryFormsView(
      programPrimaryFormsView.map((programItem) => {
        if (programItem.id === itemId) {
          return {
            ...programItem,
            modalIsOpen: true,
            modalList: questionnairesList.map((item) => {
              const isSelected = !!programItem.forms.find(
                (formItem) => formItem.id === item.id,
              )
              return { isSelected, name: item.name, id: item.id }
            }),
          }
        }
        return programItem
      }),
    )
  }

  function closeAddFormsProgramAssessmentModal(itemId) {
    setProgramPrimaryFormsView(
      programPrimaryFormsView.map((item) => {
        if (item.id === itemId) {
          return { ...item, modalIsOpen: false, modalList: [] }
        }
        return item
      }),
    )
  }

  function deleteProgramAssessmentFormItem(programId, formId) {
    setProgramPrimaryFormsView(
      programPrimaryFormsView.map((item) => {
        if (item.id === programId) {
          return {
            ...item,
            forms: item.forms.filter((item) => item.id !== formId),
          }
        }
        return item
      }),
    )
  }

  function deleteProgramAssessmentItem(id) {
    setProgramPrimaryFormsView(
      programPrimaryFormsView.filter((item) => item.id !== id),
    )
  }

  function deletePresetGoal(presetId) {
    setGoalReminderView({
      ...goalReminderView,
      templates: goalReminderView.templates.filter(
        (item) => item.id !== presetId,
      ),
    })
  }

  function addGoalReminderPreset() {
    setGoalReminderView({
      ...goalReminderView,
      templates: goalReminderView.templates.concat({
        text: '',
        id: new Date().getTime() + goalReminderView.templates.length,
      }),
    })
  }

  function addAdminEmail() {
    setAdminEmailsView(
      adminEmailsView.concat({
        text: '',
        id: new Date().getTime() + adminEmailsView.length,
      }),
    )
  }

  const onCheckboxGoalReminderChange = () => {
    setGoalReminderView({ ...goalReminderView, state: !goalReminderView.state })
  }

  const onCheckboxHighlightedWordsChange = () => {
    setChatConfigurationView({
      ...chatConfigurationView,
      highlighted_words: !chatConfigurationView.highlighted_words,
    })
  }

  const onCheckboxPatientMessagingChange = () => {
    setChatConfigurationView({
      ...chatConfigurationView,
      disableMessaging: !chatConfigurationView.disableMessaging,
      chatEnabled: true,
    })
  }

  const onCheckboxChatEnabledChange = () => {
    setChatConfigurationView({
      ...chatConfigurationView,
      chatEnabled: !chatConfigurationView.chatEnabled,
    })
  }

  const changeAppLink = (event) => {
    setAppointmentOptions({
      ...appointmentOptions,
      editAppointmentUrl: event.target.value,
      scheduleAppointmentUrl: event.target.value,
    })
  }

  function changePreset(presetId, event) {
    const { value } = event.target
    setGoalReminderView({
      ...goalReminderView,
      templates: goalReminderView.templates.map((preset) => {
        if (preset.id === presetId) {
          return { ...preset, text: value }
        }
        return preset
      }),
    })
  }

  function changeEmail(emailId, event) {
    const { value } = event.target
    setAdminEmailsView(
      adminEmailsView.map((email) => {
        if (email.id === emailId) {
          return { ...email, text: value }
        }
        return email
      }),
    )
  }

  function deleteAdminEmail(emailId) {
    setAdminEmailsView(adminEmailsView.filter((item) => item.id !== emailId))
  }

  function getClassNameDependNumOfChild(items) {
    return (
      `form-list-items-wrap ` +
      `item-${items && items.length ? items.length : '0'}`
    )
  }

  const wrapAddInputFieldStyle = { width: '308px' }

  function saveProgramSettingsChange() {
    setSettingErrorField({})
    const errorObject = { primaryFormsError: {} }
    setSettingErrorField(errorObject)
    let validSettings = true
    function addErrorFieldPrimaryForms(name, text, item) {
      validSettings = false
      if (errorObject.primaryFormsError[item.id]) {
        errorObject.primaryFormsError[item.id][name] = text
      } else {
        errorObject.primaryFormsError[item.id] = {}
        errorObject.primaryFormsError[item.id][name] = text
      }
    }

    function addErrorField(name, text) {
      if (name && text) {
        validSettings = false
        errorObject[name] = text
      }
    }
    // Init error object

    // Todo need validate the settings!!!!
    /*
        if "primaryForm" not exist validate error!
        * */
    if (!programPrimaryFormView.name) {
      addErrorField('primaryFormName', 'Primary form name is required')
    }

    if (!programPrimaryFormView.forms || !programPrimaryFormView.forms.length) {
      addErrorField('primaryFormForms', "Primary forms can't be empty")
    }

    if (!programPrimaryFormsView.length) {
      addErrorField(
        'primaryFormsForm',
        'Primary forms must include least one item',
      )
    }

    programPrimaryFormsView.forEach((form) => {
      if (!form.name) {
        addErrorFieldPrimaryForms(
          'name',
          'Primary forms name is required',
          form,
        )
      }

      if (!form.forms || !form.forms.length) {
        addErrorFieldPrimaryForms('forms', "Primary forms can't be empty", form)
      }
    })

    if (!validSettings) {
      setSettingErrorField(errorObject)
      return addUserMessage('warning', 'Program setting is not valid')
    }

    const newSettings = {
      ...settings,
      primaryForm: {
        form_name:
          programPrimaryFormView && programPrimaryFormView.name
            ? programPrimaryFormView.name
            : null,
        formTemplateIds: programPrimaryFormView.forms.map((item) => item.id),
      },
      primaryForms: programPrimaryFormsView.map((item) => {
        return {
          form_name: item.name,
          formTemplateIds: item.forms.map((item) => item.id),
        }
      }),
      goalReminder: {
        state: goalReminderView.state ? 'on' : 'off',
        templates: goalReminderView.templates
          .map((item) => item.text)
          .filter((item) => !item.text || !item.text.length), // Todo check if need delete duplicate preset.
      },
      adminEmails: adminEmailsView
        .map((item) => item.text)
        .filter((item) => !item.text || !item.text.length),
      emergencyNumber: emergencyNumberView,
      timeZone: timeZoneView,
      chat: {
        highlighted_words: chatConfigurationView.highlighted_words
          ? 'on'
          : 'off',
        disableChat: {
          active: chatConfigurationView.disableMessaging,
          disableByDefault: !chatConfigurationView.disableMessaging
            ? undefined
            : !chatConfigurationView.chatEnabled,
        },
      },
      appointments: {
        formTemplateId:
          startAppointmentView.form[0] && startAppointmentView.form[0].id
            ? startAppointmentView.form[0].id
            : null,
        form_name:
          startAppointmentView.form[0] && startAppointmentView.form[0].id
            ? startAppointmentView.form[0].name
            : null,
        showReschedule: appointmentOptions.showReschedule,
        showConfirmation: appointmentOptions.showConfirmation,
        editAppointmentUrl: appointmentOptions.editAppointmentUrl,
        scheduleAppointmentUrl: appointmentOptions.editAppointmentUrl, // this is not an error
        appointmentConfirmationEmailBody: appointmentOptions.emailBody,
        appointmentConfirmationCantMakeItText: appointmentOptions.cantMakeIt,
        reschedulePrompt: appointmentOptions.reschedulePrompt,
        promptOnDeclineActive: appointmentOptions.promptOnDeclineActive,
        promptOnDeclineBeforeDate: appointmentOptions.promptOnDeclineBeforeDate,
        notifications: appointmentNotifications,
        removeRequestAppointment: {
          active: appointmentOptions.removeAppointment,
        },
      },
      postAppointment: {
        formTemplateId:
          postAppointmentView.form[0] && postAppointmentView.form[0].id
            ? postAppointmentView.form[0].id
            : null,
        form_name:
          postAppointmentView.form[0] && postAppointmentView.form[0].id
            ? postAppointmentView.form[0].name
            : null,
      },
      offlineNotifications: {
        dailyDigest: {
          hour: offlineNotifications.dailyDigest.getHours(),
          minute: offlineNotifications.dailyDigest.getMinutes(),
        },
      },
      permitSecurityRoles: settingsSecurityRoles,
    }

    saveSetting(newSettings, programId, tenantId)
  }

  // settingErrorField
  return (
    <div className="program-tab-page">
      <div className="setting-title">Dashboard layout</div>
      <div className="settings-section">
        <div className="section-name">Alert assessment</div>
        <div className="section-details not-last-item">
          <div className="assessment-body-box">
            <div className="assessment-name">
              <div className="assessment-name-label">Name:</div>
              <input
                type="text"
                onChange={changeAlertAssessmentName}
                placeholder="Enter Alert Assessment Name"
                value={programPrimaryFormView.name || ''}
              />
            </div>
            <InputError
              showError={settingErrorField && settingErrorField.primaryFormName}
              text={settingErrorField.primaryFormName}
            />
            <div className="assessment-name">
              <div className="assessment-name-label">Form:</div>
              <div className="add-assessment-icon-wrap">
                <CallToAction
                  onClick={addAlertAssessment}
                  text="Add Alert Assessment Form"
                  iconType="plus"
                />
                <SelectFormsItems
                  title="Choose Alert Assessment Form"
                  isOpen={programPrimaryFormView.modalIsOpen}
                  changeCheckItem={changeCheckFormPrimaryFormModal}
                  modalFormList={programPrimaryFormModalListView}
                  closeModal={closeAddFormsAlertAssessmentModal}
                  saveOnClick={saveAlertAssessmentChange}
                />
              </div>
            </div>
            <InputError
              showError={
                settingErrorField && settingErrorField.primaryFormForms
              }
              text={settingErrorField.primaryFormForms}
            />
            <div
              className={getClassNameDependNumOfChild(
                programPrimaryFormView.forms,
              )}
            >
              {programPrimaryFormView.forms &&
                programPrimaryFormView.forms.map((item) => {
                  return (
                    <ChipsItem
                      key={item.id}
                      text={item.name}
                      deleteClick={() => deleteAlertAssessmentItem(item.id)}
                    />
                  )
                })}
            </div>
          </div>
        </div>
      </div>
      <div className="settings-section">
        <div className="section-name">Program assessment</div>
        <div>
          {programPrimaryFormsView &&
            programPrimaryFormsView.map((item) => {
              return (
                <div key={item.id} className="section-details">
                  <SelectFormsItems
                    title="Choose Program Assessment Form"
                    isOpen={item.modalIsOpen}
                    closeModal={() =>
                      closeAddFormsProgramAssessmentModal(item.id)
                    } // Todo
                    modalFormList={item.modalList}
                    changeCheckItem={(formId) =>
                      changeCheckProgramPrimaryFormModal(item.id, formId)
                    }
                    saveOnClick={() => saveProgramAssessmentChange(item.id)}
                  />
                  <div className="assessment-body-box">
                    <div className="close-assessment-body-box-icon">
                      {programPrimaryFormsView.length > 1 && (
                        <IconButton
                          onClick={() => {
                            deleteProgramAssessmentItem(item.id)
                          }}
                          type="close"
                        />
                      )}
                    </div>
                    <div className="assessment-name">
                      <div className="assessment-name-label">Name:</div>
                      <input
                        type="text"
                        onChange={changeProgramAssessmentName}
                        id={item.id}
                        value={item.name}
                        placeholder="Enter Program Assessment Name"
                      />
                    </div>
                    <InputError
                      showError={
                        settingErrorField &&
                        settingErrorField.primaryFormsError &&
                        settingErrorField.primaryFormsError[item.id] &&
                        settingErrorField.primaryFormsError[item.id].name
                      }
                      text={
                        settingErrorField &&
                        settingErrorField.primaryFormsError &&
                        settingErrorField.primaryFormsError[item.id] &&
                        settingErrorField.primaryFormsError[item.id].name
                          ? settingErrorField.primaryFormsError[item.id].name
                          : ''
                      }
                    />
                    <div className="assessment-name">
                      <div className="assessment-name-label">Form:</div>

                      <div className="add-assessment-icon-wrap">
                        <CallToAction
                          onClick={() => addProgramAssessmentForm(item.id)}
                          text="Add Program Assessment Form"
                          iconType="plus"
                        />
                      </div>
                    </div>
                    <InputError
                      showError={
                        settingErrorField &&
                        settingErrorField.primaryFormsError &&
                        settingErrorField.primaryFormsError[item.id] &&
                        settingErrorField.primaryFormsError[item.id].forms
                      }
                      text={
                        settingErrorField &&
                        settingErrorField.primaryFormsError &&
                        settingErrorField.primaryFormsError[item.id] &&
                        settingErrorField.primaryFormsError[item.id].forms
                          ? settingErrorField.primaryFormsError[item.id].forms
                          : ''
                      }
                    />
                    <div className={getClassNameDependNumOfChild(item.forms)}>
                      {item.forms &&
                        item.forms.map((form, index) => {
                          return (
                            <ChipsItem
                              key={`program_assessment_id-${index + 1}`}
                              text={form.name}
                              deleteClick={() =>
                                deleteProgramAssessmentFormItem(
                                  item.id,
                                  form.id,
                                )
                              }
                            />
                          )
                        })}
                    </div>
                  </div>
                </div>
              )
            })}

          <AddInputField
            text="Add Program Assessment"
            onClick={addProgramAssessment}
          />
        </div>
      </div>
      <div className="setting-title border-top">
        <div className="main-setting-title">Goal reminder</div>
        <div className="sub-setting-title">
          <InputCheckBox
            text="This feature is enabled"
            onChange={onCheckboxGoalReminderChange}
            checked={goalReminderView.state}
            name="gaol_reminder"
          />
        </div>
      </div>
      <div className="settings-section">
        <div className="section-name">Presets</div>
        <div className="section-details">
          {goalReminderView &&
            goalReminderView.templates &&
            goalReminderView.templates.map((preset) => (
              <InputFreeText
                key={`input-preset-${preset.id}`}
                text={preset.text}
                onDelete={() => deletePresetGoal(preset.id)}
                onChange={(event) => changePreset(preset.id, event)}
                placeholder="Enter Preset"
              />
            ))}
          <div style={wrapAddInputFieldStyle}>
            <AddInputField text="Add Preset" onClick={addGoalReminderPreset} />
          </div>
        </div>
      </div>

      <div className="settings-section without-title border-top">
        <div className="section-name">Admin emails</div>
        <div className="section-details">
          {adminEmailsView &&
            adminEmailsView.map((email) => {
              return (
                <div
                  className="list-items-wrap"
                  key={`input-email-${email.id}`}
                >
                  <InputFreeText
                    text={email.text}
                    onDelete={() => deleteAdminEmail(email.id)}
                    onChange={(event) => changeEmail(email.id, event)}
                    placeholder="Enter Email"
                  />
                </div>
              )
            })}
          <div style={wrapAddInputFieldStyle}>
            <AddInputField text="Add Email" onClick={addAdminEmail} />
          </div>
        </div>
      </div>

      <div className="setting-title only-main-title border-top">
        <div className="main-setting-title">Emergency number</div>
        <div className="sub-setting-title">
          <input
            type="text"
            value={emergencyNumberView}
            className="emergency"
            placeholder="ex. +1234567453467"
            onChange={changeEmergencyNumber}
          />
        </div>

        <div className="main-setting-title">Time Zone</div>
        <div className="sub-setting-title">
          <select value={timeZoneView} onChange={changeTimeZone}>
            {availableTimezones.map((tz, ind) => {
              return (
                <option value={tz} key={ind}>
                  {tz}
                </option>
              )
            })}
          </select>
        </div>
      </div>
      <div className="chat-area border-top">
        <div className="main-setting-title">Chat</div>
        <div className="sub-setting-title chat">
          <InputCheckBox
            text="Highlighted words"
            onChange={onCheckboxHighlightedWordsChange}
            checked={chatConfigurationView.highlighted_words}
            name="highlighted_words"
          />
          <InputCheckBox
            text="Disable messaging feature"
            onChange={onCheckboxPatientMessagingChange}
            checked={chatConfigurationView.disableMessaging}
            name="disableMessaging"
          />
          {chatConfigurationView.disableMessaging && (
            <div className="sub-sub-menu">
              <InputCheckBox
                text="Chat enabled by default"
                onChange={onCheckboxChatEnabledChange}
                checked={chatConfigurationView.chatEnabled}
                name="chatEnabled"
              />
            </div>
          )}
        </div>
      </div>
      <div className="setting-title border-top setting-container">
        <div className="main-setting-title">Appointments</div>
        <div className="setting-content">
          <div className="sub-setting-title">
            <div className="fix-size">
              <div className="add-assessment-icon-wrap">
                <SelectFormsItems
                  title="Choose Start Appointment Form"
                  isOpen={startAppointmentView.modalIsOpen}
                  closeModal={closeAddStartAppointmentForm}
                  modalFormList={startAppointmentView.modalFormList}
                  changeCheckItem={changeCheckStartAppointmentFormModal}
                  saveOnClick={saveStartAppointmentChange}
                />
                <CallToAction
                  onClick={addStartAppointmentForm}
                  text="Add Start Appointment Form"
                  iconType="plus"
                />
              </div>
            </div>
          </div>
          <div className="section-details">
            {startAppointmentView &&
              startAppointmentView.form &&
              startAppointmentView.form.map((form, index) => {
                return (
                  <div key={index} className="list-items-wrap">
                    <ChipsItem
                      text={form.name}
                      deleteClick={() => {
                        deleteStartAppointmentForm(form.id)
                      }}
                    />
                  </div>
                )
              })}
          </div>
          <div className="setting-title">
            <div className="sub-setting-title">
              <div className="fix-size">
                <div className="add-assessment-icon-wrap">
                  <SelectFormsItems
                    title="Choose post appointment form"
                    isOpen={postAppointmentView.modalIsOpen}
                    closeModal={closeAddPostAppointmentForm}
                    modalFormList={postAppointmentView.modalFormList}
                    changeCheckItem={changeCheckPostAppointmentFormModal}
                    saveOnClick={savePostAppointmentChange}
                  />
                  <CallToAction
                    onClick={addPostAppointmentForm}
                    text="Add post Appointment Form"
                    iconType="plus"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="section-details">
            {postAppointmentView &&
              postAppointmentView.form &&
              postAppointmentView.form.map((form, index) => {
                return (
                  <div key={index} className="list-items-wrap">
                    <ChipsItem
                      text={form.name}
                      deleteClick={() => {
                        deletePostAppointmentForm(form.id)
                      }}
                    />
                  </div>
                )
              })}
          </div>
          <div>
            <div>Appointment confirmation</div>
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={!appointmentOptions.showConfirmation}
                    onChange={(event) => {
                      setAppointmentOptions({
                        ...appointmentOptions,
                        showConfirmation: !event.target.checked,
                      })
                    }}
                  />
                }
                label="Remove confirmation button"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={!appointmentOptions.showReschedule}
                    onChange={(event) => {
                      setAppointmentOptions({
                        ...appointmentOptions,
                        showReschedule: !event.target.checked,
                      })
                    }}
                  />
                }
                label="Remove reschedule button"
              />
            </FormGroup>
          </div>
          <div className="appointment-link">
            <div>Appointment call to action</div>
            <RadioGroup
              aria-labelledby="demo-controlled-radio-buttons-group"
              name="controlled-radio-buttons-group"
              value={appointmentOptions.editAppointmentUrl}
              onChange={changeAppLink}
            >
              <FormControlLabel
                value="internal"
                control={<Radio />}
                label="Link internally"
              />
              <FormControlLabel
                value="external"
                control={<Radio />}
                label="Link externally"
              />
            </RadioGroup>
          </div>
          <div className="appointment-email">
            <div>Email</div>
            <TextField
              fullWidth
              id="outlined-basic"
              label="Email Subject"
              variant="outlined"
              defaultValue="Appointment Confirmation"
              disabled
            />
            <TextField
              fullWidth
              multiline
              minRows={3}
              id="outlined-basic"
              label="Email body"
              variant="outlined"
              value={appointmentOptions.emailBody}
              onChange={(e) => {
                setAppointmentOptions({
                  ...appointmentOptions,
                  emailBody: e.target.value,
                })
              }}
            />
            <TextField
              fullWidth
              multiline
              minRows={3}
              id="cantMakeIt"
              label="Can’t make it"
              variant="outlined"
              value={appointmentOptions.cantMakeIt}
              onChange={(e) => {
                setAppointmentOptions({
                  ...appointmentOptions,
                  cantMakeIt: e.target.value,
                })
              }}
            />
          </div>
          <div className="appointment-reschedule">
            <Checkbox
              checked={appointmentOptions.promptOnDeclineActive}
              onChange={(event) => {
                setAppointmentOptions({
                  ...appointmentOptions,
                  promptOnDeclineActive: event.target.checked,
                })
              }}
            />
            <div
              className="label"
              style={{ cursor: 'pointer' }}
              onClick={() => {
                setAppointmentOptions({
                  ...appointmentOptions,
                  promptOnDeclineActive:
                    !appointmentOptions.promptOnDeclineActive,
                })
              }}
            >
              Number of hours before cancellation
            </div>
            {appointmentOptions.promptOnDeclineActive && (
              <>
                <TextField
                  sx={{ width: '70px', margin: '0 10px' }}
                  type="number"
                  id="reschedule-hour"
                  label="Hour"
                  variant="outlined"
                  value={appointmentOptions.promptOnDeclineBeforeDate.value}
                  onChange={(e) => {
                    setAppointmentOptions({
                      ...appointmentOptions,
                      promptOnDeclineBeforeDate: {
                        value: e.target.value,
                        unit: 'hours',
                      },
                    })
                  }}
                />
                <div className="label">Hours</div>
              </>
            )}
          </div>
          {appointmentNotifications && (
            <AppointmentNotifications
              notifications={appointmentNotifications}
              handleChange={(newNotifications) => {
                setAppointmentNotifications(newNotifications)
              }}
            />
          )}
          <div>Reschedule Flow (only for wellness)</div>
          <RadioGroup
            aria-labelledby="demo-controlled-radio-buttons-group"
            name="controlled-radio-buttons-group"
            value={appointmentOptions.reschedulePrompt}
            onChange={(event) => {
              setAppointmentOptions({
                ...appointmentOptions,
                reschedulePrompt: event.target.value,
              })
            }}
          >
            <FormControlLabel
              value="free_text"
              control={<Radio />}
              label="Patient provided time and date (selected by default)"
            />
            <FormControlLabel
              value="available_slot"
              control={<Radio />}
              label="Available time slots of provider"
            />
          </RadioGroup>
          <FormControlLabel
            control={
              <Checkbox
                checked={appointmentOptions.removeAppointment}
                onChange={(event) => {
                  setAppointmentOptions({
                    ...appointmentOptions,
                    removeAppointment: event.target.checked,
                  })
                }}
              />
            }
            label='Remove "Request Appointment" CTA in mobile app until Initial appointment completed'
          />
        </div>
      </div>

      <div className="setting-title only-main-title border-top">
        <div className="main-setting-title">Offline notification</div>
        <div className="sub-setting-title">
          <TimePicker
            label="Digest"
            value={offlineNotifications.dailyDigest}
            onChange={(newValue) => {
              setOfflineNotifications({
                ...offlineNotifications,
                dailyDigest: newValue,
              })
            }}
            renderInput={(params) => <TextField {...params} />}
          />
        </div>
      </div>

      <div className="setting-title only-main-title border-top">
        <div className="main-setting-title">Security Roles</div>
        <div className="sub-setting-title">
          <ChipMultiSelect
            label="Security Roles"
            name="permitSecurityRoles"
            disableCloseOnSelect
            options={securityRoles || []}
            getLabel={(a) => a.name}
            getKey={(a) => a.key}
            sx={{ minWidth: '200px' }}
            value={settingsSecurityRoles}
            onChange={(list) => {
              setSettingsSecurityRoles(
                list.map(({ key, name }) => ({ key, name })),
              )
            }}
          />
        </div>
      </div>
      <div className="setting-title only-main-title border-top">
        <div className="sub-setting-title">
          <div className="action-btn-section">
            <ButtonNew
              type="link"
              text="cancel"
              onClick={() => {
                setCancelValue(cancelValue + 1)
                setSettingErrorField({})
              }}
            />
            <ButtonNew
              type="primary"
              text="Save"
              onClick={() => saveProgramSettingsChange()}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

function mapStateToProps(state, ownProps) {
  const { id } = ownProps.match.params
  const programId = ownProps.match.params.pId
  const tenant =
    id && state.tenantReducer.length > 0
      ? getTenantsById(state.tenantReducer, id)
      : null
  const program =
    tenant && tenant.programs
      ? tenant.programs.find((program) => program.id === programId)
      : null

  const settings = program ? program.settings : null
  const securityRoles = program?.securityRoles

  const primaryForm = settings ? settings.primaryForm : null
  const primaryForms = settings ? settings.primaryForms : null

  const questionnairesList =
    program && program.questionnaires ? program.questionnaires : null

  const programPrimaryForm =
    questionnairesList && primaryForm && primaryForm.form_name
      ? {
          modalIsOpen: false,
          name: primaryForm.form_name,
          forms: primaryForm.formTemplateIds
            .map((form) =>
              questionnairesList.find((quest) => quest.id === form),
            )
            .filter((itemF) => itemF),
        }
      : {}
  const programPrimaryForms =
    questionnairesList && primaryForms && primaryForms.length > 0
      ? primaryForms.map((form, index) => {
          return {
            id: new Date().getTime() + index,
            modalIsOpen: false,
            name: form.form_name,
            modalList: [],
            forms: form.formTemplateIds
              .map((form) =>
                questionnairesList.find((quest) => quest.id === form),
              )
              .filter((itemF) => itemF),
          }
        })
      : []

  return {
    settings,
    securityRoles,
    questionnairesList,
    programPrimaryForm,
    programPrimaryForms,
  }
}

const mapDispatchToProps = {
  loadSecurityRoles: tenantsActions.loadSecurityRoles,
  loadProgramSetting: tenantsActions.getProgramSetting,
  loadProgramQuestionnaires: tenantsActions.loadProgramQuestionnaires,
  saveSetting: tenantsActions.saveSetting,
  addUserMessage: globalAction.addUserMessage,
}

Setting.propTypes = {
  saveSetting: PropTypes.func.isRequired,
  loadProgramSetting: PropTypes.func.isRequired,
  loadProgramQuestionnaires: PropTypes.func.isRequired,
  tenant: PropTypes.object.isRequired,
  settings: PropTypes.object,
  programPrimaryForm: PropTypes.object,
  programPrimaryForms: PropTypes.array,
  questionnairesList: PropTypes.array,
  match: PropTypes.object.isRequired,
  addUserMessage: PropTypes.func.isRequired,
}

export default connect(mapStateToProps, mapDispatchToProps)(Setting)
