import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import IconButton from '../../common/IconButton/IconButton'
import ButtonNew from '../../common/Button/ButtonNew'
import Modal from 'react-modal'
import './dialog-select-forms.less'

function SelectFormsItems({
  title,
  isOpen,
  closeModal,
  modalFormList,
  changeCheckItem,
  saveOnClick,
  itemComponent,
  saveLoading,
  widthType,
  saveBtnText = 'Save',
}) {
  const ItemComponent = ({ item }) => {
    if (itemComponent) {
      return <itemComponent />
    }
    return <div>{item.name}</div>
  }
  const [searchInput, setSearchInput] = useState('')

  const [modalFormListView, setModalFormListView] = useState([])

  useEffect(() => {
    if (modalFormList) {
      if (searchInput && searchInput.length) {
        setModalFormListView(
          modalFormList.filter((item) =>
            item.name.toLowerCase().includes(searchInput.toLowerCase()),
          ),
        )
      } else {
        setModalFormListView(modalFormList)
      }
    }
  }, [modalFormList])

  const initModalState = () => {
    setSearchInput('')
    setModalFormListView(modalFormList)
  }

  const changeSearchInput = (event) => {
    const { value } = event.target
    setSearchInput(value)
    if (value && value.length) {
      return setModalFormListView(
        modalFormList.filter((item) =>
          item.name.toLowerCase().includes(value.toLowerCase()),
        ),
      )
    }
    setModalFormListView(modalFormList)
  }

  const customStyles = {
    content: {
      height: '80%',
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      padding: '0px',
      maxHeight: '80%',
      // height: "max-content",
      // minHeight: "413px",
    },
  }
  // widthType = large/xLarge
  const classNames = {
    large: 'modal-wrap large-modal-wrap',
    xLarge: 'modal-wrap x-large-modal-wrap',
  }
  const modalWrapClass = classNames[widthType] || 'modal-wrap'
  return (
    <Modal
      isOpen={isOpen}
      style={customStyles}
      contentLabel="Example Modal"
      ariaHideApp={false}
      onAfterOpen={initModalState}
    >
      <div className={modalWrapClass}>
        <div className="modal-header">
          <div>{title}</div>
          <IconButton
            onClick={() => {
              closeModal()
            }}
            type="close"
          />
        </div>
        <div className="modal-body">
          <input
            type="text"
            className="add-form-search-inbox-wrap"
            placeholder="Search"
            value={searchInput}
            onChange={changeSearchInput}
          />
          <div className="add-form-list-item-wrap">
            {modalFormListView &&
              modalFormListView.map((item, index) => {
                return (
                  <div
                    className="modal-list-item"
                    key={'modal-list-' + (index + 1)}
                  >
                    <input
                      type="checkbox"
                      id={item.id}
                      onChange={() => changeCheckItem(item.id)}
                      checked={item.isSelected}
                    />
                    <label htmlFor={item.id}>
                      <ItemComponent item={item} />
                    </label>
                  </div>
                )
              })}
          </div>
        </div>
        <div className="modal-footer">
          <ButtonNew type="link" text={'cancel'} onClick={closeModal} />
          <ButtonNew
            loading={!!saveLoading}
            text={saveBtnText}
            onClick={() => saveOnClick()}
            type="primary"
          />
        </div>
      </div>
    </Modal>
  )
}

SelectFormsItems.propTypes = {
  isOpen: PropTypes.bool,
  title: PropTypes.string.isRequired,
  closeModal: PropTypes.func.isRequired,
  modalFormList: PropTypes.array,
  changeCheckItem: PropTypes.func.isRequired,
  saveOnClick: PropTypes.func.isRequired,
  saveLoading: PropTypes.bool,
  widthType: PropTypes.string,
  itemComponent: PropTypes.func,
  saveBtnText: PropTypes.string,
}

export default SelectFormsItems
