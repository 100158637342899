import React from 'react'
import ButtonNew from '../../common/Button/ButtonNew'

const Forgot = ({ emailRecovery, setEmailRecovery, goBack, onSubmit }) => {
  return (
    <form>
      <div className="text-instruction main-text">
        <div className="start">
          <ButtonNew text="Back" onClick={goBack} type="link" />
        </div>
        <div className="center bold-text">Password Recovery</div>
        <div className="end" />
      </div>
      <div className="text-instruction">
        <div className="center">Enter your email address and you’ll</div>
      </div>
      <div className="text-instruction">
        <div className="center"> get a link to reset your password</div>
      </div>
      <div className="input-field">
        <div className="input-name-label">Enter email</div>
        <input
          placeholder="email"
          type="text"
          value={emailRecovery}
          onChange={(event) => {
            setEmailRecovery(event.target.value)
          }}
        />
      </div>
      <ButtonNew
        text="Submit"
        type="primary"
        onClick={onSubmit}
        disable={!emailRecovery.length}
      />
    </form>
  )
}

export default Forgot
