import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import '../item-rule-style.less'
import * as tenantsActions from '../../../../../../redux/actions/tenantsActions'
import * as constant from './constants'
import AddInputField from '../../../../../common/AddInputField/AddInputField'
import IconRight from '../../../../../../image/icon_arrow_right.svg'
import IconDown from '../../../../../../image/icon_arrow_down.svg'
import InputCheckBox from '../../../../../common/Input/InputCheckBox'
import OptionRuleMenu from './optionRuleMenu'
import NonTypeRule from './rulesComponents/nonTypeRule'
import UnchangedLocation from './rulesComponents/unchangedLocation'
import TimeWindow from './rulesComponents/TimeWindow'
import QuestionnaireScore from './rulesComponents/questionnaireScore'
import AnswerOnQuestion from './rulesComponents/answerOnQuestion'
import '../rules-components-style.less'
import ButtonNew from '../../../../../common/Button/ButtonNew'
import * as AlertRuleModels from './models'
import { getTenantsById } from '../../../../../utilities'
import QuestionnaireSelection from './rulesComponents/questionnaireSelection'
import EscalationWindow from './rulesComponents/EscalationWindow'

function AlertRules({
  match,
  programQuestionnaireArray,
  loadProgramQuestionnaire,
  programAlertRules,
  loadProgramAlertRules,
  saveNewAlertRule,
  updateAlertRule,
  deleteAlertRule,
}) {
  const initAlertRule = {
    active: false,
    optionMenuOpen: false,
    isOpen: false,
    type: '',
    name: '',
    alertText: '',
  }
  const initAlertRuleUnchangedLocationItem =
    AlertRuleModels.initAlertRuleUnchangedLocationItem
  const initAlertRuleQuestionnaireScoreItem =
    AlertRuleModels.initAlertRuleQuestionnaireScoreItem
  const initAlertRuleAnswerQuestionItem =
    AlertRuleModels.initAlertRuleAnswerQuestionItem
  const initAlertRuleIncompleteAssessment =
    AlertRuleModels.initAlertRuleIncompleteAssessment
  const initAlertRuleChatDisabled = AlertRuleModels.initChatDisabledItem

  function updateUiAlertRuleArray(
    revertItemId = null,
    afterSaveOrUpdateId = null,
    alertRuleFormStore = {},
  ) {
    // if (programAlertRules && programQuestionnaireArray) {
    if (programAlertRules) {
      // set local state
      let rulesArrayUi = []
      programAlertRules.forEach((rules) => {
        let uiItem = AlertRuleModels.getTransformItemForUiArray(rules)
        if (uiItem) {
          rulesArrayUi.push(uiItem)
        }
      })
      /**
       * fetch from api if need --> if this not the one we want update from api dont change it.
       **/
      if (revertItemId) {
        setAlertRulesArray(
          alertRulesArray.map((ruleItem) => {
            if (ruleItem.id === revertItemId) {
              /// Need get from redux STORE and that locate at rulesArrayUi
              if (!afterSaveOrUpdateId) {
                // This is after cancel!
                // this is cancel!!
                let oldItemUi = rulesArrayUi.find(
                  (ruleTransformItem) => ruleTransformItem.id === revertItemId,
                )
                return Object.assign({}, oldItemUi, {
                  isOpen: ruleItem.isOpen,
                })
              } else {
                // This is after save/update!
                let newItem =
                  AlertRuleModels.getTransformItemForUiArray(alertRuleFormStore)
                return Object.assign({}, newItem, {
                  isOpen: ruleItem.isOpen,
                  isNewAlertRule: false,
                })
              }
            }
            return ruleItem
          }),
        )
      } else {
        /// this is after cancel!
        if (!alertRulesArray || !alertRulesArray.length) {
          setAlertRulesArray(rulesArrayUi)
        }
      }
    }
  }

  useEffect(() => {
    if (!programAlertRules) {
      let tenantId = match.params.id
      let programId = match.params.pId
      loadProgramAlertRules(tenantId, programId)
    }
    if (!programQuestionnaireArray) {
      let tenantId = match.params.id
      let programId = match.params.pId
      loadProgramQuestionnaire(tenantId, programId)
    }
    if (
      programAlertRules &&
      programQuestionnaireArray &&
      !alertRulesArray.length
    ) {
      updateUiAlertRuleArray()
    }
  }, [programAlertRules, programQuestionnaireArray])

  const [alertRulesArray, setAlertRulesArray] = useState([])
  const [alertInputErrors, setAlertInputErrors] = useState({})

  const addNewAlertRule = () => {
    setAlertRulesArray([
      ...alertRulesArray,
      Object.assign({}, initAlertRule, {
        id: new Date().getTime(),
        isNewAlertRule: true,
        isOpen: true,
      }),
    ])
  }

  const showHideRuleBody = (id) => {
    setAlertRulesArray(
      alertRulesArray.map((ruleItem) => {
        if (ruleItem.id === id) {
          return Object.assign({}, ruleItem, { isOpen: !ruleItem.isOpen })
        }
        return ruleItem
      }),
    )
  }

  const changeOptionsStatusOpenClose = (itemId, isOutside) => {
    setAlertRulesArray(
      alertRulesArray.map((item) => {
        if (itemId === item.id) {
          let newOptionMenu =
            isOutside === itemId ? false : !item.optionMenuOpen
          return Object.assign({}, item, {
            optionMenuOpen: newOptionMenu,
          })
        }
        return item
      }),
    )
  }

  // This delete is always delete from API!!!
  const deleteRule = (id) => {
    // Todo If is exist one sen api request!
    let tenantId = match.params.id
    let programId = match.params.pId
    deleteAlertRule(tenantId, programId, id, (res) => {
      if (res.ok) {
        setAlertRulesArray(
          alertRulesArray.filter((ruleItem) => ruleItem.id !== id),
        )
      }
    })
  }

  const saveAlertByItemId = (ruleId) => {
    let selectItem = alertRulesArray.find((item) => item.id === ruleId)
    if (!selectItem) {
      return
    } else {
      let newErrorObj = {}
      newErrorObj[ruleId] = null
      setAlertInputErrors(Object.assign({}, alertInputErrors, newErrorObj))
    }
    // Todo validation the rule!!!!
    /**
     * Init this alert rule error
     * **/
    let uiItemId = selectItem.id
    let saveModal = AlertRuleModels.getRuleModal(selectItem)
    if (!saveModal.ok) {
      let newErrorObj = {}
      newErrorObj[ruleId] = saveModal.error
      setAlertInputErrors(Object.assign({}, alertInputErrors, newErrorObj))
    } else {
      let tenantId = match.params.id
      let programId = match.params.pId
      if (selectItem.isNewAlertRule) {
        let updateObj = saveModal.data
        // delete updateObj.active;
        saveNewAlertRule(tenantId, programId, updateObj, (res) => {
          if (res.ok) {
            updateUiAlertRuleArray(uiItemId, res.id, res.alertRuleFromStore)
          }
        })
      } else {
        // if is update cannot change type
        let updateObj = saveModal.data
        delete updateObj.type
        updateAlertRule(tenantId, programId, saveModal.data, (res) => {
          if (res.ok) {
            updateUiAlertRuleArray(uiItemId, res.id, res.alertRuleFromStore)
          }
        })
      }
    }
  }

  const activeRuleChecked = (id) => {
    setAlertRulesArray(
      alertRulesArray.map((ruleItem) => {
        if (ruleItem.id === id) {
          return Object.assign({}, ruleItem, { active: !ruleItem.active })
        }
        return ruleItem
      }),
    )
  }

  const onChangeRuleType = (e, id) => {
    switch (e.target.value) {
      case constant.UNCHANGED_LOCATION: {
        setAlertRulesArray(
          alertRulesArray.map((ruleItem) => {
            if (ruleItem.id === id) {
              return Object.assign({}, initAlertRuleUnchangedLocationItem, {
                active: ruleItem.active,
                optionMenuOpen: ruleItem.optionMenuOpen,
                isOpen: ruleItem.isOpen,
                name: ruleItem.name,
                alertText: ruleItem.alertText,
                isNewAlertRule: ruleItem.isNewAlertRule,
                id: id,
              })
            }
            return ruleItem
          }),
        )
        break
      }
      case constant.ASSESSMENT_SCORE: {
        setAlertRulesArray(
          alertRulesArray.map((ruleItem) => {
            if (ruleItem.id === id) {
              return Object.assign({}, initAlertRuleQuestionnaireScoreItem, {
                active: ruleItem.active,
                optionMenuOpen: ruleItem.optionMenuOpen,
                isOpen: ruleItem.isOpen,
                name: ruleItem.name,
                alertText: ruleItem.alertText,
                isNewAlertRule: ruleItem.isNewAlertRule,
              })
            }
            return ruleItem
          }),
        )
        break
      }
      case constant.ASSESSMENT_ANSWER: {
        setAlertRulesArray(
          alertRulesArray.map((ruleItem) => {
            if (ruleItem.id === id) {
              return Object.assign({}, initAlertRuleAnswerQuestionItem, {
                active: ruleItem.active,
                optionMenuOpen: ruleItem.optionMenuOpen,
                isOpen: ruleItem.isOpen,
                name: ruleItem.name,
                alertText: ruleItem.alertText,
                isNewAlertRule: ruleItem.isNewAlertRule,
              })
            }
            return ruleItem
          }),
        )
        break
      }
      case constant.INCOMPLETE_ASSESSMENT: {
        setAlertRulesArray(
          alertRulesArray.map((ruleItem) => {
            if (ruleItem.id === id) {
              return Object.assign({}, initAlertRuleIncompleteAssessment, {
                active: ruleItem.active,
                optionMenuOpen: ruleItem.optionMenuOpen,
                isOpen: ruleItem.isOpen,
                name: ruleItem.name,
                alertText: ruleItem.alertText,
                isNewAlertRule: ruleItem.isNewAlertRule,
              })
            }
            return ruleItem
          }),
        )
        break
      }
      case constant.CHAT_DISABLED: {
        setAlertRulesArray(
          alertRulesArray.map((ruleItem) => {
            if (ruleItem.id === id) {
              return Object.assign({}, initAlertRuleChatDisabled, {
                active: ruleItem.active,
                optionMenuOpen: ruleItem.optionMenuOpen,
                isOpen: ruleItem.isOpen,
                name: ruleItem.name,
                alertText: ruleItem.alertText,
                isNewAlertRule: ruleItem.isNewAlertRule,
              })
            }
            return ruleItem
          }),
        )
        break
      }
      default:
        break
    }
  }

  const cancelAlertRuleChange = (id, isNewAlertRule) => {
    if (isNewAlertRule) {
      setAlertRulesArray(alertRulesArray.filter((alItem) => alItem.id !== id))
    } else {
      updateUiAlertRuleArray(id)
    }

    let newErrorObj = {}
    newErrorObj[id] = null
    setAlertInputErrors(Object.assign({}, alertInputErrors, newErrorObj))
  }

  const changeTimeWindow = (e, windowName, field = 'unit', item) => {
    const otherField = field === 'unit' ? 'value' : 'unit'

    const value = e.target.value !== 'null' ? e.target.value : null
    const resetOther = field === 'unit' && value === null

    setAlertRulesArray(
      alertRulesArray.map((a) => {
        if (a.id !== item.id) return a
        return Object.assign({}, a, {
          [windowName]: !resetOther
            ? {
                [field]: value,
                [otherField]: a[windowName][otherField],
              }
            : undefined,
        })
      }),
    )
  }

  return (
    <div className="program-tab-page item-rule-wrapper">
      {alertRulesArray.length > 0 &&
        alertRulesArray.map((item, index) => {
          return (
            <div className="item-rule-content" key={item.id}>
              <div className="rule-item-header">
                {item.alertText && item.alertText.length > 0
                  ? item.alertText
                  : ''}
                {!item.isNewAlertRule && (
                  <OptionRuleMenu
                    topOptionsOpen={item.optionMenuOpen}
                    changeOptionsStatusOpenClose={changeOptionsStatusOpenClose}
                    id={item.id}
                    deleteRule={deleteRule}
                    optionsText={{ text: 'Delete rule' }}
                  />
                )}
                <div className="header-end-section">
                  <div
                    className="header-click-area"
                    onClick={() => {
                      showHideRuleBody(item.id)
                    }}
                  />
                  <InputCheckBox
                    name={'send-all-check'}
                    onChange={() => {
                      activeRuleChecked(item.id)
                    }}
                    text="Active rule"
                    checked={item.active}
                  />
                  <div className="line-separate" />
                  <div
                    className="is-open-icon-wrapper"
                    onClick={() => {
                      showHideRuleBody(item.id)
                    }}
                  >
                    {!item.isOpen && <IconRight />}
                    {item.isOpen && <IconDown />}
                  </div>
                </div>
              </div>
              {item.isOpen && (
                <div className="rule-item-details">
                  <div className="rules-body-wrapper">
                    <div className="rule-body-details">
                      <NonTypeRule
                        onChangeType={onChangeRuleType}
                        item={item}
                        alertRulesArray={alertRulesArray}
                        setAlertRulesArray={setAlertRulesArray}
                        error={alertInputErrors[item.id] || {}}
                      />
                      {item.type ===
                        initAlertRuleUnchangedLocationItem.type && (
                        <UnchangedLocation
                          item={item}
                          alertRulesArray={alertRulesArray}
                          setAlertRulesArray={setAlertRulesArray}
                          error={alertInputErrors[item.id] || {}}
                        />
                      )}
                      {item.type ===
                        initAlertRuleQuestionnaireScoreItem.type && (
                        <QuestionnaireScore
                          item={item}
                          alertRulesArray={alertRulesArray}
                          setAlertRulesArray={setAlertRulesArray}
                          loadQuestionnaireArray={loadProgramQuestionnaire}
                          questionnaireArray={programQuestionnaireArray}
                          programId={match.params.pId}
                          tenantId={match.params.id}
                          error={alertInputErrors[item.id] || {}}
                        />
                      )}
                      {item.type === initAlertRuleAnswerQuestionItem.type && (
                        <AnswerOnQuestion
                          item={item}
                          setAlertRulesArray={setAlertRulesArray}
                          alertRulesArray={alertRulesArray}
                          loadQuestionnaireArray={loadProgramQuestionnaire}
                          questionnaireArray={programQuestionnaireArray}
                          programId={match.params.pId}
                          tenantId={match.params.id}
                          error={alertInputErrors[item.id] || {}}
                        />
                      )}
                      {item.type === initAlertRuleIncompleteAssessment.type && (
                        <>
                          <QuestionnaireSelection
                            item={item}
                            alertRulesArray={alertRulesArray}
                            handleSetAlertRulesArray={setAlertRulesArray}
                          />
                          <TimeWindow
                            item={item}
                            handleChange={(newItem) => {
                              setAlertRulesArray(
                                alertRulesArray.map((rule) => {
                                  if (rule.id !== item.id) return rule
                                  return newItem
                                }),
                              )
                            }}
                          />
                        </>
                      )}
                      {item.type === initAlertRuleChatDisabled.type && (
                        <>
                          <div className="rule-filed">
                            <div className="rule-label">Call to actions</div>
                            <div className="rule-input">
                              <select
                                placeholder="Select action(s)"
                                className=""
                                value={
                                  (item.callToActions &&
                                    item.callToActions[0]) ||
                                  ''
                                }
                                onChange={(e) => {
                                  const value =
                                    e.target.selectedOptions[0].value !== 'null'
                                      ? [e.target.selectedOptions[0].value]
                                      : []

                                  setAlertRulesArray(
                                    alertRulesArray.map((rule) => {
                                      if (rule.id !== item.id) return rule

                                      return Object.assign({}, item, {
                                        callToActions: value,
                                      })
                                    }),
                                  )
                                }}
                              >
                                <option value={'null'}></option>
                                <option value={'enable_chat'}>
                                  Enable chat
                                </option>
                              </select>
                            </div>
                          </div>
                          <TimeWindow
                            item={item}
                            allowedTimes={['days', 'weeks']}
                            error={alertInputErrors[item.id] || {}}
                            handleChange={(newItem) => {
                              setAlertRulesArray(
                                alertRulesArray.map((rule) => {
                                  if (rule.id !== item.id) return rule
                                  return newItem
                                }),
                              )
                            }}
                          />
                          <EscalationWindow
                            item={item}
                            error={alertInputErrors[item.id] || {}}
                            withEmptyField
                            allowedTimes={['days', 'weeks']}
                            changeTimeWindow={(e, windowName, field) => {
                              changeTimeWindow(e, windowName, field, item)
                            }}
                          />
                        </>
                      )}
                    </div>
                    <div className="rule-btn-section">
                      <ButtonNew
                        type="link"
                        text={'cancel'}
                        onClick={() => {
                          cancelAlertRuleChange(item.id, item.isNewAlertRule)
                        }}
                      />

                      <ButtonNew
                        text={'Save'}
                        onClick={() => {
                          saveAlertByItemId(item.id)
                        }}
                        type="primary"
                        disable={!item.type}
                      />
                    </div>
                  </div>
                </div>
              )}
            </div>
          )
        })}
      <div className="add-rule-wrap">
        <AddInputField
          text="Add alert rule"
          onClick={() => {
            addNewAlertRule()
          }}
        />
      </div>
    </div>
  )
}

function mapStateToProps(state, ownProps) {
  let tenantId = ownProps.match.params.id
  let programId = ownProps.match.params.pId
  if (!tenantId || !programId) {
    return {
      programQuestionnaireArray: [],
    }
  }
  let tenant =
    state.tenantReducer.length > 0
      ? getTenantsById(state.tenantReducer, tenantId)
      : {}
  let program =
    !tenant || !tenant.programs || !tenant.programs.length
      ? {}
      : tenant.programs.find((p) => p.id === programId)
  return {
    programAlertRules: program.alertRules,
    programQuestionnaireArray: program.questionnaires || [],
  }
}

const mapDispatchToProps = {
  loadProgramQuestionnaire: tenantsActions.loadProgramQuestionnaires, // tenantId. programId
  loadProgramAlertRules: tenantsActions.loadProgramAlertRules,
  saveNewAlertRule: tenantsActions.createProgramAlertRule,
  updateAlertRule: tenantsActions.updateProgramAlertRule,
  deleteAlertRule: tenantsActions.deleteAlertRule,
}

export default connect(mapStateToProps, mapDispatchToProps)(AlertRules)
