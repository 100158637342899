import React from 'react'
import { connect } from 'react-redux'
import * as globalAction from '../../redux/actions/globalAction'
import IconButton from '../common/IconButton/IconButton'
import ButtonNew from '../common/Button/ButtonNew'
import Modal from 'react-modal'
import PropTypes from 'prop-types'

const NotImplementedDialog = ({ isOpen, hideDialog }) => {
  const onClose = () => {
    hideDialog()
  }
  const customStyles = {
    content: {
      // height: "186px",
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      padding: '0px',
      height: 'max-content',
      minHeight: '186px',
      maxHeight: '80%',
    },
  }
  return (
    <Modal isOpen={isOpen} style={customStyles} ariaHideApp={false}>
      <div className="modal-wrap large-modal-wrap">
        <div className="modal-header">
          <div>Oops…</div>
          <IconButton
            onClick={() => {
              onClose()
            }}
            type="close"
          />
        </div>
        <div className="modal-body">
          <div>This action is not implemented yet.</div>
        </div>
        <div className="modal-footer">
          <ButtonNew text={'Ok'} onClick={() => onClose()} type="primary" />
        </div>
      </div>
    </Modal>
  )
}

function mapStateToProps(state) {
  return {
    isOpen: state.globalReducer.showNotImplementedDialog.show,
  }
}

const mapDispatchToProps = {
  hideDialog: globalAction.hideNotImplementedDialog,
}

NotImplementedDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  hideDialog: PropTypes.func.isRequired,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(NotImplementedDialog)
