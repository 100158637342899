import * as types from './actionsType'
export function initializeAppState(isNotAuth) {
  return { type: types.INITIALIZE_APP_STATE, isAuthProblem: !isNotAuth }
}

export function showNotImplementedDialog() {
  return { type: types.SHOW_NOT_IMPLEMENTED_DIALOG }
}
export function hideNotImplementedDialog() {
  return { type: types.HIDE_NOT_IMPLEMENTED_DIALOG }
}
export function deleteUserMessage(id) {
  return { type: types.REMOVE_USER_MESSAGE_POPUP, id }
}
export function addUserMessageItem(messageItem) {
  return { type: types.ADD_USER_MESSAGE_POPUP, messageItem }
}
export function displayConfirmation(confirmation) {
  return { type: types.DISPLAY_CONFIRMATION, confirmation }
}
export function hideConfirmation() {
  return { type: types.HIDE_CONFIRMATION }
}
export function addUserMessage(type, text) {
  const messageItem = {
    type,
    text,
    id: 'user_message_id' + new Date().getTime(),
  }

  return (dispatch) => {
    dispatch(addUserMessageItem(messageItem))
    setTimeout(() => {
      dispatch(deleteUserMessage(messageItem.id))
    }, 5000)
  }
}
