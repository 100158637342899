export const getServerObject = (data) => {
  const obj = {}
  for (const key in data) {
    if (Object.hasOwnProperty.call(data, key)) {
      const list = data[key]

      if (!obj[key]) obj[key] = []

      for (let index = 0; index < list.length; index++) {
        const element = list[index]
        if (!element.deleted) {
          obj[key].push(element.value)
        }
      }
    }
  }

  return obj
}

export const getInitialData = (configuration) => {
  const obj = {}

  const getList = (list) => {
    return list.map((l, index) => {
      return {
        index,
        originalValue: l,
        value: l,
        deleted: false,
      }
    })
  }

  obj.ageGroups = configuration.ageGroups
    ? getList(configuration.ageGroups)
    : []
  obj.ethnicity = configuration.ethnicity
    ? getList(configuration.ethnicity)
    : []
  obj.insurancePlans = configuration.insurancePlans
    ? getList(configuration.insurancePlans)
    : []
  obj.languages = configuration.languages
    ? getList(configuration.languages)
    : []
  obj.licenseLocations = configuration.licenseLocations
    ? getList(configuration.licenseLocations)
    : []
  obj.licenseTypes = configuration.licenseTypes
    ? getList(configuration.licenseTypes)
    : []
  obj.modalities = configuration.modalities
    ? getList(configuration.modalities)
    : []
  obj.specialities = configuration.specialities
    ? getList(configuration.specialities)
    : []
  obj.spirituality = configuration.spirituality
    ? getList(configuration.spirituality)
    : []

  return obj
}

export const hasEmptyField = (data, setDisplayInvalid) => {
  const keys = Object.keys(data)
  for (let y = 0; y < keys.length; y++) {
    const key = keys[y]
    if (key !== 'insurancePlans') {
      for (let i = 0; i < data[key].length; i++) {
        let element_1 = data[key][i]
        if (!element_1.deleted && !element_1.value.length) {
          setDisplayInvalid(true)
          return true
        }
      }
    } else {
      for (let i = 0; i < data[key].length; i++) {
        let element_2 = data[key][i]
        if (!element_2.deleted && !element_2.value.name.length) {
          setDisplayInvalid(true)
          return true
        }
      }
    }
  }

  return false
}
