import React from 'react'
import PropTypes from 'prop-types'
import TenantIcon from '../../../image/icon_tenants.svg'
import ToolsIcon from '../../../image/icon_tools.svg'
import UsersIcon from '../../../image/icon_users.svg'
import './style.less'

const Icon = {
  tenant: TenantIcon,
  tools: ToolsIcon,
  users: UsersIcon,
}
const MenuItem = ({ text, type, disable }) => {
  const IconPresent = Icon[type]
  return (
    <div
      className={
        disable ? 'menu-item-component disable' : 'menu-item-component'
      }
    >
      {IconPresent && <IconPresent />}
      {text && <span>{text}</span>}
    </div>
  )
}

MenuItem.propTypes = {
  disable: PropTypes.bool,
  text: PropTypes.string.isRequired,
  type: PropTypes.string,
}

export default MenuItem
