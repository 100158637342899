/**
 * Global action type
 * **/
export const INITIALIZE_APP_STATE = 'INITIALIZE_APP_STATE'
/**
 * Tenants action types!
 * */
export const INITIALIZE_TENANTS_LIST = 'INITIALIZE_TENANTS_LIST'
export const INITIALIZE_PROGRAMS_LIST = 'INITIALIZE_PROGRAMS_LIST'
export const SET_PROGRAMS_PERSONNEL = 'SET_PROGRAMS_PERSONNEL'
export const SET_PROGRAMS_SETTING = 'SET_PROGRAMS_SETTING'
export const SET_PROGRAMS_QUESTIONNAIRES = 'SET_PROGRAMS_QUESTIONNAIRES'
export const SET_PROGRAMS_SENTIMENTS = 'SET_PROGRAMS_SENTIMENTS'
export const SET_PROGRAMS_MEDIA_ITEMS = 'SET_PROGRAMS_MEDIA_ITEMS'
export const SET_PROGRAMS_CONSENT_FORM = 'SET_PROGRAMS_CONSENT_FORM'
export const SET_PROGRAMS_RULES = 'SET_PROGRAMS_RULES'
export const SET_PROGRAMS_NOTIFICATION = 'SET_PROGRAMS_NOTIFICATION'
export const ADD_NEW_TENANT = 'ADD_NEW_TENANT'
export const ADD_NEW_PROGRAM = 'ADD_NEW_PROGRAM'
export const SET_TENANT_CONSENT_FORM_ITEMS = 'SET_TENANT_CONSENT_FORM_ITEMS'
export const SET_TENANT_MEDIA_ITEMS = 'SET_TENANT_MEDIA_ITEMS'
export const SET_TENANT_USERS = 'SET_TENANT_USERS'
export const UPDATE_PROGRAM_USER = 'UPDATE_PROGRAM_USER'
export const REVOKE_PROGRAM_USER = 'REVOKE_PROGRAM_USER'
export const RESTORE_PROGRAM_USER = 'RESTORE_PROGRAM_USER'
export const REMOVE_PROGRAM_USER = 'REMOVE_PROGRAM_USER'
export const CREATE_PROGRAM_USER = 'CREATE_PROGRAM_USER'
export const CHANGE_PASSWORD_TENANT_USER = 'CHANGE_PASSWORD_TENANT_USER'
export const SET_TENANT_SENTIMENTS_ITEMS = 'SET_TENANT_SENTIMENTS_ITEMS'
export const SET_TENANT_QUESTIONNAIRES_ITEMS = 'SET_TENANT_QUESTIONNAIRES_ITEMS'
export const SAVE_NEW_TENANT_TOOLS_MEDIA_ITEM =
  'SAVE_NEW_TENANT_TOOLS_MEDIA_ITEM'
export const SAVE_NEW_TENANT_QUESTIONNAIRE = 'SAVE_NEW_TENANT_QUESTIONNAIRE'
export const UPDATE_PROGRAM_DETAILS = 'UPDATE_PROGRAM_DETAILS'
export const UPDATE_PROGRAM_ALERT_RULE = 'UPDATE_PROGRAM_ALERT_RULE'
export const DELETE_PROGRAM_ALERT_RULE = 'DELETE_PROGRAM_ALERT_RULE'
export const SAVE_PROGRAM_ALERT_RULE_ITEM = 'SAVE_PROGRAM_ALERT_RULE_ITEM'

export const SELECT_PROGRAM = 'SELECT_PROGRAM'
export const UPDATE_PROGRAM_NOTIFICATION = 'UPDATE_PROGRAM_NOTIFICATION'
export const DELETE_PROGRAM_NOTIFICATION = 'DELETE_PROGRAM_NOTIFICATION'
export const SAVE_PROGRAM_NOTIFICATION_ITEM = 'SAVE_PROGRAM_NOTIFICATION_ITEM'
export const LOAD_PROGRAM_CONFIGURATIONS = 'LOAD_PROGRAM_CONFIGURATIONS'
export const UPDATE_PROGRAM_CONFIGURATIONS = 'UPDATE_PROGRAM_CONFIGURATIONS'
export const REPLACE_PROGRAM_CONFIGURATIONS = 'REPLACE_PROGRAM_CONFIGURATIONS'
export const LOAD_PROGRAM_SECURITY_ROLES = 'LOAD_PROGRAM_SECURITY_ROLES'

export const UPDATE_TENANTS_MEDIA_ITEM = 'UPDATE_TENANTS_MEDIA_ITEM'
export const UPDATE_TENANTS_CONSENT_FORM = 'UPDATE_TENANTS_CONSENT_FORM'
export const UPDATE_TENANTS_QUESTIONNAIRE = 'UPDATE_TENANTS_QUESTIONNAIRE'
export const CREATE_TENANTS_CONSENT_FORM = 'CREATE_TENANTS_CONSENT_FORM'
/**
 * Tools action types!
 * */
export const INITIALIZES_TOOLS_MEDIA_LIST = 'INITIALIZES_TOOLS_MEDIA_LIST'
export const INITIALIZES_TOOLS_QUESTIONNAIRES_LIST =
  'INITIALIZES_TOOLS_QUESTIONNAIRES_LIST'
export const INITIALIZES_CONSENT_FORM_LIST = 'INITIALIZES_CONSENT_FORM_LIST'
export const SAVE_NEW_TOOLS_MEDIA_ITEM = 'SAVE_NEW_TOOLS_MEDIA_ITEM'
export const SAVE_NEW_TOOLS_CONSENT_FORM = 'SAVE_NEW_TOOLS_CONSENT_FORM'
export const SAVE_NEW_TOOLS_QUESTIONNAIRE = 'SAVE_NEW_TOOLS_QUESTIONNAIRE'
export const UPDATE_TOOLS_MEDIA_ITEM = 'UPDATE_TOOLS_MEDIA_ITEM'
export const UPDATE_TOOLS_CONSENT_FORM = 'UPDATE_TOOLS_CONSENT_FORM'
export const UPDATE_TOOLS_QUESTIONNAIRE = 'UPDATE_TOOLS_QUESTIONNAIRE'
/**
 * Global action type
 * **/
export const SHOW_NOT_IMPLEMENTED_DIALOG = 'SHOW_NOT_IMPLEMENTED_DIALOG'
export const HIDE_NOT_IMPLEMENTED_DIALOG = 'HIDE_NOT_IMPLEMENTED_DIALOG'
export const ADD_USER_MESSAGE_POPUP = 'ADD_USER_MESSAGE_POPUP'
export const DISPLAY_CONFIRMATION = 'DISPLAY_CONFIRMATION'
export const HIDE_CONFIRMATION = 'HIDE_CONFIRMATION'
export const REMOVE_USER_MESSAGE_POPUP = 'REMOVE_USER_MESSAGE_POPUP'

/**
 * Program action types
 */
export const LOAD_PROVIDERS = 'LOAD_PROVIDERS'

export const CALLING_QUESTIONNAIRE = 'CALLING_QUESTIONNAIRE'
export const CALLING_SETTINGS = 'CALLING_SETTINGS'
