import React from 'react'
import List from '../common/List/List'
import PropTypes from 'prop-types'

const ToolsPage = ({ history }) => {
  const toolsClick = (id) => {
    switch (id) {
      case 'tools-media': {
        return history.push('/home/tools/media')
      }
      case 'tools-questionnaires': {
        return history.push('/home/tools/questionnaires')
      }
      case 'tools-consent': {
        return history.push('/home/tools/consent_forms')
      }
    }
  }
  const tools = [
    { id: 'tools-media', name: 'media', constantLogo: 'media' },
    {
      id: 'tools-questionnaires',
      name: 'questionnaires',
      constantLogo: 'questionnaires',
    },
    {
      id: 'tools-consent',
      name: 'consent forms',
      constantLogo: 'consent_forms',
    },
  ]
  return (
    <div>
      <List list={tools} titleText={'Tool type'} itemClickFunc={toolsClick} />
    </div>
  )
}

ToolsPage.propTypes = {
  history: PropTypes.object.isRequired,
}

export default ToolsPage
