import React from 'react'
import PropTypes from 'prop-types'
import IconButton from '../IconButton/IconButton'
import './style.less'

const InputFreeText = ({
  text,
  onChange,
  compClass,
  onDelete,
  placeholder,
  autoFocus,
  onFocus,
}) => {
  return (
    <div className="wrap-input-field">
      <input
        placeholder={placeholder || ''}
        className={compClass}
        value={text}
        autoFocus={autoFocus}
        onFocus={onFocus}
        onChange={onChange}
        type="text"
      />
      <div className="close-btn-preset">
        <IconButton type="close" onClick={onDelete} />
      </div>
    </div>
  )
}

InputFreeText.propTypes = {
  placeholder: PropTypes.string,
  text: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onFocus: PropTypes.func,
  autoFocus: PropTypes.bool,
}

export default InputFreeText
