export const genderEnum = {
  m: 'Male',
  f: 'Female',
  o: 'Other',
}

export const CONFIGURATION_STRINGS = {
  ageGroups: 'Age groups',
  ethnicity: 'Ethnicity',
  insurancePlans: 'Insurance plans',
  languages: 'Languages',
  licenseLocations: 'License locations',
  licenseTypes: 'License Types',
  modalities: 'Modalities',
  specialities: 'Specialities',
  spirituality: 'Spirituality',
}

export const integrationStatusEnum = {
  active: 'Active',
  inactive: 'Inactive',
}
