//component default
import React, { useState, useRef, useLayoutEffect } from 'react'
import PropTypes from 'prop-types'
import './style.css'
import IconSearch from '../../../image/icn_search.svg'
import ClearSearch from '../../../image/btn_clear_search.svg'

const SearchInput = ({
  value = '',
  handleChange,
  searchPlaceholder,
  searchClass,
  handleKeyPress,
  handleErase,
}) => {
  const [displayEraseBtn, setDisplayEraseBtn] = useState(!!value.length)
  const [searchValue, setSearchValue] = useState(value)
  const checkboxRef = useRef()

  const firstUpdate = useRef(true)
  //in place of useEffect, to avoid call on first render
  useLayoutEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false
      return
    }

    handleChange(searchValue)
    setDisplayEraseBtn(!!searchValue.length)
  }, [searchValue])

  return (
    <div className="input-search">
      <span className="input-search-icon">
        <IconSearch />
      </span>
      <input
        type="text"
        value={searchValue}
        ref={checkboxRef}
        placeholder={searchPlaceholder || 'Search'}
        className={searchClass}
        onChange={(e) => {
          setSearchValue(e.target.value)
        }}
        onKeyUp={(e) => {
          handleKeyPress && handleKeyPress(e)
        }}
      />
      {displayEraseBtn && (
        <span
          className="input-search-remove"
          onClick={() => {
            handleErase && searchValue.length && handleErase()
            setSearchValue('')
            checkboxRef.current.focus()
          }}
        >
          <ClearSearch />
        </span>
      )}
    </div>
  )
}

export default SearchInput

SearchInput.propTypes = {
  value: PropTypes.string,
  searchPlaceholder: PropTypes.string,
  searchClass: PropTypes.string,
  handleChange: PropTypes.func,
  handleErase: PropTypes.func,
  handleKeyPress: PropTypes.func,
}
