import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import '../item-rule-style.less'
import '../rules-components-style.less'
import * as notificationConstant from './constant'
import * as notificationModal from './models'
import * as tenantsActions from '../../../../../../redux/actions/tenantsActions'
import OneNotificationItem from './OneNotificationItem'
import AddInputField from '../../../../../common/AddInputField/AddInputField'
import OptionRuleMenu from '../AlertRules/optionRuleMenu'
import InputCheckBox from '../../../../../common/Input/InputCheckBox'
import IconRight from '../../../../../../image/icon_arrow_right.svg'
import IconDown from '../../../../../../image/icon_arrow_down.svg'
import ButtonNew from '../../../../../common/Button/ButtonNew'
import { getTenantsById } from '../../../../../utilities'

function Notification({
  match,
  loadProgramQuestionnaire,
  programQuestionnaireArray,
  loadProgramNotifications,
  programNotifications,
  programConsentForms,
  loadProgramConsentForms,
  createProgramNotification,
  updateProgramNotification,
  deleteProgramNotification,
}) {
  const initNotificationItem = {
    type: '',
    active: false,
    activeEmail: false,
    optionMenuOpen: false,
    isOpen: false,
    isNewNotification: false,
    notificationText: '',
    questionnaireModalOpen: false,
    modalFormsList: [],
    questionnaireIds: [],
    consentFormIds: [],
  }
  const [notification, setNotification] = useState([])

  useEffect(() => {
    if (!programNotifications) {
      let tenantId = match.params.id
      let programId = match.params.pId
      loadProgramNotifications(tenantId, programId)
    }
    if (!programQuestionnaireArray) {
      let tenantId = match.params.id
      let programId = match.params.pId
      loadProgramQuestionnaire(tenantId, programId)
    }
    if (!programConsentForms) {
      let tenantId = match.params.id
      let programId = match.params.pId
      loadProgramConsentForms(tenantId, programId)
    }
    if (
      programNotifications &&
      programQuestionnaireArray &&
      programConsentForms &&
      !notification.length
    ) {
      updateUiNotificationArray()
    }
  }, [programNotifications, programQuestionnaireArray, programConsentForms])

  function updateUiNotificationArray(
    revertItemId = null,
    afterSaveOrUpdateId = null,
    notificationRuleFormStore = {},
  ) {
    if (programNotifications) {
      // set local state
      let notificationArrayUi = []
      programNotifications.forEach((notItem) => {
        let notItemForArray =
          notificationModal.getTransformItemForUiArray(notItem)
        notificationArrayUi.push(notItemForArray)
      })
      /**
       * fetch from api if need --> if this not the one we want update from api dont change it.
       **/
      if (revertItemId) {
        setNotification(
          notification.map((noteItem) => {
            if (noteItem.id === revertItemId) {
              if (!afterSaveOrUpdateId) {
                let oldItemUi = notificationArrayUi.find(
                  (ruleTransformItem) => ruleTransformItem.id === revertItemId,
                )
                return Object.assign({}, oldItemUi, {
                  isOpen: noteItem.isOpen,
                })
              } else {
                let newItem = notificationModal.getTransformItemForUiArray(
                  notificationRuleFormStore,
                )
                return Object.assign({}, newItem, {
                  isOpen: noteItem.isOpen,
                  isNewNotification: false,
                })
              }
            }
            return noteItem
          }),
        )
      } else {
        if (!notification || !notification.length) {
          setNotification(notificationArrayUi)
        }
      }
    }
  }

  const changeOptionsStatusOpenClose = (itemId, isOutside) => {
    setNotification(
      notification.map((item) => {
        if (itemId === item.id) {
          let newOptionMenu =
            isOutside === itemId ? false : !item.optionMenuOpen
          return Object.assign({}, item, {
            optionMenuOpen: newOptionMenu,
          })
        }
        return item
      }),
    )
  }

  const deleteNotification = (id) => {
    // Todo If is exist one sen api request!
    let tenantId = match.params.id
    let programId = match.params.pId
    deleteProgramNotification(tenantId, programId, id, (res) => {
      if (res.ok) {
        setNotification(notification.filter((notItem) => notItem.id !== id))
      }
    })
    setNotification(notification.filter((item) => item.id !== id))
  }

  const addNewNotification = () => {
    setNotification([
      ...notification,
      Object.assign({}, initNotificationItem, {
        id: new Date().getTime(),
        isNewNotification: true,
        isOpen: true,
      }),
    ])
  }
  const showHideNotificationBody = (id) => {
    setNotification(
      notification.map((noteItem) => {
        if (noteItem.id === id) {
          return Object.assign({}, noteItem, { isOpen: !noteItem.isOpen })
        }
        return noteItem
      }),
    )
  }
  const onChangeRuleType = (e, id) => {
    setNotification(
      notification.map((noteItem) => {
        if (noteItem.id === id) {
          // questionnaireIds
          // consentFormIds
          if (e.target.value === notificationConstant.QUESTIONNAIRE_COMPLETED) {
            return Object.assign({}, noteItem, {
              type: e.target.value,
              consentFormIds: [],
            })
          } else if (
            e.target.value !== notificationConstant.CONSENT_FORM_AGREED
          ) {
            return Object.assign({}, noteItem, {
              type: e.target.value,
              questionnaireIds: [],
            })
          } else {
            return Object.assign({}, noteItem, {
              type: e.target.value,
              questionnaireIds: [],
              consentFormIds: [],
            })
          }
        }
        return noteItem
      }),
    )
  }
  const cancelNotificationChange = (id, isNewNotification) => {
    if (isNewNotification) {
      setNotification(
        notification.filter((noteItem) => {
          return noteItem.id !== id
        }),
      )
    } else {
      // is
      updateUiNotificationArray(id)
      /*setNotification(
        notification.map(noteItem => {
          if (noteItem.id === id) {

            return Object.assign({}, noteItem, { isOpen: !noteItem.isOpen });
          }
          return noteItem;
        })
      );*/
    }
    /*updateUiAlertRuleArray(id);
      let newErrorObj = {};
      newErrorObj[id] = null;
      setAlertInputErrors(Object.assign({}, alertInputErrors, newErrorObj));*/
  }
  const saveNotificationByItemId = (noteId) => {
    let selectItem = notification.find((noteItem) => noteItem.id === noteId)
    if (!selectItem) {
      return console.log('Something get wrong with notification item')
    }
    let transformNotification =
      notificationModal.getNotificationModal(selectItem)
    // If id exist --> update!
    let uiItemId = selectItem.id // == noteId
    let tenantId = match.params.id
    let programId = match.params.pId
    if (selectItem.isNewNotification) {
      // create notification
      createProgramNotification(
        tenantId,
        programId,
        transformNotification,
        (res) => {
          if (res.ok) {
            updateUiNotificationArray(uiItemId, res.id, res.alertRuleFromStore)
          }
        },
      )
    } else {
      // update notification
      delete transformNotification.type
      updateProgramNotification(
        tenantId,
        programId,
        transformNotification,
        (res) => {
          if (res.ok) {
            updateUiNotificationArray(uiItemId, res.id, res.alertRuleFromStore)
          }
        },
      )
    }
  }
  return (
    <div className="program-tab-page item-rule-wrapper">
      <div className="add-rule-wrap">
        {notification.length > 0 &&
          notification.map((item, index) => {
            return (
              <div className="item-rule-content" key={item.id}>
                <div className="rule-item-header">
                  {item.notificationText && item.notificationText.length > 0
                    ? item.notificationText
                    : ''}
                  {!item.isNewNotification && (
                    <OptionRuleMenu
                      topOptionsOpen={!!item.optionMenuOpen}
                      changeOptionsStatusOpenClose={
                        changeOptionsStatusOpenClose
                      }
                      id={item.id}
                      deleteRule={deleteNotification}
                      optionsText={{ text: 'Delete notification' }}
                    />
                  )}
                  <div className="header-end-section">
                    <div
                      className="header-click-area"
                      onClick={() => {
                        showHideNotificationBody(item.id)
                      }}
                    />
                    <div
                      className="is-open-icon-wrapper"
                      onClick={() => {
                        showHideNotificationBody(item.id)
                      }}
                    >
                      {!item.isOpen && <IconRight />}
                      {item.isOpen && <IconDown />}
                    </div>
                  </div>
                </div>
                {item.isOpen && (
                  <div className="rule-item-details">
                    <div className="rules-body-wrapper">
                      <div className="rule-body-details">
                        <OneNotificationItem
                          item={item}
                          onChangeType={onChangeRuleType}
                          notificationArray={notification}
                          setNotificationArray={setNotification}
                          questionnaireArray={programQuestionnaireArray}
                          consentFormArray={programConsentForms}
                        />
                      </div>
                      <div className="rule-btn-section">
                        <ButtonNew
                          type="link"
                          text={'cancel'}
                          onClick={() => {
                            cancelNotificationChange(
                              item.id,
                              item.isNewNotification,
                            )
                          }}
                        />

                        <ButtonNew
                          text={'Save'}
                          onClick={() => {
                            saveNotificationByItemId(item.id)
                          }}
                          type="primary"
                          disable={!item.type || !item.notificationText}
                        />
                      </div>
                    </div>
                  </div>
                )}
              </div>
            )
          })}
        <AddInputField
          text="Add notification"
          onClick={() => {
            addNewNotification()
          }}
        />
      </div>
    </div>
  )
}

function mapStateToProps(state, ownProps) {
  let tenantId = ownProps.match.params.id
  let programId = ownProps.match.params.pId
  if (!tenantId || !programId) {
    return {
      programQuestionnaireArray: null,
    }
  }
  let tenant =
    state.tenantReducer.length > 0
      ? getTenantsById(state.tenantReducer, tenantId)
      : {}
  let program =
    !tenant || !tenant.programs || !tenant.programs.length
      ? {}
      : tenant.programs.find((p) => p.id === programId)

  let consentForms =
    program.consentForms && program.consentForms.length
      ? program.consentForms.map((cf) => {
          return {
            id: cf.id,
            name: cf.title,
          }
        })
      : program.consentForms
  return {
    programNotifications: program.notifications,
    programQuestionnaireArray: program.questionnaires,
    programConsentForms: consentForms,
  }
}

const mapDispatchToProps = {
  loadProgramQuestionnaire: tenantsActions.loadProgramQuestionnaires, // tenantId. programId
  loadProgramNotifications: tenantsActions.loadProgramNotifications,
  loadProgramConsentForms: tenantsActions.loadProgramConsentForms,
  createProgramNotification: tenantsActions.createProgramNotification,
  updateProgramNotification: tenantsActions.updateProgramNotification,
  deleteProgramNotification: tenantsActions.deleteProgramNotification,
}

export default connect(mapStateToProps, mapDispatchToProps)(Notification)
