import React from 'react'
import InputError from '../../../../../common/InputError/InputError'
import * as ruleConstant from '../AlertRules/constants'
import CallToAction from '../../../../../common/CallToAction/CallToAction'
import SelectFormsItems from '../../../../../GlobalComponent/SelectFormsItems/SelectFormsItems'
import ChipsItem from '../../../../../common/ChipsItem/ChipsItem'
import * as notificationConstant from './constant'
import { Checkbox, FormControlLabel } from '@valerahealth/ui-components'
const NOTIFICATION_OPTIONS = notificationConstant.NOTIFICATION_OPTIONS
const OneNotificationItem = ({
  item,
  onChangeType,
  setNotificationArray,
  notificationArray,
  questionnaireArray,
  consentFormArray,
  error,
}) => {
  const activeNotificationChecked = (id) => {
    setNotificationArray(
      notificationArray.map((notItem) => {
        if (notItem.id === id) {
          return { ...notItem, active: !notItem.active }
        }
        return notItem
      }),
    )
  }

  const activeEmailNotificationChecked = (id) => {
    setNotificationArray(
      notificationArray.map((notItem) => {
        if (notItem.id === id) {
          return {
            ...notItem,
            activeEmail: !notItem.activeEmail,
          }
        }
        return notItem
      }),
    )
  }

  const onChangeFiled = (e, itemId, valueName) => {
    setNotificationArray(
      notificationArray.map((a) => {
        if (itemId === a.id) {
          return {
            ...a,
            [valueName]: e.target.value,
          }
        }

        return a
      }),
    )
  }

  const AddTemplate = () => {
    setNotificationArray(
      notificationArray.map((a) => {
        if (a.id === item.id) {
          const [formArray, idsType] =
            item.type === notificationConstant.QUESTIONNAIRE_COMPLETED
              ? [questionnaireArray, 'questionnaireIds']
              : item.type === notificationConstant.CONSENT_FORM_AGREED
              ? [consentFormArray, 'consentFormIds']
              : [[], '']

          return {
            ...a,
            questionnaireModalOpen: true,
            modalFormsList:
              !formArray || !formArray.length
                ? []
                : formArray.map((qItem) => {
                    const isSelected =
                      item[idsType] &&
                      item[idsType].length &&
                      item[idsType].includes(qItem.id)

                    return {
                      isSelected,
                      name: qItem.alias
                        ? `${qItem.name} (${qItem.alias})`
                        : qItem.name,
                      id: qItem.id,
                    }
                  }),
          }
        }

        return a
      }),
    )
  }

  const closeModal = () => {
    setNotificationArray(
      notificationArray.map((a) => {
        if (a.id === item.id) {
          return {
            ...a,
            questionnaireModalOpen: false,
            modalFormsList: [],
          }
        }
        return a
      }),
    )
  }

  const saveModal = () => {
    setNotificationArray(
      notificationArray.map((a) => {
        if (a.id === item.id) {
          if (item.type === notificationConstant.QUESTIONNAIRE_COMPLETED) {
            return {
              ...a,
              questionnaireModalOpen: false,
              modalFormsList: [],
              questionnaireIds: a.modalFormsList
                .filter((fItem) => fItem.isSelected)
                .map((mItem) => mItem.id),
            }
          }
          /**item.type === notificationConstant.CONSENT_FORM_AGREED**/
          return {
            ...a,
            questionnaireModalOpen: false,
            modalFormsList: [],
            consentFormIds: a.modalFormsList
              .filter((fItem) => fItem.isSelected)
              .map((mItem) => mItem.id),
          }
        }
        return a
      }),
    )
  }

  function changeCheckFormModal(id) {
    setNotificationArray(
      notificationArray.map((a) => {
        if (a.id === item.id) {
          return {
            ...a,
            modalFormsList: a.modalFormsList.map((qItem) => {
              if (qItem.id === id) {
                return { ...qItem, isSelected: !qItem.isSelected }
              }

              return qItem
            }),
          }
        }
        return a
      }),
    )
  }
  const deleteQuestItem = (id, type = 'qu') => {
    setNotificationArray(
      notificationArray.map((a) => {
        if (a.id === item.id) {
          if (type === 'cf') {
            return {
              ...a,
              consentFormIds: a.consentFormIds.filter((qId) => qId !== id),
            }
          }

          /**type === "cf"**/
          return {
            ...a,
            questionnaireIds: a.questionnaireIds.filter((qId) => qId !== id),
          }
        }
        return a
      }),
    )
  }
  return (
    <>
      <div className="rule-filed">
        <div className="rule-label">Type*</div>
        <div className="rule-input">
          <select
            disabled={!item.isNewNotification}
            className=""
            value={item.type || ''}
            onChange={(e) => onChangeType(e, item.id)}
          >
            <option value="" disabled hidden>
              Select Notification Type
            </option>
            {NOTIFICATION_OPTIONS.map((note) => {
              return (
                <option key={note.value} value={note.value}>
                  {note.text}
                </option>
              )
            })}
          </select>
        </div>
      </div>
      <div className="rule-filed">
        <div className="rule-label">Notification text*</div>
        <div className="rule-input">
          <input
            type="text"
            placeholder="Enter notification text"
            value={item.notificationText}
            onChange={(e) => onChangeFiled(e, item.id, 'notificationText')}
          />
        </div>
      </div>
      <InputError
        showError={error && error.text}
        text={ruleConstant.FAILED_ERROR_TEXT}
      />
      {(item.type === notificationConstant.QUESTIONNAIRE_COMPLETED ||
        item.type === notificationConstant.CONSENT_FORM_AGREED) && (
        <div className="rule-filed aline-top-field">
          <div className="rule-label">
            {item.type === notificationConstant.QUESTIONNAIRE_COMPLETED
              ? 'Questionnaire templates'
              : 'Consent Form templates'}
          </div>
          <div className="rule-input">
            <div className="select-questionnaire-section">
              <CallToAction
                onClick={AddTemplate}
                text="Add template"
                iconType="plus"
              />
              <SelectFormsItems
                closeModal={closeModal}
                saveOnClick={saveModal}
                changeCheckItem={changeCheckFormModal}
                title="Add templates"
                isOpen={item.questionnaireModalOpen}
                modalFormList={item.modalFormsList}
                saveBtnText="Save"
              />
              {Boolean(
                item.type === notificationConstant.QUESTIONNAIRE_COMPLETED &&
                  item.questionnaireIds?.length &&
                  questionnaireArray?.length,
              ) &&
                item.questionnaireIds.map((qId) => {
                  const qItem = questionnaireArray.find(
                    (qItem) => qItem.id === qId,
                  )

                  return (
                    <ChipsItem
                      key={qItem.id}
                      text={qItem.name}
                      deleteClick={() => deleteQuestItem(qItem.id, 'qu')}
                    />
                  )
                })}
              {Boolean(
                item.type === notificationConstant.CONSENT_FORM_AGREED &&
                  item.consentFormIds &&
                  consentFormArray?.length,
              ) &&
                item.consentFormIds.map((qId) => {
                  const qItem = consentFormArray.find(
                    (qItem) => qItem.id === qId,
                  )

                  return (
                    <ChipsItem
                      key={qItem.id}
                      text={qItem.name}
                      deleteClick={() => deleteQuestItem(qItem.id, 'cf')}
                    />
                  )
                })}
            </div>
          </div>
        </div>
      )}
      <div className="rule-filed">
        <div className="rule-label">Notification type</div>
        <div className="rule-input">
          <FormControlLabel
            control={
              <Checkbox
                checked={item.active}
                onChange={() => {
                  activeNotificationChecked(item.id)
                }}
              />
            }
            label="Care Manager App"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={item.activeEmail}
                onChange={() => {
                  activeEmailNotificationChecked(item.id)
                }}
              />
            }
            label="Email"
          />
        </div>
      </div>
    </>
  )
}
export default OneNotificationItem
