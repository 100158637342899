//default component
import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import SelectFormsItems from '../../../../../../GlobalComponent/SelectFormsItems/SelectFormsItems'
import CallToAction from '../../../../../../common/CallToAction/CallToAction'
import ChipsItem from '../../../../../../common/ChipsItem/ChipsItem'
import * as tenantApi from '../../../../../../../api/tenantApi'
import { loadProgramQuestionnaires } from '../../..../../../../../../../redux/actions/tenantsActions'
import { connect } from 'react-redux'
import { getTenantsById } from '../../../../../../utilities'

const QuestionnaireSelection = ({
  item,
  tenantId,
  programId,
  questionnaireArray,
  loadProgramQuestionnaires,
  alertRulesArray,
  handleSetAlertRulesArray,
}) => {
  useEffect(() => {
    if (
      item &&
      item.questionnaireSelected &&
      item.questionnaireSelected.onlyId
    ) {
      tenantApi
        .getOneQuestionnaireItem(item.questionnaireSelected.onlyId, tenantId)
        .then((quest) => {
          let questions = quest.questions
          if (questions && questions.length) {
            let questArrayView = questions.map((q) => {
              return {
                id: q.id,
                number: q.number,
                text: 'Q' + q.number,
                answerArray:
                  q.answers && q.answers.length
                    ? q.answers.map((asn) => {
                        return {
                          id: asn.id,
                          number: asn.number,
                          text: 'A' + asn.number,
                        }
                      })
                    : [],
              }
            })

            handleSetAlertRulesArray(
              alertRulesArray.map((a) => {
                if (a.id === item.id) {
                  return Object.assign({}, a, {
                    questionnaireSelected: quest,
                    questArrayView,
                    modalFormsList: [],
                    questionnaireModalOpen: false,
                  })
                }
                return a
              }),
            )
          }
        })
    }
  }, [item])

  useEffect(() => {
    if (!questionnaireArray || !questionnaireArray.length) {
      loadProgramQuestionnaires(tenantId, programId)
    }
  }, [questionnaireArray])

  const [saveLoading, setSaveLoading] = useState(false)
  const closeModal = () => {
    handleSetAlertRulesArray(
      alertRulesArray.map((a) => {
        if (a.id === item.id) {
          return Object.assign({}, a, {
            questionnaireModalOpen: false,
            modalFormsList: [],
          })
        }
        return a
      }),
    )
  }

  const saveModal = () => {
    // Todo start loading
    // {id, isSelected: true, name}
    setSaveLoading(true)
    let selectQuestionnaire = item.modalFormsList.find(
      (qItem) => qItem.isSelected,
    )
    if (selectQuestionnaire && selectQuestionnaire.id) {
      tenantApi
        .getOneQuestionnaireItem(selectQuestionnaire.id, tenantId)
        .then((quest) => {
          let questions = quest.questions
          if (questions && questions.length) {
            let questArrayView = questions.map((q) => {
              return {
                id: q.id,
                number: q.number,
                text: 'Q' + q.number,
                answerArray:
                  q.answers && q.answers.length
                    ? q.answers.map((asn) => {
                        return {
                          id: asn.id,
                          number: asn.number,
                          text: 'A' + asn.number,
                        }
                      })
                    : [],
              }
            })

            handleSetAlertRulesArray(
              alertRulesArray.map((a) => {
                if (a.id === item.id) {
                  return Object.assign({}, a, {
                    questionnaireSelected: quest,
                    questArrayView,
                    modalFormsList: [],
                    questionnaireModalOpen: false,
                    questionIdSelect: '',
                    answerIdSelect: '',
                  })
                }
                return a
              }),
            )
          }
          setSaveLoading(false)
        })
        .catch((err) => {
          console.log('THE LOAD QUEST ERROR: ', err)
          // Todo failed load questionnaire
          setSaveLoading(false)
        })
      // get questionnaireTemplate
    }
  }

  const AddQuestionnaire = () => {
    // questionnaireModalOpen
    handleSetAlertRulesArray(
      alertRulesArray.map((a) => {
        if (a.id === item.id) {
          return Object.assign({}, a, {
            questionnaireModalOpen: true,
            modalFormsList:
              !questionnaireArray || !questionnaireArray.length
                ? []
                : questionnaireArray.map((qItem) => {
                    let isSelected =
                      item.questionnaireSelected.length &&
                      item.questionnaireSelected.id === qItem.id
                    // let isSelected = false;
                    return {
                      isSelected: isSelected,
                      name: qItem.alias
                        ? qItem.name + ' (' + qItem.alias + ')'
                        : qItem.name,
                      id: qItem.id,
                    }
                  }),
          })
        }
        return a
      }),
    )
  }

  /**
   * Delete the one that have (if select one it is only one questionnaire)
   * */
  const deleteQuestItem = () => {
    handleSetAlertRulesArray(
      alertRulesArray.map((a) => {
        if (a.id === item.id) {
          return Object.assign({}, a, {
            questionnaireSelected: {},
            questionIdSelect: '',
            answerIdSelect: '',
          })
        }
        return a
      }),
    )
  }

  const changeCheckFormPrimaryFormModal = (id) => {
    handleSetAlertRulesArray(
      alertRulesArray.map((a) => {
        if (a.id === item.id) {
          return Object.assign({}, a, {
            modalFormsList: a.modalFormsList.map((qItem) => {
              if (qItem.id === id) {
                return Object.assign({}, qItem, {
                  isSelected: !qItem.isSelected,
                })
              } else {
                return Object.assign({}, qItem, {
                  isSelected: false,
                })
              }
            }),
          })
        }
        return a
      }),
    )
  }

  return (
    <div className="rule-filed aline-top-field">
      <div className="rule-label">Questionnaire</div>
      <div className="rule-input">
        <div className="select-questionnaire-section">
          <SelectFormsItems
            saveLoading={saveLoading}
            closeModal={closeModal}
            saveOnClick={saveModal}
            changeCheckItem={changeCheckFormPrimaryFormModal}
            title={'Add templates'}
            isOpen={item.questionnaireModalOpen}
            modalFormList={item.modalFormsList}
            saveBtnText="Save"
          />
          {!item.questionnaireSelected.id && (
            <CallToAction
              onClick={AddQuestionnaire}
              text="Choose questionnaire"
              iconType="plus"
            />
          )}
          {item.questionnaireSelected &&
            item.questionnaireSelected.id &&
            item.questionnaireSelected.id.length > 0 && (
              <ChipsItem
                key={item.questionnaireSelected.id}
                text={item.questionnaireSelected.name}
                deleteClick={() =>
                  deleteQuestItem(item.questionnaireSelected.id)
                }
              />
            )}
        </div>
      </div>
    </div>
  )
}

QuestionnaireSelection.propTypes = {}

// export default QuestionnaireSelection;
function mapStateToProps(state, ownProps) {
  const path = location.pathname.split('/')

  let tenantId = path[3]
  let programId = path[5]
  if (!tenantId || !programId) {
    return {
      questionnaireArray: [],
    }
  }

  let tenant =
    state.tenantReducer.length > 0
      ? getTenantsById(state.tenantReducer, tenantId)
      : {}
  let program =
    !tenant || !tenant.programs || !tenant.programs.length
      ? {}
      : tenant.programs.find((p) => p.id === programId)

  return {
    questionnaireArray: program.questionnaires || [],
    tenantId,
    programId,
  }
}

const mapDispatchToProps = {
  loadProgramQuestionnaires,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(QuestionnaireSelection)
