import React from 'react'
import { Route, Switch } from 'react-router-dom'
import NavBar from '../../../common/NavBar/NavBar'
import Programs from '../Programs'
import Tools from './ToolsTabs/Tools'
import Logs from '../Logs'
import PropTypes from 'prop-types'

function TenantBodyPage({ tenant, baseRoute, history, match, saveNewProgram }) {
  const programItemClicked = (programId) => {
    const tenantId = match.params && match.params.id ? match.params.id : null
    if (tenantId) {
      history.push(
        '/home/tenants/' + tenantId + '/programs/' + programId + '/settings',
      )
    }
  }

  const barsTenantPage = [
    { text: 'Programs', link: baseRoute + '/programs' },
    { text: 'Tools', link: baseRoute + '/tools' },
    // { text: "Users", link: baseRoute + "/users" },
    { text: 'Logs', link: baseRoute + '/logs' },
  ]
  return (
    <div className="tenant-component-body">
      <div className="tenant-component-headers">
        <NavBar bars={barsTenantPage} />
      </div>
      <Switch className="tenant-component-inside-body">
        <Route
          path="/home/tenants/:id/programs"
          render={() => (
            <Programs
              programs={tenant.programs}
              itemClick={programItemClicked}
              saveProgram={(program, cb) => {
                let tenantId = match.params.id
                saveNewProgram(program, tenantId, cb)
              }}
            />
          )}
        />
        <Route
          path="/home/tenants/:id/tools"
          render={(props) => <Tools {...props} />}
        />
        {/* <Route path="/home/tenants/:id/users" component={Patients} /> */}
        <Route path="/home/tenants/:id/logs" component={Logs} />
        <Route
          render={() => (
            <Programs
              programs={tenant.programs}
              itemClick={programItemClicked}
              saveProgram={(a, b, c) => {
                console.log('program saved')
              }}
            />
          )}
        />
      </Switch>
    </div>
  )
}

TenantBodyPage.propTypes = {
  tenant: PropTypes.object.isRequired,
  baseRoute: PropTypes.string.isRequired,
  match: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  showNotImplemented: PropTypes.func.isRequired,
}
export default TenantBodyPage
