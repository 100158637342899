import * as constant from './constants'
export const initAlertRuleUnchangedLocationItem = {
  active: false,
  optionMenuOpen: false,
  isOpen: false,
  type: constant.UNCHANGED_LOCATION,
  isNewAlertRule: false,
  name: '',
  alertText: '',
  timeWindow: {
    unit: '',
    value: '',
  },
  movementRadius: '',
  escalationWindow: {
    unit: '',
    value: '',
  },
  frequencyDataPoint: '',
  frequencyTimeWindow: {
    unit: '',
    value: '',
  },
}
export const initAlertRuleQuestionnaireScoreItem = {
  active: false,
  optionMenuOpen: false,
  isOpen: false,
  type: constant.ASSESSMENT_SCORE,
  isNewAlertRule: false,
  name: '',
  alertText: '',
  timeWindow: {
    unit: '',
    value: '',
  },
  questionnaireModalOpen: false,
  modalFormsList: [],
  questionnaireIds: [],
  condition: {
    type: '',
    values: {},
  },
}
export const initAlertRuleAnswerQuestionItem = {
  active: false,
  optionMenuOpen: false,
  isOpen: false,
  type: constant.ASSESSMENT_ANSWER,
  isNewAlertRule: false,
  name: '',
  alertText: '',
  // timeWindow: {
  //   unit: "",
  //   value: ""
  // },
  questionnaireModalOpen: false,
  modalFormsList: [],
  questionnaireIds: [],
  questionnaireSelected: {},
  questionIdSelect: '',
  answerIdSelect: '',
  questArrayView: [],
}
export const initAlertRuleIncompleteAssessment = {
  active: false,
  optionMenuOpen: false,
  isOpen: false,
  type: constant.INCOMPLETE_ASSESSMENT,
  isNewAlertRule: false,
  name: '',
  alertText: '',
  timeWindow: {
    unit: '',
    value: '',
  },
  questionnaireModalOpen: false,
  modalFormsList: [],
  questionnaireIds: [],
  questionnaireSelected: {},
  questionIdSelect: '',
  answerIdSelect: '',
  questArrayView: [],
}
export const initChatDisabledItem = {
  optionMenuOpen: false,
  isOpen: false,
  isNewAlertRule: false,

  type: constant.CHAT_DISABLED,
  name: '',
  alertText: '',
  active: false,
  timeWindow: {
    unit: '',
    value: '',
  },
  escalationWindow: {
    unit: '',
    value: '',
  },
  callToActions: [],
}

const getUnchangedLocationModal = (modal = {}) => {
  let modalTransform = {
    type: modal.type,
    active: modal.active,
    name: modal.name,
    appData: { text: modal.alertText },
    timeWindow: modal.timeWindow,
    parameters: {
      radius: modal.movementRadius,
      escalationWindow: modal.escalationWindow,
      locationsFrequency: {
        number: modal.frequencyDataPoint,
        frequency: modal.frequencyTimeWindow,
      },
    },
  }
  if (!modal.isNewAlertRule) {
    modalTransform.id = modal.id
  }
  return modalTransform
}
const getUnchangedLocationError = (input = {}) => {
  let modal = getUnchangedLocationModal(input)
  // Type must be exist!
  let error = {}
  let errorExist = false
  function updateError(filed) {
    errorExist = true
    error[filed] = true
  }

  if (!modal.name) {
    updateError('name')
  }
  if (!modal.appData.text) {
    updateError('text')
  }
  if (
    !modal.timeWindow.unit ||
    (!modal.timeWindow.value && modal.timeWindow.value !== 0)
  ) {
    updateError('timeWindow')
  }
  if (!modal.parameters.radius) {
    updateError('radius')
  }
  if (
    !modal.parameters.escalationWindow ||
    !modal.parameters.escalationWindow.unit ||
    !modal.parameters.escalationWindow.value
  ) {
    updateError('escalationWindow')
  }
  if (
    !modal.parameters.locationsFrequency ||
    (modal.parameters.locationsFrequency &&
      (!modal.parameters.locationsFrequency.number ||
        modal.parameters.locationsFrequency.number < 1))
  ) {
    updateError('locationsFrequencyNumber')
  }
  /**
   * locationsFrequency: {
   *     umber: 6,
   *     frequency: {
   *         value,
   *         unit
   *     }
   * }
   * **/
  if (
    !modal.parameters.locationsFrequency ||
    !modal.parameters.locationsFrequency.frequency ||
    (modal.parameters.locationsFrequency.frequency &&
      (!modal.parameters.locationsFrequency.frequency.value ||
        !modal.parameters.locationsFrequency.frequency.unit))
  ) {
    updateError('locationsFrequencyWindow')
  }
  if (errorExist) {
    return { ok: false, error: error }
  }
  return { ok: true, data: modal }
}
const getAssessmentScoreModal = (modal = {}) => {
  let score = {
    operator: modal.condition ? modal.condition.type : null,
  }
  if (modal.condition && modal.condition.values) {
    if (modal.condition.values.score || modal.condition.values.score === 0) {
      score.value1 = modal.condition.values.score
    } else {
      score.value1 = modal.condition.values.minScore
      score.value2 = modal.condition.values.maxScore
    }
  }
  let modalTransform = {
    type: modal.type,
    active: modal.active,
    name: modal.name,
    appData: { text: modal.alertText },
    // timeWindow: modal.timeWindow,
    parameters: {
      questionnaireTemplateIds: modal.questionnaireIds,
      score,
    },
  }
  if (!modal.isNewAlertRule) {
    modalTransform.id = modal.id
  }
  return modalTransform
}
const getAssessmentScoreError = (input = {}) => {
  let modal = getAssessmentScoreModal(input)
  // Type must be exist!
  let error = {}
  let errorExist = false
  function updateError(filed) {
    errorExist = true
    error[filed] = true
  }
  if (!modal.name) {
    updateError('name')
  }
  if (!modal.appData.text) {
    updateError('text')
  }
  if (!modal.parameters.questionnaireTemplateIds.length) {
    updateError('questionnaire')
  }
  if (!modal.parameters.score.operator) {
    updateError('conditionOperator')
  }
  if (modal.parameters.score.operator) {
    let score = modal.parameters.score
    let scoreKeys = Object.keys(score)
    if (!scoreKeys.includes('value1')) {
      updateError('conditionValues')
    } else {
      // value1 exist
      if (scoreKeys.includes('value2')) {
        // need the two values
        if (
          (!score.value1 && score.value1 !== 0) ||
          (!score.value2 && score.value2 !== 0)
        ) {
          updateError('conditionValues')
        }
      } else if (!score.value1 && score.value1 !== 0) {
        // need only value1
        updateError('conditionValues')
      }
    }
  } else {
    updateError('conditionOperator')
  }
  if (errorExist) {
    return { ok: false, error: error }
  }
  return { ok: true, data: modal }
}
const getAssessmentAnswerModal = (modal = {}) => {
  let modalTransform = {
    type: modal.type,
    active: modal.active,
    name: modal.name,
    appData: { text: modal.alertText },
    // timeWindow: modal.timeWindow,
    parameters: {
      questionnaireTemplateId: modal.questionnaireSelected
        ? modal.questionnaireSelected.id
        : null,
      questionId: modal.questionIdSelect || null,
      answerId: modal.answerIdSelect || null,
    },
  }
  if (!modal.isNewAlertRule) {
    modalTransform.id = modal.id
  }
  return modalTransform
}

const getIncompleteAssessmentModal = (modal = {}) => {
  let modalTransform = {
    type: modal.type,
    active: modal.active,
    name: modal.name,
    appData: { text: modal.alertText },
    timeWindow: modal.timeWindow,
    parameters: {
      questionnaireTemplateIds: modal.questionnaireSelected
        ? [modal.questionnaireSelected.id]
        : null,
    },
  }
  if (!modal.isNewAlertRule) {
    modalTransform.id = modal.id
  }
  return modalTransform
}

const getAssessmentAnswerError = (input = {}) => {
  let modal = getAssessmentAnswerModal(input)
  // Type must be exist!
  let error = {}
  let errorExist = false
  function updateError(filed) {
    errorExist = true
    error[filed] = true
  }
  if (!modal.name) {
    updateError('name')
  }
  if (!modal.appData.text) {
    updateError('text')
  }
  if (!modal.parameters.questionnaireTemplateId) {
    updateError('questionnaire')
  }
  if (!modal.parameters.questionId) {
    updateError('questionnaireQuest')
  }
  if (!modal.parameters.answerId) {
    updateError('questionnaireAns')
  }

  if (errorExist) {
    return { ok: false, error: error }
  }
  return { ok: true, data: modal }
}

const getIncompleteAssessmentError = (input = {}) => {
  let modal = getIncompleteAssessmentModal(input)
  // Type must be exist!
  let error = {}
  let errorExist = false
  function updateError(filed) {
    errorExist = true
    error[filed] = true
  }
  if (!modal.name) {
    updateError('name')
  }
  if (!modal.appData.text) {
    updateError('text')
  }
  if (
    !modal.parameters.questionnaireTemplateIds ||
    !modal.parameters.questionnaireTemplateIds.length
  ) {
    updateError('questionnaire')
  }

  if (errorExist) {
    return { ok: false, error: error }
  }
  return { ok: true, data: modal }
}

const getChatDisabledModal = (modal = {}) => {
  let modalTransform = {
    type: modal.type,
    active: modal.active,
    name: modal.name,
    appData: { text: modal.alertText },
    timeWindow: modal.timeWindow,
    callToActions: modal.callToActions,
    parameters: {
      escalationWindow: modal.escalationWindow,
    },
  }

  if (!modal.isNewAlertRule) {
    modalTransform.id = modal.id
  }
  return modalTransform
}

const getChatDisabledError = (input = {}) => {
  let modal = getChatDisabledModal(input)
  // Type must be exist!
  let error = {}
  let errorExist = false
  function updateError(filed) {
    errorExist = true
    error[filed] = true
  }

  if (!modal.name) {
    updateError('name')
  }
  if (!modal.appData.text) {
    updateError('text')
  }
  if (
    !modal.timeWindow.unit ||
    (!modal.timeWindow.value && modal.timeWindow.value !== 0)
  ) {
    updateError('timeWindow')
  }
  if (
    modal.parameters.escalationWindow &&
    modal.parameters.escalationWindow.unit &&
    !modal.parameters.escalationWindow.value
  ) {
    updateError('escalationWindow')
  }
  if (errorExist) {
    return { ok: false, error: error }
  }
  return { ok: true, data: modal }
}

export const getRuleModal = (modal = {}) => {
  switch (modal.type) {
    case constant.UNCHANGED_LOCATION: {
      return getUnchangedLocationError(modal)
    }
    case constant.ASSESSMENT_SCORE: {
      return getAssessmentScoreError(modal)
    }
    case constant.ASSESSMENT_ANSWER: {
      return getAssessmentAnswerError(modal)
    }
    case constant.INCOMPLETE_ASSESSMENT: {
      return getIncompleteAssessmentError(modal)
    }
    case constant.CHAT_DISABLED: {
      return getChatDisabledError(modal)
    }
  }
}
const getConditionForQuestionnaire = (rules) => {
  let condition = {
    type: '',
  }
  if (rules.parameters && rules.parameters.score) {
    let score = rules.parameters.score
    condition.type = score && score.operator ? score.operator : ''
    if (score.value1 && score.value2) {
      let minScore = score.value1 > score.value2 ? score.value2 : score.value1
      let maxScore = score.value1 > score.value2 ? score.value1 : score.value2
      condition.values = { minScore, maxScore }
    } else {
      // there is only value 1
      condition.values = { score: score.value1 }
    }
  }
  return condition
}
export const transformUnchangedLocationToUiView = (initState, rules) => {
  return Object.assign({}, initState, {
    id: rules.id,
    isOpen: false,
    active: rules.active,
    name: rules.name,
    alertText: rules.appData && rules.appData.text ? rules.appData.text : '',
    timeWindow: {
      unit: rules.timeWindow ? rules.timeWindow.unit : '',
      value: rules.timeWindow ? rules.timeWindow.value : '',
    },
    movementRadius: rules.parameters ? rules.parameters.radius : '',
    escalationWindow: {
      unit:
        rules.parameters && rules.parameters.escalationWindow
          ? rules.parameters.escalationWindow.unit
          : '',
      value:
        rules.parameters && rules.parameters.escalationWindow
          ? rules.parameters.escalationWindow.value
          : '',
    },
    frequencyDataPoint:
      rules.parameters && rules.parameters.locationsFrequency
        ? rules.parameters.locationsFrequency.number
        : '',
    frequencyTimeWindow: {
      unit:
        rules.parameters &&
        rules.parameters.locationsFrequency &&
        rules.parameters.locationsFrequency.frequency
          ? rules.parameters.locationsFrequency.frequency.unit
          : '',
      value:
        rules.parameters &&
        rules.parameters.locationsFrequency &&
        rules.parameters.locationsFrequency.frequency
          ? rules.parameters.locationsFrequency.frequency.value
          : '',
    },
  })
}
export const transformAssessmentScore = (initState, rules) => {
  return Object.assign({}, initState, {
    id: rules.id,
    isOpen: false,
    active: rules.active,
    name: rules.name,
    alertText: rules.appData && rules.appData.text ? rules.appData.text : '',
    /**
     * To use at timeWindow this moment
     * **/
    timeWindow: {
      unit: rules.timeWindow ? rules.timeWindow.unit : '',
      value: rules.timeWindow ? rules.timeWindow.value : '',
    },
    questionnaireIds:
      (rules.parameters && rules.parameters.questionnaireTemplateIds) || [],
    /** At that moment no enable this because the values is not correct**/
    condition: getConditionForQuestionnaire(rules),
  })
}
export const transformAssessmentAnswer = (initState, rules) => {
  return Object.assign({}, initState, {
    id: rules.id,
    isOpen: false,
    active: rules.active,
    name: rules.name,
    alertText: rules.appData && rules.appData.text ? rules.appData.text : '',
    questionIdSelect: (rules.parameters && rules.parameters.questionId) || '',
    answerIdSelect: (rules.parameters && rules.parameters.answerId) || '',
    questionnaireSelected: {
      onlyId: rules.parameters.questionnaireTemplateId,
    },
  })
}

export const transformDisableChat = (initState, rules) => {
  return Object.assign({}, initState, {
    id: rules.id,
    isOpen: false,
    active: rules.active,
    name: rules.name,
    callToActions: rules.callToActions,
    alertText: rules.appData && rules.appData.text ? rules.appData.text : '',
    timeWindow: {
      unit: rules.timeWindow ? rules.timeWindow.unit : '',
      value: rules.timeWindow ? rules.timeWindow.value : '',
    },
    escalationWindow: {
      unit:
        rules.parameters && rules.parameters.escalationWindow
          ? rules.parameters.escalationWindow.unit
          : '',
      value:
        rules.parameters && rules.parameters.escalationWindow
          ? rules.parameters.escalationWindow.value
          : '',
    },
  })
}

export const transformIncompleteAssessment = (initState, rules) => {
  return Object.assign({}, initState, {
    id: rules.id,
    isOpen: false,
    active: rules.active,
    name: rules.name,
    timeWindow: {
      unit: rules.timeWindow ? rules.timeWindow.unit : '',
      value: rules.timeWindow ? rules.timeWindow.value : '',
    },
    alertText: rules.appData && rules.appData.text ? rules.appData.text : '',
    questionIdSelect: (rules.parameters && rules.parameters.questionId) || '',
    answerIdSelect: (rules.parameters && rules.parameters.answerId) || '',
    questionnaireSelected: {
      onlyId:
        rules.parameters.questionnaireTemplateIds &&
        rules.parameters.questionnaireTemplateIds[0],
    },
    questionnaireIds:
      (rules.parameters && rules.parameters.questionnaireTemplateIds) || [],
  })
}

export const getTransformItemForUiArray = (rules) => {
  switch (rules.type) {
    case constant.UNCHANGED_LOCATION: {
      return transformUnchangedLocationToUiView(
        initAlertRuleUnchangedLocationItem,
        rules,
      )
    }
    case constant.ASSESSMENT_SCORE: {
      return transformAssessmentScore(
        initAlertRuleQuestionnaireScoreItem,
        rules,
      )
    }
    case constant.ASSESSMENT_ANSWER: {
      return transformAssessmentAnswer(initAlertRuleAnswerQuestionItem, rules)
    }
    case constant.INCOMPLETE_ASSESSMENT: {
      return transformIncompleteAssessment(
        initAlertRuleIncompleteAssessment,
        rules,
      )
    }
    case constant.CHAT_DISABLED: {
      return transformDisableChat(initChatDisabledItem, rules)
    }
    default:
      return null
  }
}
