import React from 'react'
import PropTypes from 'prop-types'
import './styleButtonNew.less'

const ButtonNew = ({ text, onClick, type, disable, loading, style }) => {
  /*if (loading) {
    return (
      <div className="button_simulate_loader">
        <div className="loader" />
      </div>
    );
  }*/
  let className =
    type === 'secondary'
      ? 'secondary-button'
      : type === 'primary'
      ? 'primary-button'
      : 'link-button'
  // link-button

  let localStyle = style || {}
  if (disable) {
    className += ' disable'
    return (
      <div className={className} style={localStyle}>
        {text}
      </div>
    )
  }
  if (loading) {
    className += ' disable'
    return (
      <div className={className} style={localStyle}>
        Loading...
      </div>
    )
  }

  return (
    <div className={className} onClick={onClick} style={localStyle}>
      {text}
    </div>
  )

  /*return (
          <div className={className} onClick={onClick}>
                  {text}
          </div>
      );*/
}

ButtonNew.propTypes = {
  text: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired, // primary/secondary/link
  disable: PropTypes.bool,
  loading: PropTypes.bool,
  style: PropTypes.object,
}

export default ButtonNew
