import React from 'react'
import { Route, Switch } from 'react-router-dom'
import StatusBar from './StatusBar'
import Tenants from '../Tenants/Tenants'
import Tools from '../Tools/Tools'
import Users from '../Users/Users'

const Body = () => {
  return (
    <div className="body-box">
      <StatusBar />
      <div className="body-content">
        <Switch>
          <Route
            path="/home/tenants"
            render={(props) => <Tenants {...props} />}
            key="tenants"
            name="tenants"
          />
          <Route
            path="/home/tools"
            render={(props) => <Tools {...props} />}
            key="tools"
            name="tools"
          />
          <Route
            path="/home/users"
            component={Users}
            key="users"
            name="users"
          />
          <Route
            render={(props) => <Tenants {...props} />}
            // component={PageNotFound}
          />
        </Switch>
      </div>
    </div>
  )
}

export default Body
