import React from 'react'
import './style.less'
import CloseIcon from '../../../image/icon_close.svg'
import AddIcon from '../../../image/icon_plus.svg'
import AddInputField from '../AddInputField/AddInputField'
/**
 *
 * */
const OneItem = ({
  conditions,
  options,
  changeCondition,
  addCondition,
  addRowCondition,
  deleteRowCondition,
  deleteCondition,
  onlyAndCondition = false,
}) => {
  const setSelectItem = (rowId, id, value) => {
    changeCondition(rowId, id, value)
  }
  const conditionExist = !!Object.keys(conditions).length
  return (
    <div className="wrap-condition">
      {conditions &&
        Object.keys(conditions).map((mapKey, key) => {
          const itemR = conditions[mapKey]
          const uniqKey = 'condition-key-' + key + '-' + JSON.stringify(itemR)
          const style = { display: 'flex', alignItems: 'center' }
          const iconStyle = {
            cursor: 'pointer',
            marginBottom: '11px',
            marginLeft: '8px',
          }
          return (
            <div key={uniqKey} style={style}>
              <div className="condition-row">
                {itemR.map((itemC, index) => {
                  return (
                    <div
                      className="condition-item"
                      key={uniqKey + '-item-' + index}
                    >
                      <select
                        value={itemC.value}
                        id={itemC.id}
                        onChange={(event) => {
                          setSelectItem(mapKey, itemC.id, event.target.value)
                        }}
                      >
                        <option value="" key="" disabled hidden>
                          A?
                        </option>

                        {options.length &&
                          options.map((option, index) => {
                            return (
                              <option
                                id={option.id}
                                key={option.id + index}
                                value={option.value}
                              >
                                {option.text}
                              </option>
                            )
                          })}
                      </select>
                      <div
                        className="close-icon-item"
                        onClick={() => {
                          deleteCondition(mapKey, itemC.id)
                        }}
                      >
                        <CloseIcon />
                      </div>
                      <div className="separate-line" />
                    </div>
                  )
                })}
                {!!onlyAndCondition && !itemR.length && (
                  <div
                    className="add-or-icon"
                    onClick={() => {
                      addCondition(mapKey)
                    }}
                  >
                    <AddIcon />
                  </div>
                )}
              </div>
              <div
                style={iconStyle}
                onClick={() => {
                  deleteRowCondition(mapKey)
                }}
              >
                <CloseIcon />
              </div>
            </div>
          )
        })}
      <div
        className={
          conditionExist ? 'add-row-btn' : 'add-row-btn empty-conditions'
        }
      >
        {
          <AddInputField
            text={conditionExist ? 'OR ' : 'Add Condition'}
            disable={onlyAndCondition && conditionExist}
            onClick={() => {
              addRowCondition()
            }}
          />
        }
      </div>
    </div>
  )
}

export default OneItem

/*const cond = {
  1: [
    { text: "A1", id: "a1", value: "A1" },
    { text: "A2", id: "a2", value: "A2" }
  ],
  5: [
    { text: "A3", id: "a3", value: "A3" },
    { text: "A4", id: "a4", value: "A4" }
  ]
};
const options = [
  { text: "A1", id: "a1", value: "A1" },
  { text: "A2", id: "a2", value: "A2" },
  { text: "A3", id: "a3", value: "A3" },
  { text: "A4", id: "a4", value: "A4" },
  { text: "A5", id: "a5", value: "A5" },
  { text: "A6", id: "a6", value: "A6" }
];*/
