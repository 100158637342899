import * as tenantApi from '../../api/tenantApi'
import * as programApi from '../../api/programApi'
import * as types from './actionsType'
import * as globalAction from './globalAction'
import * as storageConfig from '../../api/storageConfig'

export function loadTenantsSuccess(tenants) {
  return { type: types.INITIALIZE_TENANTS_LIST, tenants }
}
//
/**
 * Tenants Media actions
 * */
export function setTenantMediaItems(tenantId, mediaItems) {
  return {
    type: types.SET_TENANT_MEDIA_ITEMS,
    tenantId,
    mediaItems,
  }
}
export function setTenantUsers(tenantId, users) {
  return {
    type: types.SET_TENANT_USERS,
    tenantId,
    users,
  }
}

export function selectProgram(tenantId, programId) {
  return { type: types.SELECT_PROGRAM, tenantId, programId }
}

export function setTenantProviders(tenantId, programId, providers) {
  return {
    type: types.LOAD_PROVIDERS,
    tenantId,
    programId,
    providers,
  }
}

export function createNewMediaItemSuccess(media, tenantId) {
  return {
    type: types.SAVE_NEW_TENANT_TOOLS_MEDIA_ITEM,
    media,
    tenantId,
  }
}
export function updateMediaItemSuccess(media, id, tenantId) {
  return { type: types.UPDATE_TENANTS_MEDIA_ITEM, media, id, tenantId }
}
/**
 * Tenants Questionnaire actions
 * */
export function setTenantQuestionnaires(tenantId, questionnairesItems) {
  return {
    type: types.SET_TENANT_QUESTIONNAIRES_ITEMS,
    tenantId,
    questionnairesItems,
  }
}
export function createNewQuestionnaireSuccess(item, tenantId) {
  return {
    type: types.SAVE_NEW_TENANT_QUESTIONNAIRE,
    item,
    tenantId,
  }
}
export function updateQuestionnaireItemSuccess(item, tenantId) {
  return {
    type: types.UPDATE_TENANTS_QUESTIONNAIRE,
    item,
    id: item.id,
    tenantId,
  }
}
/**
 * Tenants ConsentForm actions
 * */
export function setTenantConsentFormItems(tenantId, consentFormItems) {
  return {
    type: types.SET_TENANT_CONSENT_FORM_ITEMS,
    tenantId,
    consentFormItems,
  }
}
export function createConsentFormSuccess(item, tenantId) {
  return {
    type: types.CREATE_TENANTS_CONSENT_FORM,
    tenantId,
    item,
  }
}
export function updateConsentFormSuccess(item, tenantId) {
  return {
    type: types.UPDATE_TENANTS_CONSENT_FORM,
    tenantId,
    item,
  }
}

export function setProgramConsentForm(tenantId, programId, consentForms) {
  return {
    type: types.SET_PROGRAMS_CONSENT_FORM,
    tenantId,
    programId,
    consentForms,
  }
}
export function setProgramMediaItems(tenantId, programId, mediaItems) {
  return {
    type: types.SET_PROGRAMS_MEDIA_ITEMS,
    tenantId,
    programId,
    mediaItems,
  }
}
export function createAlertRuleSuccess(tenantId, programId, alertRule) {
  return {
    type: types.SAVE_PROGRAM_ALERT_RULE_ITEM,
    alertRule,
    programId,
    tenantId,
  }
}
export function updateAlertRuleSuccess(tenantId, programId, alertRule) {
  return {
    type: types.UPDATE_PROGRAM_ALERT_RULE,
    alertRule,
    programId,
    tenantId,
  }
}
export function deleteAlertRuleSuccess(tenantId, programId, id) {
  return {
    type: types.DELETE_PROGRAM_ALERT_RULE,
    programId,
    tenantId,
    id,
  }
}
export function createNotificationSuccess(tenantId, programId, notification) {
  return {
    type: types.SAVE_PROGRAM_NOTIFICATION_ITEM,
    notification,
    programId,
    tenantId,
  }
}
export function updateNotificationSuccess(tenantId, programId, notification) {
  return {
    type: types.UPDATE_PROGRAM_NOTIFICATION,
    notification,
    programId,
    tenantId,
  }
}
export function deleteNotificationSuccess(tenantId, programId, id) {
  return {
    type: types.DELETE_PROGRAM_NOTIFICATION,
    id,
    programId,
    tenantId,
  }
}

export function addNewTenant(tenant) {
  return { type: types.ADD_NEW_TENANT, tenant }
}
export function addNewProgram(tenantId, program) {
  return { type: types.ADD_NEW_PROGRAM, program, tenantId }
}
export function setProgramList(programs, tenantId) {
  return { type: types.INITIALIZE_PROGRAMS_LIST, programs, tenantId }
}
export function setProgramSetting(tenantId, programId, settings) {
  return { type: types.SET_PROGRAMS_SETTING, tenantId, programId, settings }
}
export function setProgramQuestionnaires(
  tenantId,
  programId,
  questionnaires,
  sendAll = false,
) {
  return {
    type: types.SET_PROGRAMS_QUESTIONNAIRES,
    tenantId,
    programId,
    questionnaires,
    sendAll,
  }
}
export function setProgramSentiments(tenantId, programId, sentiments) {
  // SET_PROGRAMS_SENTIMENTS
  return {
    type: types.SET_PROGRAMS_SENTIMENTS,
    tenantId,
    programId,
    sentiments,
  }
}
// tenantId, programId, {name: program.name, logo: program.log, description: program.description}
export function updateProgramDetailsLocal(tenantId, programId, details) {
  return {
    type: types.UPDATE_PROGRAM_DETAILS,
    tenantId,
    programId,
    details,
  }
}
export function setProgramAlertRules(tenantId, programId, alertRules) {
  return {
    type: types.SET_PROGRAMS_RULES,
    tenantId,
    programId,
    alertRules,
  }
}
export function setProgramNotification(tenantId, programId, notifications) {
  return {
    type: types.SET_PROGRAMS_NOTIFICATION,
    tenantId,
    programId,
    notifications,
  }
}
/**
 * Tenant sets functions
 * * */

export function setTenantSentiments(tenantId, sentimentsItems) {
  return {
    type: types.SET_TENANT_SENTIMENTS_ITEMS,
    tenantId,
    sentimentsItems,
  }
}
// Actions with async call
export function loadTenants(tenantId) {
  return (dispatch) => {
    return tenantApi
      .getTenants()
      .then((tenants) => {
        dispatch(loadTenantsSuccess(tenants))
        if (tenantId && tenants.find((tenant) => tenant.id === tenantId)) {
          dispatch(loadPrograms(tenantId))
        }
        // dispatch(loadTenantsSuccess(initializeState.tenants));
      })
      .catch((err) => {
        if (err === 'Bad token') {
          storageConfig.clearStorage()
          dispatch(globalAction.initializeAppState())
        }
      })
  }
}
export function loadPrograms(tenantId) {
  return (dispatch) => {
    return tenantApi
      .getProgramsByTenantId(tenantId)
      .then((programs) => {
        dispatch(setProgramList(programs, tenantId))
        // dispatch(loadTenantsSuccess(initializeState.tenants));
      })
      .catch((err) => {
        if (err === 'Bad token') {
          storageConfig.clearStorage()
          dispatch(globalAction.initializeAppState())
        }
        console.error('Get programs failed failed:   ', err)
      })
  }
}
export function getProgramSetting(tenantId, programId) {
  return (dispatch, getState) => {
    if (getState().globalReducer && getState().globalReducer.callingSettings)
      return

    dispatch({ type: types.CALLING_SETTINGS, calling: true })

    return programApi
      .getProgramSetting(programId)
      .then((settings) => {
        dispatch({ type: types.CALLING_SETTINGS, calling: false })
        dispatch(setProgramSetting(tenantId, programId, settings))
      })
      .catch((err) => {
        if (err === 'Bad token') {
          storageConfig.clearStorage()
          dispatch(globalAction.initializeAppState())
        }
        console.error('Get programs failed failed:   ', err)
      })
  }
}

export function loadProgramQuestionnaires(tenantId, programId) {
  return (dispatch, getState) => {
    if (
      getState().globalReducer &&
      getState().globalReducer.callingQuestionnaire
    )
      return

    dispatch({ type: types.CALLING_QUESTIONNAIRE, calling: true })
    return programApi
      .getProgramQuestionnaires(programId)
      .then((questionnairesRes) => {
        dispatch({ type: types.CALLING_QUESTIONNAIRE, calling: false })
        const { items: questionnaires, all: sendAll } = questionnairesRes
        dispatch(
          setProgramQuestionnaires(
            tenantId,
            programId,
            questionnaires,
            sendAll,
          ),
        )
      })
      .catch((err) => {
        if (err === 'Bad token') {
          storageConfig.clearStorage()
          dispatch(globalAction.initializeAppState())
        }
        console.error('Get programs failed questionnaire failed:   ', err)
      })
  }
}
export function loadProgramSentiments(tenantId, programId) {
  return (dispatch) => {
    return programApi
      .getProgramQuestionnaires(programId, 'Sentiment')
      .then((questionnairesRes) => {
        const { items: questionnaires } = questionnairesRes
        dispatch(setProgramSentiments(tenantId, programId, questionnaires))
      })
      .catch((err) => {
        if (err === 'Bad token') {
          storageConfig.clearStorage()
          dispatch(globalAction.initializeAppState())
        }
        console.error('Get programs failed questionnaire failed:   ', err)
      })
  }
}
export function loadProgramMedia(tenantId, programId) {
  return (dispatch) => {
    return programApi
      .getProgramMediaItems(programId)
      .then((mediaItems) => {
        dispatch(setProgramMediaItems(tenantId, programId, mediaItems))
      })
      .catch((err) => {
        if (err === 'Bad token') {
          storageConfig.clearStorage()
          dispatch(globalAction.initializeAppState())
        }
        console.error('Get programs failed questionnaire failed:   ', err)
      })
  }
}
export function loadProgramConsentForms(tenantId, programId) {
  return (dispatch) => {
    return programApi
      .getProgramConsentFormItems(programId)
      .then((consentForms) => {
        dispatch(setProgramConsentForm(tenantId, programId, consentForms))
      })
      .catch((err) => {
        if (err === 'Bad token') {
          storageConfig.clearStorage()
          dispatch(globalAction.initializeAppState())
        }
        console.error('Get programs failed questionnaire failed:   ', err)
      })
  }
}
export function loadProgramAlertRules(tenantId, programId) {
  return (dispatch) => {
    return programApi
      .getProgramAlertRules(programId)
      .then((alertRules) => {
        dispatch(setProgramAlertRules(tenantId, programId, alertRules))
      })
      .catch((err) => {
        if (err === 'Bad token') {
          storageConfig.clearStorage()
          dispatch(globalAction.initializeAppState())
        }
        if (err) {
          console.error('Get programs failed questionnaire failed:   ', err)
        }
      })
  }
}
export function loadProgramNotifications(tenantId, programId) {
  return (dispatch) => {
    return programApi
      .getProgramNotification(programId)
      .then((notifications) => {
        dispatch(setProgramNotification(tenantId, programId, notifications))
      })
      .catch((err) => {
        if (err === 'Bad token') {
          storageConfig.clearStorage()
          dispatch(globalAction.initializeAppState())
        }
        if (err) {
          console.error('Get programs failed questionnaire failed:   ', err)
        }
      })
  }
}
export function loadTenantQuestionnaires(tenantId) {
  return (dispatch) => {
    return tenantApi
      .getTenantQuestionnaires(tenantId)
      .then((questionnairesItems) => {
        dispatch(setTenantQuestionnaires(tenantId, questionnairesItems))
      })
      .catch((err) => {
        if (err === 'Bad token') {
          storageConfig.clearStorage()
          dispatch(globalAction.initializeAppState())
        }
        console.error('Get programs failed questionnaire failed:   ', err)
      })
  }
}
export function loadTenantSentiments(tenantId) {
  return (dispatch) => {
    return tenantApi
      .getTenantQuestionnaires(tenantId, 'Sentiment')
      .then((sentimentsItems) => {
        dispatch(setTenantSentiments(tenantId, sentimentsItems))
      })
      .catch((err) => {
        if (err === 'Bad token') {
          storageConfig.clearStorage()
          dispatch(globalAction.initializeAppState())
        }
        console.error('Get programs failed questionnaire failed:   ', err)
      })
  }
}
export function loadTenantConsentForm(tenantId) {
  return (dispatch) => {
    return tenantApi
      .getTenantConsentForm(tenantId)
      .then((consentFormItems) => {
        dispatch(setTenantConsentFormItems(tenantId, consentFormItems))
      })
      .catch((err) => {
        if (err === 'Bad token') {
          storageConfig.clearStorage()
          dispatch(globalAction.initializeAppState())
        }
        console.error('Get programs failed questionnaire failed:   ', err)
      })
  }
}
export function loadTenantMedia(tenantId) {
  return (dispatch) => {
    return tenantApi
      .getTenantMedia(tenantId)
      .then((mediaItems) => {
        dispatch(setTenantMediaItems(tenantId, mediaItems))
      })
      .catch((err) => {
        if (err === 'Bad token') {
          storageConfig.clearStorage()
          dispatch(globalAction.initializeAppState())
        }
        console.error('Get programs failed questionnaire failed:   ', err)
      })
  }
}

export function saveSetting(settings, programId, tenantId) {
  return (dispatch) => {
    return programApi
      .saveSetting(settings, programId)
      .then((settings) => {
        dispatch(setProgramSetting(tenantId, programId, settings))
        dispatch(
          globalAction.addUserMessage('success', 'Update setting success'),
        )
        // dispatch(loadTenantsSuccess(initializeState.tenants));
      })
      .catch((err) => {
        if (err) {
          if (err === 'Bad token') {
            storageConfig.clearStorage()
            dispatch(globalAction.initializeAppState())
          } else {
            console.error('Get programs failed failed:   ', err)
            dispatch(
              globalAction.addUserMessage(
                'warning',
                'Update setting has failed',
              ),
            )
          }
        }
      })
  }
}
export function updateProgramDetails(tenantId, programId, payload, cb) {
  return (dispatch) => {
    return programApi
      .updateProgramDetails(tenantId, programId, payload)
      .then((program) => {
        dispatch(
          updateProgramDetailsLocal(tenantId, programId, {
            name: program.name,
            logo: program.logo,
            description: program.description,
          }),
        )
        dispatch(
          globalAction.addUserMessage('success', 'Update program success'),
        )
        cb({ ok: true })
      })
      .catch((err) => {
        if (err) {
          if (err === 'Bad token') {
            storageConfig.clearStorage()
            dispatch(globalAction.initializeAppState())
          } else {
            console.error('Get programs failed failed:   ', err)
            dispatch(
              globalAction.addUserMessage('warning', 'Update program failed'),
            )
            cb({ ok: false })
          }
        }
      })
  }
}
export function createNewProgram(programItem, tenantId, cb) {
  return (dispatch) => {
    return tenantApi
      .createNewProgram(programItem, tenantId)
      .then((program) => {
        // Todo need add that questionnaire to questionnaire array.
        dispatch(addNewProgram(tenantId, program))
        dispatch(
          globalAction.addUserMessage('success', 'Save new Program success'),
        )
        cb({ ok: true })
      })
      .catch((err) => {
        // globalAction
        if (err) {
          if (err === 'Bad token') {
            storageConfig.clearStorage()
            dispatch(globalAction.initializeAppState())
          } else {
            dispatch(
              globalAction.addUserMessage(
                'warning',
                'This Program is not valid',
              ),
            )
            cb({ ok: false, err })
          }
        }
      })
  }
}

/**
 * This is also for sentiment ids.
 * */
export function saveProgramQuestionnaires(tenantId, programId, questIds) {
  return (dispatch) => {
    return programApi
      .saveProgramLinkQuestionnaires(programId, questIds)
      .then((quest) => {
        // Todo need update the redux!
        // dispatch(setProgramSetting(tenantId, programId, settings));
        // dispatch(loadTenantsSuccess(initializeState.tenants));
      })
      .catch((err) => {
        if (err === 'Bad token') {
          storageConfig.clearStorage()
          dispatch(globalAction.initializeAppState())
        }
        console.error('Get programs failed failed:   ', err)
      })
  }
}
export function saveProgramToolBox(
  tenantId,
  programId,
  questIds,
  sendAllQues,
  mediaIds,
  consentFormsIds,
  cb,
) {
  const tools = {
    questionnaires: {
      all: !!sendAllQues,
    },
    media: {
      templateIds: mediaIds,
    },
    consent_forms: {
      templateIds: consentFormsIds,
    },
  }
  /**
   * The the ids only if all not mark
   * * */
  if (!tools.questionnaires.all) {
    tools.questionnaires.templateIds = questIds
  }
  return (dispatch) => {
    return programApi
      .saveProgramToolbox(programId, tools)
      .then((toolsRes) => {
        console.log('THE RESPONSE IS!!!:   ', toolsRes)
        dispatch(globalAction.addUserMessage('success', 'save toolbox success'))
        // dispatch(
        //     consodagvfdasfv
        // )
        // Todo need update redux
        dispatch(loadProgramQuestionnaires(tenantId, programId))
        dispatch(loadProgramSentiments(tenantId, programId))
        dispatch(loadProgramMedia(tenantId, programId))
        dispatch(loadProgramConsentForms(tenantId, programId))
        cb({ ok: true })
      })
      .catch((err) => {
        if (err) {
          if (err === 'Bad token') {
            storageConfig.clearStorage()
            dispatch(globalAction.initializeAppState())
          } else {
            console.error('Get programs failed failed:   ', err)
            dispatch(
              globalAction.addUserMessage('warning', 'failed save toolbox'),
            )
            cb({ ok: false })
          }
        }
      })
  }
}
export function saveProgramMedia(tenantId, programId, mediaIds) {
  return (dispatch) => {
    return programApi
      .saveProgramLinkMedia(programId, mediaIds)
      .then((idsRes) => {
        // Todo need update the redux!
      })
      .catch((err) => {
        if (err === 'Bad token') {
          storageConfig.clearStorage()
          dispatch(globalAction.initializeAppState())
        }
        console.error('Get programs failed failed:   ', err)
      })
  }
}
export function createMediaItem(item, tenantId, cb) {
  return (dispatch) => {
    return tenantApi
      .saveMediaItem(item, tenantId)
      .then((media) => {
        dispatch(createNewMediaItemSuccess(media, tenantId))
        dispatch(
          globalAction.addUserMessage(
            'success',
            'Create new media item success',
          ),
        )
        cb({ ok: true })
      })
      .catch((err) => {
        if (err) {
          if (err === 'Bad token') {
            storageConfig.clearStorage()
            dispatch(globalAction.initializeAppState())
          } else {
            dispatch(globalAction.addUserMessage('warning', err))
            cb({ ok: false, err })
          }
        }
      })
  }
}
export function updateMediaItem(item, id, tenantId, cb) {
  return (dispatch) => {
    return tenantApi
      .updateMediaItem(item, id, tenantId)
      .then((media) => {
        dispatch(updateMediaItemSuccess(media, id, tenantId))
        dispatch(
          globalAction.addUserMessage('success', 'update media item success'),
        )
        cb({ ok: true })
      })
      .catch((err) => {
        if (err) {
          if (err === 'Bad token') {
            storageConfig.clearStorage()
            dispatch(globalAction.initializeAppState())
          } else {
            dispatch(globalAction.addUserMessage('warning', err))
            cb({ ok: false, err })
          }
        }
      })
  }
}
export function createQuestionnaireItem(tenantId, item, cb) {
  return (dispatch) => {
    return tenantApi
      .saveQuestionnaireItem(tenantId, item)
      .then((questionnaire) => {
        dispatch(createNewQuestionnaireSuccess(questionnaire, tenantId))
        dispatch(
          globalAction.addUserMessage(
            'success',
            'create questionnaire item success',
          ),
        )
        cb({ ok: true })
      })
      .catch((err) => {
        // globalAction
        if (err) {
          if (err === 'Bad token') {
            storageConfig.clearStorage()
            dispatch(globalAction.initializeAppState())
          } else {
            console.error('save new questionnaire item has failed:   ', err)
            dispatch(
              globalAction.addUserMessage(
                'warning',
                'Failed to save questionnaire',
              ),
            )
            cb({ ok: false, err })
          }
        }
      })
  }
}
export function saveEditQuestionnaireItem(tenantId, item, id, cb) {
  return (dispatch) => {
    /// editQuestionnaireItem(tenantId, item, id)
    return tenantApi
      .editQuestionnaireItem(tenantId, item, id)
      .then((questionnaire) => {
        /**
         * Not need update the react because every time when open the edit dialog we get the questionnaire.
         * */
        const { name, type, id } = questionnaire
        dispatch(updateQuestionnaireItemSuccess({ name, type, id }, tenantId))
        dispatch(
          globalAction.addUserMessage(
            'success',
            'edit questionnaire item success',
          ),
        )
        cb({ ok: true })
      })
      .catch((err) => {
        // globalAction
        if (err) {
          if (err === 'Bad token') {
            storageConfig.clearStorage()
            dispatch(globalAction.initializeAppState())
          } else {
            console.error('save new questionnaire item has failed:   ', err)
            dispatch(
              globalAction.addUserMessage(
                'warning',
                'Failed to update questionnaire',
              ),
            )
            cb({ ok: false, err })
          }
        }
      })
  }
}
export function createNewTenant(item, cb) {
  return (dispatch) => {
    return tenantApi
      .createNewTenant(item)
      .then((tenant) => {
        // Todo need add that questionnaire to questionnaire array.
        dispatch(addNewTenant(tenant))
        dispatch(
          globalAction.addUserMessage('success', 'Save new Tenant success'),
        )
        cb({ ok: true })
      })
      .catch((err) => {
        // globalAction
        if (err) {
          if (err === 'Bad token') {
            storageConfig.clearStorage()
            dispatch(globalAction.initializeAppState())
          } else {
            dispatch(
              globalAction.addUserMessage(
                'warning',
                'This tenant is not valid',
              ),
            )
            console.error('save new tenant failed:   ', err)
            cb({ ok: false, err })
          }
        }
      })
  }
}
export function saveNewConsentForm(tenantId, item, cb) {
  return (dispatch) => {
    return tenantApi
      .createNewConsentForm(tenantId, item)
      .then((consentForm) => {
        dispatch(
          globalAction.addUserMessage('success', 'Create const form success'),
        )
        dispatch(createConsentFormSuccess(consentForm, tenantId))
        cb({ ok: true })
      })
      .catch((err) => {
        // globalAction
        if (err) {
          if (err === 'Bad token') {
            storageConfig.clearStorage()
            dispatch(globalAction.initializeAppState())
          } else {
            dispatch(
              globalAction.addUserMessage(
                'warning',
                'Failed to save consent from',
              ),
            )
            cb({ ok: false, err })
          }
        }
      })
  }
  // Todo implement...
}
export function saveEditConsentForm(item, tenantId, cb) {
  const itemId = item.id
  const updateItem = {
    aliasName: item.aliasName,
    body: item.body,
    bodyFormat: item.bodyFormat,
    title: item.title,
  }

  return (dispatch) => {
    return tenantApi
      .editConsentForm(tenantId, itemId, updateItem)
      .then((consentForm) => {
        dispatch(
          globalAction.addUserMessage('success', 'Update const-form success'),
        )
        dispatch(updateConsentFormSuccess(consentForm, tenantId))

        cb({ ok: true })
      })
      .catch((err) => {
        // globalAction
        if (err) {
          if (err === 'Bad token') {
            storageConfig.clearStorage()
            dispatch(globalAction.initializeAppState())
          } else {
            dispatch(
              globalAction.addUserMessage(
                'warning',
                'Failed to update consent from',
              ),
            )
            cb({ ok: false, err })
          }
        }
      })
  }
}
export function createProgramAlertRule(tenantId, programId, item, cb) {
  delete item.id
  return (dispatch) => {
    return programApi
      .saveUpdateProgramAlertRule(programId, item, true)
      .then((alertRule) => {
        dispatch(createAlertRuleSuccess(tenantId, programId, alertRule))
        dispatch(
          globalAction.addUserMessage('success', 'Create Alert Rule success'),
        )
        // Todo need update the redux!
        cb({ ok: true, id: alertRule.id, alertRuleFromStore: alertRule })
      })
      .catch((err) => {
        if (err) {
          if (err === 'Bad token') {
            storageConfig.clearStorage()
            dispatch(globalAction.initializeAppState())
          }

          let errorMessage = 'Failed to create alert rule'
          if (err === 'template_already_exists_for_program_id') {
            errorMessage =
              'A Chat disabled alert already exist. Only one alert can be created.'
          }
          dispatch(globalAction.addUserMessage('warning', errorMessage))
          cb({ ok: false, err })
        }
      })
  }
}
export function updateProgramAlertRule(tenantId, programId, item, cb) {
  return (dispatch) => {
    return programApi
      .saveUpdateProgramAlertRule(programId, item, false)
      .then((alertRule) => {
        // Todo need update the redux!
        dispatch(updateAlertRuleSuccess(tenantId, programId, alertRule))
        dispatch(
          globalAction.addUserMessage('success', 'Update Alert Rule success'),
        )
        cb({ ok: true, id: alertRule.id, alertRuleFromStore: alertRule })
      })
      .catch((err) => {
        if (err) {
          if (err === 'Bad token') {
            storageConfig.clearStorage()
            dispatch(globalAction.initializeAppState())
          }
          debugger
          dispatch(
            globalAction.addUserMessage(
              'warning',
              'Failed to update alert rule',
            ),
          )
          cb({ ok: false, err })
        }
      })
  }
}
export function deleteAlertRule(tenantId, programId, id, cb) {
  return (dispatch) => {
    return programApi
      .deleteProgramAlertRule(programId, id)
      .then((notificationRule) => {
        // Todo need update the redux!
        dispatch(deleteAlertRuleSuccess(tenantId, programId, id))
        dispatch(
          globalAction.addUserMessage('success', 'Delete Alert Rule success'),
        )
        cb({ ok: true, id: notificationRule.id })
      })
      .catch((err) => {
        if (err) {
          if (err === 'Bad token') {
            storageConfig.clearStorage()
            dispatch(globalAction.initializeAppState())
          }
          dispatch(
            globalAction.addUserMessage('warning', 'Delete alert rule failed'),
          )
          cb({ ok: false, err })
        }
      })
  }
}
export function createProgramNotification(tenantId, programId, item, cb) {
  delete item.id
  return (dispatch) => {
    return programApi
      .saveUpdateProgramNotification(programId, item, true)
      .then((notification) => {
        dispatch(createNotificationSuccess(tenantId, programId, notification))
        // Todo...
        dispatch(
          globalAction.addUserMessage('success', 'Create Notification success'),
        )
        // Todo need update the redux!
        cb({
          ok: true,
          id: notification[0].id,
          alertRuleFromStore: notification[0],
        })
      })
      .catch((err) => {
        if (err) {
          if (err === 'Bad token') {
            storageConfig.clearStorage()
            dispatch(globalAction.initializeAppState())
          }
          dispatch(
            globalAction.addUserMessage(
              'warning',
              'Failed to create notification',
            ),
          )
          cb({ ok: false, err })
        }
      })
  }
}
export function updateProgramNotification(tenantId, programId, item, cb) {
  return (dispatch) => {
    return programApi
      .saveUpdateProgramNotification(programId, item, false)
      .then((notification) => {
        if (notification && notification.length) {
          dispatch(
            updateNotificationSuccess(tenantId, programId, notification[0]),
          )
        }
        dispatch(
          globalAction.addUserMessage('success', 'Update Notification success'),
        )
        // Todo need update the redux!
        cb({
          ok: true,
          id: notification[0].id,
          alertRuleFromStore: notification[0],
        })
      })
      .catch((err) => {
        if (err) {
          if (err === 'Bad token') {
            storageConfig.clearStorage()
            dispatch(globalAction.initializeAppState())
          }
          dispatch(
            globalAction.addUserMessage(
              'warning',
              'Failed to update notification',
            ),
          )
          cb({ ok: false, err })
        }
      })
  }
}
export function deleteProgramNotification(tenantId, programId, id, cb) {
  return (dispatch) => {
    return programApi
      .deleteProgramNotification(programId, id)
      .then((alertRule) => {
        // Todo need update the redux!
        dispatch(deleteNotificationSuccess(tenantId, programId, id))
        dispatch(
          globalAction.addUserMessage('success', 'Delete Notification success'),
        )
        cb({ ok: true, id: alertRule.id })
      })
      .catch((err) => {
        if (err) {
          if (err === 'Bad token') {
            storageConfig.clearStorage()
            dispatch(globalAction.initializeAppState())
          }
          dispatch(
            globalAction.addUserMessage(
              'warning',
              'failed to delete notification',
            ),
          )
          cb({ ok: false, err })
        }
      })
  }
}

export function loadProgramProviders(tenantId, programId) {
  return (dispatch) => {
    return programApi
      .getProgramProviders(programId)
      .then((users) => {
        dispatch(setTenantProviders(tenantId, programId, users))
      })
      .catch((err) => {
        if (err === 'Bad token') {
          storageConfig.clearStorage()
          dispatch(globalAction.initializeAppState())
        }
      })
  }
}

export function updateUser(tenantId, programId, userId, data, cb) {
  return (dispatch) => {
    return programApi
      .updateUser(programId, userId, data)
      .then((userData) => {
        dispatch({
          type: types.UPDATE_PROGRAM_USER,
          tenantId,
          programId,
          userData,
        })
        dispatch(
          globalAction.addUserMessage('success', 'User successfully updated'),
        )

        cb && cb()
      })
      .catch((err) => {
        if (err === 'Bad token') {
          storageConfig.clearStorage()
          dispatch(globalAction.initializeAppState())
        }
      })
  }
}

export function createUser(tenantId, programId, data, cb) {
  return (dispatch) => {
    return programApi
      .createUser(programId, data)
      .then((userData) => {
        dispatch({
          type: types.CREATE_PROGRAM_USER,
          tenantId,
          programId,
          userData,
        })
        dispatch(
          globalAction.addUserMessage('success', 'User successfully created'),
        )

        cb && cb()
      })
      .catch((err) => {
        if (err === 'Bad token') {
          storageConfig.clearStorage()
          dispatch(globalAction.initializeAppState())
        }
      })
  }
}

export function revokeUser(tenantId, programId, userId) {
  return (dispatch) => {
    return programApi
      .revokeUser(programId, userId)
      .then(() => {
        dispatch({
          type: types.REVOKE_PROGRAM_USER,
          programId,
          tenantId,
          userId,
        })
        dispatch(
          globalAction.addUserMessage('success', 'User successfully revoked.'),
        )
      })
      .catch((err) => {
        if (err === 'Bad token') {
          storageConfig.clearStorage()
          dispatch(globalAction.initializeAppState())
        }
      })
  }
}

export function restoreUser(tenantId, programId, userId) {
  return (dispatch) => {
    return programApi
      .restoreUser(programId, userId)
      .then(() => {
        dispatch({
          type: types.RESTORE_PROGRAM_USER,
          programId,
          tenantId,
          userId,
        })
        dispatch(
          globalAction.addUserMessage('success', 'User successfully restored.'),
        )
      })
      .catch((err) => {
        if (err === 'Bad token') {
          storageConfig.clearStorage()
          dispatch(globalAction.initializeAppState())
        }
      })
  }
}

export function removeUser(tenantId, programId, userId) {
  return (dispatch) => {
    return programApi
      .removeUser(programId, userId)
      .then(() => {
        dispatch({
          type: types.REVOKE_PROGRAM_USER,
          tenantId,
          programId,
          userId,
        })
        dispatch(
          globalAction.addUserMessage('success', 'User successfully removed.'),
        )
      })
      .catch((err) => {
        if (err === 'Bad token') {
          storageConfig.clearStorage()
          dispatch(globalAction.initializeAppState())
        }
      })
  }
}

export const loadConfiguration = (tenantId, programId) => {
  return (dispatch) => {
    return programApi
      .getConfiguration(programId)
      .then((config) => {
        dispatch({
          type: types.LOAD_PROGRAM_CONFIGURATIONS,
          programId,
          tenantId,
          config,
        })
      })
      .catch((err) => {
        if (err === 'Bad token') {
          storageConfig.clearStorage()
          dispatch(globalAction.initializeAppState())
        }
      })
  }
}

export const editConfiguration = (tenantId, programId, data, handleError) => {
  return (dispatch) => {
    return programApi
      .updateConfiguration(programId, data)
      .then((res) => {
        dispatch({
          type: types.UPDATE_PROGRAM_CONFIGURATIONS,
          programId,
          tenantId,
          config: res,
        })

        dispatch(
          globalAction.addUserMessage(
            'success',
            'Configuration successfully updated.',
          ),
        )
      })
      .catch((err) => {
        if (err === 'Bad token') {
          storageConfig.clearStorage()
          dispatch(globalAction.initializeAppState())
        } else if (handleError) handleError(err)
        else dispatch(globalAction.addUserMessage('error', 'Error occurred'))
      })
  }
}

export const replaceConfiguration = (
  tenantId,
  programId,
  data,
  handleSuccess,
  handleError,
) => {
  return (dispatch) => {
    return programApi
      .replaceConfiguration(programId, data)
      .then((res) => {
        dispatch({
          type: types.REPLACE_PROGRAM_CONFIGURATIONS,
          programId,
          tenantId,
          config: res,
        })

        dispatch(
          globalAction.addUserMessage(
            'success',
            'Value successfully replaced.',
          ),
        )

        handleSuccess()
      })
      .catch((err) => {
        if (err === 'Bad token') {
          storageConfig.clearStorage()
          dispatch(globalAction.initializeAppState())
        } else if (handleError) handleError(err)
        else dispatch(globalAction.addUserMessage('error', 'Error occurred'))
      })
  }
}

export const loadSecurityRoles = (tenantId, programId) => {
  return (dispatch) => {
    return programApi
      .getSecurityRoles(programId)
      .then((roles) => {
        dispatch({
          type: types.LOAD_PROGRAM_SECURITY_ROLES,
          programId,
          tenantId,
          roles,
        })
      })
      .catch((err) => {
        if (err === 'Bad token') {
          storageConfig.clearStorage()
          dispatch(globalAction.initializeAppState())
        } else {
          console.error('failed to load permit roles')
          return []
        }
      })
  }
}
