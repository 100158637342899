import React from 'react'
import PropTypes from 'prop-types'
import './style.less'
import IconButton from '../IconButton/IconButton'

const ChipsItem = ({ text, deleteClick, disable = false }) => {
  const closeStyle = { width: '10px', height: '10px' }
  const className = disable
    ? 'chips-item-wrap disable-chips-item'
    : 'chips-item-wrap'
  return (
    <div className={className}>
      <div className="tooltip-text">{text}</div>
      <div>{text}</div>
      <IconButton
        inlineStyle={closeStyle}
        onClick={() => {
          if (disable) {
            return
          }
          deleteClick()
        }}
        type="close"
        disable={disable}
      />
    </div>
  )
}

ChipsItem.propTypes = {
  text: PropTypes.string.isRequired,
  deleteClick: PropTypes.func.isRequired,
  disable: PropTypes.bool,
}

export default ChipsItem
