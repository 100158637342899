// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.small-popup {
  width: 356px;
}

.popup-header {
  display: grid;
  grid-template-columns: 80% 20%;
  align-items: center;
  display: grid;
  padding: 16px;
  border-bottom: 1px solid #dadcdf;
}
.popup-header .popup-header-content {
  font-size: 16px;
}
.popup-body {
  padding: 16px;
}
.popup-footer {
  padding: 16px;
  border-top: 1px solid #dadcdf;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.popup-footer .button-new {
  margin-left: 5px;
}
.popup-header-icon {
  justify-self: end;
}

/* .icon-button-component {
  cursor: pointer;
  display: grid;
} */
`, "",{"version":3,"sources":["webpack://./src/components/common/Popup/style.css"],"names":[],"mappings":"AAAA;EACE,YAAY;AACd;;AAEA;EACE,aAAa;EACb,8BAA8B;EAC9B,mBAAmB;EACnB,aAAa;EACb,aAAa;EACb,gCAAgC;AAClC;AACA;EACE,eAAe;AACjB;AACA;EACE,aAAa;AACf;AACA;EACE,aAAa;EACb,6BAA6B;EAC7B,aAAa;EACb,mBAAmB;EACnB,yBAAyB;AAC3B;;AAEA;EACE,gBAAgB;AAClB;AACA;EACE,iBAAiB;AACnB;;AAEA;;;GAGG","sourcesContent":[".small-popup {\n  width: 356px;\n}\n\n.popup-header {\n  display: grid;\n  grid-template-columns: 80% 20%;\n  align-items: center;\n  display: grid;\n  padding: 16px;\n  border-bottom: 1px solid #dadcdf;\n}\n.popup-header .popup-header-content {\n  font-size: 16px;\n}\n.popup-body {\n  padding: 16px;\n}\n.popup-footer {\n  padding: 16px;\n  border-top: 1px solid #dadcdf;\n  display: flex;\n  flex-direction: row;\n  justify-content: flex-end;\n}\n\n.popup-footer .button-new {\n  margin-left: 5px;\n}\n.popup-header-icon {\n  justify-self: end;\n}\n\n/* .icon-button-component {\n  cursor: pointer;\n  display: grid;\n} */\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
