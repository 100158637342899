import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { Route, Switch } from 'react-router-dom'
import TenantStatus from './TenantTabs/StatusBar/TenantStatus'
import ProgramStatus from './TenantTabs/StatusBar/ProgramStatus'
import ProgramsBodyPage from './TenantTabs/TenantsBodyContent/ProgramsBodyPage'
import TenantBodyPage from './TenantTabs/TenantsBodyContent/TenantBodyPage'
import * as tenantsActions from '../../redux/actions/tenantsActions'
import * as globalAction from '../../redux/actions/globalAction'
import PropTypes from 'prop-types'
import './tenant-page-style.less'
import { getTenantsById } from '../utilities'

function TenantPage({
  tenant,
  loadPrograms,
  updateProgram,
  showNotImplemented,
  showUserMessage,
  createNewProgram,
  match,
  history,
  location,
}) {
  useEffect(() => {
    if (tenant && tenant.id && !tenant.programs) {
      loadPrograms(tenant.id)
    }
  }, [tenant && tenant.id])

  if (!tenant) {
    return <div />
  }
  const EditTenantDetailsClick = () => {
    showNotImplemented()
  }
  const EditProgramItemClicked = (program, cb) => {
    let tenantId = tenant.id
    let programId = program.id
    let payload = {
      name: program.name,
      logo: program.logo,
      description: program.description,
    }
    updateProgram(tenantId, programId, payload, cb)
  }

  const baseRoute = '/home/tenants/' + tenant.id

  return (
    <div className="tenant-component">
      <Switch>
        <Route
          exact
          path="/home/tenants/:id/programs/:pId/:tabs"
          render={() => (
            <ProgramStatus
              showUserMessage={showUserMessage}
              pathname={location.pathname}
              tenant={tenant}
              saveNewProgram={EditProgramItemClicked}
            />
          )}
        />
        <Route
          render={() => (
            <TenantStatus
              tenant={tenant}
              editTenantDetailsClick={EditTenantDetailsClick}
            />
          )}
        />
      </Switch>

      <Switch>
        <Route
          path="/home/tenants/:id/programs/:pId/:tabs"
          render={() => (
            <ProgramsBodyPage
              pathname={location.pathname}
              match={match}
              history={history}
              baseRoute={baseRoute}
              tenant={tenant}
            />
          )}
        />
        <Route
          path="/home/tenants/:id/:tabs"
          render={() => (
            <TenantBodyPage
              match={match}
              history={history}
              baseRoute={baseRoute}
              tenant={tenant}
              showNotImplemented={showNotImplemented}
              saveNewProgram={createNewProgram}
            />
          )}
        />
      </Switch>
    </div>
  )
}

function mapStateToProps(state, ownProps) {
  const id = ownProps.match.params.id
  const tenant =
    id && state.tenantReducer.length > 0
      ? getTenantsById(state.tenantReducer, id)
      : {}
  return {
    tenant,
  }
}

const mapDispatchToProps = {
  loadPrograms: tenantsActions.loadPrograms,
  createNewProgram: tenantsActions.createNewProgram,
  showNotImplemented: globalAction.showNotImplementedDialog,
  showUserMessage: globalAction.addUserMessage,
  updateProgram: tenantsActions.updateProgramDetails,
}

TenantPage.propTypes = {
  loadPrograms: PropTypes.func.isRequired,
  tenant: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  showNotImplemented: PropTypes.func.isRequired,
}

export default connect(mapStateToProps, mapDispatchToProps)(TenantPage)
