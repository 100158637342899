import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Route, Switch } from 'react-router-dom'
import Header from '../Tools/ToolsHeader/ToolsHeader'
import ToolsPage from './ToolsPage'
import Media from '../GlobalComponent/Media/Media'
import Questionnaires from '../GlobalComponent/Questionnaires/Questionnaires'
import ConsentForms from '../GlobalComponent/ConsentForm/ConsentForms'
import { getOneQuestionnaireItem } from '../../api/toolsApi'
// import "./style.less";
import * as toolsActions from '../../redux/actions/toolsActions'
import * as globalAction from '../../redux/actions/globalAction'

function Tools({
  location,
  mediaList,
  loadToolsMedia,
  questionnairesList,
  loadToolsQuestionnaires,
  loadToolsConsentForms,
  consentFormsList,
  saveNewConsentForm,
  showNotImplementedFunc,
  addUserMessage,
  saveEditConsentForm,
}) {
  useEffect(() => {
    if (!mediaList) {
      loadToolsMedia()
    }
  }, [mediaList])

  useEffect(() => {
    if (!questionnairesList) {
      loadToolsQuestionnaires()
    }
  }, [questionnairesList])

  useEffect(() => {
    if (!consentFormsList) {
      loadToolsConsentForms()
    }
  }, [consentFormsList])

  return (
    <>
      <Header
        pathname={location.pathname.slice(1)}
        addUserMessage={addUserMessage}
      />
      <Switch>
        <Route
          exact
          path="/home/tools"
          render={(props) => (
            <ToolsPage {...props} questionnairesList={questionnairesList} />
          )}
        />
        <Route
          path="/home/tools/media"
          render={(props) => (
            <Media {...props} mediaList={mediaList} section="tools" />
          )}
        />
        <Route
          path="/home/tools/questionnaires"
          render={(props) => (
            <Questionnaires
              {...props}
              questionnairesList={questionnairesList}
              showNotImplemented={showNotImplementedFunc}
              getOneQuestionnaireItem={getOneQuestionnaireItem}
              section="tools"
            />
          )}
        />
        <Route
          path="/home/tools/consent_forms"
          render={(props) => (
            <ConsentForms
              {...props}
              consentFormsList={consentFormsList}
              showNotImplemented={showNotImplementedFunc}
              addUserMessage={addUserMessage}
              saveNewConsentForm={(item, cb) => saveNewConsentForm(item, cb)}
              saveEditConsentForm={saveEditConsentForm}
              section="tools"
            />
          )}
        />
      </Switch>
    </>
  )
}

Tools.propTypes = {
  mediaList: PropTypes.array,
  questionnairesList: PropTypes.array,
  consentFormsList: PropTypes.array,
  loadToolsMedia: PropTypes.func.isRequired,
  loadToolsQuestionnaires: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
  showNotImplementedFunc: PropTypes.func.isRequired,
  addUserMessage: PropTypes.func.isRequired,
}

function mapStateToProps(state) {
  return {
    mediaList: state.toolsReducer.mediaList,
    questionnairesList: state.toolsReducer.questionnairesList,
    consentFormsList: state.toolsReducer.consentFormList,
  }
}

const mapDispatchToProps = {
  loadToolsMedia: toolsActions.loadToolsMedia,
  loadToolsQuestionnaires: toolsActions.loadToolsQuestionnaires,
  loadToolsConsentForms: toolsActions.loadToolsConsentForms,
  showNotImplementedFunc: globalAction.showNotImplementedDialog,
  addUserMessage: globalAction.addUserMessage,
  saveNewConsentForm: toolsActions.saveNewConsentForm,
  saveEditConsentForm: toolsActions.saveEditConsentForm,
}

export default connect(mapStateToProps, mapDispatchToProps)(Tools)
