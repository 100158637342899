import React from 'react'
import PropTypes from 'prop-types'
import './style.less'
import IconButton from '../IconButton/IconButton'

const CallToAction = ({ text, iconType, onClick, disable }) => {
  const localIconType = iconType || 'plus'
  const localText = text + ' →'
  if (disable) {
    return (
      <div className="call-to-action-wrap">
        <div>{localText}</div>
        <IconButton onClick={() => {}} type={localIconType} disable={disable} />
      </div>
    )
  }
  return (
    <div className="call-to-action-wrap">
      <div>{localText}</div>
      <IconButton onClick={onClick} type={localIconType} />
    </div>
  )
}

CallToAction.propTypes = {
  text: PropTypes.string.isRequired,
  iconType: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  disable: PropTypes.bool,
}

export default CallToAction
