import React from 'react'
import PropTypes from 'prop-types'
import './style.less'
import DatePicker from 'react-date-picker'
import CloseIcon from '../../../image/icon_close.svg'
import { daysLetters } from '../../utilities'

const DDatePicker = ({
  value,
  onChange,
  className,
  disabled,
  maxDate,
  minDate,
  dateFormat,
}) => {
  //test
  return (
    <DatePicker
      calendarType="US"
      formatShortWeekday={(locale, date) => {
        return daysLetters[date.getDay()]
      }}
      onChange={onChange}
      value={value}
      className={(className ? className : '') + ' dashboard-datepicker'}
      clearIcon={<CloseIcon />}
      calendarIcon={null}
      disabled={disabled}
      format={dateFormat || 'MM/dd/yyyy'}
      maxDate={maxDate}
      minDate={minDate}
    />
  )
}

DDatePicker.propTypes = {
  value: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
  onChange: PropTypes.func,
}

export default DDatePicker
