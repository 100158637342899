import React, { useState } from 'react'
import PropTypes from 'prop-types'
import EditCreateElementDialog from '../../../GlobalComponent/EditCreateElement/EditCreateElementDialog'
import ButtonNew from '../../../common/Button/ButtonNew'

function ProgramStatus({ tenant, pathname, saveNewProgram, showUserMessage }) {
  const [editProgramModal, setEditProgramModal] = useState({
    isOpen: false,
  })
  const routeArray = pathname.split('/')

  if (routeArray.length < 6) {
    return <div />
  }
  const programsId = routeArray[5]
  const program = tenant.programs
    ? tenant.programs.find((item) => item.id === programsId)
    : null

  const editProgramDetailsLocalClick = () => {
    if (!program) {
      return console.log('Somthig get wrong!')
    }
    let programToEdit = {
      description: program.description,
      id: program.id,
      logo: program.logo,
      name: program.name,
    }

    setEditProgramModal({
      isOpen: true,
      program: programToEdit,
    })
  }

  const saveProgram = (program, cb) => {
    let elementToSave = {
      name: program.name,
      description: program.description,
      logo: program.logo,
      id: program.id,
    }
    // if edit add programId
    saveNewProgram(elementToSave, (res) => {
      if (res.ok) {
        setEditProgramModal({ isOpen: false })
        return cb({ ok: true })
      }
      return cb({ ok: false })
    })

    // Todo call to api
  }
  const closeEditDialog = () => {
    setEditProgramModal({ isOpen: false })
  }
  if (!program) {
    return <div />
  }
  /**
   * Programs = {description: "We have the best clinics in the world"
   id: "558afc069972eb6550e04dd7"
   logo: "https://res.cloudinary.com/ethos/image/upload/v1488536293/amerihealth_w5rrve.png"
   name: "Trail-Nov"}
   * */

  const editBtnStyle = { width: '100%' }

  return (
    <div className="tenant-component-status">
      <div className="paragraph">
        <div className="paragraph-title">
          <div>Logo</div>
        </div>
        {program.logo && <img src={program.logo} />}
      </div>
      <div className="paragraph">
        <div className="paragraph-title">
          <div>Programs</div>
          <div>Name</div>
        </div>
        <div>{program.name ? program.name : 'none'}</div>
      </div>
      <div className="paragraph description-box">
        <div className="paragraph-title">
          <div>Description</div>
        </div>
        <div>{program.description ? program.description : 'none'}</div>
      </div>
      <div className="paragraph">
        <EditCreateElementDialog
          isOpen={editProgramModal.isOpen}
          saveClick={saveProgram}
          onClose={closeEditDialog}
          element={program}
          showUserMessage={showUserMessage}
          type="program"
          actionType="edit"
        />
        <ButtonNew
          style={editBtnStyle}
          text={'Edit'}
          type="secondary"
          onClick={() => editProgramDetailsLocalClick()}
        />
      </div>
    </div>
  )
}

ProgramStatus.propTypes = {
  tenant: PropTypes.object.isRequired,
  pathname: PropTypes.string.isRequired,
}

export default ProgramStatus
