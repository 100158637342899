export default {
  tenants: [],
  tools: {},
  global: {
    redirectToLogin: false,
    showNotImplementedDialog: { show: false },
    confirmation: {
      display: false,
      callback: null,
      details: {
        title: 'Confirmation',
        text: 'Are you sure',
        cancelBtnText: 'Cancel',
        confirmBtnText: 'Ok',
      },
    },
    userMessageArray: [
      /**
       * item structure: { type: "warning/success", text: "...", id: "..." }
       * */
    ],
  },
}
