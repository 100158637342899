import React from 'react'
import { Route, Switch } from 'react-router-dom'
import PropTypes from 'prop-types'
import Media from '../../../../GlobalComponent/Media/Media'
import Questionnaires from '../../../../GlobalComponent/Questionnaires/Questionnaires'
import ConsentForms from '../../../../GlobalComponent/ConsentForm/ConsentForms'
import List from '../../../../common/List/List'
import { connect } from 'react-redux'
import * as tenantsActions from '../../../../../redux/actions/tenantsActions'
import * as tenantApi from '../../../../../api/tenantApi'
import * as globalAction from '../../../../../redux/actions/globalAction'
const getOneQuestionnaireItem = tenantApi.getOneQuestionnaireItem

function Tools({
  history,
  match,
  loadMedia,
  mediaList,
  loadQuestionnaire,
  questionnairesList,
  loadConsentForm,
  consentFormsList,
  saveNewConsentForm,
  showNotImplemented,
  addUserMessage,
  saveEditConsentForm,
}) {
  const toolsClick = (id) => {
    const tenantId = match.params.id
    switch (id) {
      case 'tools-media-category': {
        if (tenantId) {
          history.push('/home/tenants/' + tenantId + '/tools/media')
        }
        console.log('CLICK ON TOOLS  MEDIA')
        break
      }
      case 'tools-questionnaires-category': {
        history.push('/home/tenants/' + tenantId + '/tools/questionnaires')
        break
      }
      case 'tools-consent-forms': {
        history.push('/home/tenants/' + tenantId + '/tools/consent_forms')
        break
      }
      default:
        break
    }
  }
  const tools = [
    { id: 'tools-media-category', name: 'media', constantLogo: 'media' },
    {
      id: 'tools-questionnaires-category',
      name: 'questionnaires',
      constantLogo: 'questionnaires',
    },
    {
      id: 'tools-consent-forms',
      name: 'consent forms',
      constantLogo: 'consent_forms',
    },
  ]
  const ToolList = () => {
    return (
      <div className="tenant-page-program-page">
        <List list={tools} titleText={'Tool type'} itemClickFunc={toolsClick} />
      </div>
    )
  }
  const saveMediaItem = (media) => {
    console.log('SAVE MEDIA ITEM!')
  }
  return (
    <Switch>
      <Route
        path="/home/tenants/:id/tools/media"
        render={() => {
          if (!mediaList) {
            const tenantId = match.params.id
            loadMedia(tenantId)
          }
          return (
            <Media
              match={match}
              history={history}
              tenantId={match.params.id}
              mediaList={mediaList}
              section="tenants"
              saveMediaItemTools={saveMediaItem}
            />
          )
        }}
      />
      <Route
        path="/home/tenants/:id/tools/questionnaires"
        render={() => {
          if (!questionnairesList) {
            const tenantId = match.params.id
            loadQuestionnaire(tenantId)
          }
          return (
            <Questionnaires
              match={match}
              history={history}
              questionnairesList={questionnairesList}
              showNotImplemented={showNotImplemented}
              section="tenants"
              tenantId={match.params.id}
              getOneQuestionnaireItem={(id) =>
                getOneQuestionnaireItem(id, match.params.id)
              }
            />
          )
        }}
      />
      <Route
        path="/home/tenants/:id/tools/consent_forms"
        render={() => {
          if (!consentFormsList) {
            const tenantId = match.params.id
            loadConsentForm(tenantId)
          }
          return (
            <ConsentForms
              match={match}
              history={history}
              consentFormsList={consentFormsList}
              showNotImplemented={showNotImplemented}
              addUserMessage={addUserMessage}
              saveNewConsentForm={(item, cb) =>
                saveNewConsentForm(match.params.id, item, cb)
              }
              saveEditConsentForm={(item, cb) => {
                saveEditConsentForm(item, match.params.id, cb)
              }}
              tenantId={match.params.id}
              section="tenants"
            />
          )
        }}
      />
      <Route render={() => <ToolList />} />
    </Switch>
  )
}

Tools.propTypes = {
  // tenantId: PropTypes.string.isRequired
}

function mapStateToProps(state, ownProps) {
  const tenantId = ownProps.match.params.id
  const tenant = state.tenantReducer.find((item) => item.id === tenantId)
  return {
    mediaList: tenant ? tenant.mediaList : [],
    questionnairesList: tenant ? tenant.questionnairesList : [],
    consentFormsList: tenant ? tenant.consentFormList : [],
  }
}

const mapDispatchToProps = {
  loadMedia: tenantsActions.loadTenantMedia,
  loadQuestionnaire: tenantsActions.loadTenantQuestionnaires,
  loadConsentForm: tenantsActions.loadTenantConsentForm,
  showNotImplemented: globalAction.showNotImplementedDialog,
  addUserMessage: globalAction.addUserMessage,
  saveNewConsentForm: tenantsActions.saveNewConsentForm,
  saveEditConsentForm: tenantsActions.saveEditConsentForm,
}

export default connect(mapStateToProps, mapDispatchToProps)(Tools)
