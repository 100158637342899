import React, { useEffect } from 'react'
import * as constants from '../constants'
import InputError from '../../../../../../common/InputError/InputError'
import { getOneQuestionnaireItem } from '../../../../../../../api/tenantApi'
import QuestionnaireSelection from './questionnaireSelection'

const AnswerOnQuestion = ({
  item,
  alertRulesArray,
  setAlertRulesArray,
  tenantId,
  error,
}) => {
  const questionSelect = (id, e) => {
    setAlertRulesArray(
      alertRulesArray.map((a) => {
        if (a.id === id) {
          return Object.assign({}, a, {
            questionIdSelect: e.target.value,
            answerIdSelect:
              a.questionIdSelect !== e.target.value ? '' : a.answerIdSelect, // Init the answer selected
          })
        }
        return a
      }),
    )
  }
  const answerSelect = (id, e) => {
    setAlertRulesArray(
      alertRulesArray.map((a) => {
        if (a.id === id) {
          return Object.assign({}, a, {
            answerIdSelect: e.target.value,
          })
        }
        return a
      }),
    )
  }
  return (
    <>
      <QuestionnaireSelection
        item={item}
        alertRulesArray={alertRulesArray}
        handleSetAlertRulesArray={setAlertRulesArray}
      />
      <InputError
        showError={error && error.questionnaire}
        text={constants.FAILED_ERROR_TEXT}
      />
      {item.questionnaireSelected.id && (
        <>
          <div className="rule-filed">
            <div className="rule-label">Question</div>
            <div className="rule-input">
              <select
                disabled={false}
                className=""
                value={item.questionIdSelect || ''}
                onChange={(e) => {
                  questionSelect(item.id, e)
                }}
              >
                <option value="" disabled hidden>
                  Select Question
                </option>
                {item.questArrayView &&
                  item.questArrayView.map((questItem) => {
                    return (
                      <option key={questItem.id} value={questItem.id}>
                        {questItem.text}
                      </option>
                    )
                  })}
              </select>
            </div>
          </div>
          <InputError
            showError={error && error.questionnaireQuest}
            text={constants.FAILED_ERROR_TEXT}
          />
          {item.questionIdSelect && (
            <>
              <div className="rule-filed">
                <div className="rule-label">Answer</div>
                <div className="rule-input">
                  <select
                    disabled={false}
                    className=""
                    value={item.answerIdSelect || ''}
                    onChange={(e) => {
                      answerSelect(item.id, e)
                    }}
                  >
                    <option value="" disabled hidden>
                      Select Answer
                    </option>
                    {item.questArrayView &&
                      item.questArrayView.find(
                        (q) => q.id === item.questionIdSelect,
                      ) &&
                      item.questArrayView
                        .find((q) => q.id === item.questionIdSelect)
                        .answerArray.map((questItem) => {
                          return (
                            <option key={questItem.id} value={questItem.id}>
                              {questItem.text}
                            </option>
                          )
                        })}
                  </select>
                </div>
              </div>
              <InputError
                showError={error && error.questionnaireAns}
                text={constants.FAILED_ERROR_TEXT}
              />
            </>
          )}
        </>
      )}
    </>
  )
}

export default AnswerOnQuestion
