import React from 'react'
import './media-list-style.less'
const MediaListHeader = () => {
  return (
    <div className="media-list-item media-header">
      <div className="list-media-header-icon" />
      <div className="list-media-name">Media Name</div>
      <div className="list-media-category">Category</div>
      <div className="list-media-tags">Tags</div>
      <div className="list-media-options-btn" />
    </div>
  )
}

export default MediaListHeader
