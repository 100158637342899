import React, { useState, useEffect } from 'react'
import DragIcon from '../../../image/icon_drag.svg'
import './style.less'
/**
 * the dragList = [{listIndex}]
 * **/
const DragDropListNew = ({
  dragList,
  ItemComponent,
  propsComponent,
  setDragList,
  styleDropNumber,
  styleDropIcon,
}) => {
  const [state, setState] = useState({ items: [] })
  const [draggedItem, setDraggedItem] = useState(null)

  useEffect(() => {
    setState(Object.assign({}, state, { items: dragList || [] }))
  }, [dragList])

  const onDragStart = (e, index) => {
    setDraggedItem(state.items[index])
    e.dataTransfer.effectAllowed = 'move'
    e.dataTransfer.setData('text/html', e.target.parentNode)
    e.dataTransfer.setDragImage(e.target.parentNode, 20, 20)
  }

  const onDragOver = (index) => {
    const draggedOverItem = state.items[index]

    // if the item is dragged over itself, ignore
    if (draggedItem === draggedOverItem) {
      return
    }

    // filter out the currently dragged item
    let items = state.items.filter((item) => item !== draggedItem)

    // add the dragged item after the dragged over item
    items.splice(index, 0, draggedItem)
    setState({ items })
  }

  const onDragEnd = (e) => {
    /**
     * This set is for set the react list to sort the same the view list set location for all item:
     * // itemLocation
     * */
    setDragList(
      state.items.map((item, index) =>
        Object.assign({}, item, { itemLocation: index + 1 }),
      ),
    )
  }

  if (!state.items || !state.items.length) {
    return <div />
  }
  return (
    <div className="drag-list-wrap">
      <ul>
        {state.items
          .filter((item) => !!item) /// Filter the all null item (dont now way i have those)
          .map((item, idx) => {
            const dragType =
              item.dragType === 'top-left' ||
              item.dragType === 'center-left' ||
              item.dragType === 'bottom-left'
                ? item.dragType
                : 'top-left' //top-left/center-left/bottom-left  default = top-left;

            const localItem = { num: idx + 1, item }
            return (
              <li key={item.id} onDragOver={() => onDragOver(idx)}>
                <div className={'drag-fetcher ' + dragType}>
                  <div
                    className="drag-icon-wrap"
                    style={styleDropIcon}
                    draggable
                    onDragStart={(e) => onDragStart(e, idx)}
                    onDragEnd={onDragEnd}
                  >
                    <DragIcon />
                  </div>
                  <div className="num-of-item" style={styleDropNumber}>
                    {localItem.num}
                  </div>
                </div>
                <ItemComponent item={localItem.item} {...propsComponent} />
              </li>
            )
          })}
      </ul>
    </div>
  )
}
export default DragDropListNew
