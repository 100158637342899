import React, { useEffect, useState } from 'react'
import TableComponent from '../../../../../common/Table/table'
import {
  loadProgramProviders,
  updateUser,
  createUser,
  restoreUser,
  revokeUser,
  removeUser,
} from '../../../../../../redux/actions/tenantsActions'
import {
  addUserMessage,
  displayConfirmation,
} from '../../../../../../redux/actions/globalAction'
import { connect } from 'react-redux'
import { getTenantsById } from '../../../../../utilities'
import Popup from '../../../../../common/Popup'
import FormComponent from '../../../../../common/Form'
import defaultAvatar from '../../../../../../image/user_male.jpeg'
import UsersIcon from '../../../../../../image/es_users.svg'
import RevokedIcon from '../../../../../../image/revoked.svg'
import ButtonNew from '../../../../../common/Button/ButtonNew'
import './style.css'

const Providers = ({
  match,
  providers,
  loadProgramProviders,
  updateUser,
  createUser,
  restoreUser,
  revokeUser,
  removeUser,
  displayConfirmation,
}) => {
  const [displayEditPopup, setDisplayEditPopup] = useState(false)
  const [providersData, setProviderData] = useState(getProviderData)
  const [addProviderMode, setAddProviderMode] = useState(false)
  const [displayInvalid, setDisplayInvalid] = useState(false)

  const getProviderData = () => {
    if (!providers || !providers.length) return []
    const t = providers.map((user) => {
      return {
        id: user.id,
        accessStatus: user.accessStatus,
        picture: user.profile.picture || defaultAvatar,
        name: user.profile.firstName + ' ' + user.profile.lastName,
        type:
          user.permissions && user.permissions.isSupervisor ? 'Supervisor' : '',
      }
    })

    return t
  }

  const saveEditChanges = () => {
    if (
      !displayEditPopup.firstName.length ||
      !displayEditPopup.lastName.length ||
      !displayEditPopup.email.length
    ) {
      setDisplayInvalid(true)
      return
    }

    const tenantId = match.params.id
    const programId = match.params.pId

    let d = {
      programId,
      profile: {
        firstName: displayEditPopup.firstName,
        lastName: displayEditPopup.lastName,
        picture: displayEditPopup.picture,
        phone: displayEditPopup.phone || null,
        email: displayEditPopup.email,
      },
    }

    // if (displayEditPopup.role !== "Admin") {
    d.permissions = {
      addPatients: displayEditPopup.addPatients,
      viewAllPatients: displayEditPopup.viewAllPatients,
      isAdmin: displayEditPopup.isAdmin,
      isSupervisor: displayEditPopup.isSupervisor,
    }
    // }

    const callback = () => {
      setDisplayEditPopup(false)
    }

    if (!addProviderMode) {
      updateUser(tenantId, programId, displayEditPopup.id, d, callback)
    } else {
      createUser(tenantId, programId, d, callback)
    }
  }

  const getPopupTitle = () => {
    if (addProviderMode) return 'Add Provider'
    return 'Edit Provider'
  }

  useEffect(() => {
    if (!providers) {
      const tenantId = match.params.id
      const programId = match.params.pId
      loadProgramProviders(tenantId, programId)
    } else {
      setProviderData(getProviderData())
    }
  }, [providers])

  const editUserFields = [
    {
      text: 'First Name',
      key: 'firstName',
      type: 'text',
      required: true,
    },
    {
      text: 'Last Name',
      key: 'lastName',
      type: 'text',
      required: true,
    },
    {
      text: 'Email',
      key: 'email',
      type: 'text',
      required: true,
    },
    {
      text: 'Phone',
      key: 'phone',
      type: 'text',
    },
    {
      type: 'checkbox',
      text: 'Can add a patient',
      key: 'addPatients',
    },
    {
      type: 'checkbox',
      text: 'Can view all patients in clinics',
      key: 'viewAllPatients',
    },
    {
      type: 'checkbox',
      text: 'Admin',
      key: 'isAdmin',
    },
    {
      type: 'checkbox',
      text: 'Supervisor',
      key: 'isSupervisor',
    },
  ]

  const getEditedData = (data) => {
    const selected = providers.find((a) => a.id === data.id)
    return {
      id: selected.id,
      firstName: selected.profile.firstName,
      lastName: selected.profile.lastName,
      email: selected.profile.email,
      phone: selected.profile.phone,
      addPatients: selected.permissions && selected.permissions.addPatients,
      viewAllPatients:
        selected.permissions && selected.permissions.viewAllPatients,
      isAdmin: selected.permissions && selected.permissions.isAdmin,
      isSupervisor: selected.permissions && selected.permissions.isSupervisor,
    }
  }

  const isRevoked = (user) => {
    return !!~['revoked', 'locked'].indexOf(user.accessStatus)
  }

  const dislayAddUserPopup = () => {
    setAddProviderMode(true)
    setDisplayEditPopup({
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
      addPatients: true,
      viewAllPatients: false,
      isAdmin: false,
      isSupervisor: false,
      password: '',
    })
  }

  const options = {
    search: true,
    addNew: {
      text: 'Create new Provider',
      onclick: () => {
        dislayAddUserPopup()
      },
    },
    rowMenu: [
      {
        title: 'Edit details',
        onclick: (data) => {
          setAddProviderMode(false)
          setDisplayEditPopup(getEditedData(data))
        },
      },
      {
        title: 'Revoke access',
        getVisible: (data) => {
          return !isRevoked(data)
        },
        onclick: (data) => {
          displayConfirmation({
            details: {
              title: 'Revoke user',
              content: 'User will be revoked. Continue?',
            },
            callback: () => {
              revokeUser(match.params.id, match.params.pId, data.id)
            },
          })
        },
      },
      {
        title: 'Restore access',
        getVisible: (data) => {
          return isRevoked(data)
        },
        onclick: (data) => {
          displayConfirmation({
            details: {
              title: 'Restore user',
              content: 'User will be restored. Continue?',
            },
            callback: () => {
              restoreUser(match.params.id, match.params.pId, data.id)
            },
          })
        },
      },

      {
        title: 'Remove user',
        onclick: (data) => {
          displayConfirmation({
            details: {
              title: 'Remove user',
              content: 'User will be removed. Continue?',
            },
            callback: () => {
              removeUser(match.params.id, match.params.pId, data.id)
            },
          })
        },
        isRed: true,
      },
    ],
  }

  const ProviderPicture = ({ data }) => {
    return (
      <span style={{ position: 'relative' }}>
        <img
          style={{
            width: '40px',
            height: '40px',
            borderRadius: '40px',
          }}
          src={data.picture}
          onError={(e) => {
            e.target.onerror = null
            e.target.src = defaultAvatar
          }}
        />
        {data.accessStatus === 'revoked' && (
          <span
            className="revoked-picture"
            style={{
              position: 'absolute',
              top: '7px',
              left: '27px',
            }}
          >
            <RevokedIcon />
          </span>
        )}
      </span>
    )
  }

  const header = [
    {
      key: 'picture',
      name: ' ',
      type: 'customize',
      // eslint-disable-next-line react/display-name
      getCell: (data) => {
        return <ProviderPicture data={data} />
      },
    },
    {
      key: 'name',
      name: 'User Name',
      sortable: true,
      type: 'text',
      classe: 'basic-cell user-name-column',
    },
    {
      key: 'type',
      name: 'Role',
      sortable: true,
      type: 'text',
    },
  ]

  return (
    <div className="tenant-page-providers">
      {displayEditPopup && (
        <Popup
          title={getPopupTitle()}
          onClosed={() => {
            setDisplayEditPopup(false)
          }}
          buttons={[
            {
              text: 'Cancel',
              type: 'link',
              onClick: () => {
                setDisplayEditPopup(false)
              },
            },
            {
              text: !addProviderMode ? 'Save' : 'Add',
              type: 'primary',
              onClick: saveEditChanges,
            },
          ]}
        >
          <div className="edit-user-popup">
            <FormComponent
              displayInvalid={displayInvalid}
              fields={editUserFields}
              data={displayEditPopup || {}}
              handleDataChange={(data) => {
                setDisplayEditPopup(data)
              }}
            ></FormComponent>
          </div>
        </Popup>
      )}
      {providersData && (
        <>
          {providersData.length ? (
            <TableComponent
              options={options}
              data={providersData}
              header={header}
            />
          ) : (
            <NoUser handleAddUser={dislayAddUserPopup} />
          )}
        </>
      )}
    </div>
  )
}

function mapStateToProps(state, ownProps) {
  const tenantId = ownProps.match.params.id
  const programId = ownProps.match.params.pId
  const tenant = getTenantsById(state.tenantReducer, tenantId)

  if (!tenant) return {}
  const program =
    tenant.programs && tenant.programs.find((item) => item.id === programId)

  return {
    providers: program && program.providers,
  }
}

const mapDispatchToProps = {
  loadProgramProviders,
  updateUser,
  createUser,
  addUserMessage,
  restoreUser,
  revokeUser,
  removeUser,
  displayConfirmation,
}

export default connect(mapStateToProps, mapDispatchToProps)(Providers)

const NoUser = ({ handleAddUser }) => {
  return (
    <div className="no-user-container">
      <div className="no-user">
        <UsersIcon />
        <div className="no-user-text" style={{ marginTop: '20px' }}>
          There’s no Users yet.
          <div>Please create new User by clicking this button</div>
        </div>
        <div className="no-user-button">
          <ButtonNew
            text="Create new User"
            type="primary"
            onClick={handleAddUser}
          />
        </div>
      </div>
    </div>
  )
}
