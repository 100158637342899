import { useEffect, useState } from 'react'
import {
  DataGrid,
  GridRowModes,
  useGridApiRef,
  GridActionsCellItem,
} from '@valerahealth/ui-components/grid'
import { Edit, Delete, Save, Cancel } from '@valerahealth/ui-components/icons'

const getDisplayedState = (state) => {
  return `${state.name} (${state.code})`
}

const InsuranceGrid = ({ data, states = [], handleChange, handleDelete }) => {
  const [rows, setRows] = useState([])
  const [paginationModel, setPaginationModel] = useState({
    pageSize: 10,
    page: 0,
  })
  const [rowModesModel, setRowModesModel] = useState({})

  const handleEditClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } })
  }

  const handleSaveClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } })
  }

  const handleDeleteClick = (id) => () => {
    setRows(rows.filter((row) => row.id !== id))
    handleDelete(id)
  }
  const handleCancelClick = (id) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    })

    const editedRow = rows.find((row) => row.id === id)
    if (editedRow.isNew) {
      setRows(rows.filter((row) => row.id !== id))
    }
  }

  const columns = [
    {
      field: 'payerCode',
      headerName: 'Payer ID',
      width: 150,
      editable: true,
    },
    {
      field: 'state',
      headerName: 'Insurance State',
      type: 'singleSelect',
      valueOptions: (states && states.map((s) => getDisplayedState(s))) || [],
      width: 250,
      editable: true,
      valueGetter: ({ value }) => value && getDisplayedState(value),
    },
    {
      field: 'name',
      headerName: 'Insurance Plan',
      width: 250,
      editable: true,
    },
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Actions',
      width: 100,
      cellClassName: 'actions',
      getActions: ({ id }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit

        const btns = [
          <GridActionsCellItem
            key={1}
            icon={<Save />}
            label="Save"
            onClick={handleSaveClick(id)}
          />,
          <GridActionsCellItem
            key={2}
            icon={<Cancel />}
            label="Cancel"
            className="textPrimary"
            onClick={handleCancelClick(id)}
            color="inherit"
          />,
        ]

        if (isInEditMode) {
          return btns
        }

        return [
          <GridActionsCellItem
            key={1}
            icon={<Edit />}
            label="Edit"
            className="textPrimary"
            onClick={handleEditClick(id)}
            color="inherit"
          />,
          <GridActionsCellItem
            key={2}
            icon={<Delete />}
            label="Delete"
            onClick={handleDeleteClick(id)}
            color="inherit"
          />,
        ]
      },
    },
  ]

  const handleRowEditStart = (params, event) => {
    event.defaultMuiPrevented = true
  }

  const handleRowEditStop = (params, event) => {
    event.defaultMuiPrevented = true
  }

  const processRowUpdate = (newRow) => {
    const updatedRow = { ...newRow, isNew: false }
    setRows(rows.map((row) => (row.id === newRow.id ? updatedRow : row)))

    let state
    if (updatedRow.state) {
      state = states.find((s) => getDisplayedState(s) === updatedRow.state)
    }

    handleChange(updatedRow.id, {
      state,
      name: updatedRow.name,
      payerCode: updatedRow.payerCode,
    })

    return updatedRow
  }

  useEffect(() => {
    const _d =
      data &&
      data
        .filter((d) => !d.deleted)
        .map((l) => {
          return { id: l.index, ...l.value }
        })

    setRows(_d)

    if (_d) {
      const temp = {}

      _d.forEach((element) => {
        let val
        if (rowModesModel[element.id]) val = rowModesModel[element.id]
        else if (element.isNew) {
          val = { mode: GridRowModes.Edit, fieldToFocus: 'name' }
        }

        if (val) temp[element.id] = val
      })

      setRowModesModel(temp)
    }
  }, [data, rowModesModel])

  const apiRef = useGridApiRef()

  return (
    <div
      className="insurance-grid"
      style={{
        height: `${52 * (paginationModel.pageSize + 2) + 7}px`,
        width: '1000px',
      }}
    >
      <DataGrid
        rows={rows || []}
        columns={columns}
        paginationModel={paginationModel}
        pageSizeOptions={[5, 10, 20]}
        pagination
        onPageSizeChange={(ps) => {
          setPaginationModel((s) => ({ ...s, pageSize: ps }))
        }}
        editMode="row"
        rowModesModel={rowModesModel}
        onRowEditStart={handleRowEditStart}
        onRowEditStop={handleRowEditStop}
        slotProps={{
          toolbar: { setRows, setRowModesModel },
        }}
        apiRef={apiRef}
        experimentalFeatures={{ newEditingApi: true }}
        processRowUpdate={processRowUpdate}
      />
    </div>
  )
}
export default InsuranceGrid
