import React from 'react'
import './common-questionnaire-style.less'
import './questions-item-style.less'
import AddInputField from '../../../common/AddInputField/AddInputField'
import DragDropList from '../../../common/DragDropList/dragDropList'
import OneQuestionItem from './OneQuestionItem'
import * as globalFunction from './globalFunction'
const QuestionsItem = ({
  sectionOpen,
  questionArray,
  setQuestionArray,
  addQuestionsClick,
  openClosetQuest,
  questionnaireType,
  error,
}) => {
  const disableAddQuestionnaire =
    questionArray.length && questionnaireType === 'Sentiment'
  const setQuestionArrayMiddleware = (arr) => {
    globalFunction.changeQuestionnairesArray(arr, setQuestionArray)
  }
  const isOpen = sectionOpen === 'questions'
  return (
    <div
      className={
        isOpen ? 'questions-item-wrap' : 'questions-item-wrap is-close'
      }
    >
      <div
        className={
          isOpen
            ? 'questionnaire-item-title'
            : 'questionnaire-item-title is-close'
        }
      >
        {isOpen && <div className="main-number">1</div>}
        {!isOpen && <div className="main-number">2</div>}
        <div>Questions</div>
      </div>
      {isOpen && (
        <div className="questions-body">
          <div className="questions-body-details">
            <DragDropList
              ItemComponent={OneQuestionItem}
              dragList={questionArray}
              propsComponent={{
                openClosetQuest,
                setQuestionArray,
                questionArray,
                error,
              }}
              setDragList={setQuestionArrayMiddleware}
            />
            <div className="add-quest-wrap">
              <AddInputField
                text="Add question"
                onClick={addQuestionsClick}
                disable={disableAddQuestionnaire}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default QuestionsItem
