import React from 'react'
import * as constant from '../constants'

const TimeWindow = ({
  item,
  handleChange,
  key = 'timeWindow',
  allowedTimes = ['hours'],
}) => {
  return (
    <div className="rule-filed">
      <div className="rule-label">Time window</div>
      <div className="rule-input">
        <select
          className="small-input"
          disabled={false}
          value={item[key].unit || ''}
          onChange={(e) =>
            handleChange({
              ...item,
              [key]: {
                ...item[key],
                unit: e.target.value,
              },
            })
          }
        >
          <option value="" disabled hidden>
            Select unit
          </option>
          {constant.TIME_UNIT.filter(
            (unit) => allowedTimes.indexOf(unit.value) !== -1,
          ).map((unit) => {
            return (
              <option
                key={'location_time_window_' + item.id + '_' + unit.value}
                value={unit.value}
              >
                {unit.text}
              </option>
            )
          })}
        </select>
        <input
          type="number"
          min="0"
          className="small-input not-first"
          placeholder="Value"
          value={item[key].value}
          onChange={(e) =>
            handleChange({
              ...item,
              [key]: {
                ...item[key],
                value: parseInt(e.target.value),
              },
            })
          }
        />
      </div>
    </div>
  )
}

export default TimeWindow
