import React, { useState } from 'react'
import PropTypes from 'prop-types'
import LoginTopIcon from '../../image/login_top_icon.svg'
import LoginScreen from './loginInputScreen/login'
import ForgotScreen from './loginInputScreen/forgot'
import * as loginApi from '../../api/loginApi'
import * as storageConfig from '../../api/storageConfig'
import './style.less'

function Login({ history }) {
  const [loginScreen, setLoginScreen] = useState('login') // login/forgot
  const [userName, setUserName] = useState('')
  const [password, setPassword] = useState('')
  const [emailRecovery, setEmailRecovery] = useState('')
  const [loginError, setLoginError] = useState('')

  const backToLogin = () => {
    setUserName('')
    setPassword('')
    setLoginScreen('login')
  }
  const goToForgot = () => {
    setUserName('')
    setPassword('')
    setLoginScreen('forgot')
  }
  const onLogin = () => {
    setLoginError('')
    loginApi
      .login(userName, password)
      .then((res) => {
        console.log('THE RESPONSE! res:  ', res)
        if (res.token) {
          /**
           * Login success --> save the token at local storage!
           * */
          storageConfig.setToken('Bearer ' + res.token)
          history.push('/home')
          // reRoute to home screen
        }
      })
      .catch((err) => {
        setLoginError('Username or/and password incorrect')
        console.error('Get programs failed questionnaire failed:   ', err)
      })
  }
  const onSubmitRecovery = () => {}
  // If storageConfig.getToke() ==> go to home!
  if (storageConfig.getToken()) {
    history.push('/home')
  }
  return (
    <div className="login-screen-page">
      <div className="login-user-wrapper">
        <div className="valera-icons-section">
          <LoginTopIcon />
        </div>
        <div className="valera-user-input">
          {loginScreen === 'login' && (
            <LoginScreen
              onLogin={onLogin}
              password={password}
              username={userName}
              setPassword={setPassword}
              setUserName={setUserName}
              forgotClick={goToForgot}
              loginError={loginError}
            />
          )}
          {loginScreen === 'forgot' && (
            <ForgotScreen
              emailRecovery={emailRecovery}
              setEmailRecovery={setEmailRecovery}
              onSubmit={onSubmitRecovery}
              goBack={backToLogin}
            />
          )}
        </div>
      </div>
    </div>
  )
}
Login.propTypes = {
  // login: PropTypes.func.isRequired
}

export default Login
