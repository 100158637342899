import * as constant from './constant'
function getQuestionnaireCompleted(input = {}) {
  let notificationModal = {
    type: input.type,
    text: input.notificationText,
    active: input.active,
    activeEmail: input.activeEmail,
    parameters: {
      questionnaireTemplateIds:
        input.questionnaireIds && input.questionnaireIds.length
          ? input.questionnaireIds
          : [],
    },
  }
  if (!input.isNewNotification) {
    notificationModal.id = input.id
  }
  return notificationModal
}
function getConsentFormAgreed(input = {}) {
  let notificationModal = {
    type: input.type,
    text: input.notificationText,
    active: input.active,
    activeEmail: input.activeEmail,
    parameters: {
      consentFormTemplateIds:
        input.consentFormIds && input.consentFormIds.length
          ? input.consentFormIds
          : [],
    },
  }
  if (!input.isNewNotification) {
    notificationModal.id = input.id
  }
  return notificationModal
}
function getGoalReminderAcknowledged(input = {}) {
  let notificationModal = {
    type: input.type,
    text: input.notificationText,
    active: input.active,
    activeEmail: input.activeEmail,
  }
  if (!input.isNewNotification) {
    notificationModal.id = input.id
  }
  return notificationModal
}
export const getNotificationModal = (modal = {}) => {
  switch (modal.type) {
    case constant.QUESTIONNAIRE_COMPLETED: {
      return getQuestionnaireCompleted(modal)
    }
    case constant.CONSENT_FORM_AGREED: {
      return getConsentFormAgreed(modal)
    }
    case constant.GOAL_REMINDER_ACKNOWLEDGED: {
      return getGoalReminderAcknowledged(modal)
    }
    default:
      return {}
  }
}
export const getTransformItemForUiArray = (modal = {}) => {
  let notItemForArray = {
    id: modal.id,
    type: modal.type,
    isOpen: false,
    active: modal.active,
    activeEmail: modal.activeEmail,
    notificationText: modal.text ? modal.text : '',
  }
  if (notItemForArray.type === constant.QUESTIONNAIRE_COMPLETED) {
    notItemForArray.questionnaireIds =
      modal.parameters && modal.parameters.questionnaireTemplateIds
        ? modal.parameters.questionnaireTemplateIds
        : []
  } else if (notItemForArray.type === constant.CONSENT_FORM_AGREED) {
    notItemForArray.consentFormIds =
      modal.parameters && modal.parameters.consentFormTemplateIds
        ? modal.parameters.consentFormTemplateIds
        : []
  }
  return notItemForArray
}
