import React from 'react'
import PropTypes from 'prop-types'

const ConsentFormListItem = ({ item }) => {
  return (
    <div className="consent-form-list-item">
      <div className="consent-form-list-name">{item.name}</div>
      <div className="consent-form-list-privacy">{item.privacy}</div>
    </div>
  )
}

ConsentFormListItem.propTypes = {
  item: PropTypes.object.isRequired,
}

export default ConsentFormListItem
