import React, { useEffect } from 'react'
import SelectFormsItems from '../../../../../../GlobalComponent/SelectFormsItems/SelectFormsItems'
import * as constants from '../constants'
import CallToAction from '../../../../../../common/CallToAction/CallToAction'
import ChipsItem from '../../../../../../common/ChipsItem/ChipsItem'
import InputError from '../../../../../../common/InputError/InputError'
const conditionArray = constants.CONDITION_ARRAY

const QuestionnaireScore = ({
  item,
  alertRulesArray,
  setAlertRulesArray,
  loadQuestionnaireArray,
  questionnaireArray,
  programId,
  tenantId,
  error,
}) => {
  useEffect(() => {
    if (!questionnaireArray || !questionnaireArray.length) {
      loadQuestionnaireArray(tenantId, programId)
    }
  }, [questionnaireArray])

  const AddTemplate = () => {
    /**
       * item.questionnaireModalOpen
       item.modalFormsList
       */
    setAlertRulesArray(
      alertRulesArray.map((a) => {
        if (a.id === item.id) {
          return Object.assign({}, a, {
            questionnaireModalOpen: true,
            modalFormsList:
              !questionnaireArray || !questionnaireArray.length
                ? []
                : questionnaireArray.map((qItem) => {
                    let isSelected =
                      item.questionnaireIds &&
                      item.questionnaireIds.length &&
                      item.questionnaireIds.includes(qItem.id)
                    // let isSelected = false;
                    return {
                      isSelected: isSelected,
                      name: qItem.alias
                        ? qItem.name + ' (' + qItem.alias + ')'
                        : qItem.name,
                      id: qItem.id,
                    }
                  }),
          })
        }
        return a
      }),
    )
  }
  function changeCheckFormPrimaryFormModal(id) {
    setAlertRulesArray(
      alertRulesArray.map((a) => {
        if (a.id === item.id) {
          return Object.assign({}, a, {
            modalFormsList: a.modalFormsList.map((qItem) => {
              if (qItem.id === id) {
                return Object.assign({}, qItem, {
                  isSelected: !qItem.isSelected,
                })
              }
              return qItem
            }),
          })
        }
        return a
      }),
    )
  }
  const closeModal = () => {
    setAlertRulesArray(
      alertRulesArray.map((a) => {
        if (a.id === item.id) {
          return Object.assign({}, a, {
            questionnaireModalOpen: false,
            modalFormsList: [],
          })
        }
        return a
      }),
    )
  }
  const saveModal = () => {
    setAlertRulesArray(
      alertRulesArray.map((a) => {
        if (a.id === item.id) {
          return Object.assign({}, a, {
            questionnaireModalOpen: false,
            modalFormsList: [],
            questionnaireIds: a.modalFormsList
              .filter((fItem) => fItem.isSelected)
              .map((mItem) => mItem.id),
          })
        }
        return a
      }),
    )
  }
  const deleteQuestItem = (id) => {
    setAlertRulesArray(
      alertRulesArray.map((a) => {
        if (a.id === item.id) {
          return Object.assign({}, a, {
            questionnaireIds: a.questionnaireIds.filter((qId) => qId !== id),
          })
        }
        return a
      }),
    )
  }
  const onChangeCondition = (e, itemId) => {
    setAlertRulesArray(
      alertRulesArray.map((a) => {
        if (a.id === item.id) {
          return Object.assign({}, a, {
            // item.condition.type
            condition: {
              type: e.target.value,
              values: {},
            },
          })
        }
        return a
      }),
    )
  }
  /**
   * return: oneScore/twoScore/nonScore
   * */
  const getScoreTypeToShow = () => {
    if (item && item.condition && item.condition.type) {
      let conditionSelect = conditionArray.find(
        (c) => c.value === item.condition.type,
      )
      if (!conditionSelect) return 'nonScore'
      if (conditionSelect.scoreType === 'two') {
        return 'twoScore'
      }
      if (conditionSelect.scoreType === 'one') {
        return 'oneScore'
      }
    }
    return 'nonScore'
  }
  const changeConditionValues = (e, type) => {
    setAlertRulesArray(
      alertRulesArray.map((a) => {
        if (a.id === item.id) {
          const condition = {
            type: item.condition.type,
            values: item.condition.values || {},
          }
          switch (type) {
            case 'score': {
              condition.values = { score: e.target.value }
              break
            }
            case 'maxScore': {
              condition.values = Object.assign({}, condition.values, {
                maxScore: e.target.value,
                minScore: condition.values.minScore || null,
              })
              break
            }
            case 'minScore': {
              condition.values = Object.assign({}, condition.values, {
                maxScore: condition.values.maxScore || null,
                minScore: e.target.value,
              })
              break
            }
            default: {
              break
            }
          }
          return Object.assign({}, a, {
            // item.condition.type
            condition,
          })
        }
        return a
      }),
    )
  }
  return (
    <>
      <div className="rule-filed aline-top-field">
        <div className="rule-label">Questionnaire templates</div>
        <div className="rule-input">
          <div className="select-questionnaire-section">
            <CallToAction
              onClick={AddTemplate}
              text="Add template"
              iconType="plus"
            />
            <SelectFormsItems
              closeModal={closeModal}
              saveOnClick={saveModal}
              changeCheckItem={changeCheckFormPrimaryFormModal}
              title={'Add templates'}
              isOpen={item.questionnaireModalOpen}
              modalFormList={item.modalFormsList}
              saveBtnText="Save"
            />
            {item.questionnaireIds &&
              item.questionnaireIds &&
              questionnaireArray &&
              questionnaireArray.length &&
              item.questionnaireIds.map((qId) => {
                let qItem = questionnaireArray.find((qItem) => qItem.id === qId)
                return (
                  <ChipsItem
                    key={qItem.id}
                    text={qItem.name}
                    deleteClick={() => deleteQuestItem(qItem.id)}
                  />
                )
              })}
          </div>
        </div>
      </div>
      <InputError
        showError={error && error.questionnaire}
        text={constants.FAILED_ERROR_TEXT}
      />
      <div className="rule-filed">
        <div className="rule-label">Condition</div>
        <div className="rule-input">
          <select
            disabled={false}
            className=""
            value={item.condition.type || ''}
            onChange={(e) => onChangeCondition(e, item.id)}
          >
            <option value="" disabled hidden>
              Select condition
            </option>
            {conditionArray.map((cond) => {
              return (
                <option key={cond.value} value={cond.value}>
                  {cond.text}
                </option>
              )
            })}
          </select>
        </div>
      </div>
      <InputError
        showError={error && error.conditionOperator}
        text={constants.FAILED_ERROR_TEXT}
      />
      <div className="rule-filed">
        <div className="rule-label">Score</div>
        {item.condition.type.length > 0 && (
          <div className="rule-input">
            {getScoreTypeToShow() === 'oneScore' && (
              <input
                type="number"
                className="small-input"
                placeholder="Value"
                value={item.condition.values.score}
                onChange={(e) => changeConditionValues(e, 'score')}
              />
            )}
            {getScoreTypeToShow() === 'twoScore' && (
              <>
                <input
                  type="number"
                  className="small-input"
                  placeholder="Min"
                  value={item.condition.values.minScore}
                  onChange={(e) => changeConditionValues(e, 'minScore')}
                />
                <input
                  type="number"
                  className="small-input not-first"
                  placeholder="Max"
                  value={item.condition.values.maxScore}
                  onChange={(e) => changeConditionValues(e, 'maxScore')}
                />
              </>
            )}
          </div>
        )}
      </div>
      <InputError
        showError={error && error.conditionValues}
        text={constants.FAILED_ERROR_TEXT}
      />
    </>
  )
}

export default QuestionnaireScore
