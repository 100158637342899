import React from 'react'
import './style.less'
import CloseIcon from '../../../image/icon_close.svg'
import AddIcon from '../../../image/icon_plus.svg'
import AddInputField from '../AddInputField/AddInputField'
const TwoItem = ({
  conditions,
  optionsDepend,
  changeCondition,
  addCondition,
  addRowCondition,
  deleteRowCondition,
  deleteCondition,
}) => {
  /*const optionsL = [
    { text: "Q1", id: "q1", value: "Q1" },
    { text: "Q2", id: "q2", value: "Q2" },
    { text: "Q3", id: "q3", value: "Q3" },
    { text: "Q4", id: "q4", value: "Q4" },
    { text: "Q5", id: "q5", value: "Q5" },
    { text: "Q6", id: "q6", value: "Q6" }
  ];*/
  // const optionsR = [
  //   { text: "A1", id: "a1", value: "A1" },
  //   { text: "A2", id: "a2", value: "A2" },
  //   { text: "A3", id: "a3", value: "A3" },
  //   { text: "A4", id: "a4", value: "A4" },
  //   { text: "A5", id: "a5", value: "A5" },
  //   { text: "A6", id: "a6", value: "A6" }
  // ];

  const setSelectItem = (rowId, id, value, type) => {
    changeCondition(rowId, id, value, type)
  }
  const conditionExist = !!Object.keys(conditions).length
  return (
    <div className="wrap-condition">
      {conditions &&
        Object.keys(conditions).map((mapKey, key) => {
          const itemR = conditions[mapKey]
          const uniqKey = 'condition-key-' + key + '-' + JSON.stringify(itemR)
          const style = { display: 'flex', alignItems: 'center' }
          const iconStyle = {
            cursor: 'pointer',
            marginBottom: '11px',
            marginLeft: '8px',
          }
          return (
            <div key={uniqKey} style={style}>
              <div className="condition-row">
                {itemR.map((itemC, index) => {
                  return (
                    <div
                      className="condition-item two-item"
                      key={uniqKey + '-item-' + index}
                    >
                      <select
                        value={itemC.q ? itemC.q.value : ''}
                        onChange={(event) => {
                          setSelectItem(
                            mapKey,
                            itemC.id,
                            event.target.value,
                            'q',
                          )
                        }}
                      >
                        <option value="" key="" disabled hidden>
                          Q?
                        </option>

                        {optionsDepend.length &&
                          optionsDepend.map((option, index) => {
                            return (
                              <option
                                key={option.id + index}
                                value={option.value}
                              >
                                {option.text}
                              </option>
                            )
                          })}
                      </select>

                      <select
                        className="second-select"
                        value={itemC.a.value}
                        onChange={(event) => {
                          setSelectItem(
                            mapKey,
                            itemC.id,
                            event.target.value,
                            'a',
                          )
                        }}
                      >
                        <option value="" key="" disabled hidden>
                          A?
                        </option>

                        {itemC.q.value &&
                          optionsDepend.find(
                            (op) =>
                              op.value == itemC.q.value &&
                              optionsDepend.find(
                                (op) => op.value == itemC.q.value,
                              ).ansArray &&
                              optionsDepend.find(
                                (op) => op.value == itemC.q.value,
                              ).ansArray.length,
                          ) &&
                          optionsDepend
                            .find((op) => op.value == itemC.q.value)
                            .ansArray.map((option, index) => {
                              return (
                                <option
                                  key={option.id + index}
                                  value={option.value}
                                >
                                  {option.text}
                                </option>
                              )
                            })}
                      </select>

                      <div
                        className="close-icon-item"
                        onClick={() => {
                          deleteCondition(mapKey, itemC.id)
                        }}
                      >
                        <CloseIcon />
                      </div>
                      <div className="separate-line" />
                    </div>
                  )
                })}
                <div
                  className="add-or-icon"
                  onClick={() => {
                    addCondition(mapKey)
                  }}
                >
                  <AddIcon />
                </div>
              </div>
              <div
                style={iconStyle}
                onClick={() => {
                  deleteRowCondition(mapKey)
                }}
              >
                <CloseIcon />
              </div>
            </div>
          )
        })}
      <div
        className={
          conditionExist ? 'add-row-btn' : 'add-row-btn empty-conditions'
        }
      >
        <AddInputField
          text={conditionExist ? 'OR ' : 'Add Condition'}
          onClick={() => {
            addRowCondition()
          }}
        />
      </div>
    </div>
  )
}

export default TwoItem

/*const cond = {
  1: [
    { text: "A1", id: "a1", value: "A1" },
    { text: "A2", id: "a2", value: "A2" }
  ],
  5: [
    { text: "A3", id: "a3", value: "A3" },
    { text: "A4", id: "a4", value: "A4" }
  ]
};
const options = [
  { text: "A1", id: "a1", value: "A1" },
  { text: "A2", id: "a2", value: "A2" },
  { text: "A3", id: "a3", value: "A3" },
  { text: "A4", id: "a4", value: "A4" },
  { text: "A5", id: "a5", value: "A5" },
  { text: "A6", id: "a6", value: "A6" }
];*/
