import React from 'react'
import { createRoot } from 'react-dom/client'
import { Provider as ReduxProvider } from 'react-redux'
import { initMuiLicenseKey } from '@valerahealth/ui-components/grid'
import { BrowserRouter as Router } from 'react-router-dom'
import 'bootstrap/dist/css/bootstrap.min.css'
import App from './components/App'
import './fontFiles/Avenir/font_files'
import configureStore from './redux/configureStore'
import { LocalizationProvider } from '@valerahealth/ui-components'

initMuiLicenseKey()

const store = configureStore()
const container = document.getElementById('root')
if (container) {
  const root = createRoot(container)
  root.render(
    <ReduxProvider store={store}>
      <LocalizationProvider>
        <Router>
          <App />
        </Router>
      </LocalizationProvider>
    </ReduxProvider>,
  )
} else {
  console.error('Failed to find the root element.')
}
