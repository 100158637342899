import React from 'react'
import OneItem from '../../../common/Condition/oneItem'
import TwoItem from '../../../common/Condition/twoItem'
import AnswerItem from './AnswerItem'
import IconRight from '../../../../image/icon_arrow_right.svg'
import IconDown from '../../../../image/icon_arrow_down.svg'
import './one-question-style.less'
import OptionQuestMenu from './optionQuestMenu'
import DragDropList from '../../../common/DragDropList/dragDropList'
import AddInputField from '../../../common/AddInputField/AddInputField'
import * as globalFunction from './globalFunction'
import InputError from '../../../common/InputError/InputError'
import { Switch } from '@valerahealth/ui-components'

const OneQuestionItem = ({ item, ...props }) => {
  /**
   * props = {openClosetQuest, setQuestionArray, questionArray}
   * */

  const condTerminate =
    props.questionArray &&
    props.questionArray.length &&
    props.questionArray.find((questItem) => questItem.id === item.id)
      ? props.questionArray.find((questItem) => questItem.id === item.id)
          .terminateOn
      : {}
  const condDepend =
    props.questionArray &&
    props.questionArray.length &&
    props.questionArray.find((questItem) => questItem.id === item.id)
      ? props.questionArray.find((questItem) => questItem.id === item.id)
          .dependsOn
      : {}
  const questionError =
    props.error && props.error[item.itemLocation]
      ? props.error[item.itemLocation]
      : {}
  // Todo check the depend condition and if is not possible set the condition to default value.
  // Activate at: 1. answer delete, 2. answer order change, 3. order of question change.
  const setAnswerArray = (answersArray, itemId) => {
    let setQuestionArray = props.setQuestionArray
    let questionArray = props.questionArray
    setQuestionArray(
      questionArray.map((item) => {
        if (item.id === itemId) {
          return Object.assign({}, item, {
            answers: answersArray,
          })
        }
        return item
      }),
    )
  }
  const setAnswerItem = (itemId, answerId, field, newValue) => {
    let setQuestionArray = props.setQuestionArray
    let questionArray = props.questionArray
    setQuestionArray(
      questionArray.map((item) => {
        if (item.id === itemId) {
          return Object.assign({}, item, {
            answers: item.answers.map((answerItem) => {
              if (answerItem.id === answerId) {
                let newItem = Object.assign({}, answerItem, {})
                newItem[field] = newValue
                return Object.assign({}, answerItem, newItem)
              }
              return answerItem
            }),
          })
        }
        return item
      }),
    )
  }
  const deleteAnswer = (itemId, answerId) => {
    globalFunction.deleteAnswer(
      itemId,
      answerId,
      props.setQuestionArray,
      props.questionArray,
    )
  }
  const optionsTerminate = item.answers.length
    ? item.answers.map((optItem, index) =>
        Object.assign(
          {},
          {},
          {
            text: 'A' + (index + 1),
            id: optItem.id,
            value: optItem.id,
          },
        ),
      )
    : []
  const optionsDepend = props.questionArray
    .filter((questItem) => questItem.itemLocation < item.itemLocation)
    .map((questToMap) => {
      return {
        text: 'Q' + questToMap.itemLocation,
        id: questToMap.id,
        value: questToMap.id,
        ansArray: questToMap.answers.map((ans) => {
          return {
            id: ans.id,
            value: ans.id,
            text: 'A' + ans.itemLocation,
          }
        }),
      }
    })

  const disableAddFreeText =
    item &&
    item.answers &&
    item.answers.length &&
    item.answers.find((ans) => ans.type === 'freeText')
  /**
   * The next 5 function are the terminateCondition function
   * */
  const addTerminateRowCondition = () => {
    let itemId = item.id
    let setQuestionArray = props.setQuestionArray
    let questionArray = props.questionArray
    setQuestionArray(
      questionArray.map((item) => {
        if (item.id === itemId) {
          let objChange = {}
          objChange[new Date().getTime()] = []
          return Object.assign({}, item, {
            terminateOn: Object.assign({}, item.terminateOn, objChange),
          })
        }
        return item
      }),
    )
  }
  const addTerminateCondition = (rowId) => {
    let itemId = item.id
    let setQuestionArray = props.setQuestionArray
    let questionArray = props.questionArray
    setQuestionArray(
      questionArray.map((item) => {
        if (item.id === itemId) {
          let tepObj = item.terminateOn
          tepObj[rowId].push({
            id: new Date().getTime(),
            value: '',
          })
          return Object.assign({}, item, {
            terminateOn: tepObj,
          })
        }
        return item
      }),
    )
  }
  const changeConditionTerminate = (rowId, id, value) => {
    let itemId = item.id
    let setQuestionArray = props.setQuestionArray
    let questionArray = props.questionArray
    // Todo need change the all item of terminateOn
    setQuestionArray(
      questionArray.map((item) => {
        if (item.id === itemId) {
          let tepObj = item.terminateOn
          tepObj[rowId] = tepObj[rowId].map((rowItem) => {
            if (rowItem.id === id) {
              rowItem.value = value
            }
            return rowItem
          })
          return Object.assign({}, item, {
            terminateOn: tepObj,
          })
        }
        return item
      }),
    )
  }
  const deleteTerminateCondition = (rowId, id) => {
    let itemId = item.id
    let setQuestionArray = props.setQuestionArray
    let questionArray = props.questionArray
    setQuestionArray(
      questionArray.map((item) => {
        if (item.id === itemId) {
          let tepObj = item.terminateOn
          tepObj[rowId] = tepObj[rowId].filter(
            (filterItem) => filterItem.id !== id,
          )
          return Object.assign({}, item, {
            terminateOn: tepObj,
          })
        }
        return item
      }),
    )
  }
  const deleteTerminateRowCondition = (rowId) => {
    let itemId = item.id
    let setQuestionArray = props.setQuestionArray
    let questionArray = props.questionArray
    setQuestionArray(
      questionArray.map((item) => {
        if (item.id === itemId) {
          delete item.terminateOn[rowId]
        }
        return item
      }),
    )
  }
  /**
   * The next 5 function are the dependCondition function
   * */
  const addDependRowCondition = () => {
    /*
          {
          a: { text: "A1", id: "a1", value: "A1" },
          q: { text: "Q1", id: "q1", value: "Q1" },
          qOptions: [],
          aOptions: [],
          id: ""
        }
      * */
    let itemId = item.id
    let setQuestionArray = props.setQuestionArray
    let questionArray = props.questionArray
    setQuestionArray(
      questionArray.map((item) => {
        if (item.id === itemId) {
          let objChange = {}
          objChange[new Date().getTime()] = []
          return Object.assign({}, item, {
            dependsOn: Object.assign({}, item.dependsOn, objChange),
          })
        }
        return item
      }),
    )
  }
  const addDependCondition = (rowId) => {
    let itemId = item.id
    let setQuestionArray = props.setQuestionArray
    let questionArray = props.questionArray
    setQuestionArray(
      questionArray.map((item) => {
        if (item.id === itemId) {
          let tepObj = item.dependsOn
          tepObj[rowId].push({
            a: { text: '', id: '', value: '' },
            q: { text: '', id: '', value: '' },
            qOptions: [],
            aOptions: [],
            id: new Date().getTime(),
          })
          return Object.assign({}, item, {
            dependsOn: tepObj,
          })
        }
        return item
      }),
    )
  }
  const deleteDependRowCondition = (rowId) => {
    let itemId = item.id
    let setQuestionArray = props.setQuestionArray
    let questionArray = props.questionArray
    setQuestionArray(
      questionArray.map((item) => {
        if (item.id === itemId) {
          delete item.dependsOn[rowId]
        }
        return item
      }),
    )
  }
  const deleteDependCondition = (rowId, id) => {
    let itemId = item.id
    let setQuestionArray = props.setQuestionArray
    let questionArray = props.questionArray
    setQuestionArray(
      questionArray.map((item) => {
        if (item.id === itemId) {
          let tepObj = item.dependsOn
          tepObj[rowId] = tepObj[rowId].filter(
            (filterItem) => filterItem.id !== id,
          )
          return Object.assign({}, item, {
            dependsOn: tepObj,
          })
        }
        return item
      }),
    )
  }
  const changeConditionDepend = (rowId, id, value, type) => {
    let itemId = item.id
    let setQuestionArray = props.setQuestionArray
    let questionArray = props.questionArray
    // Todo need change the all item of terminateOn
    setQuestionArray(
      questionArray.map((item) => {
        if (item.id === itemId) {
          let tepObj = item.dependsOn
          tepObj[rowId] = tepObj[rowId].map((rowItem) => {
            if (rowItem.id === id) {
              rowItem[type] = { id: value, value }
            }
            return rowItem
          })
          return Object.assign({}, item, {
            dependsOn: tepObj,
          })
        }
        return item
      }),
    )
  }
  const setField = (itemId, name, newValue) => {
    let setQuestionArray = props.setQuestionArray
    let questionArray = props.questionArray
    setQuestionArray(
      questionArray.map((item) => {
        if (item.id === itemId) {
          const objChange = {}
          objChange[name] = newValue
          return Object.assign({}, item, objChange)
        }
        return item
      }),
    )
  }
  /**
   * type = "regular"/"freeText"
   * { id: 1, score: 0, text: "", type: "regular" },
   * { id: 2, score: 0, text: "", type: "freeText" }
   * */
  const addAnswer = (itemId, type) => {
    let setQuestionArray = props.setQuestionArray
    let questionArray = props.questionArray
    if (type !== 'regular' && type !== 'freeText') return

    setQuestionArray(
      questionArray.map((item) => {
        if (item.id === itemId) {
          let newItem = {
            id: new Date().getTime(),
            text: '',
            type: type,
            itemLocation: item.answers.length + 1,
            dragType: 'center-left',
          }
          newItem.dragType = 'center-left'
          if (type === 'regular') {
            newItem.score = 0
          } else {
            // newItem.dragType = "center-left";
          }
          return Object.assign({}, item, {
            answers: [...item.answers, newItem],
          })
        }
        return item
      }),
    )
  }
  const changeOptionsStatusOpenClose = (itemId, isOutside) => {
    let setQuestionArray = props.setQuestionArray
    let questionArray = props.questionArray
    setQuestionArray(
      questionArray.map((item) => {
        if (itemId === item.id) {
          let newOptionMenu =
            isOutside === itemId ? false : !item.topOptionsOpen
          return Object.assign({}, item, {
            topOptionsOpen: newOptionMenu,
          })
        }
        return item
      }),
    )
  }
  const deleteQuestions = (itemId) => {
    let setQuestionArray = props.setQuestionArray
    let questionArray = props.questionArray
    /**
     * 1. delete the all depends on this question.
     * 2. reorder th array.
     * */
    let deleteDependResult = questionArray.map((item) => {
      let dependsOn = item.dependsOn
      if (!dependsOn || !Object.keys(dependsOn).length) {
        return item
      }
      let dependOnKey = Object.keys(dependsOn)

      // dependItem.a = { value: "", id: "" };
      dependOnKey.forEach((key) => {
        // let dependOnRow = dependsOn[key];
        let dependOnRow = dependsOn[key].map((condition) => {
          if (condition.q.value == itemId) {
            condition.q = { value: '', id: '' }
            condition.a = { value: '', id: '' }
          }
          return condition
        })
        dependsOn[key] = dependOnRow
      })
      return Object.assign({}, item, { dependsOn })
    })
    let deleteQuest = deleteDependResult.filter((item) => item.id != itemId)
    let deleteDependResultReOrder = deleteQuest.map((item, index) => {
      return Object.assign({}, item, { itemLocation: index + 1 })
    })
    setQuestionArray(deleteDependResultReOrder)
    // Todo complete that method!
  }
  return (
    <div className="one-question-wrap" key={item.id}>
      <div
        className={
          item.open ? 'one-question-header' : 'one-question-header close-quest'
        }
      >
        <div className="text-quest">{item.text}</div>

        <OptionQuestMenu
          id={item.id}
          changeOptionsStatusOpenClose={changeOptionsStatusOpenClose}
          deleteQuestions={deleteQuestions}
          topOptionsOpen={item.topOptionsOpen}
        />
        <div
          className="arrow-icon"
          onClick={() => {
            if (!props.openClosetQuest || item.topOptionsOpen) return
            props.openClosetQuest(item.id, !item.open)
          }}
        >
          {!item.open && <IconRight />}
          {item.open && <IconDown />}
        </div>
      </div>
      {item.open && (
        <div className="one-question-body">
          <div className="input-row-2">
            <div className="input-field">
              <div className="label">Question text</div>
              <input
                placeholder="Insert question text"
                value={item.text}
                onChange={(e) => {
                  setField(item.id, 'text', e.target.value)
                }}
              />
              <InputError
                text="This field is required"
                showError={questionError && questionError.text}
              />
            </div>
            <div className="input-field second-item">
              <div className="label">Layout code</div>
              <select
                className="details-section"
                value={item.layoutCode}
                onChange={(e) => {
                  setField(item.id, 'layoutCode', e.target.value)
                }}
              >
                <option value="" key="" disabled hidden>
                  Layout code
                </option>
                <option key="V" value="V">
                  Vertical
                </option>
                <option key="2H" value="2H">
                  Horizontal
                </option>
                <option key="B" value="B">
                  Text Box
                </option>
                <option key="MV" value="MV">
                  Multiple Vertical
                </option>
              </select>
              <InputError
                text="This field is required"
                showError={questionError && questionError.layoutCode}
              />
            </div>
          </div>

          <div className="input-row-2">
            <div className="input-field">
              <div className="label">Input method</div>
              <select
                className="details-section"
                value={item.inputMethod}
                onChange={(e) => {
                  setField(item.id, 'inputMethod', e.target.value)
                }}
              >
                <option value="" disabled hidden>
                  Input method
                </option>
                <option key="single" value="single">
                  single
                </option>

                <option key="multiple" value="multiple">
                  multiple
                </option>
                <option key="free_text" value="free_text">
                  freeText
                </option>
              </select>
              <InputError
                text="This field is required"
                showError={questionError && questionError.inputMethod}
              />
            </div>
            <div className="input-field second-item">
              <div className="label">Prefix</div>
              <input
                placeholder="Insert Prefix text"
                value={item.prefix}
                onChange={(e) => {
                  setField(item.id, 'prefix', e.target.value)
                }}
              />
            </div>
          </div>

          <div className="input-row-2">
            <div className="input-field">
              <div className="label">Terminate on</div>
              <OneItem
                conditions={condTerminate}
                options={optionsTerminate}
                changeCondition={changeConditionTerminate}
                addCondition={addTerminateCondition}
                addRowCondition={addTerminateRowCondition}
                deleteCondition={deleteTerminateCondition}
                deleteRowCondition={deleteTerminateRowCondition}
                onlyAndCondition={true}
              />
              <InputError
                text="please insert valid condition"
                showError={questionError && questionError.terminateCondition}
              />
            </div>

            <div className="input-field second-item">
              <div className="label">Make optional</div>
              <Switch
                name="isOptional"
                label="Make optional"
                checked={item.isOptional}
                onChange={(e) => {
                  setField(item.id, 'isOptional', e.target.checked)
                }}
              />
            </div>
          </div>
          <div className="input-row-2 button-border">
            <div className="input-field">
              <div className="label">Depends on</div>
              <TwoItem
                conditions={condDepend}
                optionsDepend={optionsDepend}
                deleteRowCondition={deleteDependRowCondition}
                deleteCondition={deleteDependCondition}
                addRowCondition={addDependRowCondition}
                changeCondition={changeConditionDepend}
                addCondition={addDependCondition}
              />
              <InputError
                text="please insert valid condition"
                showError={questionError && questionError.dependsCondition}
              />
            </div>
            <div className="input-field second-item">
              <div className="label">Page</div>
              <input
                placeholder="Select page"
                type="number"
                value={item.page}
                onChange={(e) => {
                  setField(item.id, 'page', parseInt(e.target.value))
                }}
              />
            </div>
          </div>
          <div className="input-row-1 answer-section">
            <div className="label">Answers</div>
            <div className="answer-array-body">
              <DragDropList
                ItemComponent={AnswerItem}
                dragList={item.answers}
                propsComponent={{
                  itemId: item.id,
                  setItem: setAnswerItem,
                  deleteItem: deleteAnswer,
                }}
                setDragList={(array) => setAnswerArray(array, item.id)}
              />
              <div className="answer-btn-section">
                <div className="add-answer-btn">
                  <AddInputField
                    text="Add regular answer"
                    onClick={() => {
                      addAnswer(item.id, 'regular')
                    }}
                  />
                </div>
                <div className="add-answer-btn">
                  <AddInputField
                    disable={disableAddFreeText}
                    text="Add free text answer"
                    onClick={() => {
                      addAnswer(item.id, 'freeText')
                    }}
                  />
                </div>
              </div>
              <InputError
                text="please insert valid answers"
                showError={questionError && questionError.answersLength}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default OneQuestionItem
