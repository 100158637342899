import React, { useRef } from 'react'
import useOutsideClick from '../../../GlobalComponent/useOutsideClick/useOutsideClick'
import OptionsIcon from '../../../../image/icon_btn_options.svg'
import PropTypes from 'prop-types'

const optionQuestMenu = ({
  changeOptionsStatusOpenClose,
  deleteQuestions,
  topOptionsOpen,
  id,
}) => {
  const ref = useRef()

  useOutsideClick(ref, () => {
    if (topOptionsOpen) {
      changeOptionsStatusOpenClose(id, id)
    }
  })

  return (
    <div className="options-quest-icon-wrap">
      <div
        onClick={() => {
          changeOptionsStatusOpenClose(id)
        }}
      >
        <OptionsIcon />
      </div>
      {topOptionsOpen && (
        <div
          className="options-menu"
          ref={ref}
          onClick={() => deleteQuestions(id)}
        >
          Delete question
        </div>
      )}
    </div>
  )
}

optionQuestMenu.propTypes = {
  changeOptionsStatusOpenClose: PropTypes.func.isRequired,
  deleteQuestions: PropTypes.func.isRequired,
  topOptionsOpen: PropTypes.bool.isRequired,
  id: PropTypes.string.isRequired,
}
export default optionQuestMenu
