import * as types from '../actions/actionsType'
import initializeState from './initializeState'

export default function toolsReducer(state = initializeState.tools, action) {
  switch (action.type) {
    case types.INITIALIZE_APP_STATE: {
      return initializeState.tools
    }
    case types.INITIALIZES_TOOLS_MEDIA_LIST: {
      return { ...state, mediaList: action.mediaList }
    }
    case types.INITIALIZES_TOOLS_QUESTIONNAIRES_LIST: {
      return { ...state, questionnairesList: action.questionnairesList }
    }
    case types.INITIALIZES_CONSENT_FORM_LIST: {
      return { ...state, consentFormList: action.consentFormList }
    }
    case types.SAVE_NEW_TOOLS_MEDIA_ITEM: {
      const mediaItem = action.media

      return { ...state, mediaList: [...state.mediaList, mediaItem] }
    }
    /*
    return {
        type: types.UPDATE_TOOLS_MEDIA_ITEM,
        media,
        id
    };
    * */
    case types.UPDATE_TOOLS_MEDIA_ITEM: {
      return {
        ...state,
        mediaList: state.mediaList.map((item) => {
          if (item.id === action.id) {
            return action.media
          }
          return item
        }),
      }
    }
    case types.UPDATE_TOOLS_CONSENT_FORM: {
      return {
        ...state,
        consentFormList: state.consentFormList.map((item) => {
          if (item.id === action.id) {
            return action.item
          }
          return item
        }),
      }
    }
    case types.SAVE_NEW_TOOLS_CONSENT_FORM: {
      return {
        ...state,
        consentFormList: [...state.consentFormList, action.item],
      }
    }
    case types.SAVE_NEW_TOOLS_QUESTIONNAIRE: {
      return {
        ...state,
        questionnairesList: [...state.questionnairesList, action.item],
      }
    }
    case types.UPDATE_TOOLS_QUESTIONNAIRE: {
      return {
        ...state,
        questionnairesList: state.questionnairesList.map((qu) => {
          if (action.id === qu.id) {
            return action.item
          }
          return qu
        }),
      }
    }
    default: {
      return state
    }
  }
}
