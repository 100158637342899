import React from 'react'
import './style.less'
import PropTypes from 'prop-types'
import MediaIcon from '../../../image/icon_media.svg'
import QuestionnairesIcon from '../../../image/icon_questionnaires.svg'
import ConsentFormsIcon from '../../../image/icon_consent_forms.svg'

const constantsLogos = {
  media: MediaIcon,
  questionnaires: QuestionnairesIcon,
  consent_forms: ConsentFormsIcon,
}

const LogoElement = ({ item }) => {
  if (item.logo) {
    return <img src={item.logo} />
  }
  if (item.constantLogo) {
    const ConstantLogo = constantsLogos[item.constantLogo]
    return <ConstantLogo />
  }
  return <div />
}

LogoElement.propTypes = {
  item: PropTypes.object,
}

const List = ({ list, titleText, itemClickFunc }) => {
  return (
    <div className="list-component">
      <div className="list-component-body">
        <div className="list-component-header">{titleText}</div>
        {list &&
          list.length > 0 &&
          list.map((item) => {
            return (
              <div
                className="list-component-item list-item-component-simple"
                key={item.id}
                onClick={() => itemClickFunc(item.id)}
              >
                {<LogoElement item={item} />}
                <div>{item.name}</div>
              </div>
            )
          })}
      </div>
    </div>
  )
}

List.propTypes = {
  list: PropTypes.array.isRequired,
  titleText: PropTypes.string.isRequired,
  itemClickFunc: PropTypes.func,
}

export default List
