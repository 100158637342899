import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import '../tab-page-style.less'
import { connect } from 'react-redux'
import * as tenantsActions from '../../../../../../redux/actions/tenantsActions'
import CallToAction from '../../../../../common/CallToAction/CallToAction'
import ToolboxItemList from '../ToolboxItemList'
import ButtonNew from '../../../../../common/Button/ButtonNew'
import InputCheckBox from '../../../../../common/Input/InputCheckBox'
import { getTenantsById } from '../../../../../utilities'

function Toolbox({
  loadTenantQuestionnaires,
  loadTenantSentiments,
  loadTenantMedia,
  loadTenantConsentForm,
  questionnairesList,
  sentimentsList,
  consentFormList,
  mediaList,
  program,
  loadProgramQuestionnaires,
  sendAllQuestionnaires,
  loadProgramSentiments,
  loadProgramMedia,
  loadProgramConsentForms,
  programQuestionnairesList,
  programSentimentList,
  programMediaList,
  programConsentFormList,
  saveProgramToolBox,
  saveProgramQuestionnaires,
  saveProgramMedia,
  match,
}) {
  const tenantId = match.params.id
  const programId = match.params.pId

  const [assessmentQuestionnairesView, setAssessmentQuestionnairesView] =
    useState({
      modalIsOpen: false,
      modalList: [],
      forms: [],
      showAll: false,
    })
  const [sendAllQuestionnaire, setSendAllQuestionnaire] = useState(false)

  const [cancelValue, setCancelValue] = useState(0)

  const [assessmentSentimentView, setAssessmentSentimentView] = useState({
    modalIsOpen: false,
    modalList: [],
    forms: [],
    showAll: false,
  })

  const [mediaContentListView, setMediaContentListView] = useState({
    modalIsOpen: false,
    modalList: [],
    forms: [],
    showAll: false,
  })

  const [consentFormListView, setConsentFormListView] = useState({
    modalIsOpen: false,
    modalList: [],
    forms: [],
    showAll: false,
  })
  /**
   * This loads for Tenants level.
   * */
  useEffect(() => {
    if (sendAllQuestionnaires) {
      setSendAllQuestionnaire(true)
    }
  }, [sendAllQuestionnaires, cancelValue])
  useEffect(() => {
    if (!questionnairesList) {
      loadTenantQuestionnaires(tenantId)
    }
  }, [questionnairesList])
  useEffect(() => {
    if (!sentimentsList) {
      loadTenantSentiments(tenantId)
    }
  }, [sentimentsList])
  useEffect(() => {
    if (!mediaList) {
      loadTenantMedia(tenantId)
    }
  }, [mediaList])
  useEffect(() => {
    if (!consentFormList) {
      loadTenantConsentForm(tenantId)
    }
  }, [consentFormList, cancelValue])

  /**
   * This loads for Program level.
   * */
  useEffect(() => {
    if (!programQuestionnairesList) {
      loadProgramQuestionnaires(tenantId, programId)
    } else {
      // If exist load it to local view
      setAssessmentQuestionnairesView(
        Object.assign({}, assessmentQuestionnairesView, {
          forms: programQuestionnairesList,
        }),
      )
    }
  }, [programQuestionnairesList, cancelValue])

  useEffect(() => {
    if (!programSentimentList) {
      loadProgramSentiments(tenantId, programId)
    } else {
      // If exist load it to local view
      setAssessmentSentimentView(
        Object.assign({}, assessmentSentimentView, {
          forms: programSentimentList,
        }),
      )
    }
  }, [programSentimentList, cancelValue])

  useEffect(() => {
    if (!programMediaList) {
      loadProgramMedia(tenantId, programId)
    } else {
      // If exist load it to local view
      setMediaContentListView(
        Object.assign({}, mediaContentListView, {
          forms: programMediaList,
        }),
      )
    }
  }, [programMediaList, cancelValue])

  useEffect(() => {
    if (!programConsentFormList) {
      loadProgramConsentForms(tenantId, programId)
    } else {
      // If exist load it to local view
      setConsentFormListView(
        Object.assign({}, consentFormListView, {
          forms: programConsentFormList,
        }),
      )
    }
  }, [programConsentFormList, cancelValue])

  const showAll = (type, show = false) => {
    // "consent form"
    const [assessmentView, setAssessmentView] =
      type === 'sentiment'
        ? [assessmentSentimentView, setAssessmentSentimentView]
        : type === 'media'
        ? [mediaContentListView, setMediaContentListView]
        : type === 'consent form'
        ? [consentFormListView, setConsentFormListView]
        : [assessmentQuestionnairesView, setAssessmentQuestionnairesView]

    setAssessmentView(Object.assign({}, assessmentView, { showAll: show }))
  }
  const closeAddModal = (type) => {
    const [assessmentView, setAssessmentView] =
      type === 'sentiment'
        ? [assessmentSentimentView, setAssessmentSentimentView]
        : type === 'media'
        ? [mediaContentListView, setMediaContentListView]
        : type === 'consent form'
        ? [consentFormListView, setConsentFormListView]
        : [assessmentQuestionnairesView, setAssessmentQuestionnairesView]

    setAssessmentView(Object.assign({}, assessmentView, { modalIsOpen: false }))
  }
  const addItem = (type) => {
    const [assessmentView, setAssessmentView, modalListView] =
      type === 'sentiment'
        ? [assessmentSentimentView, setAssessmentSentimentView, sentimentsList]
        : type === 'media'
        ? [mediaContentListView, setMediaContentListView, mediaList]
        : type === 'consent form'
        ? [consentFormListView, setConsentFormListView, consentFormList]
        : [
            assessmentQuestionnairesView,
            setAssessmentQuestionnairesView,
            questionnairesList,
          ]

    setAssessmentView(
      Object.assign({}, assessmentView, {
        modalIsOpen: true,
        modalList: modalListView.map((item) => {
          const isSelected = !!assessmentView.forms.find(
            (formItem) => formItem.id === item.id,
          )
          return Object.assign(
            { name: item.name },
            { isSelected: isSelected, id: item.id },
          )
        }),
      }),
    )
  }
  const saveFormList = (type) => {
    const [assessmentView, setAssessmentView] =
      type === 'sentiment'
        ? [assessmentSentimentView, setAssessmentSentimentView]
        : type === 'media'
        ? [mediaContentListView, setMediaContentListView]
        : type === 'consent form'
        ? [consentFormListView, setConsentFormListView]
        : [assessmentQuestionnairesView, setAssessmentQuestionnairesView]

    setAssessmentView(
      Object.assign({}, assessmentView, {
        modalIsOpen: false,
        forms: assessmentView.modalList.filter((item) => item.isSelected),
        modalList: [],
      }),
    )
  }
  const changeCheckFormModal = (id, type) => {
    const [assessmentView, setAssessmentView] =
      type === 'sentiment'
        ? [assessmentSentimentView, setAssessmentSentimentView]
        : type === 'media'
        ? [mediaContentListView, setMediaContentListView]
        : type === 'consent form'
        ? [consentFormListView, setConsentFormListView]
        : [assessmentQuestionnairesView, setAssessmentQuestionnairesView]

    setAssessmentView(
      Object.assign({}, assessmentView, {
        modalList: assessmentView.modalList.map((item) => {
          if (id === item.id) {
            return Object.assign({}, item, { isSelected: !item.isSelected })
          }
          return item
        }),
      }),
    )
  }
  const changeSendAllCheckBox = () => {
    setSendAllQuestionnaire(!sendAllQuestionnaire)
  }
  const assessmentFormItemDelete = (id, type) => {
    /**
     * type = questionnaire/sentiment
     * */
    const [assessmentView, setAssessmentView] =
      type === 'sentiment'
        ? [assessmentSentimentView, setAssessmentSentimentView]
        : type === 'media'
        ? [mediaContentListView, setMediaContentListView]
        : type === 'consent form'
        ? [consentFormListView, setConsentFormListView]
        : [assessmentQuestionnairesView, setAssessmentQuestionnairesView]

    setAssessmentView(
      Object.assign({}, assessmentView, {
        forms: assessmentView.forms.filter((item) => item.id !== id),
      }),
    )
  }
  const saveProgramTool = () => {
    //1.  save the Questionnaires change
    //2.  save the Sentiments change
    //3.  save the Media Content change
    //4.  save the ConsentForm change

    const questSentIds = assessmentQuestionnairesView.forms
      .concat(assessmentSentimentView.forms)
      .map((item) => item.id)
    const mediaIds = mediaContentListView.forms.map((item) => item.id)

    /**
     * This not work at that moment!
     * */

    const constFormIds = consentFormListView.forms.map((item) => item.id)
    saveProgramToolBox(
      tenantId,
      programId,
      questSentIds,
      sendAllQuestionnaire,
      mediaIds,
      constFormIds,
      (res) => {
        if (res.ok) {
          console.log('SAVE TOOLBOX SUCCESS')
        } else {
          console.log('SAVE TOOLBOX FAILED')
        }
      },
    )
  }

  return (
    <div className="program-tab-page">
      <div className="setting-title">
        <div className="main-setting-title">Assessments</div>
        <div className="sub-setting-title">
          <InputCheckBox
            name={'send-all-check'}
            onChange={changeSendAllCheckBox}
            text={'All'}
            checked={sendAllQuestionnaire}
          />
        </div>
      </div>
      <div className="settings-section">
        <div className="section-name">Questionnaires</div>

        <ToolboxItemList
          type={'questionnaire'}
          tools={assessmentQuestionnairesView}
          addItem={addItem}
          changeCheckFormModal={changeCheckFormModal}
          changeSendAllCheckBox={changeSendAllCheckBox}
          closeAddModal={closeAddModal}
          formItemDelete={assessmentFormItemDelete}
          saveFormList={saveFormList}
          showAll={showAll}
          sendAllChecked={sendAllQuestionnaire}
        />
      </div>

      <div className="settings-section">
        <div className="section-name">Sentiments</div>

        <ToolboxItemList
          type={'sentiment'}
          tools={assessmentSentimentView}
          addItem={addItem}
          changeCheckFormModal={changeCheckFormModal}
          changeSendAllCheckBox={changeSendAllCheckBox}
          closeAddModal={closeAddModal}
          formItemDelete={assessmentFormItemDelete}
          saveFormList={saveFormList}
          showAll={showAll}
          sendAllChecked={sendAllQuestionnaire}
        />
      </div>

      <div className="setting-title border-top">
        <div className="main-setting-title">Media content</div>
        <div className="sub-setting-title">
          <div className="fix-size">
            <CallToAction
              onClick={() => addItem('media')}
              text="Add Media"
              iconType="plus"
            />
          </div>
        </div>
      </div>

      <div className="settings-section">
        <div className="section-name" />

        <ToolboxItemList
          type={'media'}
          tools={mediaContentListView}
          changeCheckFormModal={changeCheckFormModal}
          changeSendAllCheckBox={changeSendAllCheckBox}
          closeAddModal={closeAddModal}
          formItemDelete={assessmentFormItemDelete}
          saveFormList={saveFormList}
          showAll={showAll}
        />
      </div>

      <div className="setting-title border-top">
        <div className="main-setting-title">Consent forms</div>
        <div className="sub-setting-title">
          <div className="fix-size">
            <CallToAction
              onClick={() => addItem('consent form')}
              text="Add Consent From"
              iconType="plus"
            />
          </div>
        </div>
      </div>

      <div className="settings-section">
        <div className="section-name" />

        <ToolboxItemList
          tools={consentFormListView}
          type={'consent form'}
          addItem={addItem}
          changeCheckFormModal={changeCheckFormModal}
          changeSendAllCheckBox={changeSendAllCheckBox}
          closeAddModal={closeAddModal}
          formItemDelete={assessmentFormItemDelete}
          saveFormList={saveFormList}
          showAll={showAll}
        />
      </div>

      <div className="setting-title only-main-title border-top">
        <div className="sub-setting-title">
          <div className="action-btn-section">
            <ButtonNew
              type="link"
              text={'cancel'}
              onClick={() => setCancelValue(cancelValue + 1)}
            />

            <ButtonNew
              text={'Save'}
              onClick={() => saveProgramTool()}
              type="primary"
            />
          </div>
        </div>
      </div>
    </div>
  )
}

function mapStateToProps(state, ownProps) {
  const id = ownProps.match.params.id
  const programId = ownProps.match.params.pId
  const tenant =
    id && state.tenantReducer.length > 0
      ? getTenantsById(state.tenantReducer, id)
      : null
  const program =
    tenant && tenant.programs
      ? tenant.programs.find((program) => program.id === programId)
      : null

  // This is the global element!
  const [questionnairesList, sentimentsList, consentFormList, mediaList] =
    tenant
      ? [
          tenant.questionnairesList,
          tenant.sentimentsList,
          tenant.consentFormList
            ? tenant.consentFormList.map((cf) => {
                return { name: cf.title, id: cf.id }
              })
            : null,
          tenant.mediaList
            ? tenant.mediaList.map((mI) => {
                return { name: mI.title, id: mI.id }
              })
            : null,
        ]
      : [[], [], [], []]

  const [
    programQuestionnairesList,
    programSentimentList,
    programMediaList,
    programConsentFormList,
    sendAllQuestionnaires,
  ] = program
    ? [
        program.questionnaires,
        program.sentiments,
        program.mediaItems
          ? program.mediaItems.map((mI) => {
              return { name: mI.title, id: mI.id }
            })
          : null,
        program.consentForms
          ? program.consentForms.map((cf) => {
              return { name: cf.title, id: cf.id }
            })
          : null,
        program.sendAllQuestionnaires,
      ]
    : [[], [], [], [], false]

  return {
    program,
    questionnairesList,
    programQuestionnairesList,
    sentimentsList,
    programSentimentList,
    mediaList,
    programMediaList,
    consentFormList,
    programConsentFormList,
    sendAllQuestionnaires,
  }
}

const mapDispatchToProps = {
  loadTenantQuestionnaires: tenantsActions.loadTenantQuestionnaires,
  loadTenantSentiments: tenantsActions.loadTenantSentiments,
  loadTenantConsentForm: tenantsActions.loadTenantConsentForm,
  loadTenantMedia: tenantsActions.loadTenantMedia,
  loadProgramQuestionnaires: tenantsActions.loadProgramQuestionnaires,
  loadProgramSentiments: tenantsActions.loadProgramSentiments,
  loadProgramMedia: tenantsActions.loadProgramMedia,
  loadProgramConsentForms: tenantsActions.loadProgramConsentForms,
  saveProgramQuestionnaires: tenantsActions.saveProgramQuestionnaires,
  saveProgramToolBox: tenantsActions.saveProgramToolBox,
  saveProgramMedia: tenantsActions.saveProgramMedia,
}

Toolbox.propTypes = {
  loadTenantQuestionnaires: PropTypes.func.isRequired,
  loadTenantSentiments: PropTypes.func.isRequired,
  loadTenantConsentForm: PropTypes.func.isRequired,
  loadTenantMedia: PropTypes.func.isRequired,
  loadProgramQuestionnaires: PropTypes.func.isRequired,
  loadProgramSentiments: PropTypes.func.isRequired,
  loadProgramMedia: PropTypes.func.isRequired,
  loadProgramConsentForms: PropTypes.func.isRequired,
  saveProgramQuestionnaires: PropTypes.func.isRequired,
  saveProgramToolBox: PropTypes.func.isRequired,
  saveProgramMedia: PropTypes.func.isRequired,
  program: PropTypes.object.isRequired,
  questionnairesList: PropTypes.array,
  programQuestionnairesList: PropTypes.array,
  programSentimentList: PropTypes.array,
  programMediaList: PropTypes.array,
  mediaList: PropTypes.array,
  consentFormList: PropTypes.array,
  programConsentFormList: PropTypes.array,
  match: PropTypes.object.isRequired,
  sendAllQuestionnaires: PropTypes.bool,
}

export default connect(mapStateToProps, mapDispatchToProps)(Toolbox)
