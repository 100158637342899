import { redirectToAWSLoginPage } from '../components/utilities'

export function parseRes(res) {
  if (~[200, 204].indexOf(res.status)) {
    return res.json()
  }
  throw res
}
export function parseError(err, returnObject) {
  debugger
  return err.text().then((errorMessage) => {
    const errorObject = JSON.parse(errorMessage)

    if (returnObject) return errorObject

    if (errorObject.message) {
      if (errorObject.error === 'Unauthorized') {
        redirectToAWSLoginPage()
      }
      return errorObject.message
    }
    return errorMessage
  })
}
