import React, { useState } from 'react'
import NavBar from '../common/NavBar/NavBar'
import { Redirect, Route, Switch } from 'react-router-dom'
import Treatments from './Treatments'
import './style.less'
import Pending from './Pending'

function Users({ match }) {
  const [url] = useState(match.url)
  const [path] = useState(match.path)

  const bars = [
    {
      text: 'Treatments',
      link: path + '/treatments',
    },
    {
      text: 'Pending',
      link: path + '/pending',
    },
  ]

  return (
    <>
      <header className="users-header">
        <NavBar bars={bars} />
      </header>
      <Switch className="tenant-component-inside-body">
        <Route
          exact
          path={url + '/treatments'}
          render={(props) => <Treatments {...props} />}
        />
        <Route
          exact
          path={url + '/pending'}
          render={(props) => <Pending {...props} />}
        />
        <Redirect to={url + '/treatments'} />
      </Switch>
    </>
  )
}

export default Users
