import React from 'react'
import './questionnaire-list-style.less'
const QuestionnaireListHeader = () => {
  return (
    <div className="questionnaire-list-item questionnaire-header">
      <div className="list-questionnaire-header-icon" />
      <div className="list-questionnaire-name">Questionnaire name</div>
      <div className="list-questionnaire-type">Type</div>
      <div className="list-questionnaire-options-btn" />
    </div>
  )
}

export default QuestionnaireListHeader
