import React, { useState } from 'react'
import List from '../../common/List/List'
import PropTypes from 'prop-types'
import './program-style.less'
import EditCreateElementDialog from '../../GlobalComponent/EditCreateElement/EditCreateElementDialog'
import EmptyContent from '../../GlobalComponent/EmptyContent/emptyContent'
import IconButton from '../../common/IconButton/IconButton'

function Programs({ programs, itemClick, saveProgram }) {
  const [createProgram, setCreateProgram] = useState({ isOpen: false })

  const saveProgramLocal = (program, cb) => {
    let elementToSave = {
      name: program.name,
      description: program.description,
      logo: program.logo,
    }
    saveProgram(elementToSave, (res) => {
      if (res.ok) {
        setCreateProgram({ isOpen: false })
        return cb({ ok: true })
      }
      return cb({ ok: false })
    })
  }
  const createProgramClick = () => {
    setCreateProgram({ isOpen: true })
  }
  const closeCreateProgram = () => {
    setCreateProgram({ isOpen: false })
  }
  return (
    <div className="tenant-page-program-page">
      <div className="program-list-header">
        <EditCreateElementDialog
          isOpen={createProgram.isOpen}
          saveClick={saveProgramLocal}
          onClose={closeCreateProgram}
          type="program"
          actionType="create"
        />
        <IconButton
          onClick={() => {
            setCreateProgram({ isOpen: true })
          }}
          type={'plus'}
          labelText={'Create new Program'}
        />
      </div>
      {programs && programs.length > 0 && (
        <List
          list={programs}
          titleText={'Program Name'}
          itemClickFunc={itemClick}
        />
      )}
      {(!programs || programs.length === 0) && (
        <EmptyContent type="program" onClick={createProgramClick} />
      )}
    </div>
  )
}

Programs.propTypes = {
  programs: PropTypes.array.isRequired,
  itemClick: PropTypes.func.isRequired,
}

export default Programs
