// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.options-menu-container {
  cursor: pointer;
}
.options-menu-container.opened {
  display: block !important;
}
.options-menu {
  position: absolute;
  border: 1px solid #dadcdf;
  border-radius: 3px;
  background-color: #ffffff;
  box-shadow: 0 4px 8px 0 rgba(114, 114, 114, 0.5);
  z-index: 2;
  padding: 5px 0;
}

.option-item {
  direction: ltr;
  white-space: nowrap;
  padding: 8px 20px 8px 10px;
  text-align: left;
}

.option-item:hover {
  background: #f1f3f4;
}
.option-item-border {
  padding: 10px;
  border-bottom: 1px solid #dadcdf;
}
span.option-item-end-icon {
  float: right;
}
.right-direction {
  right: auto;
}
.left-direction {
  right: 40px;
  top: 0;
}
.top-direction {
  bottom: 28px;
}
span.option-item-icon {
  width: 24px;
  height: 24px;
  display: inline-block;
}
`, "",{"version":3,"sources":["webpack://./src/components/common/OptionsMenu/style.css"],"names":[],"mappings":"AAAA;EACE,eAAe;AACjB;AACA;EACE,yBAAyB;AAC3B;AACA;EACE,kBAAkB;EAClB,yBAAyB;EACzB,kBAAkB;EAClB,yBAAyB;EACzB,gDAAgD;EAChD,UAAU;EACV,cAAc;AAChB;;AAEA;EACE,cAAc;EACd,mBAAmB;EACnB,0BAA0B;EAC1B,gBAAgB;AAClB;;AAEA;EACE,mBAAmB;AACrB;AACA;EACE,aAAa;EACb,gCAAgC;AAClC;AACA;EACE,YAAY;AACd;AACA;EACE,WAAW;AACb;AACA;EACE,WAAW;EACX,MAAM;AACR;AACA;EACE,YAAY;AACd;AACA;EACE,WAAW;EACX,YAAY;EACZ,qBAAqB;AACvB","sourcesContent":[".options-menu-container {\n  cursor: pointer;\n}\n.options-menu-container.opened {\n  display: block !important;\n}\n.options-menu {\n  position: absolute;\n  border: 1px solid #dadcdf;\n  border-radius: 3px;\n  background-color: #ffffff;\n  box-shadow: 0 4px 8px 0 rgba(114, 114, 114, 0.5);\n  z-index: 2;\n  padding: 5px 0;\n}\n\n.option-item {\n  direction: ltr;\n  white-space: nowrap;\n  padding: 8px 20px 8px 10px;\n  text-align: left;\n}\n\n.option-item:hover {\n  background: #f1f3f4;\n}\n.option-item-border {\n  padding: 10px;\n  border-bottom: 1px solid #dadcdf;\n}\nspan.option-item-end-icon {\n  float: right;\n}\n.right-direction {\n  right: auto;\n}\n.left-direction {\n  right: 40px;\n  top: 0;\n}\n.top-direction {\n  bottom: 28px;\n}\nspan.option-item-icon {\n  width: 24px;\n  height: 24px;\n  display: inline-block;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
