import { combineReducers } from 'redux'
import tenantReducer from './tenantReducer'
import toolsReducer from './toolsReducer'
import globalReducer from './globalReducer'

const rootReducer = combineReducers({
  tenantReducer,
  toolsReducer,
  globalReducer,
})

export default rootReducer
