import React from 'react'
import * as constants from '../constants'
import EscalationWindow from './EscalationWindow'
import InputError from '../../../../../../common/InputError/InputError'
const regexMovement = new RegExp(/^(?=.+)(?:[1-9.]*|0)?(?:\.\d+)?$/)
const regexPoint = new RegExp(/^[1-9]*|0?$/)

const unchangedLocation = ({
  item,
  alertRulesArray,
  setAlertRulesArray,
  error,
}) => {
  const changeTimeWindow = (e, windowName, field = 'unit') => {
    const otherField = field === 'unit' ? 'value' : 'unit'

    setAlertRulesArray(
      alertRulesArray.map((a) => {
        if (a.id !== item.id) return a

        return Object.assign({}, a, {
          [windowName]: {
            [field]: e.target.value,
            [otherField]: a[windowName][otherField],
          },
        })
      }),
    )
  }

  const changeField = (e, field) => {
    if (!field) return
    setAlertRulesArray(
      alertRulesArray.map((a) => {
        if (a.id === item.id) {
          switch (field) {
            case 'movementRadius': {
              if (!e.target.value) {
                return Object.assign({}, a, {
                  movementRadius: '',
                })
              }
              if (!regexMovement.test(e.target.value)) {
                return a
              }
              if (
                e.target.value.includes('.') &&
                e.target.value.split('.') &&
                e.target.value.split('.').length > 2
              ) {
                return a
              }
              return Object.assign({}, a, {
                movementRadius: e.target.value,
              })
            }
            case 'frequencyDataPoint': {
              if (!e.target.value) {
                return Object.assign({}, a, {
                  frequencyDataPoint: '',
                })
              }
              if (!regexPoint.test(e.target.value)) {
                return a
              }
              return Object.assign({}, a, {
                frequencyDataPoint: e.target.value,
              })
            }
            default:
              return a
          }
        }
        return a
      }),
    )
  }
  return (
    <>
      <div className="rule-filed">
        <div className="rule-label">Time window</div>
        <div className="rule-input">
          <select
            className="small-input"
            disabled={false}
            value={item.timeWindow.unit || ''}
            onChange={(e) => changeTimeWindow(e, 'timeWindow', 'unit')}
          >
            <option value="" disabled hidden>
              Select unit
            </option>
            {constants.TIME_UNIT.filter((unit) => unit.value === 'days').map(
              (unit) => {
                return (
                  <option
                    key={'location_time_window_' + item.id + '_' + unit.value}
                    value={unit.value}
                  >
                    {unit.text}
                  </option>
                )
              },
            )}
          </select>
          <input
            type="number"
            min="0"
            className="small-input not-first"
            placeholder="Value"
            value={item.timeWindow.value}
            onChange={(e) => changeTimeWindow(e, 'timeWindow', 'value')}
          />
        </div>
      </div>
      <InputError
        text={constants.FAILED_ERROR_TEXT}
        showError={error && error.timeWindow}
      />
      <div className="rule-separate-line" />
      <div className="rule-filed">
        <div className="rule-label">Movement radius</div>
        <div className="rule-input">
          <input
            type="text"
            placeholder="Value"
            value={item.movementRadius}
            onChange={(e) => changeField(e, 'movementRadius')}
          />
        </div>
      </div>
      <InputError
        text={constants.FAILED_ERROR_TEXT}
        showError={error && error.radius}
      />
      <EscalationWindow item={item} changeTimeWindow={changeTimeWindow} />
      <InputError
        text={constants.FAILED_ERROR_TEXT}
        showError={error && error.escalationWindow}
      />
      <div className="rule-filed">
        <div className="rule-label subject-label">Frequency</div>
      </div>
      <div className="rule-filed">
        <div className="rule-label">Number of data points</div>
        <div className="rule-input">
          <input
            type="number"
            min="0"
            placeholder="Enter value"
            value={item.frequencyDataPoint}
            onChange={(e) => changeField(e, 'frequencyDataPoint')}
          />
        </div>
      </div>
      <InputError
        text={constants.FAILED_ERROR_TEXT}
        showError={error && error.locationsFrequencyNumber}
      />
      <div className="rule-filed">
        <div className="rule-label">Time window</div>
        <div className="rule-input">
          <select
            className="small-input"
            disabled={false}
            value={item.frequencyTimeWindow.unit || ''}
            onChange={(e) => changeTimeWindow(e, 'frequencyTimeWindow', 'unit')}
          >
            <option value="" disabled hidden>
              Select unit
            </option>
            {constants.TIME_UNIT.map((unit) => {
              return (
                <option
                  key={
                    'location_time_frequency_window_' +
                    item.id +
                    '_' +
                    unit.value
                  }
                  value={unit.value}
                >
                  {unit.text}
                </option>
              )
            })}
          </select>
          <input
            type="number"
            min="0"
            className="small-input not-first"
            placeholder="Value"
            value={item.frequencyTimeWindow.value}
            onChange={(e) =>
              changeTimeWindow(e, 'frequencyTimeWindow', 'value')
            }
          />
        </div>
      </div>
      <InputError
        text={constants.FAILED_ERROR_TEXT}
        showError={error && error.locationsFrequencyWindow} // locationsFrequencyWindow
      />
    </>
  )
}
export default unchangedLocation
