import React, { useState } from 'react'
import PropTypes from 'prop-types'
import IconButton from '../../common/IconButton/IconButton'
import ListDynamic from '../../common/List/ListDynamic'
import Header from './ConsentFormListHeader'
import ConsentFormListItem from './ConsentFormListItem'
import CreateEditConsentForms from './createConsentForm/CreateEditConsentForm'
import './consent-form.less'

function ConsentForms({
  history,
  match,
  consentFormsList,
  showNotImplemented,
  addUserMessage,
  saveNewConsentForm,
  saveEditConsentForm,
  tenantId,
  section,
}) {
  const initConsentFormModal = { isOpen: false }
  const [createConsentFormsModal, setCreateConsentFormsModal] =
    useState(initConsentFormModal)
  const [consentFormEditElement, setConsentFormEditElement] = useState({
    isOpen: false,
    element: {},
  })
  const createNewConsentForms = () => {
    setCreateConsentFormsModal(
      Object.assign({}, createConsentFormsModal, { isOpen: true }),
    )
  }
  const saveEditConsentFormLocal = (item) => {
    saveEditConsentForm(item, (res) => {
      if (res.ok) {
        closeModal('edit')
      }
    })
  }
  const saveNewConsentFormLocal = (item, cb) => {
    saveNewConsentForm(item, (res) => {
      if (res.ok) {
        closeModal('create')
      }
    })
  }
  const consentFormItemClick = (id) => {
    let selectedConsentForm =
      consentFormsList && consentFormsList.length
        ? consentFormsList.find((iter) => iter.id === id)
        : null
    if (!selectedConsentForm) return
    if (
      (section === 'tenants' &&
        selectedConsentForm.appData &&
        selectedConsentForm.appData.tenantId) ||
      section === 'tools'
    ) {
      let editItem = {
        title: selectedConsentForm.title,
        aliasName: selectedConsentForm.aliasName,
        bodyFormat: selectedConsentForm.bodyFormat,
        body: selectedConsentForm.body,
        id: selectedConsentForm.id,
      }

      setConsentFormEditElement({ isOpen: true, element: editItem })
      let newUrl =
        section === 'tenants'
          ? '/home/tenants/' + tenantId + '/tools/consent_forms/' + id
          : '/home/tools/consent_forms/' + id
      history.push(newUrl)

      /**
       *
       * */
    } else {
      addUserMessage(
        'warning',
        "This element can't be edited from this section",
      )
    }
  }
  const closeModal = (operation) => {
    if (operation === 'edit') {
      let newUrl =
        section === 'tenants'
          ? '/home/tenants/' + tenantId + '/tools/consent_forms'
          : '/home/tools/consent_forms'
      history.push(newUrl)
      setConsentFormEditElement({ isOpen: false, element: {} })
    } else {
      setCreateConsentFormsModal(initConsentFormModal)
    }
  }
  // const privacy = item.appData && item.appData.tenantId ? "Private" : "Global";
  const list =
    consentFormsList && consentFormsList.length
      ? consentFormsList.map((item, index) => {
          return {
            id: item.id,
            name: item.aliasName
              ? item.title + '(' + item.aliasName + ')'
              : item.title,
            privacy:
              item.appData && item.appData.tenantId ? 'Private' : 'Global',
          }
        })
      : []

  return (
    <div className="consent-form-component">
      <div className="consent-form-component-header">
        <CreateEditConsentForms
          isOpen={createConsentFormsModal.isOpen}
          closeModal={() => closeModal('create')}
          addUserMessage={addUserMessage}
          saveConsentForm={saveNewConsentFormLocal}
          type="create"
        />
        <IconButton
          onClick={createNewConsentForms}
          type={'plus'}
          labelText="Create new Consent form"
        />
      </div>
      <div className="consent-form-component-body">
        <CreateEditConsentForms
          isOpen={consentFormEditElement.isOpen}
          closeModal={() => closeModal('edit')}
          addUserMessage={addUserMessage}
          saveConsentForm={saveEditConsentFormLocal}
          element={consentFormEditElement.element}
          type="edit"
        />
        <ListDynamic
          list={list}
          HeaderComponent={Header}
          ItemComponent={ConsentFormListItem}
          ItemClickFunc={consentFormItemClick}
        />
      </div>
    </div>
  )
}

ConsentForms.propTypes = {
  consentFormsList: PropTypes.array.isRequired,
  showNotImplemented: PropTypes.func.isRequired,
  addUserMessage: PropTypes.func.isRequired,
  section: PropTypes.string.isRequired, // tenants/tools
  tenantId: PropTypes.string,
}

export default ConsentForms
