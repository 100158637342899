import React, { useRef } from 'react'
import ButtonNew from '../../../common/Button/ButtonNew'
import SearchTextOptions from '../../../common/SearchTextOptions/SearchTextOptions'
import InputError from '../../../common/InputError/InputError'
import CameraPlaceHolder from '../../../../image/icon_camera_placeholder.svg'
import PropTypes from 'prop-types'

const ArticleContent = ({ articleMediaItem, setArticleMediaItem, error }) => {
  const pictureBtnStyle = {
    marginLeft: '10px',
  }
  const fileImageInput = useRef(null)

  const uploadImageClick = () => {
    fileImageInput.current.click()
  }

  const handleImageChange = (event) => {
    event.preventDefault()

    let reader = new FileReader()
    let file = event.target.files[0]

    reader.onloadend = () => {
      setArticleMediaItem(
        Object.assign({}, articleMediaItem, {
          image: {
            file: file,
            imagePreviewUrl: reader.result,
          },
        }),
      )
    }

    reader.readAsDataURL(file)
  }

  const inputTagOnChange = (event) => {
    const tagsSearch = event.target.value
    setArticleMediaItem(Object.assign({}, articleMediaItem, { tagsSearch }))
  }

  const deleteImageClick = () => {
    fileImageInput.current.value = ''
    setArticleMediaItem(
      Object.assign({}, articleMediaItem, {
        image: {
          file: '',
          imagePreviewUrl: '',
        },
      }),
    )
  }
  const pictureTypeHasChange = (event) => {
    const changeParams = { imageType: event.target.name }
    if (changeParams.imageType === 'actual_picture') {
      setArticleMediaItem(
        Object.assign({}, articleMediaItem, {
          imageType: changeParams.imageType,
          imageUrl: '',
        }),
      )
    } else if (changeParams.imageType === 'url_picture') {
      setArticleMediaItem(
        Object.assign({}, articleMediaItem, {
          imageType: changeParams.imageType,
          image: {
            file: '',
            imagePreviewUrl: '',
          },
        }),
      )
    }
  }
  // let { imagePreviewUrl } = imagePreview;
  let { imagePreviewUrl } = articleMediaItem.image

  const ImageElement = () => {
    return imagePreviewUrl ? (
      <img src={imagePreviewUrl} />
    ) : (
      <CameraPlaceHolder />
    )
  }
  return (
    <>
      <div className="modal-section">
        <div className="category-section">Title</div>
        <input
          type="text"
          value={articleMediaItem.title}
          className="details-section"
          placeholder="Enter Title"
          onChange={(event) => {
            setArticleMediaItem(
              Object.assign({}, articleMediaItem, {
                title: event.target.value,
              }),
            )
          }}
        />
      </div>
      <InputError
        text={error && error.title ? error.title : ''}
        showError={error && error.title}
      />
      <div className="modal-section">
        <div className="category-section">Picture</div>
        <div className="details-section">
          <div className="radio-group">
            <div className="radio-element">
              <input
                type="radio"
                name="url_picture"
                value="url_picture"
                checked={articleMediaItem.imageType === 'url_picture'}
                onChange={pictureTypeHasChange}
              />
              <label />
              <div className="radio-element-body">
                <input
                  type="text"
                  value={articleMediaItem.imageUrl}
                  className="details-section"
                  placeholder="Enter Picture Url"
                  disabled={articleMediaItem.imageType !== 'url_picture'}
                  onChange={(event) =>
                    setArticleMediaItem(
                      Object.assign({}, articleMediaItem, {
                        imageUrl: event.target.value,
                      }),
                    )
                  }
                />
              </div>
            </div>
            <InputError
              text={error && error.picture ? error.picture : ''}
              showError={error && error.picture}
            />
            <div className="radio-element">
              <input
                type="radio"
                name="actual_picture"
                value="actual_picture"
                disabled={true}
                checked={articleMediaItem.imageType === 'actual_picture'}
                onChange={pictureTypeHasChange}
              />
              <label />
              <div className="radio-element-body">
                <ImageElement />
                <input
                  onChange={handleImageChange}
                  className="not-show-input"
                  ref={fileImageInput}
                  type="file"
                  accept="image/x-png,image/jpeg"
                />
                <div>
                  <ButtonNew
                    style={pictureBtnStyle}
                    text="Upload Image"
                    type="secondary"
                    disable={articleMediaItem.imageType !== 'actual_picture'}
                    onClick={uploadImageClick}
                  />
                  <ButtonNew
                    style={pictureBtnStyle}
                    text="Delete"
                    type="link"
                    disable={articleMediaItem.imageType !== 'actual_picture'}
                    onClick={deleteImageClick}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="modal-section">
        <div className="category-section">Tags</div>
        <div className="details-section">
          <SearchTextOptions
            placeholder="Start typing a tag… "
            deleteItem={(item) => {
              setArticleMediaItem(
                Object.assign({}, articleMediaItem, {
                  tags:
                    articleMediaItem.tags && articleMediaItem.tags.length
                      ? articleMediaItem.tags.filter(
                          (iterate) => item !== iterate,
                        )
                      : [],
                  tagsSearch: '',
                }),
              )
            }}
            addItem={(item) => {
              setArticleMediaItem(
                Object.assign({}, articleMediaItem, {
                  tags: articleMediaItem.tags.includes(item)
                    ? articleMediaItem.tags
                    : articleMediaItem.tags.concat(item),
                  tagsSearch: '',
                }),
              )
            }}
            inputOnChange={inputTagOnChange}
            inputValue={articleMediaItem.tagsSearch}
            optionsArray={articleMediaItem.tagOptions}
            valueArray={articleMediaItem.tags}
          />
        </div>
      </div>
      <div className="modal-section">
        <div className="category-section">Content URL</div>
        <input
          type="text"
          value={articleMediaItem.contentUrl}
          className="details-section"
          placeholder="Enter URL"
          onChange={(event) => {
            setArticleMediaItem(
              Object.assign({}, articleMediaItem, {
                contentUrl: event.target.value,
              }),
            )
          }}
        />
      </div>
      <InputError
        text={error && error.contentUrl ? error.contentUrl : ''}
        showError={error && error.contentUrl}
      />
    </>
  )
}

ArticleContent.propTypes = {
  articleMediaItem: PropTypes.object.isRequired,
  setArticleMediaItem: PropTypes.func.isRequired,
  error: PropTypes.object,
}

export default ArticleContent
