import constant from './constant'
import { parseRes, parseError } from './parseResponse'
import * as storageConfig from './storageConfig'

const baseUrl = `${constant.baseUrl}/api/users` // http://localhost:9000/api/tenant";

export function getTreatments({
  searchText = '',
  pageSize = 30,
  pageNumber = 1,
}) {
  return new Promise((resolve, reject) => {
    const url = `${baseUrl}/treatments?pageSize=${pageSize}&pageNumber=${pageNumber}&searchText=${searchText}`

    fetch(url, {
      headers: {
        authorization: storageConfig.getToken(),
        'Content-Type': 'application/json',
      },
    })
      .then(parseRes)
      .then(function (data) {
        resolve(data)
      })
      .catch((err) => {
        parseError(err).then((errMessage) => {
          reject(errMessage)
        })
      })
  })
}

export function getTreatmentsCount({
  searchText = '',
  pageSize = 30,
  pageNumber = 1,
}) {
  return new Promise((resolve, reject) => {
    fetch(
      `${baseUrl}/treatments/count?pageSize=${pageSize}&pageNumber=${pageNumber}&searchText=${searchText}`,
      {
        headers: {
          authorization: storageConfig.getToken(),
          'Content-Type': 'application/json',
        },
      },
    )
      .then(parseRes)
      .then(function (data) {
        resolve(data)
      })
      .catch((err) => {
        parseError(err).then((errMessage) => {
          reject(errMessage)
        })
      })
  })
}

export function getTreatmentProfile(id) {
  return new Promise((resolve, reject) => {
    fetch(`${baseUrl}/treatments/${id}/profile`, {
      headers: {
        authorization: storageConfig.getToken(),
        'Content-Type': 'application/json',
      },
    })
      .then(parseRes)
      .then(function (data) {
        resolve(data)
      })
      .catch((err) => {
        parseError(err).then((errMessage) => {
          reject(errMessage)
        })
      })
  })
}

export function editProfile(id, data) {
  return new Promise((resolve, reject) => {
    fetch(`${baseUrl}/treatments/${id}/profile`, {
      method: 'PUT',
      headers: {
        authorization: storageConfig.getToken(),
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    })
      .then(parseRes)
      .then(function (data) {
        resolve(data)
      })
      .catch((err) => {
        parseError(err).then((errMessage) => {
          reject(errMessage)
        })
      })
  })
}

export const toggleIntegration = (id, isIntegrationActive) => {
  return fetch(
    `${constant.baseUrl}/api/integration/treatments/${id}/changeStatus`,
    {
      method: 'POST',
      headers: {
        Authorization: storageConfig.getToken(),
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ isIntegrationActive }),
    },
  )
    .then(parseRes)
    .catch((err) => {})
}

export const changeDrChronoId = (id, drChronoId) => {
  return fetch(
    `${constant.baseUrl}/api/integration/treatments/${id}/changeLink`,
    {
      method: 'POST',
      headers: {
        Authorization: storageConfig.getToken(),
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ drChronoId }),
    },
  )
    .then(parseRes)
    .catch((err) => {})
}
/// ////////////////////////////////////////////////////
export const dischargePatient = (id, data) => {
  return fetch(`${baseUrl}/${id}/discharge`, {
    method: 'POST',
    headers: {
      Authorization: storageConfig.getToken(),
      'Content-Type': 'application/json',
    },
    data: JSON.stringify(data),
  })
    .then((resp) => resp.text())
    .catch((err) => {})
}

export const changeProgram = (id) => {
  return fetch(`${baseUrl}/${id}/program`, {
    method: 'POST',
    headers: {
      Authorization: storageConfig.getToken(),
      'Content-Type': 'application/json',
    },
  })
    .then((resp) => resp.text())
    .catch((err) => {})
}

export function getPending({ searchText = '', pageSize = 30, pageNumber = 1 }) {
  return new Promise((resolve, reject) => {
    const url = `${baseUrl}/pending?pageSize=${pageSize}&pageNumber=${pageNumber}&searchText=${searchText}`

    fetch(url, {
      headers: {
        authorization: storageConfig.getToken(),
        'Content-Type': 'application/json',
      },
    })
      .then(parseRes)
      .then(function (data) {
        resolve(data)
      })
      .catch((err) => {
        parseError(err).then((errMessage) => {
          reject(errMessage)
        })
      })
  })
}

export function getPendingCount({ searchText = '' }) {
  return new Promise((resolve, reject) => {
    const url = `${baseUrl}/pending/count?searchText=${searchText}`

    fetch(url, {
      headers: {
        authorization: storageConfig.getToken(),
        'Content-Type': 'application/json',
      },
    })
      .then(parseRes)
      .then(function (data) {
        resolve(data)
      })
      .catch((err) => {
        parseError(err).then((errMessage) => {
          reject(errMessage)
        })
      })
  })
}

export function removePending(userId) {
  return new Promise((resolve, reject) => {
    const url = `${baseUrl}/pending/${userId}`

    fetch(url, {
      method: 'DELETE',
      headers: {
        authorization: storageConfig.getToken(),
        'Content-Type': 'application/json',
      },
    })
      .then(parseRes)
      .then(function (data) {
        resolve(data)
      })
      .catch((err) => {
        parseError(err).then((errMessage) => {
          reject(errMessage)
        })
      })
  })
}
