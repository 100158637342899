import React from 'react'
import PropTypes from 'prop-types'
import List from '../common/List/List'
import IconButton from '../common/IconButton/IconButton'
const TenantsList = ({ list, tenantClick, showNotImplemented }) => {
  const addProgram = () => {
    if (showNotImplemented) {
      showNotImplemented()
    }
  }
  return (
    <div className="tenants-list-page">
      <div className="tenants-list-page-body">
        <List
          list={list}
          itemClickFunc={tenantClick}
          titleText={'tenant name'}
        />
      </div>
    </div>
  )
}

TenantsList.propTypes = {
  list: PropTypes.array.isRequired,
  tenantClick: PropTypes.func.isRequired,
}

export default TenantsList
