import React from 'react'
import ButtonNew from '../../common/Button/ButtonNew'

const Login = ({
  username,
  setUserName,
  password,
  setPassword,
  onLogin,
  forgotClick,
  loginError,
}) => {
  return (
    <form>
      <div className="input-field">
        <div className="input-name-label">username</div>
        <input
          type="text"
          placeholder="Username"
          value={username}
          onChange={(event) => {
            setUserName(event.target.value)
          }}
        />
      </div>
      <div className="input-field">
        <div className="input-name-label">password</div>
        <input
          placeholder="Password"
          type="password"
          value={password}
          onChange={(event) => {
            setPassword(event.target.value)
          }}
        />
      </div>
      {loginError && <div className="error-text">{loginError}</div>}
      <ButtonNew
        text="Login"
        type="primary"
        onClick={onLogin}
        disable={!username.length || !password.length}
      />
      <ButtonNew
        text="Forgot your password?"
        type="link"
        onClick={forgotClick}
      />
    </form>
  )
}

export default Login
