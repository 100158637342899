import React, { useState } from 'react'
import { NavLink, Route } from 'react-router-dom'
import ButtonNew from '../../common/Button/ButtonNew'
import PropTypes from 'prop-types'
import '../tenants-list-header-style.less'
import EditCreateElementDialog from '../../GlobalComponent/EditCreateElement/EditCreateElementDialog'

function Header({
  tenants,
  pathname,
  notImplementedFunc,
  showUserMessage,
  saveTenant,
}) {
  const [createTenant, setCreateTenant] = useState({
    isOpen: false,
  })

  const createClick = () => {
    /**
     * Not implemented!
     * **/
    // notImplementedFunc();
    setCreateTenant(Object.assign({}, createTenant, { isOpen: true }))
  }
  const closeNewTenantModal = () => {
    setCreateTenant(Object.assign({}, createTenant, { isOpen: false }))
  }
  const saveNewTenant = (tenant, cb) => {
    let elementToSave = {
      name: tenant.name,
      description: tenant.description,
      logo: tenant.logo,
    }
    saveTenant(elementToSave, (res) => {
      if (res.ok) {
        setCreateTenant({ isOpen: false })
        return cb({ ok: true })
      }
      return cb({ ok: false })
    })
  }

  const HeaderTextTenant = () => {
    /**
     * If enter here so is in route home/tenants/558afc069972eb6550e04dd7/programs
     * */
    if (pathname.split('/').length < 3) {
      return <div>Tenants</div>
    }
    const tenantId = pathname.split('/')[2]
    const tenantSelected = tenants.find((tenant) => tenant.id === tenantId)
    const activeStyle = { color: 'inherit', textDecoration: 'none' }
    const sectionName =
      pathname.length && pathname.split('/').length > 3
        ? pathname.split('/')[3]
        : null
    // sectionName = tools/programs
    if (!tenantSelected) {
      return <div />
    }
    switch (sectionName) {
      case 'programs': {
        const programId =
          pathname.length && pathname.split('/').length > 4
            ? pathname.split('/')[4]
            : null

        if (programId && tenantSelected.programs) {
          const programsName = tenantSelected.programs.find(
            (program) => program.id === programId,
          ).name
          return (
            <div className="header-route">
              <NavLink to="/home/tenants" activeStyle={activeStyle}>
                Tenants
              </NavLink>
              <div>{'>'}</div>
              <NavLink
                to={'/home/tenants/' + tenantId + '/programs'}
                activeStyle={activeStyle}
              >
                {tenantSelected.name}
              </NavLink>
              <div>{'>'}</div>
              <div>{programsName}</div>
            </div>
          )
        }
        return (
          <div className="header-route">
            <NavLink to="/home/tenants" activeStyle={activeStyle}>
              Tenants
            </NavLink>
            <div>{'>'}</div>
            <div>{tenantSelected.name}</div>
          </div>
        )
      }
      case 'tools': {
        const toolName =
          pathname.length && pathname.split('/').length > 4
            ? pathname.split('/')[4]
            : null
        if (toolName) {
          return (
            <div className="header-route">
              <NavLink to="/home/tenants" activeStyle={activeStyle}>
                Tenants
              </NavLink>
              <div>{'>'}</div>
              <NavLink
                to={'/home/tenants/' + tenantId + '/tools'}
                activeStyle={activeStyle}
              >
                {tenantSelected.name}
              </NavLink>
              <div>{'>'}</div>
              <div>{toolName}</div>
            </div>
          )
        } else {
          return (
            <div className="header-route">
              <NavLink to="/home/tenants" activeStyle={activeStyle}>
                Tenants
              </NavLink>
              <div>{'>'}</div>
              <div>{tenantSelected.name}</div>
            </div>
          )
        }

        // if (tenantSelected) {
        // }
      }
    }
    return <div />
  }

  return (
    <div className="tenants-list-header">
      <HeaderTextTenant />
      {/*<Switch>
        <Route exact path="/home/tenants" component={HeaderTextTenant} />
        <Route
          exact
          path="/home/tenants/:id/programs/:pId"
          component={HeaderTextTenant}
        />
        <Route path="/home/tenants/:id" component={HeaderTextTenant} />

        <Route component={HeaderTextTenant} />
      </Switch>*/}

      <Route
        exact
        path="/(|home|home/tenants)"
        render={() => (
          <>
            <EditCreateElementDialog
              isOpen={createTenant.isOpen}
              saveClick={saveNewTenant}
              onClose={closeNewTenantModal}
              showUserMessage={showUserMessage}
              type="tenant"
              actionType="create"
            />
            <ButtonNew
              text={'Create new Tenant'}
              type="primary"
              onClick={createClick}
            />
          </>
        )}
      />
    </div>
  )
}

Header.propTypes = {
  pathname: PropTypes.string.isRequired,
  tenants: PropTypes.array.isRequired,
  notImplementedFunc: PropTypes.func,
  showUserMessage: PropTypes.func.isRequired,
  saveTenant: PropTypes.func.isRequired,
}

export default Header
