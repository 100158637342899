import React from 'react'
import Modal from 'react-modal'
import ButtonNew from '../Button/ButtonNew'
import IconButton from '../IconButton/IconButton'
import './style.css'
import PropTypes from 'prop-types'

export default function Popup({
  title,
  buttons,
  onClosed,
  hideCloseBtn,
  children,
  bodyClass,
  options = {},
}) {
  const customStyles = {
    content: {
      // top: "50%",
      // left: "50%",
      position: 'initial',
      top: 'auto',
      left: 'auto',
      right: 'auto',
      bottom: 'auto',
      padding: '0px',
      borderRadius: '5px',
      minWidth: '350px',
      overflow: 'initial', //be carefull. Set it to "auto" cause bug when open <Select /> into.
      opacity: 1,
      zIndex: 3,
    },
    overlay: {
      background: 'rgba(20, 44, 60, 0.5)',
      zIndex: 2,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
  }

  return (
    <div>
      <Modal
        isOpen={true}
        onAfterClose={onClosed}
        style={customStyles}
        ariaHideApp={false}
      >
        <div className="popup-header">
          <div className="popup-header-content">{title}</div>
          <div className="popup-header-icon">
            {!hideCloseBtn && (
              <IconButton
                onClick={() => {
                  onClosed && onClosed()
                }}
                type="close"
              />
            )}
          </div>
        </div>
        <div className={bodyClass || 'popup-body'}>{children}</div>
        {!options.hideFooter && (
          <div className="popup-footer">
            {buttons &&
              buttons.map((b, index) => {
                return (
                  <ButtonNew
                    key={index}
                    id={b.id}
                    text={b.text}
                    disable={b.disable}
                    type={b.type || 'primary'}
                    onClick={b.onClick}
                  ></ButtonNew>
                )
              })}
          </div>
        )}
      </Modal>
    </div>
  )
}

Popup.propTypes = {
  title: PropTypes.string,
  buttons: PropTypes.array,
  type: PropTypes.string,
  onClosed: PropTypes.func,
  hideCloseBtn: PropTypes.bool,
  bodyClass: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
    .isRequired,
  options: PropTypes.object,
}
