import React from 'react'
import { NavLink } from 'react-router-dom'
import MenuItem from '../common/MenuItem/MenuItem'

const StatusBar = () => {
  const activeStyle = {
    fontWeight: 'bold',
  }
  return (
    <nav className="body-status">
      <NavLink to="/home/tenants" activeStyle={activeStyle}>
        <MenuItem text="Tenants" type="tenant" />
      </NavLink>
      <NavLink to="/home/tools" activeStyle={activeStyle}>
        <MenuItem text="Tools" type="tools" />
      </NavLink>
      <NavLink to="/home/users" activeStyle={activeStyle}>
        <MenuItem text="Users" type="users" />
      </NavLink>
    </nav>
  )
}

export default StatusBar
