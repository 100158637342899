import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import * as tenantsActions from '../../../../../../redux/actions/tenantsActions'
import { connect } from 'react-redux'
import IconButton from '../../../../../common/IconButton/IconButton'
import InputFreeText from '../../../../../common/Input/InputFreeText'
import AddInputField from '../../../../../common/AddInputField/AddInputField'
import '../tab-page-style.less'
import { getTenantsById } from '../../../../../utilities'
import ButtonNew from '../../../../../common/Button/ButtonNew'
import './style.css'
import { addUserMessage } from '../../../../../../redux/actions/globalAction'
import ReplaceValuesPopup from './ReplaceValuesPopup'
import { CONFIGURATION_STRINGS } from '../../../../../utilities/enum'
import { getInitialData, getServerObject, hasEmptyField } from './domain'
import InsuranceGrid from './InsuranceGrid'

function Configuration({
  configuration,
  match,
  loadConfiguration,
  editConfiguration,
  replaceConfiguration,
  addUserMessage,
}) {
  const tenantId = match.params.id
  const programId = match.params.pId

  const [data, setData] = useState()
  const [focused, setFocused] = useState()
  const [displayInvalid, setDisplayInvalid] = useState(false)
  const [replacedValues, setReplacedValues] = useState()

  const handleConfigEditError = (res) => {
    if (res.message === 'replace_provider_config_values' && res.details) {
      const { valuesToReplace } = res.details

      if (valuesToReplace && valuesToReplace.length) {
        setReplacedValues(
          valuesToReplace.map((val) => {
            const insurancePlanState = val.field === 'insurancePlans'
            const newValue = !insurancePlanState
              ? data[val.field].find((a) => a.originalValue === val.value).value
              : data[val.field].find(
                  (a) =>
                    a.originalValue.name === val.value.name &&
                    a.originalValue.code === val.value.code,
                ).value

            return {
              ...val,
              newValue,
            }
          }),
        )
      }
    }
  }

  const saveChanges = ({ newData }) => {
    const dataToSave = newData || data

    if (hasEmptyField(dataToSave, setDisplayInvalid)) {
      addUserMessage('error', 'Please fill empty fields.')
    } else {
      const serverData = getServerObject(dataToSave)

      editConfiguration(tenantId, programId, serverData, handleConfigEditError)
    }
  }

  useEffect(() => {
    if (!configuration) {
      loadConfiguration(tenantId, programId)
    } else {
      const _data = getInitialData(configuration)
      setData(_data)
    }
  }, [configuration])

  if (!configuration) {
    return <div>This is program configuration</div>
  }

  const deleteElement = (key, position) => {
    setData({
      ...data,
      [key]: data[key].map((element, z) => {
        if (element.index !== position) return element

        return {
          ...element,
          deleted: true,
        }
      }),
    })
  }

  const getKey = (key, position) => {
    return CONFIGURATION_STRINGS[key] + '_' + position
  }

  const changeElement = (key, position, value) => {
    setData({
      ...data,
      [key]: data[key].map((element, z) => {
        if (position !== element.index) return element
        return {
          ...element,
          value,
        }
      }),
    })
  }

  const addElement = (key) => {
    if (key !== 'insurancePlans') {
      setData({
        ...data,
        [key]: [
          ...data[key],
          {
            index: data[key].length,
            originalValue: '',
            value: '',
            deleted: false,
            isNew: true,
          },
        ],
      })
    } else {
      setData({
        ...data,
        [key]: [
          {
            index: data[key].length,
            originalValue: { name: '', isNew: true },
            value: { name: '', isNew: true },
            deleted: false,
            isNew: true,
          },
          ...data[key],
        ],
      })
    }
  }

  const removeReplaceValue = (field, value, newData) => {
    const newValues = replacedValues.filter((v) => {
      return v.field !== field || v.value !== value
    })

    if (newValues.length) {
      setReplacedValues(newValues)
    } else {
      setReplacedValues()
      saveChanges({ newData })
    }
  }

  const handleReplaceValue = (field, value, newValue) => {
    const notInsurancePlansState = field !== 'insurancePlans'
    const newData = {
      ...data,
      [field]: data[field].map((element) => {
        if (notInsurancePlansState) {
          if (element.originalValue !== value) return element
        } else {
          if (element.originalValue.name !== value.name) return element
        }

        return {
          ...element,
          value: newValue,
          deleted: false,
        }
      }),
    }

    setData(newData)

    replaceConfiguration(
      tenantId,
      programId,
      {
        field,
        values: [
          {
            from: value,
            to: newValue,
          },
        ],
      },
      () => {
        removeReplaceValue(field, value, newData)
      },
    )
  }

  const handleCancelReplaceValue = (field, value) => {
    const newData = {
      ...data,
      [field]: data[field].map((element) => {
        if (field !== 'insurancePlans') {
          if (element.originalValue !== value) return element

          return {
            ...element,
            value,
            deleted: false,
          }
        } else {
          if (element.originalValue.name !== value.name) return element

          return {
            ...element,
            value: element.originalValue,
            deleted: false,
          }
        }
      }),
    }

    setData(newData)

    removeReplaceValue(field, value, newData)
  }

  const ConfigurationButtons = () => {
    return (
      <div
        className="setting-title only-main-title"
        style={{
          position: 'absolute',
          bottom: '10px',
          right: '40px',
        }}
      >
        <div className="sub-setting-title">
          <div className="action-btn-section" style={{ display: 'flex' }}>
            <ButtonNew
              type="link"
              text={'Cancel'}
              onClick={() => {
                const initialData = getInitialData(configuration)
                setData(initialData)
              }}
            />
            <ButtonNew type="primary" text={'Save'} onClick={saveChanges} />
          </div>
        </div>
      </div>
    )
  }

  const AddElement = ({ configKey }) => {
    return (
      <div style={{ width: '308px' }}>
        <AddInputField
          text={`Add ${CONFIGURATION_STRINGS[configKey]}`}
          onClick={() => {
            if (displayInvalid) setDisplayInvalid(false)
            addElement(configKey)
          }}
        />
      </div>
    )
  }

  return (
    <>
      <div className="program-tab-page configuration-page">
        {replacedValues && (
          <ReplaceValuesPopup
            values={replacedValues}
            handleClose={() => {
              setReplacedValues()
            }}
            handleReplaceValue={handleReplaceValue}
            handleCancelReplaceValue={handleCancelReplaceValue}
          />
        )}
        {data &&
          Object.keys(data).map((key, i) => {
            const list = data[key]

            return (
              <div
                key={i + '_' + key}
                className="settings-section without-title border-top"
              >
                <div className="section-name">{CONFIGURATION_STRINGS[key]}</div>
                {key !== 'insurancePlans' ? (
                  <div className="section-details">
                    {list &&
                      list.map((element, y) => {
                        const focusKey = getKey(key, y)
                        return (
                          <div
                            className={
                              'list-items-wrap ' +
                              (element.deleted ? 'removed' : '')
                            }
                            key={focusKey}
                          >
                            <InputFreeText
                              text={element.value}
                              autoFocus={focusKey === focused}
                              compClass={
                                !displayInvalid || element.value
                                  ? ''
                                  : 'invalid-field'
                              }
                              onFocus={() => {
                                setFocused(focusKey)
                              }}
                              onDelete={() => {
                                if (data[key].length === 1) {
                                  addUserMessage(
                                    'error',
                                    `It should be at leat one element`,
                                  )
                                  return
                                }

                                deleteElement(key, y)
                              }}
                              onChange={(event) => {
                                changeElement(key, y, event.target.value)
                              }}
                              placeholder={
                                'Enter ' + CONFIGURATION_STRINGS[key]
                              }
                            />
                          </div>
                        )
                      })}
                    <AddElement configKey={key} />
                  </div>
                ) : (
                  <div className="insurance-grid-container">
                    <div style={{ width: '308px' }}>
                      <AddElement configKey={'insurancePlans'} />
                    </div>
                    <InsuranceGrid
                      data={list}
                      states={configuration && configuration.insuranceStates}
                      handleChange={(position, value) => {
                        changeElement('insurancePlans', position, value)
                      }}
                      handleDelete={(position) => {
                        deleteElement(key, position)
                      }}
                    />
                    {/* {list &&
                      list.map((element, y) => {
                        const focusKey = getKey(key, y);

                        return (
                          <div
                            className={
                              "insurance-list-items " +
                              (element.deleted ? "removed" : "")
                            }
                            key={focusKey}
                          >
                            <input
                              placeholder={"Enter code"}
                              value={element.value.payerCode}
                              autoFocus={focusKey + "_code" === focused}
                              onFocus={() => {
                                setFocused(focusKey + "_code");
                              }}
                              onChange={(event) => {
                                changeElement(key, y, {
                                  payerCode: event.target.value,
                                  name: element.value.name
                                });
                              }}
                              type="text"
                            />
                            <input
                              placeholder={"Enter name"}
                              value={element.value.name}
                              autoFocus={focusKey + "_name" === focused}
                              onFocus={() => {
                                setFocused(focusKey + "_name");
                              }}
                              className={
                                !displayInvalid || element.value.name
                                  ? ""
                                  : "invalid-field"
                              }
                              onChange={(event) => {
                                console.log(key, y, event.target.value);
                                changeElement(key, y, {
                                  name: event.target.value,
                                  payerCode: element.value.payerCode
                                });
                              }}
                              type="text"
                            />
                            <div className="close-btn-preset">
                              <IconButton
                                type="close"
                                onClick={(event) => {
                                  if (data[key].length === 1) {
                                    addUserMessage(
                                      "error",
                                      `It should be at leat one element`
                                    );
                                    return;
                                  }

                                  deleteElement(key, y);
                                }}
                              />
                            </div>
                          </div>
                        );
                      })} */}
                  </div>
                )}
              </div>
            )
          })}
      </div>

      <ConfigurationButtons />
    </>
  )
}

function mapStateToProps(state, ownProps) {
  const id = ownProps.match.params.id
  const programId = ownProps.match.params.pId
  const tenant =
    id && state.tenantReducer.length > 0
      ? getTenantsById(state.tenantReducer, id)
      : null
  let program =
    tenant && tenant.programs
      ? tenant.programs.find((program) => program.id === programId)
      : null

  const configuration = program ? program.configuration : null

  return {
    configuration,
  }
}

const mapDispatchToProps = {
  loadConfiguration: tenantsActions.loadConfiguration,
  editConfiguration: tenantsActions.editConfiguration,
  replaceConfiguration: tenantsActions.replaceConfiguration,
  addUserMessage,
}

Configuration.propTypes = {
  loadConfiguration: PropTypes.func.isRequired,
  editConfiguration: PropTypes.func.isRequired,
  addUserMessage: PropTypes.func.isRequired,
  match: PropTypes.object.isRequired,
  configuration: PropTypes.object,
}

export default connect(mapStateToProps, mapDispatchToProps)(Configuration)
