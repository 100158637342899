import React from 'react'
import './style.less'
import PropTypes from 'prop-types'

const ListDynamic = ({
  list,
  ItemComponent,
  ItemClickFunc,
  HeaderComponent,
}) => {
  return (
    <div className="list-component">
      <div className="list-component-body">
        <div className="list-component-header list-component-complex-header">
          <HeaderComponent />
        </div>
        {list &&
          list.length > 0 &&
          list.map((item) => {
            return (
              <div
                className="list-component-item list-component-complex-item"
                key={item.id}
                onClick={() => {
                  if (ItemClickFunc) {
                    ItemClickFunc(item.id)
                  }
                }}
              >
                <ItemComponent item={item} />
              </div>
            )
          })}
      </div>
    </div>
  )
}

ListDynamic.propTypes = {
  HeaderComponent: PropTypes.func.isRequired,
  ItemComponent: PropTypes.func.isRequired,
  ItemClickFunc: PropTypes.func,
  list: PropTypes.array.isRequired,
}

export default ListDynamic
