import React, { useState } from 'react'
import PropTypes from 'prop-types'
import TableCell from './tableCell'
import { tableEditMode } from './table.domain'
import { useOptionsContext } from './optionsContext'
import OptionsMenu from '../OptionsMenu/index'

export default function TableRow({
  data,
  header,
  editMode,
  handleDataChange,
  orderKey,
}) {
  const [mouseInDiv, setMouseInDiv] = useState(false)
  const [keepOpen, setKeepOpen] = useState(false)
  const [editRow, setEditRow] = useState(editMode)
  const options = useOptionsContext()

  const handleChange = (key, value) => {
    if (editMode === tableEditMode.TABLE) {
      const newData = { ...data }
      newData[key] = value
      handleDataChange(newData)
    } else if (editMode === tableEditMode.ROW) {
      //TODO: implement
      setEditRow(!editRow)
    }
  }

  return (
    <>
      <tr
        className={options.rowClass || 'basic-row'}
        onClick={() => {
          options.onRowClick && options.onRowClick(data)
        }}
        onMouseEnter={() => {
          setMouseInDiv(true)
        }}
        onMouseLeave={() => {
          setMouseInDiv(false)
        }}
      >
        {header.map((h, index) => {
          return (
            <TableCell
              key={index}
              data={data}
              orderKey={orderKey}
              editMode={editMode && editRow}
              handleChange={handleChange}
              columnHeader={h}
            ></TableCell>
          )
        })}
        {options.rowMenu && (
          <td
            className="menu-cell basic-cell"
            onClick={(e) => {
              e.stopPropagation()
            }}
          >
            {(mouseInDiv || keepOpen) && (
              <OptionsMenu
                handleOpenClose={(isOpen) => {
                  setKeepOpen(isOpen)
                }}
                items={options.rowMenu
                  .filter((row) => !row.getVisible || row.getVisible(data))
                  .map((row) => {
                    return {
                      ...row,
                      onclick: () => {
                        row.onclick(data)
                      },
                    }
                  })}
                openOn="left"
              />
            )}
          </td>
        )}
      </tr>
    </>
  )
}

TableRow.propTypes = {
  editMode: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
  data: PropTypes.object.isRequired,
  header: PropTypes.array.isRequired,
  handleDataChange: PropTypes.func.isRequired,
  orderKey: PropTypes.string,
}
