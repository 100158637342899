import React, { useRef } from 'react'
import PropTypes from 'prop-types'
import CameraPlaceHolder from '../../../../image/icon_camera_placeholder.svg'
import ButtonNew from '../../../common/Button/ButtonNew'
import InputError from '../../../common/InputError/InputError'
import SearchTextOptions from '../../../common/SearchTextOptions/SearchTextOptions'
import InputFreeText from '../../../common/Input/InputFreeText'
import AddInputField from '../../../common/AddInputField/AddInputField'

/*
{
title,
picture,
mediaUrl,
background
}
* */
const AudioTrackContent = ({
  audioTrackMediaItem,
  setAudioTrackMediaItem,
  error,
}) => {
  const pictureBtnStyle = {
    marginLeft: '10px',
  }
  const wrapAddInputFieldStyle = { width: '308px' }
  const fileImageInput = useRef(null)
  const backgroundFileImageInput = useRef(null)

  const uploadImageClick = () => {
    // backgroundFileImageInput
    fileImageInput.current.click()
  }
  const uploadBackgroundImageClick = () => {
    backgroundFileImageInput.current.click()
  }
  const handleImageChange = (event) => {
    event.preventDefault()

    let reader = new FileReader()
    let file = event.target.files[0]

    reader.onloadend = () => {
      setAudioTrackMediaItem(
        Object.assign({}, audioTrackMediaItem, {
          image: {
            file: file,
            imagePreviewUrl: reader.result,
          },
        }),
      )
    }

    reader.readAsDataURL(file)
  }
  const handleBackgroundImageChange = (event) => {
    event.preventDefault()

    let reader = new FileReader()
    let file = event.target.files[0]

    reader.onloadend = () => {
      setAudioTrackMediaItem(
        Object.assign({}, audioTrackMediaItem, {
          backgroundImage: {
            file: file,
            imagePreviewUrl: reader.result,
          },
        }),
      )
    }

    reader.readAsDataURL(file)
  }

  const inputTagOnChange = (event) => {
    const tagsSearch = event.target.value
    setAudioTrackMediaItem(
      Object.assign({}, audioTrackMediaItem, { tagsSearch }),
    )
  }

  const deleteImageClick = () => {
    fileImageInput.current.value = ''
    setAudioTrackMediaItem(
      Object.assign({}, audioTrackMediaItem, {
        image: {
          file: '',
          imagePreviewUrl: '',
        },
      }),
    )
  }
  const deleteBackgroundImageClick = () => {
    backgroundFileImageInput.current.value = ''
    setAudioTrackMediaItem(
      Object.assign({}, audioTrackMediaItem, {
        backgroundImage: {
          file: '',
          imagePreviewUrl: '',
        },
      }),
    )
  }
  const pictureTypeHasChange = (event) => {
    const changeParams = { imageType: event.target.name }

    if (changeParams.imageType === 'actual_picture') {
      setAudioTrackMediaItem(
        Object.assign({}, audioTrackMediaItem, {
          imageType: changeParams.imageType,
          imageUrl: '',
        }),
      )
    } else if (changeParams.imageType === 'url_picture') {
      setAudioTrackMediaItem(
        Object.assign({}, audioTrackMediaItem, {
          imageType: changeParams.imageType,
          image: {
            file: '',
            imagePreviewUrl: '',
          },
        }),
      )
    }
  }
  const backgroundImageTypeHasChange = (event) => {
    // "actual_picture" ==> "background_actual_picture", "url_picture" ==> "background_url_picture".
    const changeParams = { backgroundImageType: event.target.name }

    if (changeParams.backgroundImageType === 'background_actual_picture') {
      setAudioTrackMediaItem(
        Object.assign({}, audioTrackMediaItem, {
          backgroundImageType: changeParams.backgroundImageType,
          backgroundImageUrl: '',
        }),
      )
    } else if (changeParams.backgroundImageType === 'background_url_picture') {
      setAudioTrackMediaItem(
        Object.assign({}, audioTrackMediaItem, {
          backgroundImageType: changeParams.backgroundImageType,
          backgroundImage: {
            file: '',
            imagePreviewUrl: '',
          },
        }),
      )
    }
  }
  // let { imagePreviewUrl } = imagePreview;
  let { imagePreviewUrl } = audioTrackMediaItem.image
  let { imagePreviewUrl: backgroundImagePreviewUrl } =
    audioTrackMediaItem.backgroundImage

  const ImageElement = () => {
    return imagePreviewUrl ? (
      <img src={imagePreviewUrl} />
    ) : (
      <CameraPlaceHolder />
    )
  }
  const BackgroundImageElement = () => {
    return backgroundImagePreviewUrl ? (
      <img src={backgroundImagePreviewUrl} />
    ) : (
      <CameraPlaceHolder />
    )
  }
  const deleteParagraph = (presetId) => {
    setAudioTrackMediaItem(
      Object.assign({}, audioTrackMediaItem, {
        paragraph: audioTrackMediaItem.paragraph.filter(
          (item) => item.id !== presetId,
        ),
      }),
    )
  }
  const changePreset = (presetId, event) => {
    const { value } = event.target
    setAudioTrackMediaItem(
      Object.assign({}, audioTrackMediaItem, {
        paragraph: audioTrackMediaItem.paragraph.map((preset) => {
          if (preset.id === presetId) {
            return Object.assign({}, preset, { text: value })
          }
          return preset
        }),
      }),
    )
  }
  const addParagraph = () => {
    setAudioTrackMediaItem(
      Object.assign({}, audioTrackMediaItem, {
        paragraph: audioTrackMediaItem.paragraph.concat({
          text: '',
          id: new Date().getTime() + audioTrackMediaItem.paragraph.length,
        }),
      }),
    )
  }
  return (
    <>
      <div className="modal-section">
        <div className="category-section">Title</div>
        <input
          type="text"
          value={audioTrackMediaItem.title}
          className="details-section"
          placeholder="Enter Title"
          onChange={(event) => {
            setAudioTrackMediaItem(
              Object.assign({}, audioTrackMediaItem, {
                title: event.target.value,
              }),
            )
          }}
        />
      </div>
      <InputError
        showError={error && error.title}
        text={error && error.title ? error.title : ''}
      />
      <div className="modal-section">
        <div className="category-section">Picture</div>
        <div className="details-section">
          <div className="radio-group">
            <div className="radio-element">
              <input
                type="radio"
                name="url_picture"
                value="url_picture"
                checked={audioTrackMediaItem.imageType === 'url_picture'}
                onChange={pictureTypeHasChange}
              />
              <label />
              <div className="radio-element-body">
                <input
                  type="text"
                  value={audioTrackMediaItem.imageUrl}
                  className="details-section"
                  placeholder="Enter Picture Url"
                  disabled={audioTrackMediaItem.imageType !== 'url_picture'}
                  onChange={(event) =>
                    setAudioTrackMediaItem(
                      Object.assign({}, audioTrackMediaItem, {
                        imageUrl: event.target.value,
                      }),
                    )
                  }
                />
              </div>
            </div>
            <InputError
              showError={error && error.picture}
              text={error && error.picture ? error.picture : ''}
            />
            <div className="radio-element">
              <input
                type="radio"
                name="actual_picture"
                disabled={true}
                value="actual_picture"
                checked={audioTrackMediaItem.imageType === 'actual_picture'}
                onChange={pictureTypeHasChange}
              />
              <label />
              <div className="radio-element-body">
                <ImageElement />
                <input
                  onChange={handleImageChange}
                  className="not-show-input"
                  ref={fileImageInput}
                  type="file"
                  accept="image/x-png,image/jpeg"
                />
                <div>
                  <ButtonNew
                    style={pictureBtnStyle}
                    text="Upload Image"
                    type="secondary"
                    disable={audioTrackMediaItem.imageType !== 'actual_picture'}
                    onClick={uploadImageClick}
                  />
                  <ButtonNew
                    style={pictureBtnStyle}
                    text="Delete"
                    type="link"
                    disable={audioTrackMediaItem.imageType !== 'actual_picture'}
                    onClick={deleteImageClick}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="modal-section">
        <div className="category-section">Tags</div>
        <div className="details-section">
          <SearchTextOptions
            placeholder="Start typing a tag… "
            deleteItem={(item) => {
              setAudioTrackMediaItem(
                Object.assign({}, audioTrackMediaItem, {
                  tags:
                    audioTrackMediaItem.tags && audioTrackMediaItem.tags.length
                      ? audioTrackMediaItem.tags.filter(
                          (iterate) => item !== iterate,
                        )
                      : [],
                  tagsSearch: '',
                }),
              )
            }}
            addItem={(item) => {
              setAudioTrackMediaItem(
                Object.assign({}, audioTrackMediaItem, {
                  tags: audioTrackMediaItem.tags.includes(item)
                    ? audioTrackMediaItem.tags
                    : audioTrackMediaItem.tags.concat(item),
                  tagsSearch: '',
                }),
              )
            }}
            inputOnChange={inputTagOnChange}
            inputValue={audioTrackMediaItem.tagsSearch}
            optionsArray={audioTrackMediaItem.tagOptions}
            valueArray={audioTrackMediaItem.tags}
          />
        </div>
      </div>
      <div className="modal-section">
        <div className="category-section">Media URL</div>
        <input
          type="text"
          value={audioTrackMediaItem.mediaUrl}
          className="details-section"
          placeholder="Enter URL"
          onChange={(event) => {
            setAudioTrackMediaItem(
              Object.assign({}, audioTrackMediaItem, {
                mediaUrl: event.target.value,
              }),
            )
          }}
        />
      </div>
      <InputError
        showError={error && error.mediaUrl}
        text={error && error.mediaUrl ? error.mediaUrl : ''}
      />

      <div className="modal-section">
        <div className="category-section">Background image</div>
        <div className="details-section">
          <div className="radio-group">
            <div className="radio-element">
              <input
                type="radio"
                name="background_url_picture"
                value="background_url_picture"
                checked={
                  audioTrackMediaItem.backgroundImageType ===
                  'background_url_picture'
                }
                onChange={backgroundImageTypeHasChange}
              />
              <label />
              <div className="radio-element-body">
                <input
                  type="text"
                  value={audioTrackMediaItem.backgroundImageUrl}
                  className="details-section"
                  placeholder="Enter Picture Url"
                  disabled={
                    audioTrackMediaItem.backgroundImageType !==
                    'background_url_picture'
                  }
                  onChange={(event) =>
                    setAudioTrackMediaItem(
                      Object.assign({}, audioTrackMediaItem, {
                        backgroundImageUrl: event.target.value,
                      }),
                    )
                  }
                />
              </div>
            </div>
            <InputError
              showError={error && error.background}
              text={error && error.background ? error.background : ''}
            />
            <div className="radio-element">
              <input
                type="radio"
                disabled={true}
                name="background_actual_picture"
                value="background_actual_picture"
                checked={
                  audioTrackMediaItem.backgroundImageType ===
                  'background_actual_picture'
                }
                onChange={backgroundImageTypeHasChange}
              />
              <label />
              <div className="radio-element-body">
                <BackgroundImageElement />
                <input
                  onChange={handleBackgroundImageChange}
                  className="not-show-input"
                  ref={backgroundFileImageInput}
                  type="file"
                  accept="image/x-png,image/jpeg"
                />
                <div>
                  <ButtonNew
                    style={pictureBtnStyle}
                    text="Upload Image"
                    type="secondary"
                    disable={
                      audioTrackMediaItem.backgroundImageType !==
                      'background_actual_picture'
                    }
                    onClick={uploadBackgroundImageClick}
                  />
                  <ButtonNew
                    style={pictureBtnStyle}
                    text="Delete"
                    type="link"
                    disable={
                      audioTrackMediaItem.backgroundImageType !==
                      'background_actual_picture'
                    }
                    onClick={deleteBackgroundImageClick}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="modal-section">
        <div className="category-section">Paragraphs</div>
        <div className="details-section paragraph-section">
          {audioTrackMediaItem.paragraph &&
            audioTrackMediaItem.paragraph.map((paragraph) => (
              <InputFreeText
                key={'input-preset-' + paragraph.id}
                text={paragraph.text}
                onDelete={() => deleteParagraph(paragraph.id)}
                onChange={(event) => changePreset(paragraph.id, event)}
                placeholder={'Enter Preset'}
              />
            ))}

          <div style={wrapAddInputFieldStyle}>
            <AddInputField text="Add Preset" onClick={addParagraph} />
          </div>
        </div>
      </div>
    </>
  )
}

AudioTrackContent.propTypes = {
  audioTrackMediaItem: PropTypes.object.isRequired,
  setAudioTrackMediaItem: PropTypes.func.isRequired,
  error: PropTypes.object,
}

export default AudioTrackContent
