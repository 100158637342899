import React from 'react'
import { Redirect } from 'react-router-dom'
import Header from './Header'
import Confirmation from '../common/Confirmation'
import Body from '../Body/Body'
import NotImplementedDialog from './NotImplementedDialog'
import * as globalAction from '../../redux/actions/globalAction'

import './style.less'
import { connect } from 'react-redux'
import AuthComponentCheck from '../GlobalComponent/AuthComponentCheck/AuthComponentCheck'
const Home = ({ history, initState, confirmation, hideConfirmation }) => {
  // if not token redirect to login screen.

  return (
    <div className="home-box">
      {confirmation.display && (
        <Confirmation
          details={confirmation.details}
          callback={confirmation.callback}
          handleClose={() => {
            hideConfirmation()
          }}
        />
      )}
      <AuthComponentCheck history={history} />
      <NotImplementedDialog />
      <Header history={history} initState={initState} />
      <Body />
    </div>
  )
}

function mapStateToProps(state) {
  return {
    confirmation: state.globalReducer.confirmation,
  }
}

const mapDispatchToProps = {
  initState: globalAction.initializeAppState,
  hideConfirmation: globalAction.hideConfirmation,
}

export default connect(mapStateToProps, mapDispatchToProps)(Home)
