import React from 'react'

function Logs() {
  return (
    <div>
      <div>This is logs page</div>
    </div>
  )
}

export default Logs
