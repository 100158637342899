import Joi from 'joi'
function getCreateQuesitonnaireSchema() {
  const ObjectIdSchema = Joi.string().regex(/^[0-9a-fA-F]{24}$/)
  const LANGUAGE = {
    ENGLISH: 'en',
    SPANISH: 'Es',
    CHINESE: 'Ch',
  }

  function getStatsSchema() {
    return Joi.object()
      .keys({
        minScore: Joi.number()
          .integer()
          .min(0)
          .required()
          .description('minimum score'),
        maxScore: Joi.number()
          .integer()
          .min(Joi.ref('minScore'))
          .required()
          .description('maximum score'),
        standardDeviation: Joi.number()
          .min(0)
          .optional()
          .description('score standard deviation'),
        mean: Joi.number()
          .min(Joi.ref('minScore'))
          .max(Joi.ref('maxScore'))
          .optional()
          .description('score mean'),
      })
      .and('standardDeviation', 'mean')
  }

  function getMetadaSchema() {
    return {
      id: ObjectIdSchema.required(),
      name: Joi.string()
        .required()
        .description('name')
        .error((errors) => {
          return {
            message: 'Questionnaire Name is required',
          }
        }),
      alias: Joi.string().optional().allow(null).description('alias'),
      description: Joi.string()
        .optional()
        .allow(null)
        .description('description'),
      language: Joi.string()
        .valid(Object.values(LANGUAGE))
        .optional()
        .allow(null)
        .description('language')
        .error((errors) => {
          return {
            message: 'Questionnaire Language is required',
          }
        }),
      page_prefix: Joi.string()
        .optional()
        .allow(null)
        .description('page prefix'),
      instructions: Joi.string()
        .optional()
        .allow(null)
        .description('instructions'),
      instructions_mobile: Joi.string()
        .optional()
        .allow(null)
        .description('instructions mobile'),
      page_suffix: Joi.string()
        .optional()
        .allow(null)
        .description('page suffix'),
      stats: getStatsSchema().optional().allow(null),
      oneQuestion: Joi.boolean()
        .optional()
        .description("is a 'one question' questionnaire, false by default"),
      family: Joi.string().optional().allow(null).description('family'),
      score_nature: Joi.string()
        .optional()
        .allow(null)
        .description('score nature'),
      additionalId: Joi.object()
        .keys({
          guid: Joi.number()
            .integer()
            .min(1)
            .required()
            .description('another id'),
        })
        .optional()
        .allow(null),
      type: Joi.string()
        .valid('Questionnaire', 'Sentiment')
        .optional()
        .error((errors) => {
          return {
            message: 'Questionnaire Type is required',
          }
        }),
    }
  }

  function getCatSchema() {
    return Joi.object().keys({
      influence: Joi.array()
        .items(
          Joi.object({
            where: Joi.object().required(),
            result: Joi.object()
              .keys({
                type: Joi.string().valid('end', 'response').required(),
                code: Joi.string().optional(),
              })
              .required(),
          }),
        )
        .min(1)
        .allow(null)
        .description('influence'),
      depends: Joi.object()
        .keys({
          where: Joi.object().required(),
        })
        .allow(null)
        .description(
          'conditions on previous questions, if they are not met then this question cannot be answered',
        ),
    })
  }
  let getMetadaSchemaCopy = getMetadaSchema()
  delete getMetadaSchemaCopy.id
  let schema = Object.assign({}, getMetadaSchemaCopy, {
    questions: Joi.array()
      .items({
        number: Joi.number()
          .integer()
          .min(1)
          .required()
          .description('position number'),
        text: Joi.string().required().description('text'),
        prefix: Joi.string().optional().allow(null).description('prefix'),
        isOptional: Joi.boolean().optional().default(false),
        page: Joi.number().optional().description('page number'),
        layoutCode: Joi.string()
          .valid('2H', 'V', 'B', 'MV')
          .required()
          .description("layout code: 'H', 'V', 'B' or 'MV'")
          .error((errors) => {
            return {
              message: 'Layout code is required',
            }
          }),
        inputMethod: Joi.string()
          .valid('single', 'multiple', 'free_text')
          .required()
          .description("input method: 'single', 'multiple' or 'freeText'"),
        cat: getCatSchema().optional(),
        additionalId: Joi.object()
          .keys({
            code: Joi.string().required().description('another id'),
          })
          .optional(),
        answers: Joi.array()
          .items({
            number: Joi.number()
              .integer()
              .min(1)
              .required()
              .description('position number'),
            text: Joi.string().optional().allow(null).description('text'),
            score: Joi.number()
              .integer()
              .min(0)
              .optional()
              .allow(null)
              .description('score'),
            allowFreeText: Joi.boolean()
              .optional()
              .description('is free text allowed for this answer'),
            placeholder: Joi.string()
              .optional()
              .allow(null)
              .description('placeholder for free text answer'),
            additionalId: Joi.object()
              .keys({
                code: Joi.string().required().description('another id'),
              })
              .optional(),
          })
          .min(1)
          .required(),
      })
      .min(1)
      .required(),
  })

  return schema
}
export const changeQuestionnairesArray = (arr, setQuestionArray) => {
  let transformArray = arr.map((item) => {
    if (!item.dependsOn || Object.keys(item.dependsOn).length === 0) {
      return item
    }
    let dependOnKey = Object.keys(item.dependsOn)
    let dependsOn = item.dependsOn
    dependOnKey.forEach((key) => {
      let conditionItemRow = dependsOn[key]
      conditionItemRow.forEach((conditionItem) => {
        let qId = conditionItem.q.value
        let aId = conditionItem.a.value
        let questNeedToCheck = arr.find((quesItem) => quesItem.id == qId)
        if (
          !questNeedToCheck ||
          questNeedToCheck.itemLocation > item.itemLocation
        ) {
          conditionItem.q = { value: '', id: '' }
          conditionItem.a = { value: '', id: '' }
        } else {
          let answers = questNeedToCheck.answers
          if (!answers || !answers.find((ans) => ans.id == aId)) {
            conditionItem.a = { value: '', id: '' }
          }
        }
      })
    })
    return Object.assign({}, item, { dependsOn: dependsOn })
  })
  setQuestionArray(transformArray)
}
export const deleteAnswer = (
  itemId,
  answerId,
  setQuestionArray,
  questionArray,
) => {
  setQuestionArray(
    questionArray.map((item) => {
      if (item.id === itemId) {
        let newTerminateOn = {}
        for (let key in item.terminateOn) {
          newTerminateOn[key] = item.terminateOn[key].filter(
            (conItem) => conItem.value != answerId,
          )
        }
        return Object.assign({}, item, {
          answers: item.answers
            .filter((answerItem) => answerItem.id !== answerId)
            .map((ansItem, index) => {
              return Object.assign({}, ansItem, { itemLocation: index + 1 })
            }),
          terminateOn: newTerminateOn,
        })
      } else if (Object.keys(item.dependsOn).length) {
        // check if on the other item this answer exist, and if so delete it.
        let dependOnKey = Object.keys(item.dependsOn)
        let dependsOn = item.dependsOn
        // dependItem.a = { value: "", id: "" };
        dependOnKey.forEach((key) => {
          let answerThatNeedDelete = dependsOn[key].find(
            (dependItem) => dependItem.a.value == answerId,
          )
          if (answerThatNeedDelete) {
            answerThatNeedDelete.a = { value: '', id: '' }
          }
        })
        return item
      } else {
        return item
      }
    }),
  )
}
export const checkIfQuestionnaireValid = (mainItem, questionsItem) => {
  const errorObject = {
    main: {},
    questions: {},
    globalError: {},
  }
  let isValid = true
  /**
   * Check and set main error.
   * */
  if (!mainItem.name) {
    isValid = false
    errorObject.main.name = true
  }
  if (!mainItem.type) {
    isValid = false
    errorObject.main.type = true
  }
  if (!mainItem.language) {
    isValid = false
    errorObject.main.language = true
  }
  if (
    !questionsItem.length ||
    (mainItem.type === 'Sentiment' && questionsItem.length > 1)
  ) {
    isValid = false
    errorObject.globalError.sentimentLength = true
  }
  // let isValid = true;
  /**
   * Check and set questions error.
   * */
  questionsItem.forEach((quest) => {
    if (!quest.text) {
      errorObject.questions[quest.itemLocation] = Object.assign(
        {},
        errorObject.questions[quest.itemLocation],
        { text: true },
      )
      isValid = false
    }
    if (!quest.answers.length) {
      errorObject.questions[quest.itemLocation] = Object.assign(
        {},
        errorObject.questions[quest.itemLocation],
        { answersLength: true },
      )
      isValid = false
    } else {
      quest.answers.forEach((ans) => {
        // check if there is no text for one of the answer
        if (!ans.text) {
          errorObject.questions[quest.itemLocation] = Object.assign(
            {},
            errorObject.questions[quest.itemLocation],
            { answersLength: true },
          )
          isValid = false
        }
      })
    }
    if (!quest.inputMethod) {
      errorObject.questions[quest.itemLocation] = Object.assign(
        {},
        errorObject.questions[quest.itemLocation],
        { inputMethod: true },
      )
      isValid = false
    }
    if (!quest.layoutCode) {
      errorObject.questions[quest.itemLocation] = Object.assign(
        {},
        errorObject.questions[quest.itemLocation],
        { layoutCode: true },
      )
      isValid = false
    }
    let terminateOnKeys = Object.keys(quest.terminateOn)
    if (terminateOnKeys.length) {
      terminateOnKeys.forEach((terminateRowKeys) => {
        let terminateRow = quest.terminateOn[terminateRowKeys] || []
        terminateRow.forEach((condition) => {
          if (!condition.value || !condition.id) {
            errorObject.questions[quest.itemLocation] = Object.assign(
              {},
              errorObject.questions[quest.itemLocation],
              { terminateCondition: true },
            )
            isValid = false
          }
        })
      })
    }
    let dependsOnKeys = Object.keys(quest.dependsOn)
    if (dependsOnKeys.length) {
      dependsOnKeys.forEach((dependOnRowKey) => {
        let dependRow = quest.dependsOn[dependOnRowKey] || []
        dependRow.forEach((condition) => {
          if (
            !condition.a.value ||
            !condition.a.id ||
            !condition.q.value ||
            !condition.q.id
          ) {
            errorObject.questions[quest.itemLocation] = Object.assign(
              {},
              errorObject.questions[quest.itemLocation],
              { dependsCondition: true },
            )
            isValid = false
          }
        })
      })
    }
  })
  if (!isValid) {
    return { ok: false, error: errorObject }
  }
  return { ok: true }
}
export const getQuestionnaireToSave = (mainItem, questionsItem) => {
  let validateObject = checkIfQuestionnaireValid(mainItem, questionsItem)
  if (!validateObject.ok) {
    // return validateObject;
  }
  const questionnaireToSaveObject = {
    name: mainItem.name,
    language: mainItem.language,
    type: mainItem.type,
    questions: [],
  }
  if (mainItem.score_nature && mainItem.score_nature === 'Risk2') {
    questionnaireToSaveObject.score_nature = 'Risk2'
  }
  if (mainItem.description) {
    questionnaireToSaveObject.description = mainItem.description
  }
  if (mainItem.alias) {
    questionnaireToSaveObject.alias = mainItem.alias
  }
  if (mainItem.pagePrefix) {
    questionnaireToSaveObject.page_prefix = mainItem.pagePrefix
  }
  if (mainItem.instructions) {
    questionnaireToSaveObject.instructions = mainItem.instructions
    questionnaireToSaveObject.instructions_mobile = mainItem.instructions
  }
  if (mainItem.states.maxScore || mainItem.states.minScore) {
    questionnaireToSaveObject.stats = {
      maxScore: mainItem.states.maxScore || 0,
      minScore: mainItem.states.minScore || 0,
    }
  }
  if (questionnaireToSaveObject.type === 'Sentiment') {
    questionnaireToSaveObject.oneQuestion = true
  }
  questionsItem.forEach((quest) => {
    let questInsert = {
      number: quest.itemLocation,
      text: quest.text,
      layoutCode: quest.layoutCode,
      inputMethod: quest.inputMethod,
      isOptional: quest.isOptional,
      page: quest.page,
      answers: [],
      cat: {
        influence: null,
        depends: null,
      },
    }
    if (quest.prefix) {
      questInsert.prefix = quest.prefix
    }

    /**
     * Set answers
     * */
    quest.answers.forEach((ans) => {
      let ansObj = {
        number: ans.itemLocation,
        text: ans.text,
      }
      // ans.type === "regular"/"freeText"

      if (ans.type === 'freeText') {
        ansObj.allowFreeText = true
      } else {
        ansObj.score = ans.score
      }
      questInsert.answers.push(ansObj)
    })
    /**
     * Set cat
     * */

    /**
     * Set the depends on
     * */
    if (Object.keys(quest.dependsOn).length) {
      questInsert.cat.depends = {
        where: { or: [] },
      }
      Object.keys(quest.dependsOn).forEach((dependKey) => {
        if (quest.dependsOn[dependKey].length) {
          let cond = { and: [] }
          quest.dependsOn[dependKey].forEach((condition) => {
            let questDependOn = questionsItem.find(
              (questIter) => questIter.id == condition.q.id,
            )
            let ansForQuestDependOn = questDependOn.answers.find(
              (ans) => ans.id == condition.a.id,
            )
            let oneCondition = {}
            oneCondition[questDependOn.itemLocation] =
              ansForQuestDependOn.itemLocation
            cond.and.push(oneCondition) //[questDependOn.itemLocation] = ansForQuestDependOn.itemLocation;
          })
          questInsert.cat.depends.where.or.push(cond)
        }
      })
    }
    /**
     * Set the influence on
     * */
    if (Object.keys(quest.terminateOn).length) {
      /**
       * The where was {where: {or: [{and: [{1:2},{2:3}]}]}};
       * Now it become {where: {1: 2, 2: 3}}
       * */
      questInsert.cat.influence = [] //{ where: { or: [] } }; // {where: {or: [{and: [{1:2},{2:3}]}]}};
      // iterat for every row (or conditions)
      // let influenceArrayOfOr = [];
      Object.keys(quest.terminateOn).forEach((terminateKey) => {
        // let cond = { and: [] };
        let cond = {}
        // Item for every ROW of condition
        quest.terminateOn[terminateKey].forEach((terminateCondition) => {
          let ansLocation = quest.answers.find(
            (ans) => ans.id == terminateCondition.value,
          ).itemLocation
          let orObj = {}
          orObj[quest.itemLocation] = ansLocation
          // cond.and.push(orObj);
          cond = orObj
        })
        questInsert.cat.influence.push({
          where: cond,
          result: { type: 'end' },
        })
      })
    }
    questionnaireToSaveObject.questions.push(questInsert)
  })
  if (!validateObject.ok) {
    let validateQuestionanireSchema = getCreateQuesitonnaireSchema()

    const joiResult = Joi.object(validateQuestionanireSchema).validate(
      questionnaireToSaveObject,
      { abortEarly: false },
    )

    let errorMessage =
      joiResult &&
      joiResult.error &&
      joiResult.error.details &&
      joiResult.error.details.length
        ? joiResult.error.details.map((det) => {
            return { message: det.message, path: det.path }
          })
        : []
    return { ok: false, validateObject, errorMessage }
  } else {
    return { ok: true, data: questionnaireToSaveObject }
  }
}
export const getEditMainItem = (questionnaire) => {
  const setMainItem = {
    name: questionnaire.name || '',
    type: questionnaire.type,
    alias: questionnaire.alias || '',
    description: questionnaire.description || '',
    language: questionnaire.language || '',
    pagePrefix: questionnaire.page_prefix || '',
    instructions: questionnaire.instructions || '',
    states: {
      minScore:
        questionnaire.stats &&
        (questionnaire.stats.minScore || questionnaire.stats.minScore === 0)
          ? questionnaire.stats.minScore
          : null,
      maxScore:
        questionnaire.stats &&
        (questionnaire.stats.maxScore || questionnaire.stats.maxScore === 0)
          ? questionnaire.stats.maxScore
          : null,
    }, //  "score_nature" : "Risk2",
    score_nature: questionnaire.score_nature === 'Risk2' ? 'Risk2' : 'Original',
  }
  return setMainItem
}
export const getEditQuestionsItem = (questionnaire) => {
  if (
    !questionnaire ||
    !questionnaire.questions ||
    !questionnaire.questions.length
  )
    return []
  const questionsArray = questionnaire.questions.map((item) => {
    const questionsItem = {
      open: false,
      topOptionsOpen: false,
      text: item.text || '',
      layoutCode: item.layoutCode || '',
      inputMethod: item.inputMethod || '',
      prefix: item.prefix || '',
      isOptional: item.isOptional,
      page: item.page,
      terminateOn: {}, // Object of {id: Array}}
      dependsOn: {}, // Array of arrays,
      answers: [],
      id: item.id,
      itemLocation: item.number,
    }
    if (item.answers && item.answers.length) {
      questionsItem.answers = item.answers.map((ansItem) => {
        let ansObj = {
          id: ansItem.id,
          text: ansItem.text,
          itemLocation: ansItem.number,
          type: ansItem.allowFreeText ? 'freeText' : 'regular',
          dragType: 'center-left',
        }
        if (!ansItem.allowFreeText) {
          ansObj.score = ansItem.score
        }
        return ansObj
      })
    }
    if (item.cat && item.cat.influence && item.cat.influence.length) {
      let influence = item.cat.influence
      let fakeId = new Date().getTime()
      // this is or condition
      influence.forEach((rowCondition, index) => {
        let fakeRowId = fakeId + index + 1
        questionsItem.terminateOn[fakeRowId] = []
        if (rowCondition.where.and) {
          let fakeConId = new Date().getTime()
          rowCondition.where.and.forEach((oneCondition, index) => {
            let ansNum = Object.keys(oneCondition)[0]
            questionsItem.terminateOn[fakeRowId].push({
              id: fakeConId + index + 1,
              value: item.answers.find(
                (ans) => ans.number === oneCondition[ansNum],
              ).id,
            })
          })
        } else {
          //
          let ansNum = Object.keys(rowCondition.where)[0]
          let fakeConId = new Date().getTime()
          questionsItem.terminateOn[fakeRowId].push({
            id: fakeConId + index + 1,
            value: item.answers.find(
              (ans) => ans.number === rowCondition.where[ansNum],
            ).id,
          })
        }
      })
    }
    if (item.cat && item.cat.depends && item.cat.depends.where) {
      if (item.cat.depends.where.or) {
        let depend = item.cat.depends.where.or
        let fakeId = new Date().getTime()
        depend.forEach((rowCond, index) => {
          let rowId = fakeId + index + 1
          questionsItem.dependsOn[rowId] = []
          let fakeId2 = new Date().getTime()
          rowCond.and.forEach((oneCondition, index) => {
            let Qn = Object.keys(oneCondition)[0]
            let An = oneCondition[Qn]
            let selectQuest = questionnaire.questions.find(
              (qItem) => qItem.number == Qn,
            )
            let questAnswerArray = selectQuest.answers
            let ansSelected = questAnswerArray.find(
              (ansItem) => ansItem.number == An,
            )
            questionsItem.dependsOn[rowId].push({
              a: {
                id: ansSelected.id,
                value: ansSelected.id,
              },
              q: {
                id: selectQuest.id,
                value: selectQuest.id,
              },
              id: fakeId2 + index + 1,
            })
          })
        })
      }
    }
    return questionsItem
  })
  return questionsArray
}
