// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.input-search {
  position: relative;
  /* width: 200px; */
}

span.input-search-icon {
  position: absolute;
  top: 8px;
  left: 10px;
}

.input-search input {
  padding: 0 28px !important;
}

span.input-search-remove {
  cursor: pointer;
  position: absolute;
  right: 10px;
  top: 8px;
}
`, "",{"version":3,"sources":["webpack://./src/components/common/SearchInput/style.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,kBAAkB;AACpB;;AAEA;EACE,kBAAkB;EAClB,QAAQ;EACR,UAAU;AACZ;;AAEA;EACE,0BAA0B;AAC5B;;AAEA;EACE,eAAe;EACf,kBAAkB;EAClB,WAAW;EACX,QAAQ;AACV","sourcesContent":[".input-search {\n  position: relative;\n  /* width: 200px; */\n}\n\nspan.input-search-icon {\n  position: absolute;\n  top: 8px;\n  left: 10px;\n}\n\n.input-search input {\n  padding: 0 28px !important;\n}\n\nspan.input-search-remove {\n  cursor: pointer;\n  position: absolute;\n  right: 10px;\n  top: 8px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
