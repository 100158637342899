import React, { useState } from 'react'
import CreateTools from './CreateTools'
import { NavLink, Route, Switch } from 'react-router-dom'
import ButtonNew from '../../common/Button/ButtonNew'
import PropTypes from 'prop-types'
import './tools-header-list.less'

function Header({ pathname }) {
  const [createTool, setCreateTool] = useState({
    isOpen: false,
  })

  const createClick = () => {
    console.log('Create new tenant not implemented yet.')
    setCreateTool(Object.assign({}, createTool, { isOpen: true }))
  }
  const closeNewToolModal = () => {
    setCreateTool(Object.assign({}, createTool, { isOpen: false }))
  }
  const saveNewTool = () => {
    console.log('SAVE THE NEW TENANT!!!')
    setCreateTool(Object.assign({}, createTool, { isOpen: false }))
  }

  const HeaderTextTool = () => {
    /**
     * If enter here so is in route home/tools/{media/questionnaire/consent_forms}
     * */
    const pathNameArray = pathname.split('/')

    if (pathNameArray.length < 3) {
      return <div>Tools</div>
    }
    const toolName = pathNameArray[2]
    const toolNameShow =
      toolName === 'consent_forms' ? 'Consent Form' : toolName
    const activeStyle = { color: 'inherit', textDecoration: 'none' }

    return (
      <div className="header-route">
        <NavLink to="/home/tools" activeStyle={activeStyle}>
          Tools
        </NavLink>
        <div>{'>'}</div>
        <div>{toolNameShow}</div>
      </div>
    )
  }

  return (
    <div className="tolls-header">
      <Switch>
        <HeaderTextTool />
      </Switch>

      <Route
        exact
        path="/home/tools"
        render={() => (
          <>
            <CreateTools
              isOpen={createTool.isOpen}
              onClose={closeNewToolModal}
              saveClick={saveNewTool}
            />
            {false && (
              <ButtonNew
                text={'Create new Tool'}
                type="primary"
                onClick={createClick}
              />
            )}
          </>
        )}
      />
    </div>
  )
}

Header.propTypes = {
  pathname: PropTypes.string.isRequired,
}

export default Header
