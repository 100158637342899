import React from 'react'
import MessageItem from './messageItem'
import { connect } from 'react-redux'
import * as globalAction from '../../../redux/actions/globalAction'
import './style.less'
/**
 * Every item has {type: "{success/warning}", text: "..."}
 * */
const UserMessages = ({ messageItems, deleteMessageItem }) => {
  if (!messageItems || !messageItems.length) {
    return <div />
  }
  return (
    <div className="user-message-pipe">
      {messageItems.length &&
        messageItems.map((item) => (
          <MessageItem
            type={item.type}
            text={item.text}
            key={item.id}
            deleteItem={() => deleteMessageItem(item.id)}
          />
        ))}
    </div>
  )
}

function mapStateToProps(state) {
  return {
    messageItems: state.globalReducer.userMessageArray,
  }
}

const mapDispatchToProps = {
  deleteMessageItem: globalAction.deleteUserMessage,
}

export default connect(mapStateToProps, mapDispatchToProps)(UserMessages)
