import React, { useState } from 'react'
import Modal from 'react-modal'
import { connect } from 'react-redux'
import IconButton from '../../../common/IconButton/IconButton'
import ButtonNew from '../../../common/Button/ButtonNew'
import * as globalFunction from './globalFunction'
import MainItem from './MainItem'
import QuestionsItem from './QuestionsItem'
import * as globalAction from '../../../../redux/actions/globalAction'
import * as toolsActions from '../../../../redux/actions/toolsActions'
import * as tenantsActions from '../../../../redux/actions/tenantsActions'

function CreateEditQuestionnaire({
  isOpen,
  closeModal,
  showNotImplementedFunc,
  addUserMessage,
  saveNewToolsQuestionnaire,
  saveNewTenantQuestionnaire,
  editToolsQuestionnaire,
  editTenantQuestionnaire,
  getOneQuestionnaireItem,
  tenantId,
  type = 'create',
  element,
  section,
}) {
  const initMainError = {}
  const initQuestionsError = {}
  const initMainItem = {
    name: '',
    type: '',
    alias: '',
    description: '',
    language: '',
    pagePrefix: '',
    instructions: '',
    states: {
      minScore: '',
      maxScore: '',
    },
    score_nature: 'Original',
  }
  const initQuestionItem = {
    open: false,
    topOptionsOpen: false,
    text: '',
    layoutCode: '',
    inputMethod: '',
    prefix: '',
    isOptional: false,
    terminateOn: {}, // Object of {id: Array}}
    dependsOn: {}, // Array of arrays,
    answers: [
      // { id: 1, score: 0, text: "", type: "regular" },
      // { id: 2, score: 0, text: "", type: "freeText" }
    ], // {id: , score: , text, type: {regular/freeText}}
  }
  const initQuestionsItems = []
  const [sectionOpen, setSectionOpen] = useState('main') // main/questions
  const [mainItem, setMainItem] = useState(initMainItem)
  const [mainItemError, setMainItemError] = useState(initMainError)
  const [questionsError, setQuestionsError] = useState(initQuestionsError)
  const [questionsItem, setQuestionsItem] = useState(initQuestionsItems)
  const openClosetQuest = (id, open) => {
    setQuestionsItem(
      questionsItem.map((item) => {
        if (item.id === id) {
          return { ...item, open }
        }
        return item
      }),
    )
  }
  const addNewQuestion = (isCreate) => {
    /**
     * If the questionnaire type is sentiment --> only one questions allow.
     * */
    if (mainItem.type === 'Sentiment' && questionsItem.length) {
      return
    }
    setQuestionsItem([
      ...questionsItem,
      {
        ...initQuestionItem,
        id: new Date().getTime(),
        itemLocation: questionsItem.length + 1,
        open: isCreate && !questionsItem.length,
      },
    ])
  }
  const saveQuestionnaireClick = () => {
    const questionnaireToSave = globalFunction.getQuestionnaireToSave(
      mainItem,
      questionsItem,
    )
    /**
     * return {ok: true, data: Object()}
     * return {ok: false, error: Object()}
     * */
    if (questionnaireToSave.ok) {
      if (section === 'tools') {
        if (type === 'edit') {
          if (element && element.id) {
            editToolsQuestionnaire(
              questionnaireToSave.data,
              element.id,
              (res) => {
                if (res.ok) {
                  closeModal()
                }
              },
            )
          }
        } else {
          saveNewToolsQuestionnaire(questionnaireToSave.data, (res) => {
            if (res.ok) {
              closeModal()
            }
          })
        }
      } else if (tenantId) {
        if (type === 'edit') {
          if (element && element.id) {
            editTenantQuestionnaire(
              tenantId,
              questionnaireToSave.data,
              element.id,
              (res) => {
                if (res.ok) {
                  closeModal()
                }
              },
            )
          }
        } else {
          saveNewTenantQuestionnaire(
            tenantId,
            questionnaireToSave.data,
            (res) => {
              if (res.ok) {
                closeModal()
              }
            },
          )
        }
      }
    } else {
      setMainItemError(questionnaireToSave.validateObject.error.main)
      setQuestionsError(questionnaireToSave.validateObject.error.questions)
      const userMessage = questionnaireToSave.errorMessage
        ? questionnaireToSave.errorMessage.map((erOb) => [
            erOb.message,
            `on path: "${erOb.path.join(',').split(',').join('/')}"`,
          ])
        : 'This questionnaire is not valid'
      // type
      userMessage
        .slice(0, 4)
        .forEach((messageItem) => addUserMessage('warning', messageItem))
    }

    // checkIfQuestionnaireValid;
  }
  const initModal = () => {
    /**
     * Init the modal any way so if edit first user see empty fields
     * */
    setSectionOpen('main')
    setMainItem(initMainItem)
    setMainItemError(initMainError)
    setQuestionsError(initQuestionsError)
    setQuestionsItem(initQuestionsItems)

    if (type === 'create') {
      setSectionOpen('main')
      setMainItem(initMainItem)
      setMainItemError(initMainError)
      setQuestionsError(initQuestionsError)
      setQuestionsItem(initQuestionsItems)
    } else if (type === 'edit' && element && element.id) {
      getOneQuestionnaireItem(element.id).then((quest) => {
        const mainItemSet = globalFunction.getEditMainItem(quest)
        setSectionOpen('main')
        setMainItem(mainItemSet)
        setMainItemError(initMainError)
        const getQuestions = globalFunction.getEditQuestionsItem(quest)
        // setQuestionsItem(getQuestions);
        setQuestionsItem(getQuestions)
        setQuestionsError(initQuestionsError)
      })
    }
  }
  const customStyles = {
    content: {
      height: '100%',
      top: '0px',
      right: '0px',
      left: '0px',
      bottom: '0px',
      marginLeft: '273px',
      transform: 'translate(0%, 0%)',
      padding: '0px',
    },
  }
  return (
    <Modal
      isOpen={isOpen}
      style={customStyles}
      contentLabel="Example Modal"
      ariaHideApp={false}
      onAfterOpen={initModal}
    >
      <div className="modal-wrap all-size-modal-wrap">
        <div className="modal-header">
          <div>
            {type === 'create'
              ? 'Create new Questionnaire'
              : 'Edit Questionnaire'}
          </div>
          <IconButton
            onClick={() => {
              closeModal()
            }}
            type="close"
          />
        </div>
        <div className="modal-body">
          <MainItem
            disableType={type === 'edit'}
            mainItem={mainItem}
            setMainItem={setMainItem}
            nextClick={() => {
              setSectionOpen('questions')
              if (!questionsItem.length) {
                addNewQuestion(true)
              }
            }}
            sectionOpen={sectionOpen}
            openMenu={() => {
              setSectionOpen('main')
            }}
            error={mainItemError}
          />
          <QuestionsItem
            questionnaireType={mainItem.type}
            sectionOpen={sectionOpen}
            questionArray={questionsItem}
            addQuestionsClick={addNewQuestion}
            openClosetQuest={openClosetQuest}
            setQuestionArray={setQuestionsItem}
            error={questionsError}
          />
        </div>
        <div className="modal-footer">
          <ButtonNew type="link" text="Cancel" onClick={closeModal} />
          <ButtonNew
            text="Save"
            onClick={saveQuestionnaireClick}
            type="primary"
          />
        </div>
      </div>
    </Modal>
  )
}

function mapStateToProps(state) {
  return {}
}

const mapDispatchToProps = {
  showNotImplementedFunc: globalAction.showNotImplementedDialog,
  addUserMessage: globalAction.addUserMessage,
  saveNewToolsQuestionnaire: toolsActions.createQuestionnaireItem,
  saveNewTenantQuestionnaire: tenantsActions.createQuestionnaireItem,
  editToolsQuestionnaire: toolsActions.saveEditQuestionnaireItem,
  editTenantQuestionnaire: tenantsActions.saveEditQuestionnaireItem,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CreateEditQuestionnaire)
