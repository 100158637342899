// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.form-line {
  display: grid;
  grid-template-columns: 25% 75%;
  align-items: center;
}
.form-line-text input[type="text"],
.form-line-text input[type="number"],
.form-line-text input[type="date"],
.form-line-text select, 
.form-line-text  .dashboard-datepicker{
  width: 100%;
}
.form-comp {
  display: grid;
  grid-row-gap: 10px;
  padding: 10px;
}
.form-line-end-text {
  display: flex;
  align-items: center;
  align-content: stretch;
}

.form-line-end-text input {
  width: 70% !important;
}

span.end-text {
  flex-grow: 1;
}
input.invalid-field,
select.invalid-field,
textarea.invalid-field {
  border-color: #d24667 !important;
}

.form-border-bottom {
  border-bottom: 1px solid #dadcdf;
}
textarea {
  resize: none;
}
.form-line-text .react-datepicker-wrapper {
  width: 100%;
}
.form-line-text.text-field {
  align-self: start;
  margin-top: 10px;
}
`, "",{"version":3,"sources":["webpack://./src/components/common/Form/style.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,8BAA8B;EAC9B,mBAAmB;AACrB;AACA;;;;;EAKE,WAAW;AACb;AACA;EACE,aAAa;EACb,kBAAkB;EAClB,aAAa;AACf;AACA;EACE,aAAa;EACb,mBAAmB;EACnB,sBAAsB;AACxB;;AAEA;EACE,qBAAqB;AACvB;;AAEA;EACE,YAAY;AACd;AACA;;;EAGE,gCAAgC;AAClC;;AAEA;EACE,gCAAgC;AAClC;AACA;EACE,YAAY;AACd;AACA;EACE,WAAW;AACb;AACA;EACE,iBAAiB;EACjB,gBAAgB;AAClB","sourcesContent":[".form-line {\n  display: grid;\n  grid-template-columns: 25% 75%;\n  align-items: center;\n}\n.form-line-text input[type=\"text\"],\n.form-line-text input[type=\"number\"],\n.form-line-text input[type=\"date\"],\n.form-line-text select, \n.form-line-text  .dashboard-datepicker{\n  width: 100%;\n}\n.form-comp {\n  display: grid;\n  grid-row-gap: 10px;\n  padding: 10px;\n}\n.form-line-end-text {\n  display: flex;\n  align-items: center;\n  align-content: stretch;\n}\n\n.form-line-end-text input {\n  width: 70% !important;\n}\n\nspan.end-text {\n  flex-grow: 1;\n}\ninput.invalid-field,\nselect.invalid-field,\ntextarea.invalid-field {\n  border-color: #d24667 !important;\n}\n\n.form-border-bottom {\n  border-bottom: 1px solid #dadcdf;\n}\ntextarea {\n  resize: none;\n}\n.form-line-text .react-datepicker-wrapper {\n  width: 100%;\n}\n.form-line-text.text-field {\n  align-self: start;\n  margin-top: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
