import React, { useState, useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import Modal from 'react-modal'
import IconButton from '../../common/IconButton/IconButton'
import ButtonNew from '../../common/Button/ButtonNew'
import InputError from '../../common/InputError/InputError'
import CameraPlaceHolder from '../../../image/icon_camera_placeholder.svg'
import './edit-element-style.less'
import { connect } from 'react-redux'
import * as globalAction from '../../../redux/actions/globalAction'

const initElement = Object.freeze({
  name: '',
  description: '',
  imageType: 'url_picture',
  imageUrl: '',
  image: {
    file: '',
    imagePreviewUrl: '',
  },
})
function EditElementDialog({
  isOpen,
  onClose,
  saveClick,
  type = 'tenant',
  actionType,
  showUserMessage,
  element,
}) {
  const typeShow = type === 'program' ? 'Program' : 'Tenant'
  const actionShow = actionType === 'edit' ? 'Edit' : 'Create'
  const fileImageInput = useRef(null)
  const [disableSave, setDisableSave] = useState(false)
  const [elementInputFiled, setElementInputFiled] = useState(
    Object.assign({}, initElement, {
      name: element ? element.name : '',
      description: element ? element.description : '',
      imageUrl: element ? element.logo : '',
    }),
  )
  const [errorObject, setErrorObject] = useState({})

  useEffect(() => {
    if (element) {
      setElementInputFiled(
        Object.assign({}, elementInputFiled, {
          name: element ? element.name : '',
          description: element ? element.description : '',
          imageUrl: element ? element.logo : '',
        }),
      )
    }
  }, [element])

  const initModal = () => {
    setDisableSave(false)
    setErrorObject({})
    setElementInputFiled(
      Object.assign({}, initElement, {
        name: element ? element.name : '',
        description: element ? element.description : '',
        imageUrl: element ? element.logo : '',
      }),
    )
  }

  const pictureTypeHasChange = (event) => {
    // At that moment only image url!!!
    const changeParams = { imageType: event.target.name }
    if (
      changeParams.imageType === 'actual_picture' ||
      changeParams.imageType === 'url_picture'
    ) {
      return
    }
    if (changeParams.imageType) {
      /**
       * At that moment there is no actual image upload file.
       * */
      return
    }
    if (changeParams.imageType === 'actual_picture') {
      setElementInputFiled(
        Object.assign({}, elementInputFiled, {
          imageType: changeParams.imageType,
          imageUrl: '',
        }),
      )
    } else if (changeParams.imageType === 'url_picture') {
      setElementInputFiled(
        Object.assign({}, elementInputFiled, {
          imageType: changeParams.imageType,
          image: {
            file: '',
            imagePreviewUrl: '',
          },
        }),
      )
    }
  }

  const saveOnClick = () => {
    setErrorObject({})
    const saveElement = {
      name: elementInputFiled.name,
      description: elementInputFiled.description,
      logo: elementInputFiled.imageUrl,
    }
    let errorObject = {}
    let ok = true
    if (element && element.id) {
      saveElement.id = element.id
    }
    if (!saveElement.name) {
      ok = false
      errorObject.name = 'Name is required'
    }
    if (!saveElement.description) {
      ok = false
      errorObject.description = 'Description is required'
    }
    if (!saveElement.logo) {
      ok = false
      errorObject.log = 'Logo url is required'
    }
    if (!ok) {
      showUserMessage('warning', 'This not valid ' + typeShow)
      setErrorObject(errorObject)
    } else {
      setDisableSave(true)
      saveClick(saveElement, (res) => {
        setDisableSave(false)
        if (res.ok) {
          onClose()
        }
      })
    }
  }
  const customStyles = {
    content: {
      maxHeight: '90%',
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      padding: '0px',
      height: '400px',
      // minHeight: "477px",
      // maxHeight: "80%"
    },
  }

  const uploadImageClick = () => {
    fileImageInput.current.click()
  }
  const deleteImageClick = () => {
    fileImageInput.current.value = null
    setElementInputFiled(
      Object.assign({}, elementInputFiled, {
        image: {
          file: '',
          imagePreviewUrl: '',
        },
      }),
    )
  }
  const handleImageChange = (event) => {
    event.preventDefault()

    let reader = new FileReader()
    let file = event.target.files[0]

    reader.onloadend = () => {
      setElementInputFiled(
        Object.assign({}, elementInputFiled, {
          image: {
            file: file,
            imagePreviewUrl: reader.result,
          },
        }),
      )
    }

    reader.readAsDataURL(file)
  }
  // imagePreview
  let { imagePreviewUrl } = elementInputFiled.image //imagePreview.;

  const ImageElement = () => {
    return imagePreviewUrl ? (
      <img src={imagePreviewUrl} />
    ) : (
      <CameraPlaceHolder />
    )
  }
  return (
    <Modal
      isOpen={isOpen}
      style={customStyles}
      ariaHideApp={false}
      onAfterOpen={initModal}
    >
      <div className="modal-wrap large-modal-wrap">
        <div className="modal-header">
          <div>{actionShow + ' ' + typeShow}</div>
          <IconButton
            onClick={() => {
              onClose()
            }}
            type="close"
          />
        </div>
        <div className="modal-body element-element-body-modal">
          <div className="element-category-name">
            <div className="special-input-section">Logo</div>
            <div className="simple-input-section">Name</div>
            <div className="simple-input-section">Description</div>
          </div>
          <div className="element-category-details">
            <div className="special-input-section">
              <div className="radio-group">
                <div className="radio-element">
                  <input
                    type="radio"
                    name="url_picture"
                    value="url_picture"
                    checked={elementInputFiled.imageType === 'url_picture'}
                    onChange={pictureTypeHasChange}
                  />
                  <label />
                  <div className="radio-element-body">
                    <input
                      type="text"
                      value={elementInputFiled.imageUrl}
                      className="details-section"
                      placeholder="Enter Picture Url"
                      disabled={elementInputFiled.imageType !== 'url_picture'}
                      onChange={(event) =>
                        setElementInputFiled(
                          Object.assign({}, elementInputFiled, {
                            imageUrl: event.target.value,
                          }),
                        )
                      }
                    />
                  </div>
                </div>
                <InputError
                  text={
                    errorObject && errorObject.log
                      ? 'This field is required'
                      : ''
                  }
                  showError={errorObject && errorObject.log}
                />
                <div className="radio-element">
                  <input
                    type="radio"
                    name="actual_picture"
                    value="actual_picture"
                    checked={elementInputFiled.imageType === 'actual_picture'}
                    onChange={pictureTypeHasChange}
                  />
                  <label />
                  <div className="radio-element-body">
                    <ImageElement />
                    <input
                      onChange={handleImageChange}
                      className="not-show-input"
                      ref={fileImageInput}
                      type="file"
                      accept="image/x-png,image/jpeg"
                    />
                    <div className="image-button-section">
                      <ButtonNew
                        text="Upload Image"
                        type="secondary"
                        onClick={uploadImageClick}
                        disable={elementInputFiled.imageType === 'url_picture'}
                      />
                      <ButtonNew
                        text="Delete"
                        type="link"
                        onClick={deleteImageClick}
                        disable={elementInputFiled.imageType === 'url_picture'}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="simple-input-section">
              <input
                type="text"
                placeholder={'Enter ' + typeShow + ' Name'}
                value={elementInputFiled.name}
                onChange={(event) =>
                  setElementInputFiled(
                    Object.assign({}, elementInputFiled, {
                      name: event.target.value,
                    }),
                  )
                }
              />
            </div>
            <InputError
              text={
                errorObject && errorObject.name ? 'This field is required' : ''
              }
              showError={errorObject && errorObject.name}
            />
            <div className="simple-input-section">
              <input
                type="text"
                placeholder="Enter Description"
                value={elementInputFiled.description}
                onChange={(event) =>
                  setElementInputFiled(
                    Object.assign({}, elementInputFiled, {
                      description: event.target.value,
                    }),
                  )
                }
              />
            </div>
            <InputError
              text={
                errorObject && errorObject.description
                  ? 'This field is required'
                  : ''
              }
              showError={errorObject && errorObject.description}
            />
          </div>
        </div>
        <div className="modal-footer">
          <ButtonNew type="link" text={'cancel'} onClick={onClose} />

          <ButtonNew
            text={'Save'}
            onClick={saveOnClick}
            type="primary"
            disable={disableSave}
          />
        </div>
      </div>
    </Modal>
  )
}

EditElementDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  saveClick: PropTypes.func.isRequired,
  showUserMessage: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
  actionType: PropTypes.oneOf(['create', 'edit']).isRequired,
  element: PropTypes.object,
}

function mapStateToProps(state) {
  return {}
}

const mapDispatchToProps = {
  showUserMessage: globalAction.addUserMessage,
}

export default connect(mapStateToProps, mapDispatchToProps)(EditElementDialog)
