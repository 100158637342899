import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import Popup from '../../../../../common/Popup'
import Button from '../../../../../common/Button/ButtonNew'
import { CONFIGURATION_STRINGS } from '../../../../../utilities/enum'

const ReplaceValuesPopup = ({
  handleClose,
  values,
  handleReplaceValue,
  handleCancelReplaceValue,
}) => {
  const [data, setData] = useState()

  const getValuesObject = () => {
    let obj = {}

    values &&
      values.forEach((element) => {
        if (!obj[element.field]) obj[element.field] = []

        obj[element.field].push({
          index: obj[element.field].length,
          value: element.value,
          newValue: element.newValue,
          providersCount: element.providersCount,
        })
      })

    return obj
  }

  useEffect(() => {
    setData(getValuesObject())
  }, [values])

  return (
    <Popup
      title="Replace values"
      onClosed={() => {
        handleClose()
      }}
      buttons={[
        {
          text: 'Cancel',
          type: 'primary',
          onClick: () => {
            handleClose()
          },
        },
      ]}
    >
      <div>
        <div className="message">
          Some values have been defined for providers. You need to provide a
          change values.
        </div>
        {data ? (
          Object.keys(data).map((field, i) => {
            return (
              <div key={i + '_' + field} className="field-group">
                <div className="title">
                  <b>{CONFIGURATION_STRINGS[field]}</b>
                </div>
                <div className="list">
                  {data[field].map((element, y) => {
                    return (
                      <div key={element + '_' + y} className="replace-value">
                        <span>
                          {typeof element.value === 'string'
                            ? element.value
                            : element.value.name}{' '}
                          ({element.providersCount}{' '}
                          {element.providersCount > 1
                            ? 'providers'
                            : 'provider'}
                          )
                        </span>
                        <input
                          type="text"
                          value={
                            field !== 'insurancePlans'
                              ? element.newValue
                              : element.newValue.name
                          }
                          onChange={(e) => {
                            setData({
                              ...data,
                              [field]: data[field].map((_element) => {
                                if (_element.index !== element.index)
                                  return _element

                                const newValue =
                                  field !== 'insurancePlans'
                                    ? e.target.value
                                    : {
                                        ...element.newValue,
                                        name: e.target.value,
                                      }

                                return {
                                  ..._element,
                                  newValue,
                                }
                              }),
                            })
                          }}
                        />
                        <Button
                          type="link"
                          onClick={() => {
                            handleCancelReplaceValue(field, element.value)
                          }}
                          text="Keep current value"
                        />
                        <Button
                          type="primary"
                          onClick={() => {
                            handleReplaceValue(
                              field,
                              element.value,
                              element.newValue,
                            )
                          }}
                          text="Change"
                        />
                      </div>
                    )
                  })}
                </div>
              </div>
            )
          })
        ) : (
          <span></span>
        )}
      </div>
    </Popup>
  )
}

export default ReplaceValuesPopup
