import React, { useEffect, useState } from 'react'
import InputError from '../../../../../../common/InputError/InputError'
import * as constants from '../constants'

const NonTypeRule = ({
  item,
  alertRulesArray,
  setAlertRulesArray,
  onChangeType,
  error,
}) => {
  const onChangeFiled = (e, itemId, valueName) => {
    setAlertRulesArray(
      alertRulesArray.map((a) => {
        if (itemId === a.id) {
          let changeObj = {}
          changeObj[valueName] = e.target.value
          return Object.assign({}, a, changeObj)
        }
        return a
      }),
    )
  }
  return (
    <>
      <div className="rule-filed">
        <div className="rule-label">Type</div>
        <div className="rule-input">
          <select
            disabled={item && !item.isNewAlertRule}
            className=""
            value={item.type || ''}
            onChange={(e) => onChangeType(e, item.id)}
          >
            <option value="" disabled hidden>
              Select rule type
            </option>
            <option
              key={constants.UNCHANGED_LOCATION}
              value={constants.UNCHANGED_LOCATION}
            >
              Unchanged location
            </option>
            <option
              key={constants.ASSESSMENT_SCORE}
              value={constants.ASSESSMENT_SCORE}
            >
              Questionnaire score
            </option>
            <option
              key={constants.ASSESSMENT_ANSWER}
              value={constants.ASSESSMENT_ANSWER}
            >
              Answered on question
            </option>
            <option
              key={constants.INCOMPLETE_ASSESSMENT}
              value={constants.INCOMPLETE_ASSESSMENT}
            >
              Unanswered assessment
            </option>
            <option
              key={constants.CHAT_DISABLED}
              value={constants.CHAT_DISABLED}
            >
              Chat disabled
            </option>
          </select>
        </div>
      </div>
      <div className="rule-filed">
        <div className="rule-label">Name</div>
        <div className="rule-input">
          <input
            type="text"
            placeholder="Enter rule name"
            value={item.name}
            onChange={(e) => onChangeFiled(e, item.id, 'name')}
          />
        </div>
      </div>
      <InputError
        showError={error && error.name}
        text={constants.FAILED_ERROR_TEXT}
      />
      <div className="rule-filed">
        <div className="rule-label">Alert text</div>
        <div className="rule-input">
          <input
            type="text"
            placeholder="Enter alert text"
            value={item.alertText}
            onChange={(e) => onChangeFiled(e, item.id, 'alertText')}
          />
        </div>
      </div>
      <InputError
        showError={error && error.text}
        text={constants.FAILED_ERROR_TEXT}
      />
    </>
  )
}

export default NonTypeRule
