import React from 'react'
import IconButton from '../../common/IconButton/IconButton'
import SuccessIcon from '../../../image/icon_success.svg'
import WarningIcon from '../../../image/icon_warning.svg'
/**
 * Every item has {type: "{success/warning}", text: "..."}
 * */
const messageItem = ({ type, text, key, deleteItem }) => {
  const className =
    type === 'success'
      ? 'user-message-item success-message'
      : 'user-message-item warning-message'
  const typeText = type
  const IconMessage = () => {
    if (type === 'success') {
      return <SuccessIcon />
    } else {
      return <WarningIcon />
    }
  }
  let textShowArray = text instanceof Array ? text : [text]
  return (
    <div className={className} key={key}>
      <div className="message-icon">
        <IconMessage />
      </div>
      <div className="message-body">
        <div className="message-title">{typeText}</div>
        {textShowArray.map((textItem, index) => {
          return (
            <div key={'user-message-key-index-' + index}>
              {textItem}
              <br />
            </div>
          )
        })}
      </div>
      <div className="close-icon">
        <IconButton onClick={deleteItem} type="close" />
      </div>
    </div>
  )
}

export default messageItem
