import React from 'react'
import PropTypes from 'prop-types'
import ChipsItem from '../../common/ChipsItem/ChipsItem'
import BoldSubString from '../../common/BoldSubString/BoldSubString'
import './style.less'

const SearchTextOptions = ({
  placeholder = '',
  inputValue,
  inputOnChange,
  optionsArray,
  valueArray,
  deleteItem,
  addItem,
}) => {
  const optionArrayMatch =
    optionsArray.length && inputValue && inputValue.length
      ? optionsArray.filter((option) => {
          return option.toLowerCase().includes(inputValue.toLowerCase())
        })
      : []
  const OpenOptionsView = () => {
    if (!optionArrayMatch.length && inputValue && inputValue.length) {
      return (
        <div className="not-existing-tag-option">
          <div className="explanation-text">No existing tags</div>
          <div className="create-tag-text" onClick={() => addItem(inputValue)}>
            {'Create tag "'}
            <span>{inputValue}</span>
            {'"'}
          </div>
        </div>
      )
    }
    return optionArrayMatch.map((item, index) => {
      return (
        <div
          className="option-item"
          key={'option_' + item + '_' + (index + 1)}
          onClick={() => addItem(item)}
        >
          <BoldSubString boldStr={inputValue} str={item} />
        </div>
      )
    })
  }
  const showSelectOptions = inputValue && inputValue.length

  return (
    <div className="search-text-input-wrap">
      <input
        type="text"
        value={inputValue}
        onChange={inputOnChange}
        placeholder={placeholder}
      />
      {showSelectOptions && (
        <div className="suggest-options">
          <OpenOptionsView />
        </div>
      )}
      {valueArray && (
        <div className="selected-options">
          {valueArray.length > 0 &&
            valueArray.map((option, index) => {
              return (
                <ChipsItem
                  key={option + index}
                  deleteClick={() => deleteItem(option)}
                  text={option}
                />
              )
            })}
        </div>
      )}
    </div>
  )
}

SearchTextOptions.propTypes = {
  placeholder: PropTypes.string,
  inputValue: PropTypes.string,
  inputOnChange: PropTypes.func.isRequired,
  optionsArray: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
  valueArray: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
  deleteItem: PropTypes.func.isRequired,
  addItem: PropTypes.func.isRequired,
}

export default SearchTextOptions
