import React from 'react'
import { connect } from 'react-redux'

function AuthComponentCheck({ history, redirectToLogin }) {
  console.log('AuthComponentCheck component redirectToLogin; ', redirectToLogin)
  if (redirectToLogin) {
    history.push('/login')
  }
  return <div />
}

function mapStateToProps(state) {
  return {
    redirectToLogin: state.globalReducer.redirectToLogin,
  }
}

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(AuthComponentCheck)
