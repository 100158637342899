/**
 *       <div className="add-exception-popup">
        <FormComponent
          fields={[
            {
              text: "Status",
              key: "Status",
              type: "select",
              select: {
                true: "Open",
                false: "Closed"
              },
            },
            {
              text: "Text",
              key: "ClosedMessage",
              type: "text",
            },
            {
              key: "isDayRange",
              text: "Day Range",
              type: "switch",
            },
            {
              key: "days",
              text: "Day range",
              type: "fromTo"
            }
          ]}
        />
      </div>
 */
import React from 'react'
import PropTypes from 'prop-types'
import './style.css'
import DDatePicker from '../DDatePicker'

export default function FormComponent({
  fields,
  data = {},
  handleDataChange,
  displayInvalid = false,
}) {
  const changeData = (key, value) => {
    const newData = { ...data }
    newData[key] = value

    handleDataChange(newData)
  }

  const getRangeType = (field) => {
    /**
           *     {
            key: "days",
            text: "Day range",
            type: "range",
            range: {
              from: {
                type: "date"
              },
              word: "to",
              to: {
                type: "date"
              }
            }
          },
           */
    return (
      <div className="form-range">
        <div className="form-range-part">{getInput(field.range.from)}</div>
        <div className="form-range-link">{field.range.word}</div>
        <div className="form-range-part">{getInput(field.range.to)}</div>
      </div>
    )
  }

  const isFieldInvalid = (field) => {
    if (!field.required && !field.invalid) return false

    if (field.required) {
      if (field.type === 'number') {
        if (data[field.key] == undefined) {
          //do not add a third "="
          return true
        }

        return false
      }

      if (field.type === 'date') {
        if (data[field.key] instanceof Date) {
          return isNaN(data[field.key])
        }
        const d = new Date(data[field.key])
        return isNaN(d)
      }

      if (!data[field.key] || !data[field.key].length) {
        return true
      }
    }

    return field.invalid
  }

  const InvalidField = ({ message }) => {
    return (
      <div className="invalid-message small-font">
        {message || 'Required field'}
      </div>
    )
  }

  const getInput = (field, invalid) => {
    let fieldClass = invalid ? 'invalid-field' : ''

    if (field.fieldClass) {
      fieldClass += ' ' + field.fieldClass
    }

    switch (field.type) {
      case 'text':
        return (
          <input
            type={field.type}
            value={data[field.key] || ''}
            disabled={field.disabled}
            className={fieldClass}
            // defaultValue={data[field.key]}
            placeholder={field.placeholder}
            onChange={(e) => {
              changeData(field.key, e.target.value)
            }}
          />
        )
      case 'number':
        return (
          <input
            type={field.type}
            value={data[field.key]}
            disabled={field.disabled}
            className={fieldClass}
            min={field.min}
            placeholder={field.placeholder}
            onChange={(e) => {
              changeData(
                field.key,
                e.target.value && e.target.value.length
                  ? parseInt(e.target.value)
                  : null,
              )
            }}
          />
        )
      case 'date':
        return (
          <DDatePicker
            onChange={(date) => {
              changeData(field.key, date)
            }}
            value={
              data[field.key] instanceof Date
                ? data[field.key]
                : data[field.key] && new Date(data[field.key])
            }
            options={field.options}
            disabled={field.disabled}
            maxDate={field.max}
            minDate={field.min}
            className={fieldClass}
          />
        )
      case 'select': {
        let options = Object.keys(field.select)
        return (
          <select
            value={data[field.key] || ''}
            disabled={
              field.disabled || (field.getDisable && field.getDisable(data))
            }
            className={fieldClass}
            onChange={(e) => {
              changeData(field.key, e.target.selectedOptions[0].value)
            }}
          >
            {field.emptyOption && (!field.required || !data[field.key]) && (
              <option value>{field.emptyOption}</option>
            )}
            {options.map((o, i) => {
              return (
                <option key={i} value={o}>
                  {field.select[o]}
                </option>
              )
            })}
          </select>
        )
      }

      case 'textarea':
        return (
          <textarea
            value={data[field.key]}
            disabled={field.disabled}
            rows={field.rows || '4'}
            cols={field.cols || '50'}
            className={fieldClass}
            placeholder={field.placeholder}
            onChange={(e) => {
              changeData(field.key, e.target.value)
            }}
          />
        )
      case 'checkbox':
        return (
          <input
            type="checkbox"
            defaultChecked={data[field.key]}
            onChange={(e) => {
              changeData(field.key, e.target.checked)
            }}
          />
        )
      case 'range': {
        return getRangeType(field)
      }
      case 'special':
        return field.component
      default:
        break
    }
  }

  return (
    <div className="form-comp">
      {fields
        .filter((a) => {
          return !a.getVisible || a.getVisible(data)
        })
        .map((field, index) => {
          const invalid = displayInvalid && isFieldInvalid(field)

          return (
            <React.Fragment key={index}>
              <div
                className={
                  'form-line ' + (!field.lineClass ? '' : field.lineClass)
                }
                key={index}
              >
                <div className="form-line-text text-field">
                  {field.text}
                  {typeof field.text === 'string' && field.required ? (
                    <span style={{ color: '#D24667' }}>*</span>
                  ) : (
                    ''
                  )}
                </div>
                <div
                  className={
                    'form-line-text ' +
                    (!field.endText ? '' : 'form-line-end-text')
                  }
                >
                  {/* <DisplayedInput field={field} /> */}
                  <div>
                    {getInput(field, invalid)}
                    {invalid && <InvalidField message={field.invalidMessage} />}
                  </div>
                  {field.endText ? (
                    <span className="end-text"> {field.endText}</span>
                  ) : (
                    ''
                  )}
                </div>
              </div>
              {field.border && <div className="form-border-bottom"></div>}
            </React.Fragment>
          )
        })}
    </div>
  )
}

FormComponent.propTypes = {
  fields: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      text: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
      type: PropTypes.oneOf([
        'text',
        'number',
        'select',
        'checkbox',
        'date',
        'time',
        'range',
        'switch',
        'textarea',
        'special',
      ]),
      placeholder: PropTypes.string,
      fieldClass: PropTypes.string,
      disabled: PropTypes.bool,
      getDisable: PropTypes.func,
      invalid: PropTypes.bool,
      required: PropTypes.bool,
      displayTime: PropTypes.bool, //for date type
      select: PropTypes.object,
      range: PropTypes.shape({
        from: PropTypes.shape({
          type: PropTypes.string,
        }),
        word: PropTypes.string, //word between two fields
        to: PropTypes.shape({
          type: PropTypes.string,
        }),
      }),
    }),
  ).isRequired,
  data: PropTypes.object,
  handleDataChange: PropTypes.func,
}
