import React from 'react'
import PropTypes from 'prop-types'
import Close from '../../../image/icon_close.svg'
import Plus from '../../../image/icon_plus.svg'
import Link from '../../../image/icon_link.svg'
import './style.less'
const iconPath = {
  plus: Plus,
  close: Close,
  link: Link,
}

const IconButton = ({ labelText, onClick, type, disable }) => {
  const Icon = iconPath[type]
  const iconStyle = {}
  const className = disable
    ? 'icon-button-component disable-btn'
    : 'icon-button-component'
  if (onClick) {
    return (
      <div className={className} onClick={onClick}>
        {iconPath[type] && <Icon />}
        {labelText && <span>{labelText}</span>}
      </div>
    )
  }
  return (
    <div className={className + ' not-btn'}>
      {iconPath[type] && <Icon style={iconStyle} />}
      {labelText && <span>{labelText}</span>}
    </div>
  )
}

IconButton.propTypes = {
  labelText: PropTypes.string,
  onClick: PropTypes.func,
  type: PropTypes.string.isRequired,
  disable: PropTypes.bool,
}
export default IconButton
