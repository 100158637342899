import React from 'react'
import PropTypes from 'prop-types'
import { NavLink } from 'react-router-dom'
import './style.less'

const NavBar = ({ bars }) => {
  const activeStyle = {
    color: '#54585D',
    borderBottom: '3px solid #A182EC',
    fontWeight: 900,
  }
  return (
    <nav className="component-headers-nav-link">
      {bars.map((bar, index) => {
        return (
          <NavLink
            key={bar.link + bar.text + (index + 1)}
            to={bar.link}
            activeStyle={activeStyle}
            onClick={onclick}
          >
            <span>{bar.text}</span>
          </NavLink>
        )
      })}
    </nav>
  )
}

NavBar.propTypes = {
  onclick: PropTypes.func,
  bars: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string.isRequired,
      link: PropTypes.string.isRequired,
    }),
  ).isRequired,
}

export default NavBar
