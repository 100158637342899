import constant from './constant'
import { parseRes, parseError } from './parseResponse'
import * as storageConfig from './storageConfig'

const baseUrl = `${constant.baseUrl}/api/tenant` // http://localhost:9000/api/tenant";
console.log(baseUrl)
export function getTenants() {
  return new Promise((resolve, reject) => {
    fetch(baseUrl, {
      headers: {
        authorization: storageConfig.getToken(),
        'Content-Type': 'application/json',
      },
    })
      .then(parseRes)
      .then(function (data) {
        resolve(data)
      })
      .catch((err) => {
        parseError(err).then((errMessage) => {
          reject(errMessage)
        })
      })
  })
}

export function getProgramsByTenantId(tenantId) {
  const url = `${baseUrl}/${tenantId}/program`
  return new Promise((resolve, reject) => {
    fetch(url, {
      headers: {
        authorization: storageConfig.getToken(),
        'Content-Type': 'application/json',
      },
    })
      .then(parseRes) // Transform the data into json
      .then(function (data) {
        resolve(data)
      })
      .catch((err) => {
        parseError(err).then((errMessage) => {
          reject(errMessage)
        })
      })
  })
}

// Get tenants Questionnaires/Sentiments
export function getTenantQuestionnaires(tenantId, type = 'Questionnaire') {
  const url = `${baseUrl}/${tenantId}/tools/questionnaires?source=all&type=${type}`
  return new Promise((resolve, reject) => {
    fetch(url, {
      headers: {
        authorization: storageConfig.getToken(),
        'Content-Type': 'application/json',
      },
    })
      .then(parseRes) // Transform the data into json
      .then(function (data) {
        resolve(data)
      })
      .catch((err) => {
        parseError(err).then((errMessage) => {
          reject(errMessage)
        })
      })
  })
}
export function getTenantMedia(tenantId) {
  const url = `${baseUrl}/${tenantId}/tools/media?source=all`
  return new Promise((resolve, reject) => {
    fetch(url, {
      headers: {
        authorization: storageConfig.getToken(),
        'Content-Type': 'application/json',
      },
    })
      .then(parseRes) // Transform the data into json
      .then(function (data) {
        resolve(data)
      })
      .catch((err) => {
        parseError(err).then((errMessage) => {
          reject(errMessage)
        })
      })
  })
}

export function getTenantConsentForm(tenantId) {
  // Todo check what there is no source query.
  const url = `${baseUrl}/${tenantId}/tools/consent-forms?source=all`
  return new Promise((resolve, reject) => {
    fetch(url, {
      headers: {
        authorization: storageConfig.getToken(),
        'Content-Type': 'application/json',
      },
    })
      .then(parseRes) // Transform the data into json
      .then(function (data) {
        resolve(data)
      })
      .catch((err) => {
        parseError(err).then((errMessage) => {
          reject(errMessage)
        })
      })
  })
}

export function saveMediaItem(item, tenantId) {
  const url = `${baseUrl}/${tenantId}/tools/media`
  return new Promise((resolve, reject) => {
    fetch(url, {
      method: 'POST',
      headers: {
        authorization: storageConfig.getToken(),
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(item),
    })
      .then(parseRes) // Transform the data into json
      .then(function (data) {
        resolve(data)
      })
      .catch((err) => {
        parseError(err).then((errMessage) => {
          reject(errMessage)
        })
      })
  })
}
export function updateMediaItem(item, id, tenantId) {
  delete item.category
  const url = `${baseUrl}/${tenantId}/tools/media/${id}`
  return new Promise((resolve, reject) => {
    fetch(url, {
      method: 'PUT',
      headers: {
        authorization: storageConfig.getToken(),
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(item),
    })
      .then(parseRes) // Transform the data into json
      .then(function (data) {
        resolve(data)
      })
      .catch((err) => {
        parseError(err).then((errMessage) => {
          reject(errMessage)
        })
      })
  })
}

export function saveQuestionnaireItem(tenantId, item) {
  const url = `${baseUrl}/${tenantId}/tools/questionnaire`
  return new Promise((resolve, reject) => {
    fetch(url, {
      method: 'POST',
      headers: {
        authorization: storageConfig.getToken(),
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(item),
    })
      .then(parseRes) // Transform the data into json
      .then(function (data) {
        resolve(data)
      })
      .catch((err) => {
        parseError(err).then((errMessage) => {
          reject(errMessage)
        })
      })
  })
}
export function editQuestionnaireItem(tenantId, item, id) {
  delete item.type
  const url = `${baseUrl}/${tenantId}/tools/questionnaires/${id}`
  return new Promise((resolve, reject) => {
    fetch(url, {
      method: 'PUT',
      headers: {
        authorization: storageConfig.getToken(),
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(item),
    })
      .then(parseRes) // Transform the data into json
      .then(function (data) {
        resolve(data)
      })
      .catch((err) => {
        parseError(err).then((errMessage) => {
          reject(errMessage)
        })
      })
  })
}

export function createNewTenant(tenant) {
  const url = baseUrl
  return new Promise((resolve, reject) => {
    fetch(url, {
      method: 'POST',
      headers: {
        authorization: storageConfig.getToken(),
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ name: tenant.name, logo: tenant.logo }),
    })
      .then(parseRes) // Transform the data into json
      .then(function (data) {
        resolve(data)
      })
      .catch((err) => {
        parseError(err).then((errMessage) => {
          reject(errMessage)
        })
      })
  })
}

export function createNewProgram(program, tenantId) {
  const url = `${baseUrl}/${tenantId}/program`
  return new Promise((resolve, reject) => {
    fetch(url, {
      method: 'POST',
      headers: {
        authorization: storageConfig.getToken(),
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        name: program.name,
        logo: program.logo,
        description: program.description,
      }),
    })
      .then(parseRes) // Transform the data into json
      .then(function (data) {
        resolve(data)
      })
      .catch((err) => {
        parseError(err).then((errMessage) => {
          reject(errMessage)
        })
      })
  })
}

export function createNewConsentForm(tenantId, item) {
  const url = `${baseUrl}/${tenantId}/tools/consent-forms`

  return new Promise((resolve, reject) => {
    fetch(url, {
      method: 'POST',
      headers: {
        authorization: storageConfig.getToken(),
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ ...item, creatorId: '55c9b0489dae030300bf1518' }),
    })
      .then(parseRes) // Transform the data into json
      .then(function (data) {
        resolve(data)
      })
      .catch((err) => {
        parseError(err).then((errMessage) => {
          reject(errMessage)
        })
      })
  })
}
export function editConsentForm(tenantId, id, item) {
  const url = `${baseUrl}/${tenantId}/tools/consent-forms/${id}`

  return new Promise((resolve, reject) => {
    fetch(url, {
      method: 'PUT',
      headers: {
        authorization: storageConfig.getToken(),
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(item),
    })
      .then(parseRes) // Transform the data into json
      .then(function (data) {
        resolve(data)
      })
      .catch((err) => {
        parseError(err).then((errMessage) => {
          reject(errMessage)
        })
      })
  })
}
export function getOneQuestionnaireItem(id, tenantId) {
  const url = `${baseUrl}/${tenantId}/tools/questionnaires/${id}`
  return new Promise((resolve, reject) => {
    fetch(url, {
      method: 'GET',
      headers: {
        authorization: storageConfig.getToken(),
        'Content-Type': 'application/json',
      },
    })
      .then(parseRes) // Transform the data into json
      .then(function (data) {
        resolve(data)
      })
      .catch((err) => {
        parseError(err).then((errMessage) => {
          reject(errMessage)
        })
      })
  })
}
