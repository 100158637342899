import React, { useEffect } from 'react'
import { Route, Switch } from 'react-router-dom'
import NavBar from '../../../common/NavBar/NavBar'
import Settings from './ProgramTabs/Settings/Settings'
import Configuration from './ProgramTabs/Configuration/Configuration'
import Toolbox from './ProgramTabs/Toolbox/Toolbox'
import Providers from './ProgramTabs/Providers/Providers'
import Treatments from '../TenantsBodyContent/ProgramTabs/Treatments'
import Notification from '../TenantsBodyContent/ProgramTabs/Notification/Notification'
import AlertRules from './ProgramTabs/AlertRules/AlertRules'
import PropTypes from 'prop-types'
import { selectProgram } from '../../../../redux/actions/tenantsActions'
import { connect } from 'react-redux'

function ProgramsBodyPage({ tenant, pathname, baseRoute, selectProgram }) {
  const routeArray = pathname.split('/')

  useEffect(() => {
    if (routeArray < 6 || !Object.keys(tenant).length) return
    selectProgram(tenant.id, routeArray[5])
  }, [tenant && tenant.id, routeArray.length])

  if (routeArray.length < 6) {
    return <div />
  }

  const programsId = routeArray[5]
  const program = tenant.programs
    ? tenant.programs.find((item) => item.id === programsId)
    : null
  if (!program) {
    return <div />
  }

  const barsTenantPage = [
    {
      text: 'Settings',
      link: baseRoute + '/programs/' + programsId + '/settings',
    },
    {
      text: 'Toolbox',
      link: baseRoute + '/programs/' + programsId + '/toolbox',
    },
    {
      text: 'Personnel',
      link: baseRoute + '/programs/' + programsId + '/personnel',
    },
    {
      text: 'Treatments',
      link: baseRoute + '/programs/' + programsId + '/treatments',
    },
    {
      text: 'Alert rules',
      link: baseRoute + '/programs/' + programsId + '/alert_rules',
    },
    {
      text: 'Notifications',
      link: baseRoute + '/programs/' + programsId + '/notifications',
    },
    {
      text: 'Configuration',
      link: baseRoute + '/programs/' + programsId + '/configuration',
    },
  ]

  return (
    <div className="tenant-component-body">
      <div className="tenant-component-headers">
        <NavBar bars={barsTenantPage} />
      </div>
      <Switch className="tenant-component-inside-body">
        <Route
          exact
          path="/home/tenants/:id/programs/:pId/settings"
          render={(props) => <Settings {...props} />}
        />
        <Route
          exact
          path="/home/tenants/:id/programs/:pId/toolbox"
          render={(props) => <Toolbox {...props} />}
        />
        <Route
          exact
          path="/home/tenants/:id/programs/:pId/personnel"
          render={(props) => <Providers {...props} />}
        />
        <Route
          exact
          path="/home/tenants/:id/programs/:pId/treatments"
          render={() => <Treatments />}
        />
        <Route
          exact
          path="/home/tenants/:id/programs/:pId/alert_rules"
          render={(props) => <AlertRules {...props} />}
        />
        <Route
          exact
          path="/home/tenants/:id/programs/:pId/notifications"
          render={(props) => <Notification {...props} />}
        />
        <Route
          exact
          path="/home/tenants/:id/programs/:pId/configuration"
          render={(props) => <Configuration {...props} />}
        />
        <Route
          path="/home/tenants/:id/programs/:pId/settings"
          render={() => <Settings />}
        />
      </Switch>
    </div>
  )
}

function mapStateToProps(state, ownProps) {
  return {
    tenant: ownProps.tenant,
    pathname: ownProps.pathname,
    baseRoute: ownProps.baseRoute,
  }
}

const mapDispatchToProps = {
  selectProgram,
}

ProgramsBodyPage.propTypes = {
  tenant: PropTypes.object.isRequired,
  pathname: PropTypes.string.isRequired,
  baseRoute: PropTypes.string.isRequired,
}

export default connect(mapStateToProps, mapDispatchToProps)(ProgramsBodyPage)
