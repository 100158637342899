import React, { useState } from 'react'
import Modal from 'react-modal'
import ReactMde from 'react-mde'
import * as Showdown from 'showdown'
import IconButton from '../../../common/IconButton/IconButton'
import ButtonNew from '../../../common/Button/ButtonNew'
import InputError from '../../../common/InputError/InputError'
import 'react-mde/lib/styles/css/react-mde-all.css'
import './create-conset-modal.less'

const converter = new Showdown.Converter({
  tables: true,
  simplifiedAutoLink: true,
  strikethrough: true,
  tasklists: true,
})

function CreateEditConsentForm({
  history,
  match,
  isOpen,
  closeModal,
  addUserMessage,
  saveConsentForm,
  type = 'create',
  element,
}) {
  const initConsentForm = {
    title: '',
    alias: '',
    format: '',
  }
  const [textValue, setTextValue] = useState('')
  const [selectedTab, setSelectedTab] = useState('write')
  const [fieldError, setFieldError] = useState({})
  const [consentFormItem, setConsentFormItem] = useState(initConsentForm)
  const initModal = () => {
    if (type === 'create') {
      setConsentFormItem(initConsentForm)
      setTextValue('')
      setSelectedTab('write')
    } else {
      // type === "edit"
      let consentFormEdit = {
        title: element.title,
        alias: element.aliasName,
        format: element.bodyFormat,
        id: element.id,
      }
      setConsentFormItem(consentFormEdit)
      setTextValue(element.body)
      setSelectedTab('write')
    }
    setFieldError({})
  }
  const customStyles = {
    content: {
      height: '100%',
      top: '0px',
      right: '0px',
      left: '0px',
      bottom: '0px',
      marginLeft: '273px',
      transform: 'translate(0%, 0%)',
      padding: '0px',
    },
  }
  const disableSave = () => {
    let newErrorObj = {}
    let disable = false
    if (!consentFormItem.title) {
      newErrorObj.title = 'This field is required'
      disable = true
    }
    if (!consentFormItem.alias) {
      newErrorObj.alias = 'This field is required'
      disable = true
    }
    if (!consentFormItem.format) {
      newErrorObj.format = 'This field is required'
      disable = true
    }
    if (!textValue) {
      newErrorObj.textValue = 'This field is required'
      disable = true
    }
    setFieldError(newErrorObj)
    return disable
  }

  const saveConsentFormLocal = () => {
    if (disableSave()) {
      return addUserMessage('warning', 'all field are required')
    }
    const payload = {
      title: consentFormItem.title,
      aliasName: consentFormItem.alias,
      body: textValue,
      bodyFormat: consentFormItem.format,
    }
    if (element && element.id) {
      payload.id = element.id
    }
    saveConsentForm(payload, (res) => {
      if (res.ok) {
        closeModal()
      }
    })
  }
  return (
    <Modal
      isOpen={isOpen}
      style={customStyles}
      contentLabel="Example Modal"
      ariaHideApp={false}
      onAfterOpen={initModal}
    >
      <div className="modal-wrap all-size-modal-wrap">
        <div className="modal-header">
          <div>
            {type === 'create'
              ? 'Create new Consent Form'
              : 'Edit Consent Form'}
          </div>
          <IconButton
            onClick={() => {
              closeModal()
            }}
            type="close"
          />
        </div>
        <div className="modal-body">
          <div className="wrap-create-consent-form">
            <div className="simple-input-field">
              <div className="input-label">Title</div>
              <div className="input-filed">
                <input
                  type="text"
                  placeholder="Enter Consent Form Name"
                  value={consentFormItem.title}
                  onChange={(e) => {
                    setConsentFormItem(
                      Object.assign({}, consentFormItem, {
                        title: e.target.value,
                      }),
                    )
                  }}
                />
              </div>
            </div>
            <InputError
              text={fieldError && fieldError.title ? fieldError.title : ''}
              showError={fieldError && fieldError.title}
            />
            <div className="simple-input-field">
              <div className="input-label">Alias</div>
              <div className="input-filed">
                <input
                  type="text"
                  placeholder="Enter Consent Form Alias Name"
                  value={consentFormItem.alias}
                  onChange={(e) => {
                    setConsentFormItem(
                      Object.assign({}, consentFormItem, {
                        alias: e.target.value,
                      }),
                    )
                  }}
                />
              </div>
            </div>
            <InputError
              text={fieldError && fieldError.alias ? fieldError.alias : ''}
              showError={fieldError && fieldError.alias}
            />
            <div className="simple-input-field">
              <div className="input-label">Format</div>
              <div className="input-filed">
                <select
                  className="details-section"
                  value={consentFormItem.format}
                  onChange={(e) => {
                    setConsentFormItem(
                      Object.assign({}, consentFormItem, {
                        format: e.target.value,
                      }),
                    )
                  }}
                >
                  <option value="" selected disabled hidden>
                    Select format
                  </option>
                  <option key="markdown" value="markdown">
                    Markdown
                  </option>
                </select>
              </div>
            </div>
            <InputError
              text={fieldError && fieldError.format ? fieldError.format : ''}
              showError={fieldError && fieldError.format}
            />
            <div className="simple-input-field">
              <div className="input-label text-label">Consent form text</div>
              <div className="input-filed">
                <ReactMde
                  value={textValue}
                  onChange={setTextValue}
                  selectedTab={selectedTab}
                  onTabChange={setSelectedTab}
                  generateMarkdownPreview={(markdown) =>
                    Promise.resolve(converter.makeHtml(markdown))
                  }
                />
              </div>
            </div>
            <InputError
              text={
                fieldError && fieldError.textValue ? fieldError.textValue : ''
              }
              showError={fieldError && fieldError.textValue}
            />
          </div>
        </div>
        <div className="modal-footer">
          <ButtonNew type="link" text={'Cancel'} onClick={() => closeModal()} />
          <ButtonNew
            text={'Save'}
            onClick={saveConsentFormLocal}
            type="primary"
          />
        </div>
      </div>
    </Modal>
  )
}

export default CreateEditConsentForm
