import React from 'react'
import './style.less'
import ButtonNew from '../../common/Button/ButtonNew'
import Program from '../../../image/icon_program.svg'
// type = program/
const ICON = {
  program: Program,
}
const TEXT = {
  program: [
    'There’s no Programs yet.',
    'Please create new Program by clicking this button',
  ],
}
const BUTTON_TEXT = {
  program: 'Create new Program',
}
const emptyContent = ({ onClick, type }) => {
  let text = TEXT[type] || '' // array for multiple line
  let Icon = ICON[type] || null
  let buttonText = BUTTON_TEXT[type]
  switch (type) {
    case 'program':
      return (
        <div className="empty-icon-wrapper">
          {Icon && (
            <div className="empty-icon-icon-content">
              <Icon />
            </div>
          )}
          {text.map((texRow, index) => (
            <div className="icon-text" key={'empty-icon-text-' + index}>
              {texRow}
            </div>
          ))}
          <ButtonNew onClick={onClick} type="secondary" text={buttonText} />
        </div>
      )
  }
  return <div />
}

export default emptyContent
