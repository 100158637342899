import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Route, Switch } from 'react-router-dom'
import * as tenantsActions from '../../redux/actions/tenantsActions'
import * as globalAction from '../../redux/actions/globalAction'
import TenantsList from './TenantsList'
import TenantPage from './TenantPage'
import './style.less'
import Header from './TenantHeader/TenantsListHeader'

function Tenants({
  tenants,
  loadTenants,
  loadPrograms,
  showNotImplementedFunc,
  showUserMessage,
  saveNewTenant,
  location,
  history,
}) {
  useEffect(() => {
    const tenantId =
      location.state && location.state.tenantId ? location.state.tenantId : null
    if (!tenants || tenants.length === 0) {
      loadTenants(tenantId)
      //if path is with tenantId load also programs
    }
  }, [])

  function tenantItemClick(tenantId) {
    //1. get the tenant
    //2. get the tenant program list. // /tenant/{id}/program
    if (!tenants || !tenants.length) {
      return
    }

    const tenant = tenants.find((tenantItem) => {
      return tenantItem.id === tenantId
    })

    if (tenant && !tenant.programs) {
      loadPrograms(tenantId)
    }

    history.push('/home/tenants/' + tenantId + '/programs')
  }

  return (
    <>
      <Header
        tenants={tenants}
        pathname={location.pathname.slice(1)}
        notImplementedFunc={showNotImplementedFunc}
        showUserMessage={showUserMessage}
        saveTenant={saveNewTenant}
      />
      <Switch>
        <Route
          path="/home/tenants/:id"
          render={(props) => <TenantPage {...props} />}
        />
        <Route
          render={() => (
            <TenantsList
              list={tenants}
              tenantClick={tenantItemClick}
              showNotImplemented={showNotImplementedFunc}
            />
          )}
        />
      </Switch>
    </>
  )
}

Tenants.propTypes = {
  tenants: PropTypes.array,
  loadTenants: PropTypes.func.isRequired,
  loadPrograms: PropTypes.func.isRequired,
  showNotImplementedFunc: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
}

function mapStateToProps(state) {
  return {
    tenants: state.tenantReducer,
  }
}

const mapDispatchToProps = {
  loadTenants: tenantsActions.loadTenants,
  loadPrograms: tenantsActions.loadPrograms,
  showNotImplementedFunc: globalAction.showNotImplementedDialog,
  showUserMessage: globalAction.addUserMessage,
  saveNewTenant: tenantsActions.createNewTenant,
}

export default connect(mapStateToProps, mapDispatchToProps)(Tenants)
