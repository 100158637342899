import React from 'react'
import './consent-form-list.less'
const ConsentFormListHeader = () => {
  return (
    <div className="consent-form-list-item consent-form-header">
      <div className="consent-form-list-name">Consent form</div>
      <div className="consent-form-list-privacy">Privacy</div>
    </div>
  )
}

export default ConsentFormListHeader
