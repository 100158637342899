import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import TableMenu from './tableMenu'
import TableRow from './tableRow'
import TableHeader from './tableHeader'
import OptionsContext, { useOptionsContext } from './optionsContext'
import * as tableDomain from './table.domain'
import './table.css'

// export const OptionsContext = React.createContext({});
/**
 * Component for create table
 * @param {Object} props - object with 3 parameters: header, data and options
 * @param {Object[]} props.header - ttt
 * @param {string} props.header[].key - ttt
 * @param {string} props.header[].name - ttt
 * @param {string} [props.header[].type] - "text" - "number" - "select" - "checkbox" - "customize"
 * @param {boolean} [props.header[].editable] - if column is editable. Default to false
 * @param {Function} [props.header[].getEditable] - ttt
 * @param {boolean} [props.header[].sortable] - ttt
 * @param {Object} [props.header[].select] - is mandatory if type is "select". Should be like {1: "red", 2: "blue", 3: "yellow"}
 *
 * @param {Object[]} props.data - ttt
 *
 * @param {Object} [props.options] - ttt
 * @param {Object} [props.options.addNew] - ttt
 * @param {string} [props.options.addNew.text] - ttt
 * @param {Function} [props.options.addNew.onclick] - ttt
 * @param {Object} [props.options.rowMenu] - ttt
 * @param {number} [props.options.editMode] - ttt
 * @param {boolean} [props.options.hideMenu] - ttt
 * @param {boolean} [props.options.search] - ttt
 * @param {string} [props.options.tableClass] - ttt
 * @param {string} [props.options.menuClass] - ttt
 * @param {string} [props.options.cellClass] - ttt
 * @param {string} [props.options.rowClass] - ttt
 *
 * @version 0.1.0
 *
 */
export default function TableComponent({ data, header, options }) {
  return (
    <OptionsContext.Provider value={options}>
      <TableContainer data={data} header={header} />
    </OptionsContext.Provider>
  )
}

TableComponent.propTypes = {
  data: PropTypes.array.isRequired,
  header: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      type: PropTypes.string.isRequired,
      editable: PropTypes.bool,
      getEditable: PropTypes.func,
      sortable: PropTypes.bool,
      select: PropTypes.object,
    }),
  ).isRequired,
  options: PropTypes.object.isRequired,
}

export function TableContainer({ data, header }) {
  const options = useOptionsContext(OptionsContext)

  const [editMode, setEditMode] = useState(options.editMode)
  const [tableData, setTableData] = useState(data)
  const [orderKey, setOrderKey] = useState()
  const [ascOrder, setAscOrder] = useState(true)
  const [searchText, setSearchText] = useState('')
  const internals = {
    table: null,
  }
  const initializeTable = () => {
    internals.table = new tableDomain.Table(data, header)
    setTableData(internals.table.list)
  }

  useEffect(() => {
    initializeTable()
  }, [data])

  const handleDataChange = (updatedRow) => {
    const newData = tableData.map((d) => {
      //TODO: refractor
      if (d.id !== updatedRow.id) return d
      return { ...updatedRow }
    })

    if (options.onChangeData) {
      options.onChangeData(newData)
    } else {
      setTableData(newData)
    }
  }

  const renderTableRow = (displayedData) => {
    return displayedData.map((d, index) => {
      return (
        <TableRow
          key={d.id !== undefined ? d.id : index}
          data={d}
          header={header}
          // onInitializeTable={initializeTable}
          orderKey={orderKey}
          editMode={editMode}
          handleDataChange={handleDataChange}
        ></TableRow>
      )
    })
  }

  const handleOrderBy = (orderBy) => {
    //TODO: test
    if (orderKey === orderBy) {
      setAscOrder(!ascOrder)
    } else {
      setOrderKey(orderBy)
      setAscOrder(true)
    }
  }

  return (
    <div className="table-container">
      {!options.hideMenu && (
        <TableMenu
          handleSearch={(value) => {
            setSearchText(value)
          }}
          // handleChangeEdit={() => {
          //   if (editMode) setEditMode(tableDomain.tableEditMode.NOT_EDITABLE);
          //   else setEditMode(options.editMode); //set initial state
          // }}
        ></TableMenu>
      )}
      <div className="basic-table-container">
        <table className={options.tableClass || 'basic-table'}>
          <thead>
            <TableHeader
              header={header}
              orderKey={orderKey}
              ascOrder={ascOrder}
              handleOrderBy={handleOrderBy}
            ></TableHeader>
          </thead>
          <tbody>
            {renderTableRow(
              tableDomain.getDisplayedData(
                tableData,
                header,
                searchText,
                orderKey,
                ascOrder,
              ),
            )}
          </tbody>
        </table>
      </div>
    </div>
  )
}

TableContainer.propTypes = {
  data: PropTypes.array.isRequired,
  header: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      name: PropTypes.string,
      type: PropTypes.string,
      editable: PropTypes.bool,
      getEditable: PropTypes.func,
      sortable: PropTypes.bool,
      select: PropTypes.object,
    }),
  ).isRequired,
}
