import * as types from './actionsType'
import * as toolsApi from '../../api/toolsApi'
import * as globalAction from './globalAction'
import * as storageConfig from '../../api/storageConfig'

export function loadToolsMediaSuccess(mediaList) {
  return { type: types.INITIALIZES_TOOLS_MEDIA_LIST, mediaList }
}
export function loadToolsQuestionnairesSuccess(questionnairesList) {
  return {
    type: types.INITIALIZES_TOOLS_QUESTIONNAIRES_LIST,
    questionnairesList,
  }
}
export function loadToolsConsentFormsSuccess(consentFormList) {
  return {
    type: types.INITIALIZES_CONSENT_FORM_LIST,
    consentFormList,
  }
}
export function createNewMediaItemSuccess(media) {
  return {
    type: types.SAVE_NEW_TOOLS_MEDIA_ITEM,
    media,
  }
}
export function createConsentFormItemSuccess(item) {
  return {
    type: types.SAVE_NEW_TOOLS_CONSENT_FORM,
    item,
  }
}
export function createNewQuestionnaireSuccess(item) {
  return {
    type: types.SAVE_NEW_TOOLS_QUESTIONNAIRE,
    item,
  }
}
export function updateConsentFormItemSuccess(item) {
  return {
    type: types.UPDATE_TOOLS_CONSENT_FORM,
    item,
    id: item.id,
  }
}
export function updateMediaItemSuccess(media, id) {
  return {
    type: types.UPDATE_TOOLS_MEDIA_ITEM,
    media,
    id,
  }
}
export function updateQuestionnaireItemSuccess(item) {
  return {
    type: types.UPDATE_TOOLS_QUESTIONNAIRE,
    item,
    id: item.id,
  }
}
export function loadToolsMedia() {
  return (dispatch) => {
    return toolsApi
      .getToolsMedia()
      .then((tools) => {
        dispatch(loadToolsMediaSuccess(tools))
      })
      .catch((err) => {
        if (err) {
          if (err === 'Bad token') {
            storageConfig.clearStorage()
            dispatch(globalAction.initializeAppState())
          }
          console.error('Get programs failed failed:   ', err)
        }
      })
  }
}
export function loadToolsQuestionnaires() {
  return (dispatch) => {
    toolsApi
      .getToolsQuestionnaires()
      .then((tools) => {
        dispatch(loadToolsQuestionnairesSuccess(tools))
      })
      .catch((err) => {
        if (err) {
          if (err === 'Bad token') {
            storageConfig.clearStorage()
            dispatch(globalAction.initializeAppState())
          }
          console.error('Get programs failed failed:   ', err)
        }
      })
  }
}
export function loadToolsConsentForms() {
  return (dispatch) => {
    return toolsApi
      .getToolsConsentForms()
      .then((tools) => {
        dispatch(loadToolsConsentFormsSuccess(tools))
      })
      .catch((err) => {
        if (err) {
          if (err === 'Bad token') {
            storageConfig.clearStorage()
            dispatch(globalAction.initializeAppState())
          }
          console.error('Get programs failed failed:   ', err)
        }
      })
  }
}

export function createMediaItem(item, cb) {
  return (dispatch) => {
    return toolsApi
      .saveMediaItem(item)
      .then((media) => {
        dispatch(createNewMediaItemSuccess(media))
        dispatch(
          globalAction.addUserMessage('success', 'save media item success'),
        )
        cb({ ok: true })
      })
      .catch((err) => {
        if (err) {
          if (err === 'Bad token') {
            storageConfig.clearStorage()
            dispatch(globalAction.initializeAppState())
          } else {
            dispatch(globalAction.addUserMessage('warning', err))
            cb({ ok: false, err })
          }
        }
      })
  }
}
export function updateMediaItem(item, id, cb) {
  return (dispatch) => {
    return toolsApi
      .updateMediaItem(item, id)
      .then((media) => {
        dispatch(updateMediaItemSuccess(media, id))
        dispatch(
          globalAction.addUserMessage('success', 'update media item success'),
        )
        cb({ ok: true })
      })
      .catch((err) => {
        if (err) {
          if (err === 'Bad token') {
            storageConfig.clearStorage()
            dispatch(globalAction.initializeAppState())
          } else {
            dispatch(globalAction.addUserMessage('warning', err))
            cb({ ok: false, err })
          }
        }
      })
  }
}

export function createQuestionnaireItem(item, cb) {
  return (dispatch) => {
    return toolsApi
      .saveQuestionnaireItem(item)
      .then((questionnaire) => {
        // Todo need add that questionnaire to questionnaire array.
        createNewQuestionnaireSuccess(questionnaire)
        dispatch(
          globalAction.addUserMessage(
            'success',
            'create questionnaire item success',
          ),
        )
        cb({ ok: true })
      })
      .catch((err) => {
        if (err) {
          if (err === 'Bad token') {
            storageConfig.clearStorage()
            dispatch(globalAction.initializeAppState())
          } else {
            dispatch(
              globalAction.addUserMessage(
                'warning',
                'Failed to save questionnaire',
              ),
            )
            console.error('save new questionnaire item has failed:   ', err)
            cb({ ok: false, err })
          }
        }
      })
  }
}
export function saveEditQuestionnaireItem(item, id, cb) {
  return (dispatch) => {
    return toolsApi
      .editQuestionnaireItem(item, id)
      .then((questionnaire) => {
        /**
         * Not need update the react because every time when open the edit dialog we get the questionnaire.
         * */
        let { name, type, id } = questionnaire
        dispatch(updateQuestionnaireItemSuccess({ name, type, id }))
        dispatch(
          globalAction.addUserMessage(
            'success',
            'edit questionnaire item success',
          ),
        )
        cb({ ok: true })
      })
      .catch((err) => {
        if (err) {
          if (err === 'Bad token') {
            storageConfig.clearStorage()
            dispatch(globalAction.initializeAppState())
          } else {
            dispatch(
              globalAction.addUserMessage(
                'warning',
                'Failed to update questionnaire',
              ),
            )
            console.error('save new questionnaire item has failed:   ', err)
            cb({ ok: false, err })
          }
        }
      })
  }
}

export function saveNewConsentForm(item, cb) {
  return (dispatch) => {
    return toolsApi
      .createNewConsentForm(item)
      .then((consentForm) => {
        dispatch(
          globalAction.addUserMessage('success', 'Create const form success'),
        )
        // Todo need add that consent from to list.
        dispatch(createConsentFormItemSuccess(consentForm))
        cb({ ok: true })
      })
      .catch((err) => {
        // globalAction
        if (err) {
          if (err === 'Bad token') {
            storageConfig.clearStorage()
            dispatch(globalAction.initializeAppState())
          } else {
            dispatch(
              globalAction.addUserMessage(
                'warning',
                'Failed to save consent from',
              ),
            )
            cb({ ok: false, err })
          }
        }
      })
  }
}
export function saveEditConsentForm(item, cb) {
  let cfId = item.id
  let consentFormItem = {
    title: item.title,
    aliasName: item.aliasName,
    body: item.body,
    bodyFormat: item.bodyFormat,
  }
  return (dispatch) => {
    return toolsApi
      .saveExitsConsentForm(consentFormItem, cfId)
      .then((consentForm) => {
        dispatch(
          globalAction.addUserMessage('success', 'Update const form success'),
        )
        // Todo need add that consent from to list.
        dispatch(updateConsentFormItemSuccess(consentForm))
        cb({ ok: true })
      })
      .catch((err) => {
        // globalAction
        if (err) {
          if (err === 'Bad token') {
            storageConfig.clearStorage()
            dispatch(globalAction.initializeAppState())
          } else {
            dispatch(
              globalAction.addUserMessage(
                'warning',
                'Failed to update consent from',
              ),
            )
            cb({ ok: false, err })
          }
        }
      })
  }
}
