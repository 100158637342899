import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import TableComponent from '../common/Table/table'
import SearchInput from '../common/SearchInput'
import ButtonNew from '../common/Button/ButtonNew'
import {
  changeDrChronoId,
  editProfile,
  getTreatmentProfile,
  getTreatments,
  getTreatmentsCount,
  toggleIntegration,
} from '../../api/userApi'
import EditPatientPopup from './EditPatientPopup'
import { addUserMessage } from '../../redux/actions/globalAction'
import { loadTenants } from '../../redux/actions/tenantsActions'
import UserPicture from './UserPicture'
import DrChronoPopup from './DrChronoPopup'
import { integrationStatusEnum } from '../utilities/enum'
import { openContent } from '../utilities'

const Treatments = ({ tenants, addUserMessage, loadTenants }) => {
  const [originalData, setOriginalData] = useState()
  const [data, setData] = useState()
  const [searchText, setSearchText] = useState()
  const [page, setPage] = useState(1)
  const [count, setCount] = useState(0)
  const [pageNum, setPageNum] = useState()
  const [patientData, setPatientData] = useState(false)
  const [drChronoEdited, setDrChronoEdited] = useState(false)
  const PAGE_SIZE = 30

  const options = {
    rowMenu: [
      {
        title: 'Edit patient',
        onclick: (data) => {
          openContent(
            `https://${process.env.CARE_MANAGER_UI_DOMAIN}/caseload/treatment/${data.id}/basic-info`,
          )
        },
      },
      {
        title: 'Turn on integration',
        getVisible: (data) => {
          return (
            data.isWellness &&
            data.integrationStatus === integrationStatusEnum.inactive
          )
        },
        onclick: (_data) => {
          toggleIntegration(_data.id, true).then((integration) => {
            updateTreatmentIntegration(integration)
            addUserMessage('success', 'Integration status is on.')
          })
        },
      },
      {
        title: 'Turn off integration',
        getVisible: (data) => {
          return (
            data.isWellness &&
            data.integrationStatus === integrationStatusEnum.active
          )
        },
        onclick: (_data) => {
          toggleIntegration(_data.id, false).then((integration) => {
            updateTreatmentIntegration(integration)
            addUserMessage('success', 'Integration status is off.')
          })
        },
      },
      {
        title: 'Change DrChrono ID',
        getVisible: (data) => {
          return data.isWellness
        },
        onclick: (data) => {
          setDrChronoEdited(data.id)
        },
      },
    ],
  }

  const getData = (_data) => {
    return _data.map((t) => {
      const obj = {
        id: t.id,
        patientId: t.patientId,
        userName: `${t.profile.firstName} ${t.profile.lastName}`,
        email: t.profile.email,
        mrn: t.profile.mrn,
        phone: t.profile.phone,
        picture: t.profile.picture,
        program: t.program.name,
        isWellness: t.program.isWellness || true,
        tenant: tenants ? tenants[t.program.tenantId] : '',
      }

      if (t.integration) {
        obj.drChronoId = t.integration.drChronoId
        obj.integrationStatus = t.integration.isIntegrationActive
          ? integrationStatusEnum.active
          : integrationStatusEnum.inactive
      }

      return obj
    })
  }

  const callSearchText = () => {
    setPage(1)
    callData(1)
  }

  const callData = (_page = page, _searchText = searchText) => {
    if (_page > pageNum) {
      _page = pageNum
      setPage(pageNum)
    }

    const payload = {
      searchText: _searchText,
      pageSize: PAGE_SIZE,
      pageNumber: _page,
    }

    getTreatments(payload).then((_data) => {
      setData(getData(_data))
      setOriginalData(_data)
    })

    getTreatmentsCount(payload).then((_c) => {
      setCount(_c)
      setPageNum(Math.ceil(_c / PAGE_SIZE))
    })
  }

  useEffect(() => {
    if (!tenants) {
      loadTenants()
    }

    callData()
  }, [])

  useEffect(() => {
    if (tenants) {
      if (originalData && originalData.length) {
        setData(getData(originalData))
      }
    }
  }, [originalData, tenants])

  const header = [
    {
      key: 'picture',
      name: ' ',
      type: 'customize',
      // eslint-disable-next-line react/display-name
      getCell: (cellData) => {
        return <UserPicture data={cellData} />
      },
    },
    {
      key: 'userName',
      name: 'Name',
      type: 'text',
    },
    {
      key: 'id',
      name: 'ID',
      type: 'text',
    },
    {
      key: 'drChronoId',
      name: 'DrChrono ID',
      type: 'text',
    },
    {
      key: 'integrationStatus',
      name: 'Integration status',
      type: 'text',
    },
    {
      key: 'mrn',
      name: 'mrn',
      type: 'text',
    },
    {
      key: 'phone',
      name: 'Phone',
      type: 'text',
    },
    {
      key: 'email',
      name: 'Email',
      type: 'text',
    },
    // { //Noam T. said that it is not needed (18.8.2021)
    //   key: "deviceType",
    //   name: "Device type",
    //   type: "text"
    // },
    {
      key: 'tenant',
      name: 'Tenant',
      type: 'text',
    },
    {
      key: 'program',
      name: 'Program',
      type: 'text',
    },
  ]

  const getPagination = () => {
    return `Showing ${PAGE_SIZE * (page - 1) + 1} - ${
      count >= PAGE_SIZE
        ? PAGE_SIZE * page - (PAGE_SIZE - data.length)
        : data.length
    } of ${count >= PAGE_SIZE ? count : data.length}`
  }

  if (!data) return <div />

  const updateTreatmentIntegration = (integration) => {
    if (!integration) return

    setData(
      data.map((d) => {
        if (d.id !== integration.treatmentId) return d

        return {
          ...d,
          drChronoId: integration.drChronoId,
          integrationStatus: integration.isIntegrationActive
            ? integrationStatusEnum.active
            : integrationStatusEnum.inactive,
        }
      }),
    )
  }

  return (
    <div className="treatment-page">
      {drChronoEdited && (
        <DrChronoPopup
          callback={(id) => {
            changeDrChronoId(drChronoEdited, id).then((integration) => {
              if (!integration) {
                addUserMessage('error', 'Error occurred.')
                return
              }

              updateTreatmentIntegration(integration)
              setDrChronoEdited(false)
              addUserMessage('success', 'DrChrono ID has been changed.')
            })
          }}
          handleClose={() => {
            setDrChronoEdited(false)
          }}
        />
      )}
      {patientData && (
        <EditPatientPopup
          editedPatient={patientData}
          handleCancelEdit={() => {
            setPatientData()
          }}
          updatePatient={(_data, _id) => {
            editProfile(_id, _data).then((res) => {
              if (res.ok) {
                const _pat = res.data
                setData(
                  data.map((_t) => {
                    if (_t.id !== _id) return _t

                    return {
                      id: _id,
                      patientId: _t.patientId,
                      userName: `${_pat.firstName} ${_pat.lastName}`,
                      email: _pat.email,
                      mrn: _pat.mrn,
                      phone: _pat.phone,
                      picture: _t.picture,
                      program: _t.program,
                      tenant: _t.tenant,
                    }
                  }),
                )

                addUserMessage('success', 'Patient successfully updated.')
                setPatientData()
              } else {
                addUserMessage('error', 'Error occurred.')
              }
            })
          }}
        />
      )}
      <div className="treatment-header">
        <SearchInput
          handleChange={(txt) => {
            setSearchText(txt)
          }}
          handleKeyPress={(e) => {
            if (e.keyCode && e.keyCode !== 13) return
            callSearchText()
          }}
          handleErase={() => {
            callData(1, '')
          }}
        />
        <ButtonNew
          type="primary"
          text="Search"
          onClick={() => {
            callSearchText()
          }}
        />
      </div>
      <TableComponent header={header} data={data} options={options} />
      <div className="treatment-footer">
        {data && data.length <= count && (
          <div className="footer">
            <div className="left-hide">{getPagination()}</div>
            <div className="right-hide hide-arrow">
              {'page'}{' '}
              <input
                type="number"
                value={page}
                onChange={(e) => {
                  const value = parseInt(e.target.value)
                  if (page !== value) setPage(value)
                }}
                onBlur={() => {
                  callData()
                }}
                onKeyUp={(e) => {
                  if (e.keyCode && e.keyCode !== 13) return
                  callData()
                }}
              />{' '}
              of {pageNum}{' '}
              <div className="page-arrows">
                <span
                  className={`arrow-left${page !== 1 ? '' : ' disabled'}`}
                  onClick={() => {
                    callData(page - 1)
                    setPage(page - 1)
                  }}
                />
                <span
                  className={`arrow-right${
                    page !== pageNum ? '' : ' disabled'
                  }`}
                  onClick={() => {
                    callData(page + 1)
                    setPage(page + 1)
                  }}
                />
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

function mapStateToProps(state, ownProps) {
  const tenant = {}
  if (state.tenantReducer && state.tenantReducer.length) {
    for (let i = 0; i < state.tenantReducer.length; i++) {
      const element = state.tenantReducer[i]
      const { id } = element

      tenant[id] = element.name
    }
  }

  return {
    tenants:
      state.tenantReducer && state.tenantReducer.length ? tenant : undefined,
  }
}

const mapDispatchToProps = {
  addUserMessage,
  loadTenants,
}

export default connect(mapStateToProps, mapDispatchToProps)(Treatments)
